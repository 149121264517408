import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';

// componentes
import { Button, Card, Row, Col, Form, Tab, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TeiaSvg from './TeiaSvg';

// componente
export default function Cadastrar(){

    // carrega os dados
    const history = useHistory();
    const { id, idpaciente, idagendamento } = useParams();
    let [carregando, alterarCarregando] = useState(false);
    let [modo, alterarModo] = useState('cadastro'); // cadastro, edicao
    let [dados, alterarDados] = useState({
        pk_cad_teia_interrelacao_metabolica: id || null,
        opcao_1: 'N',
        opcao_2: 'N',
        opcao_3: 'N',
        opcao_4: 'N',
        opcao_5: 'N',
        opcao_6: 'N',
        opcao_7: 'N',
        opcao_8: 'N'
    });
    let [salvando, alterarSalvando] = useState(false);
    
    // inicializa
    useEffect(() => {

        // verifica se é edição
        if(dados.pk_cad_teia_interrelacao_metabolica !== null){
            alterarModo('edicao');
            consultarId();
        }else{
            alterarDados({
                ...dados,
                fk_cad_paciente: idpaciente,
                fk_cad_agenda: idagendamento
            });
        }

    }, []);

    // consulta id do registro
    async function consultarId(){
        alterarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/teiaInterRelacaoMetabolica/${dados.pk_cad_teia_interrelacao_metabolica}`);
            alterarDados({
                ...dados,
                ...data.dados
            });
            
            // finaliza
            alterarCarregando(false);

        }catch({response}){}
    }

    // salva
    async function salvar(){
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = axios.post(`/teiaInterRelacaoMetabolica`, dados);
            alterarSalvando(false);
            retornar(true);

        }catch({response}){}
    }

    // volta a tela anteiror
    function retornar(paraListagem = false){
        history.goBack();
        // if(match.params.id || paraListagem){
        //     history.push(`/painel/paciente/${match.params.idpaciente}/atender/agendamento/${match.params.idagendamento}/teia-metabolica`);
        // }else{
        //     history.push(`/painel/paciente/${match.params.idpaciente}/atender/agendamento/${match.params.idagendamento}`);
        // }
    }

    // carregando
    if(carregando){
        return <div>Carregando...</div>
    }

    return <Row>
        <Col lg='12'>
            <Card className='border-0 shadow-sm'>
                <Card.Header className='border-0 bg-white'>
                    <Card.Title>Cadastro Teia de Inter Relação Metabolica</Card.Title>
                </Card.Header>
                <Card.Body className='text-center'>
                    <TeiaSvg 
                        value={dados}
                        onChange={(campo, valor) => {
                            dados[campo] = valor ? 'S' : 'N';
                            alterarDados({...dados});
                        }}
                    />
                </Card.Body>
            </Card>
            <SalvarFormulario 
                salvando={salvando}
                cancelar={() => {
                    // history.push('/painel/pacientes');
                    retornar();
                }}
                onClick={() => salvar()}
            />
        </Col>
    </Row>
}

// salva
function SalvarFormulario({
    salvando,
    cancelar,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button variant="success" disabled={salvando} onClick={onClick}>
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <span>Salvar</span>
            }
        </Button>
    </Form.Group>
}
