import React, { useState, useEffect, useContext, useRef } from 'react';
import styles from './index.module.scss';

// importa icones
import iconeReceita2 from './../../../../../../OpcoesTab/iconeReceita2.png';
import iconeSuplemento2 from './../../../../../../OpcoesTab/iconeSuplemento2.png';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MedidaCaseira from './MedidaCaseira';
import Textarea from 'react-textarea-autosize';

// importa contexto
import { PlanoAlimentarContexto } from './../../../../../../../../../ComponentePlanoAlimentar';

// apresentar os dados do item
function ItemDados({
    dados
}){
    
    // contexto
    let planoDados = useContext(PlanoAlimentarContexto);

    // estados
    let [editar, alterarEditar] = useState(false);
    let [posicaoCursor, alterarPosicaoCursor] = useState(null); 

    // referencias
    // let editor = React.createRef();
    let editor = useRef();

    useEffect(() => {
        // console.log(dados.tipo);
        // alterarEditar(false);
    }, [dados.tipo]);


    useEffect(() => {

        if(editar && posicaoCursor === null){

            // altera posição do cursor
            let posCursor = [dados.descricao.length, dados.descricao.length];
            alterarPosicaoCursor(posCursor)
            editor.current.setSelectionRange(...posCursor);

            // texto generico
            if(dados.descricao === 'Clique para editar!'){
                editor.current.select();
            }

        }else if (editar){
            editor.current.setSelectionRange(...posicaoCursor);
        }

    }, [editar])

    return <div>
        
        { dados.categoria === 'R' &&
            <>
                {dados._dadosReceita.tipo === 'R' &&
                    <img className={[styles.icone, 'mr-1'].join(' ')} src={iconeReceita2} />
                }

                {dados._dadosReceita.tipo === 'S' &&
                    <img className={[styles.icone, 'mr-1'].join(' ')} src={iconeSuplemento2} />
                }

                {dados._dadosReceita.tipo === 'F' &&
                    // <img className={[styles.icone, 'mr-1'].join(' ')} src={iconeSuplemento2} />
                    <FontAwesomeIcon className={[styles.icone, styles.iconeFA, 'mr-1 fa-sm'].join(' ')} icon={["fas", 'capsules']} />
                }
            </>
        }

        {/* descrição do item */}
        { !editar &&
            <div
                className={styles.descricaoItem}
                onClick={() => {

                    // se for texto habilita para edição
                    if(dados.tipo === 'T'){
                        alterarEditar(true);
                    }
                }}
            >
                {dados.descricao}
            </div>
        }

        { editar &&
            <Textarea 
                // ref={editor}
                inputRef={editorRef => {
                    editor.current = editorRef;
                }}
                placeholder='Escreva seu texto!'
                className={styles.textarea}
                hidden={!editar}
                autoFocus
                onBlur={() => {alterarEditar(false)}}
                value={dados.descricao || ''}
                onChange={e => {
                    alterarPosicaoCursor([e.target.selectionStart, e.target.selectionStart]);
                    dados.descricao = e.target.value;
                    planoDados.alterarRefeicoes([...planoDados.refeicoes]);
                }}
                onClick={e => {
                    alterarPosicaoCursor([e.target.selectionStart, e.target.selectionStart]);
                }}
                onKeyDown={e => {
                    if(e.keyCode === 13){
                        alterarEditar(false);
                    }
                }}
            />
        }        

        {/* se for do tipo alimento, mostra opção para medida caseira */}
        { dados.categoria === 'A' &&
            <div className={styles.medidasCaseira}>
                <MedidaCaseira 
                    dados={dados}
                />
            </div>
        }

    </div>

}

export default ItemDados;