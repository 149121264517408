

export default function(state = Object.assign({
    logado: false,
    dadosUsuario: {},
    dadosEmpresa: {},
    dadosUsuarioEmpresa: {}
}, JSON.parse(window.localStorage.getItem('login'))), action){

    // ações globais
    switch(action.type){
        case 'loginReducer/FAZER_LOGIN':

            let dadosAtualizar = {
                logado: true,
                dadosUsuario: action.dadosUsuario, // guardar os dados do usuário
                dadosEmpresa: action.dadosEmpresa,
                dadosUsuarioEmpresa: action.dadosUsuarioEmpresa,
                // horaLogin: action.horaLogin
            };
            window.localStorage.setItem('login', JSON.stringify(dadosAtualizar));
            return Object.assign({}, state, dadosAtualizar);
            
        case 'loginReducer/FAZER_LOGGOF':
            window.localStorage.removeItem('login');
            return Object.assign({}, state, {
                logado: false,
                dadosUsuario: {},
                dadosEmpresa: {},
                dadosUsuarioEmpresa: {}
            });
        case 'loginReducer/ALTERAR_EMPRESA':
            let atualizarTrocaEmpresa = Object.assign({}, state, {
                // dadosUsuario: state.dadosUsuario, 
                dadosEmpresa: action.dadosEmpresa,
                dadosUsuarioEmpresa: action.dadosUsuarioEmpresa,
            });
            window.localStorage.setItem('login', JSON.stringify(atualizarTrocaEmpresa));
            return atualizarTrocaEmpresa;//Object.assign({}, state, dadosAtualizar);
        default: 
            return state
    }
}