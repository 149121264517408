import React, { useState, useEffect } from 'react';
import { Route, withRouter, useParams, useHistory, useLocation } from 'react-router-dom';
import styles from './index.module.scss';
import queryString from 'query-string';


import AnamneseConsultar, { AnamneseBotaoCadastro, anamenseTitulo } from './../../Anamnese/Consultar';
import RastreamentoMetabolicoConsultar, { RastreamentoMetabolicoBotaoCadastro, RastreamentoMetabolicoTitulo } from './../../RastreamentoMetabolico/Consultar';
import TeiaInterRelacaoMetabolicaConsultar, { TeiaMetabolicaBotaoCadastro, TeiaMetabolicaTitulo } from './../../TeiaInterRelacaoMetabolica/Consultar';
import AvaliacaoAntropometricaConsultar, { AvaliacaoAntropometricaBotaoCadastro, AvaliacaoAntropometricaTitulo } from './../../AvaliacaoAntropometrica/Consultar';
import BioimpedanciaMagneticaConsultar, { BioimpedanciaMagneticaBotaoCadastro, BioimpedanciaMagneticaTitulo } from './../../BioimpedanciaMagnetica/Consultar';
import SolicitacaoExameConsultar, { SolicitacaoExameBotaoCadastro, SolicitacaoExameTitulo } from './../../SolicitacaoExame/Consultar';
import PlanoAlimentarConsultar, { PlanoAlimentarBotaoCadastroNovo, PlanoAlimentarTitulo } from './../../PlanoAlimentar/Consultar';

// componentes
import Opcao from './Opcao';

// opções de atendimento
export default function OpcoesAtendimento({
    minificado=false,
    abaVoltar=null,
    idagendamento=null,
    idpaciente=null,
    ...props
}){

    // estados
    const history = useHistory();
    const params = useParams();
    const location = useLocation();
    let [aba, alterarAba] = useState(null);
    let [dados, alterarDados] = useState({
        idagendamento: idagendamento || params.idagendamento,
        idpaciente: idpaciente || params.idpaciente,
    });

    useEffect(() => {
        
        // // verifica se foi setado uma aba
        let parametros = queryString.parse(location.search);
        if(parametros.aba){
            alterarAba(parametros.aba);
        }

    }, []);

    let urlBase = `/painel/paciente/${dados.idpaciente}/atender/agendamento/${dados.idagendamento}`;
    let urlAba = `${urlBase}?aba=`;

    // função para troca de abas
    function trocarAba(novaAba){

        if(minificado){
            history.push(`${urlBase}/${novaAba}`);
        }else{
            history.push(urlAba + novaAba);
            alterarAba(novaAba);
        }
    }

    // mostra as opções de atendimento
    return <div className={[styles.container, 'rounded bg-light', minificado && 'mr-2'].join(' ')}>
        { minificado &&
            <Opcao 
                minificado={minificado}
                abrir={() => {                  
                    if(abaVoltar){
                        history.push(urlAba + abaVoltar);
                    }else{
                        history.push(urlBase);
                    }
                }}
                titulo={{
                    icone: ['fas', 'chevron-left'],
                    descricao: 'Voltar'
                }}
            />
        }
         
        <Opcao 
            minificado={minificado}
            abrir={() => {trocarAba('anamnese'); }}
            expandir={aba === 'anamnese'}
            ativo={abaVoltar === 'anamnese'}
            verMais={() => {history.push(`/painel/paciente/${dados.idpaciente}/atender/agendamento/${dados.idagendamento}/anamnese`)}}
            botaoCadastro={<AnamneseBotaoCadastro history={history} idpaciente={dados.idpaciente} idagendamento={dados.idagendamento}  />}
            titulo={anamenseTitulo()}
        >
            <AnamneseConsultar expandido={true} idpaciente={dados.idpaciente} idagendamento={dados.idagendamento} history={history} />
        </Opcao>
        
        <Opcao 
            minificado={minificado}
            abrir={() => {trocarAba('rastreamento-metabolico'); }}
            expandir={aba === 'rastreamento-metabolico'}
            ativo={abaVoltar === 'rastreamento-metabolico'}
            verMais={() => {history.push(`/painel/paciente/${dados.idpaciente}/atender/agendamento/${dados.idagendamento}/rastreamento-metabolico`)}}
            botaoCadastro={<RastreamentoMetabolicoBotaoCadastro history={history} idpaciente={dados.idpaciente} idagendamento={dados.idagendamento}  />}
            titulo={RastreamentoMetabolicoTitulo()}
        >
            <RastreamentoMetabolicoConsultar expandido={true} idpaciente={dados.idpaciente} idagendamento={dados.idagendamento} history={history} />
        </Opcao>

        <Opcao 
            minificado={minificado}
            abrir={() => {trocarAba('teia-metabolica'); }}
            expandir={aba === 'teia-metabolica'}
            ativo={abaVoltar === 'teia-metabolica'}
            verMais={() => {history.push(`/painel/paciente/${dados.idpaciente}/atender/agendamento/${dados.idagendamento}/teia-metabolica`)}}
            botaoCadastro={<TeiaMetabolicaBotaoCadastro history={history} idpaciente={dados.idpaciente} idagendamento={dados.idagendamento}  />}
            titulo={TeiaMetabolicaTitulo()}
        >
            <TeiaInterRelacaoMetabolicaConsultar expandido={true} idpaciente={dados.idpaciente} idagendamento={dados.idagendamento} history={history} />
        </Opcao>
        
        <Opcao 
            minificado={minificado}
            abrir={() => {trocarAba('avaliacao-antropometrica'); }}
            expandir={aba === 'avaliacao-antropometrica'}
            ativo={abaVoltar === 'avaliacao-antropometrica'}
            verMais={() => {history.push(`/painel/paciente/${dados.idpaciente}/atender/agendamento/${dados.idagendamento}/avaliacao-antropometrica`)}}
            botaoCadastro={<AvaliacaoAntropometricaBotaoCadastro history={history} idpaciente={dados.idpaciente} idagendamento={dados.idagendamento}  />}
            titulo={AvaliacaoAntropometricaTitulo()}
        >
            <AvaliacaoAntropometricaConsultar expandido={true} idpaciente={dados.idpaciente} idagendamento={dados.idagendamento} history={history} />
        </Opcao>

        <Opcao 
            minificado={minificado}
            abrir={() => {trocarAba('bioimpedancia-magnetica'); }}
            expandir={aba === 'bioimpedancia-magnetica'}
            ativo={abaVoltar === 'bioimpedancia-magnetica'}
            verMais={() => {history.push(`/painel/paciente/${dados.idpaciente}/atender/agendamento/${dados.idagendamento}/bioimpedancia-magnetica`)}}
            botaoCadastro={<BioimpedanciaMagneticaBotaoCadastro history={history} idpaciente={dados.idpaciente} idagendamento={dados.idagendamento}  />}
            titulo={BioimpedanciaMagneticaTitulo()}
        >
            <BioimpedanciaMagneticaConsultar expandido={true} idpaciente={dados.idpaciente} idagendamento={dados.idagendamento} history={history} />
        </Opcao>

        <Opcao 
            minificado={minificado}
            abrir={() => {trocarAba('solicitacao-exame'); }}
            expandir={aba === 'solicitacao-exame'}
            ativo={abaVoltar === 'solicitacao-exame'}
            verMais={() => {history.push(`/painel/paciente/${dados.idpaciente}/atender/agendamento/${dados.idagendamento}/solicitacao-exame`)}}
            botaoCadastro={<SolicitacaoExameBotaoCadastro history={history} idpaciente={dados.idpaciente} idagendamento={dados.idagendamento}  />}
            titulo={SolicitacaoExameTitulo()}
        >
            <SolicitacaoExameConsultar expandido={true} idpaciente={dados.idpaciente} idagendamento={dados.idagendamento} history={history} />
        </Opcao>

        <Opcao 
            minificado={minificado}
            abrir={() => {trocarAba('plano-alimentar'); }}
            expandir={aba === 'plano-alimentar'}
            ativo={abaVoltar === 'plano-alimentar'}
            verMais={() => {history.push(`/painel/paciente/${dados.idpaciente}/atender/agendamento/${dados.idagendamento}/plano-alimentar`)}}
            botaoCadastro={<PlanoAlimentarBotaoCadastroNovo history={history} idpaciente={dados.idpaciente} idagendamento={dados.idagendamento}  />}
            titulo={PlanoAlimentarTitulo()}
        >
            <PlanoAlimentarConsultar expandido={true} idpaciente={dados.idpaciente} idagendamento={dados.idagendamento} history={history} />
        </Opcao>
    </div>

}