import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';

// componentes
import { Card, Table, Button } from 'react-bootstrap';
import OpcoesAtendimento from './../../Atender/OpcoesAtendimento';
import Registro from './Registro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// botão para cadastro
export function TeiaMetabolicaBotaoCadastro({
    idpaciente,
    idagendamento
}){
    
    // estados
    const history = useHistory();

    return <Button
        variant='padrao'
        size='sm'
        onClick={e => {
            e.stopPropagation();
            history.push(`/painel/paciente/${idpaciente}/teia-metabolica/cadastrar/${idagendamento}`);
        }}
    >
        <FontAwesomeIcon className="icone" icon={["fas", "plus"]}></FontAwesomeIcon>
        <span>Cadastrar</span>
    </Button>
}

// dados do titulo
export function TeiaMetabolicaTitulo(){

    return {
        icone: ['fab', 'connectdevelop'],
        descricao: 'Teia Inter Relação Metabólica'
    }
}

// componente
export default function Consultar({
    idpaciente=null,
    idagendamento=null,
    expandido=false
}){

    // estados
    const history = useHistory();
    const params = useParams();
    let [parametros, alterarParametros] = useState({
        // pagina: 1,
        // registrosPorPagina: 10,
        fk_cad_paciente: idpaciente || params.idpaciente
    });
    let [consulta, alterarConsulta] = useState([]);

    // inicializa
    useEffect(() => {

        // faz consulta
        consultarRegistros();

    }, [parametros]);

    // registros
    async function consultarRegistros(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/teiaInterRelacaoMetabolica`, { params: parametros });
            alterarConsulta(data.registros);

        }catch({response}){}


    }


    return <div className={styles.atendimentoConsulta}>
        { !expandido &&
            <div>
                <OpcoesAtendimento 
                    minificado={true}
                    abaVoltar='teia-metabolica'
                    idpaciente={idpaciente}
                    idagendamento={idagendamento}
                />
            </div>
        }
        <div className={styles.containerPainel}>
            <Card className={'painel-consulta border-0 shadow-sm'}>
                <Card.Header className='d-flex cabecalho-atendimento'>
                    <h4 className='flex-grow-1'>
                        <FontAwesomeIcon className="icone mr-2" icon={TeiaMetabolicaTitulo().icone} />
                        <span>{TeiaMetabolicaTitulo().descricao}</span>
                    </h4>
                    <div>
                        <TeiaMetabolicaBotaoCadastro 
                            idpaciente={parametros.fk_cad_paciente}
                            idagendamento={idagendamento || params.idagendamento}
                            history={history}
                        />
                    </div>
                </Card.Header>
                <Card.Body>
                    <Table hover className="tabela">
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th></th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            { consulta.map(registro =>  
                                <Registro 
                                    key={registro.dados.pk_cad_teia_interrelacao_metabolica}
                                    dados={registro.dados}
                                    parametros={parametros}
                                    clicouEditar={() => {
                                        history.push(`/painel/paciente/${parametros.fk_cad_paciente}/agendamento/${idagendamento || params.idagendamento}/teia-metabolica/alterar/${registro.dados.pk_cad_teia_interrelacao_metabolica}`)
                                    }}
                                />
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </div>
    </div>

}
