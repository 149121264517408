import React from 'react';
import styles from './index.module.scss';

// componentes
import BotaoTabela from './../../../ComponentesCadastro/BotaoTabela';

// elemento registro
function Registro({
    dados,
    clicouEditar,
    onClickTr
}){
    
    return <tr onClick={onClickTr}>
        <td className="estreito text-center font-weight-bold">{dados.pk_cad_convenio}</td>
        <td>{dados.descricao}</td>
        <td className="text-nowrap estreito">
            <BotaoTabela 
                onClick={clicouEditar}
                icone={["fas", "pencil-alt"]}
            >Editar</BotaoTabela>
        </td>
    </tr>
}

export default Registro;