import React from 'react';
import styles from './index.module.scss';
import moment from 'moment';

// funções
// import { mascaraCpf } from './../../../../../../Funcoes';

// componente options
function OptionSelect({data, ...props}){

    // propriedades importantes
    let { innerProps, innerRef, isFocused } = props;

    // retorna componente
    return <div tabIndex='-1' ref={innerRef} {...innerProps} className={[styles.option, isFocused && styles.focado].join(' ')}>
        <p>{data.dados.descricao}</p>
        {/* <p>{mascaraCpf(data.cpf)} {data.data_nascimento ? `- ${moment(data.data_nascimento).format('DD/MM/YYYY')}` : ''}</p> */}
    </div>
}

// opção
export default OptionSelect;