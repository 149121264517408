import React from 'react';
import moment from 'moment';

// componentes
import { calcularPontuacao } from './../../Cadastrar';
import BotaoTabela from './../../../../ComponentesCadastro/BotaoTabela';
import { Badge } from 'react-bootstrap';

function Registro({
    dados,
    parametros,
    clicouEditar
}){

    return <tr>
        <td className="estreito text-center font-weight-bold">
            {moment(dados.data_cadastro).format('DD/MM/YYYY')}
        </td>
        <td className="estreito text-center">
            <Badge variant={calcularPontuacao(dados, 'cor')}>{calcularPontuacao(dados)}</Badge>
        </td>
        <td></td>
        <td className="text-nowrap estreito">
            <BotaoTabela 
                onClick={clicouEditar}
                icone={["fas", "pencil-alt"]}
            >
                Editar
            </BotaoTabela>
        </td>
    </tr>
}

export default Registro;