import React from 'react';
import styles from './index.module.scss';

// componentes
import { Row, Col, Card } from 'react-bootstrap';
import Plano from './Plano';

// copmonente
function Planos({
    formasPagamento,
    idFormaPagamento,
    alterou
}){

    // carrega os planos em uso

    // retorna
    return <div className='mt-4'>
        <Row className={[styles.planos, 'align-items-start'].join(' ')}>
            {formasPagamento.map(forma => 
                <Col
                    key={forma.pk_sis_forma_pagamento}
                >
                    <Plano 
                        meses={parseInt(forma.duracao_meses)}
                        descricao={forma.descricao}
                        valor={forma.valor}
                        mostralValorMensal={forma.duracao_meses === '1' ? false : true}
                        escolhido={() => {
                            alterou(forma.pk_sis_forma_pagamento)
                        }}
                        selecionado={idFormaPagamento === forma.pk_sis_forma_pagamento}
                    />
                </Col>
            )}
        </Row>
    </div>

}

//
export default Planos;