import React from 'react';
import styles from './index.module.scss';
import { Button } from 'react-bootstrap';

// paginação
function Paginacao(props){

    if(props.totalRegistros === 0){
        return '';
    }

    // calcula o total de páginas
    let totalPaginas = Math.ceil(props.totalRegistros / props.registrosPorPagina);

    // cria os botões
    let botoes = [];

    for(let a = 1;a <= totalPaginas;a++){

        if(((a - 2) <= props.pagina) && ((a + 2) >= props.pagina)){
            botoes.push(a);
        }
    }

    //botoes.push(totalPaginas);
    if(!botoes.includes(totalPaginas)){
        botoes.push(totalPaginas);
    }

    if(props.pagina > 3){
        botoes.unshift(1);
    }


    // retorna paginação
    return <div className={styles.paginacao}>
        {/* {totalPaginas} */}

        <Button 
            className={styles.botaoVoltar} 
            variant="padrao" 
            disabled={props.pagina === 1}
            onClick={() => {
                props.alterouPagina(props.pagina - 1);
            }}>Voltar</Button>

        <div className={styles.paginas}>
            {/* <Button className={styles.botao} variant="padrao">1</Button>
            <Button className={styles.botao} variant="padrao">2</Button>
            <Button className={styles.botao} variant="padrao">3</Button> */}

            {botoes.map((botao, a) => (
                <React.Fragment key={a} >
                    { ((totalPaginas === botao) && (props.pagina < botao - 3) ) && (
                        <span className={styles.espaco}>...</span>
                    )}

                    <Button 
                    onClick={() => {
                        props.alterouPagina(botao);
                    }}
                    disabled={props.pagina === botao}
                    className={[styles.botao, (props.pagina === botao)? styles.ativo : ''].join(' ')}

                    variant="padrao">
                        {botao}
                    </Button>

                    { (botao === 1 && props.pagina > 3) && (
                        <span className={styles.espaco}>...</span>
                    )}

                </React.Fragment>
            ))}


        </div>

        <Button 
            className={styles.botaoAvançar} 
            onClick={() => {
                props.alterouPagina(props.pagina + 1);
            }} 
            disabled={props.pagina === totalPaginas}
            variant="padrao">Avançar</Button>
    </div>
}

export default Paginacao;