import React, { useState, useEffect, PureComponent } from 'react';

// componentes
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip } from 'recharts';


const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, percent, index,
    }) => {
    
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    // console.log((percent * 100).toFixed(0));

    return <text 
        x={x} 
        y={y} 
        fill="white" 
        textAnchor={x > cx ? 'start' : 'end'} 
        dominantBaseline="central"
    >
        {`${(percent * 100).toFixed(0)}%`}
    </text>
};

// componente
export default function GraficoMassa({
    calculos
}){

    // variaveis
    const [graficoDados, alterarGraficoDados] = useState(null);

    // inicializa gráfico
    useEffect(() => {
        
        // altera os dados
        alterarGraficoDados([
            { name: 'Massa magra', value: parseFloat(calculos.massaMagra.toFixed(3)) },
            { name: 'Peso gordo', value: parseFloat(calculos.pesoGordo.toFixed(3)) },
        ]);

    }, [calculos]);


    // se não tiver sido inicializado ainda
    if(graficoDados === null){
        return <></>
    }


    return <ResponsiveContainer>
        <PieChart >
            <Pie
                isAnimationActive={false}
                data={graficoDados}
                labelLine={false}
                label={renderCustomizedLabel}
                fill="#8884d8"
                dataKey="value"
                >
                    <Cell 
                        // stackId={"a"}
                        name={'Peso gordo'}
                        // dataKey={'gordo'}
                        key={`cell-massa-gorda`} 
                        fill={'#7272e7'}
                        // stroke={'black'}
                    />
                    <Cell 
                        // stackId={"a"}
                        name={'Massa magra'}
                        // dataKey={'magra'}
                        key={`cell-massa-magra`} 
                        fill={'#f69300'} 
                        // stroke={'black'}
                    />
            </Pie>
            <Tooltip
            formatter={(value) => {
                return `${value.toString().replace('.', ',')} kg`
            }}
                contentStyle={{
                    padding: '0.25rem 0.5rem',
                    border: 'none',
                    borderRadius: '0.5rem',
                    background: '#dfdfdf',
                }}
                itemStyle={{
                    color: '#515151'
                }}
            />
        </PieChart>
    </ResponsiveContainer>
}