import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';

// componentes
import { Draggable } from 'react-beautiful-dnd';
import RefeicaoDescricao from './RefeicaoDescricao';
import BotaoOpcao from './../../BotaoOpcao';
import Opcoes from './Opcoes';

// contexto
import { PlanoAlimentarContexto } from './../../../../../ComponentePlanoAlimentar';

// componente
function Refeicao({
    dados,
    index
}){

    // contexto
    let planoAlimentarDados = useContext(PlanoAlimentarContexto);

    // estados
    let [editar, alterarEditar] = useState(false);

    return <Draggable 
        draggableId={`refeicao-${index}`} 
        index={index}
    >
        {(provided, snapshot2) =>  (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={[
                    styles.refeicao, 
                    'p-2 mr-3 rounded',
                    editar && styles.editando,
                    planoAlimentarDados.refeicaoFocada === dados && styles.focada
                ].join(' ')}
                onClick={() => {
                    
                    // focar refeição
                    planoAlimentarDados.alterarRefeicaoFocada(dados);

                }}
            >
                { editar &&
                    <RefeicaoDescricao 
                        value={dados.descricao}
                        onChange={novaDescricao => {
                            dados.descricao = novaDescricao;
                            planoAlimentarDados.alterarRefeicoes([...planoAlimentarDados.refeicoes]);
                        }}
                        onBlur={() => {alterarEditar(false)}}
                    />
                }
        
                { !editar &&
                    <div 
                        className='text-truncate font-weight-bold'
                        onClick={() => {
                            alterarEditar(true);
                        }}
                    >
                        {dados.descricao === ''? 'Inserir nome da refeição!' : dados.descricao}
                    </div>
                }

                {/* opções */}
                <Opcoes 
                    registros={dados._opcoes}
                    indexRefeicao={index}
                />

                {/* opção para excluir refeição */}
                <div 
                    className={styles.refeicaoMenuOpcoes}
                >
                    <BotaoOpcao 
                        onClick={() => {
                            planoAlimentarDados.refeicoes.splice(index, 1);
                            planoAlimentarDados.alterarRefeicoes([...planoAlimentarDados.refeicoes]);
                        }}
                        tooltip='Remover Refeição'
                        icone={["fas", "times"]}
                        placement='left'
                    />
                </div>

            </div>
        )}


    </Draggable>
}

export default Refeicao;