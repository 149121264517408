import React, { useEffect } from 'react';
import styles from './index.module.scss';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, ButtonToolbar, OverlayTrigger, Tooltip, Button  } from 'react-bootstrap';
import Telefone from './Telefone';

function Telefones({
    value,
    onChange
}){

    useEffect(() => {
        console.log(value);
    }, []);

    // [{"tipo":"celular","numero":"(48) 99925-8600","contato":"thiago"},{"tipo":"fixo","numero":"(48) 36220-250","contato":"Casa"}]
    

    // inserir telefone
    function inserir(tipo = 'celular'){ // fixo

        // insere novo tel
        let novoTel = {
            tipo: tipo,
            numero: '',
            contato: ''
        };
        value.push(novoTel);
        onChange(value);

    }

    return <Card className='mb-4 border-0 shadow-sm'>
        <Card.Header className={[styles.cabecalho, 'bg-white'].join(' ')}>
            <Card.Title as='h6' className={'mb-0'}>Telefones</Card.Title>
            <div className={styles.opcoes}>
                <ButtonToolbar className={styles.botao}>
                    <OverlayTrigger
                        placement={'top'}
                        overlay={<Tooltip>Adicionar telefone</Tooltip>}
                    >
                        <Button
                            size='sm'
                            variant='padrao'
                            className={[''].join(' ')}
                            onClick={() => {inserir('fixo')}}
                        >
                            <span>Telefone</span>
                            <FontAwesomeIcon className="icone fa-fw" icon={["fas", 'phone-alt']} />
                        </Button>
                    </OverlayTrigger>
                </ButtonToolbar>
                <ButtonToolbar className={styles.botao}>
                    <OverlayTrigger
                        placement={'top'}
                        overlay={<Tooltip>Adicionar Celular</Tooltip>}
                    >
                        <Button
                            size='sm'
                            variant='padrao'
                            className={[''].join(' ')}
                            onClick={() => {inserir()}}
                        >
                            <span>Celular</span>
                            <FontAwesomeIcon className="icone fa-fw" icon={["fas", 'mobile-alt']} />
                        </Button>
                    </OverlayTrigger>
                </ButtonToolbar>
            </div>
        </Card.Header>
        <Card.Body>
            { value.map((telefone, a) => 
                <Telefone 
                    key={a}
                    dados={telefone}
                    alterou={novoTel => {
                        Object.assign(telefone, novoTel);
                        onChange(value);
                    }}
                    excluir={() => {
                        value.splice(a, 1);
                        onChange(value);
                    }}
                />
            )}
        </Card.Body>
    </Card>
}

export default Telefones;