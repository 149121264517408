
// importa services
import AgendaServico from './../../../servicos/AgendaServico';

let dadosConsultaDefault = {
    data: null,//'2018-10-22', //22/10/2018
    dias: 2,
    evento: 'avancar', // voltar 
    iniciado: false,
    pk_cad_usuario_empresa: null
};

// define reducer de painel
export default function(state = {
    dadosConsulta: {...dadosConsultaDefault},
    registros: []
}, action){

    // ação
    switch(action.type){
        case 'agenda/ALTERAR_DADOS':
        
            state.dadosConsulta = {...state.dadosConsulta, ...action.dadosConsulta};
            return {...state};

        // case 'agenda/ALTERAR_REGISTROS':
        //     Object.assign(state.registros, action.registros);
        //     return {...state};

        // // apaga a data, fazendo ser refeita a pesquisa assim que iniciar
        case 'agenda/LIMPAR':
            state.dadosConsulta.pk_cad_usuario_empresa = null
            state.dadosConsulta = {...dadosConsultaDefault, data: null};
            state.registros = [];
            return {...state};
        default:
            return state;
    }
}
