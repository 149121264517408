import React from 'react';
import {  Draggable } from 'react-beautiful-dnd';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import styles from './index.module.scss';

function TipoExame({
    dados,
    index,
    onChange,
    checked,
    clicouEditar
}){

    return <Draggable draggableId={`item-${dados.pk_cad_tipo_exame}`} index={index}>
        {(provided, snapshot) => (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={styles.foco}
            >
                <div
                    className={['px-4 rounded py-1', styles.checkbox].join(' ')}
                >
                    <Form.Check
                        inline
                        label={dados.descricao}
                        id={`tipo-exame-${dados.pk_cad_tipo_exame}`}
                        onChange={e => {
                            onChange(dados.pk_cad_tipo_exame);
                        }}
                        checked={checked}
                    />
                    <FontAwesomeIcon 
                        icon={faPencilAlt} 
                        className={styles.icone} 
                        onClick={clicouEditar}
                    />
                </div>
            </div>
        )}
    </Draggable>

}

export default TipoExame;