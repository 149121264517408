import React from 'react';
import styles from './index.module.scss';

// componentes
import { Card, Button } from 'react-bootstrap';

// funções
import { converterReal } from './../../../../Funcoes';

// funcao
function Plano({
    meses,
    descricao,
    valor=0.0,
    enfase=false,
    mostralValorMensal=true,
    escolhido,
    selecionado
}){

    return <Card className={['border-0 shadow-sm', selecionado ? styles.selecionado : ''].join(' ')}>
        <Card.Header className='bg-transparent border-0 text-center'>
            <Card.Title as='h5' className='mb-0'>{descricao}</Card.Title>
        </Card.Header>
        <Card.Body className='pt-0'>
            <h3 className={['text-center my-0'].join(' ')}><small>R$</small> {converterReal(valor)}</h3>
            { mostralValorMensal &&
                <p className='text-center'>
                    <small>R$ {converterReal(valor / meses)} / mês</small>
                </p>
            }
            <div className='text-center mt-3'>
                <Button 
                    variant='success'
                    onClick={escolhido}
                    className={styles.botaoEscolher}
                    disabled={selecionado}
                >
                    Escolher
                </Button>
            </div>
        </Card.Body>
    </Card>

}

// retorna
export default Plano;