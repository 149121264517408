import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';

// componentes
import { Card, Table, Button } from 'react-bootstrap';
import OpcoesAtendimento from './../../Atender/OpcoesAtendimento';
import Registro from './Registro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// botão para cadastro
export function AnamneseBotaoCadastro({
    idpaciente,
    idagendamento,
    history
}){
    
    return <Button
        variant='padrao'
        size='sm'
        onClick={e => {
            e.stopPropagation();
            history.push(`/painel/paciente/${idpaciente}/anamnese/cadastrar/${idagendamento}`);
        }}
    >
        <FontAwesomeIcon className="icone" icon={["fas", "plus"]}></FontAwesomeIcon>
        <span>Cadastrar</span>
    </Button>
}

// dados do titulo
export function anamenseTitulo(){

    return {
        icone: ['far', 'list-alt'],
        descricao: 'Anamnese'
    }
}

export default function Anamnese({
    idpaciente=null,
    idagendamento=null,
    expandido=false
}){

    // estados
    const { LoginReducer } = useSelector(state => state);
    const history = useHistory();
    const params = useParams();
    let [parametros, alterarParametros] = useState({
        // pagina: 1,
        // registrosPorPagina: 10,
        fk_cad_paciente: idpaciente || params.idpaciente
    });
    let [consulta, alterarConsulta] = useState([]);

    // inicializa
    useEffect(() => {
        consultarRegistros();
    }, [parametros]);

    // faz a consulta
    async function consultarRegistros(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/anamnese`, { params: parametros });
            alterarConsulta(data.registros);

        }catch({response}){}

    }

    return <div className={styles.atendimentoConsulta}>
        { !expandido &&
            <div>
                <OpcoesAtendimento 
                    minificado={true}
                    abaVoltar='anamnese'
                    idpaciente={idpaciente}
                    idagendamento={idagendamento}
                />
            </div>
        }
        <div className={styles.containerPainel}>
            <Card className={'painel-consulta border-0 shadow-sm'}>
                <Card.Header className='d-flex cabecalho-atendimento'>
                    <h4 className='flex-grow-1'>
                        <FontAwesomeIcon className="icone mr-2" icon={anamenseTitulo().icone} />
                        <span>{anamenseTitulo().descricao}</span>
                    </h4>
                    <div>
                        <AnamneseBotaoCadastro 
                            idpaciente={parametros.fk_cad_paciente}
                            idagendamento={idagendamento || params.idagendamento}
                            history={history}
                        />
                    </div>
                </Card.Header>
                <Card.Body>
                    <Table hover className="tabela">
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th></th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            { consulta.map(registro =>  
                                <Registro 
                                    key={registro.dados.pk_cad_anamnese}
                                    dados={registro.dados}
                                    parametros={parametros}
                                    clicouEditar={() => {
                                        history.push(`/painel/paciente/${parametros.fk_cad_paciente}/agendamento/${idagendamento || params.idagendamento}/anamnese/alterar/${registro.dados.pk_cad_anamnese}`)
                                    }}
                                />
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </div>
    </div>
}

