import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import './PainelNavbar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavDropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

// componentes
import ModalEmpresas from './ModalEmpresas';

// navbar
export default function PainelNavbar(){

    // altera a troca de empresa
    const { LoginReducer } = useSelector(state => state);
    const dispatch = useDispatch();
    let [trocarEmpresa, alterarTrocarEmpresa] = useState(false);

    // clicou para fazer logoff
    function deslogar(e){

        // limpa agenda e desloga
        dispatch({ type: 'agenda/LIMPAR' });
        dispatch({ type: 'loginReducer/FAZER_LOGGOF' });
    }

    useEffect(() => {
        console.log(LoginReducer);
    }, []);

    return <>
        { trocarEmpresa &&
            <ModalEmpresas 
                // registro={concluirEvento}
                fechou={() => {
                    // alterarConcluirEvento(null);
                    alterarTrocarEmpresa(false);
                }}
                alterou={() => {
                    // fazerConsultarRegistros();
                }}
            />
        }
        <nav className="painel-navbar navbar navbar-expand-lg ">

            <div className="container-fluid">
                <p className={[styles.bemVindo, "navbar-brand"].join(' ')} >
                    Bem vindo
                </p>
                <a className="navbar-brand" href="/"></a>
                {/* <a className="navbar-brand" href="/">Agenda</a> */}

                

                <div className="collapse navbar-collapse justify-content-end">

                    {/* <ul className="nav navbar-nav mr-auto">

                        <li className="nav-item">
                            <Link className="nav-link" data-toggle="dropdown" to="/">
                                <FontAwesomeIcon className="icone" icon={["fas", "home"]}></FontAwesomeIcon>
                                <span className="d-lg-none">Inicio</span>
                            </Link>
                        </li>
                        <li className="dropdown nav-item show">
                            <NavDropdown className="nav-link nav-link-dropdown" title={(
                                <span className="">
                                    <FontAwesomeIcon className="icone" icon={["fas", "home"]}></FontAwesomeIcon>
                                    <b className="caret" />
                                    <span className="notification">{props.numero}</span>
                                </span>
                            )} >
                                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                            </NavDropdown>
                        </li>

                    </ul> */}

                    <ul className="navbar-nav mr-auto">

                        {/* se estiver dentro do plano de gratuito */}
                        { LoginReducer.dadosEmpresa.data_expira === null &&
                            <li className="nav-item">
                                <Link className={[styles.linkAssinar, "nav-link"].join(' ')} data-toggle="dropdown" to="/painel/assinatura">
                                    <span>Assine</span>
                                    <FontAwesomeIcon className="icone" icon={["far", "credit-card"]}></FontAwesomeIcon>
                                </Link>
                            </li>
                        }

                    </ul>

                    <ul className="navbar-nav ml-auto">
                        <div className={[styles.navbarBrand, 'pl-2'].join(' ')}>
                            {/* <div 
                                className={styles.iconeAlterarEmpresa}
                                onClick={() => {
                                    alterarTrocarEmpresa(true);
                                }}
                            >
                                <FontAwesomeIcon className={''} icon={["fas", "globe-americas"]}></FontAwesomeIcon>
                            </div> */}
                            <div>
                                <OverlayTrigger
                                    overlay={<Tooltip>Alterar empresa</Tooltip>}
                                    placement={'bottom'}
                                >
                                    <button
                                        className={styles.iconeAlterarEmpresa}
                                        onClick={() => {
                                            alterarTrocarEmpresa(true);
                                        }}
                                    >
                                        <FontAwesomeIcon className={'fa-2x'} icon={["fas", "globe-americas"]}></FontAwesomeIcon>
                                    </button>
                                </OverlayTrigger>
                            </div>
                            <div className={styles.info}>
                                <span className={styles.empresa}>
                                    { LoginReducer.dadosEmpresa.nome_fantasia ? 
                                        <>{LoginReducer.dadosEmpresa.nome_fantasia}</>
                                        :
                                        <>Minha clínica</>
                                    }
                                </span>
                                
                                <span className={styles.nome}>{LoginReducer.dadosUsuario.nome}</span>
                            </div>
                            { false ? // login.dadosCliente.foto
                                // <img src={`${process.env.PUBLIC_URL}/php/arquivos/fotos/${login.dadosCliente.foto}`} className={styles.foto} />
                                <></>
                                :
                                <FontAwesomeIcon className={styles.iconeUsuario} icon={["fas", "user-circle"]}></FontAwesomeIcon>
                            }

                            
                        </div>
                        <li className="nav-item">
                            <button className="nav-link botao-sair" onClick={deslogar}>
                                <FontAwesomeIcon className="icone" icon={["fas", "lock"]}></FontAwesomeIcon>
                                <span className="no-icon">Sair</span>
                            </button>
                        </li>
                    </ul>

                </div>
            </div>
            
        </nav>
    </>
}
