import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import axios from 'axios';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Container, Button, Form, Row, Col } from 'react-bootstrap';
import InputDecimal from './../../ComponentesCadastro/InputDecimal';
import DadosNutricionais from './DadosNutricionais';

export default function ModalCadastrarAlimento({
    fechou,
    salvou,
    registro=null,
    idAlimento
}){

    // estados
    const { LoginReducer } = useSelector(state => state);
    let [modo, alterarModo] = useState('cadastro') // cadastro, edicao
    let [mostrar, alterarMostrar] = useState(true);
    let [salvando, alterarSalvando] = useState(false);
    let [carregando, alterarCarregando] = useState(true);
    let [dados, alterarDados] = useState({
        pk_cad_alimento: null,
        fk_sis_alimento_tabela: 1,
        fk_sis_alimento_grupo: null,
        descricao: '',
        porcao: '100.0',
        energia: '0',
        proteina: null,
        proteina_liquida: null,
        carboidrato: null,
        gordura_total: null,
        calcio: null,
        fosforo: null,
        ferro: null,
        vitamina_a: null,
        vitamina_b1: null,
        vitamina_b2: null,
        niacina: null,
        vitamina_c: null,
        colina: null,
        fibra_total: null,
        sodio: null,
        gordura_poliinsaturada: null,
        gordura_monosaturada: null,
        gordura_saturada: null,
        fibra_soluvel: null,
        fibra_insoluvel: null,
        vitamina_b6: null,
        vitamina_b12: null,
        vitamina_d: null,
        fosfolipidios: null,
        acido_patotenico: null,
        vitamina_e: null,
        iodo: null,
        magnesio: null,
        zinco: null,
        manganes: null,
        vitamina_k: null,
        cobre: null,
        selenio: null,
        publica: 'N'
    });
    let [mostrarDadosNutricionais, alterarMostrarDadosNutricionais] = useState(false);

    // inicializa
    useEffect(() => {

        // se for edição
        if(registro !== null){

            // consulta os dados do alimento
            consultarId();
            
        }else{
            alterarCarregando(false);
        }

    }, []);

    // consulta id do alimento
    async function consultarId(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/alimento/${registro.pk_cad_alimento}`);
            alterarDados(data.dados);
            alterarCarregando(false);

        }catch({response}){

        }


    }

    // salva
    async function salvar(){
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/alimento`, dados);
            salvou(data);
            fechou();

        }catch({response}){

        }finally{

        }
    }

    // modal de carregando os dados
    if(carregando){
        return <Modal 
            show={mostrar}
            onHide={() => {}}
        >
            <Modal.Body>
                <p>Carregando <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} /></p>
            </Modal.Body>
        </Modal>
    }

    return <Modal 
        show={mostrar} 
        onExited={fechou}
        onHide={() => {}}
        size={'lg'}
    >
        <Modal.Header className='border-0'>
            <Modal.Title>
                Cadastro de alimento
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>

                <Form.Group>
                    <Form.Label>Descrição</Form.Label>
                    <Form.Control 
                        value={dados.descricao}
                        onChange={e => {
                            dados.descricao = e.target.value;
                            alterarDados({...dados});
                        }}
                    />
                </Form.Group>

                <Form.Row>
                    <Col lg='6'>
                        <Form.Group>
                            <Form.Label>Grupo</Form.Label>
                            <Form.Control 
                                as='select'
                                value={dados.fk_sis_alimento_grupo || ''}
                                onChange={e => {
                                    dados.fk_sis_alimento_grupo = null;
                                    if(e.target.value !== ''){
                                        dados.fk_sis_alimento_grupo = e.target.value;
                                    }
                                    alterarDados({...dados});
                                }}
                            >
                                <option value=''>Sem grupo</option>

                                {[
                                    {value: 1, children: 'Cereais, pães, tubérculos e raízes'},
                                    {value: 2, children: 'Frutas'},
                                    {value: 3, children: 'Hortaliças'},
                                    {value: 4, children: 'Leguminosas'},
                                    {value: 5, children: 'Carnes e ovos'},
                                    {value: 6, children: 'Leites e produtos lácteos'},
                                    {value: 7, children: 'Açucares e doces'},
                                    {value: 8, children: 'Óleos e gorduras'}
                                    
                                ].map(grupo => 
                                    <option key={grupo.value} {...grupo} />
                                )}
                                
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label size='sm'>Porção <small>(g/ml)</small></Form.Label>
                            <InputDecimal 
                                valor={dados.porcao}
                                onChange={valor => {
                                    dados.porcao = valor;
                                    alterarDados({...dados});
                                }}
                                casas={3}
                                cortarZeros={true}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Calorias</Form.Label>
                            <Form.Control 
                                value={dados.energia}
                                onChange={e => {
                                    dados.energia = e.target.value;
                                    alterarDados({...dados});
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>

                { !mostrarDadosNutricionais &&
                    <Button
                        size='sm'
                        className='mr-2'
                        variant='padrao'
                        onClick={() => {
                            alterarMostrarDadosNutricionais(true);
                        }}
                    >
                        <span>Dados nutricionais</span>
                        <FontAwesomeIcon className="icone" icon={["fas", 'angle-down']} />
                    </Button>
                }

                { mostrarDadosNutricionais &&
                    <DadosNutricionais 
                        dados={dados}
                        alterarDados={alterarDados}
                    />
                }
            </Container>
        </Modal.Body>
        <Modal.Footer  className='border-0'>
            <Button
                variant='danger'
                onClick={() => {alterarMostrar(false)}}
                disabled={salvando}
            >
                <span>Fechar</span>
            </Button>
            { dados.publica === 'N' &&
                <Button
                    onClick={salvar}
                    variant='success'
                    disabled={salvando}
                >
                    {salvando ? 
                        <>
                            <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                            <span>Salvando</span>
                        </> : 
                        <span>Salvar</span>
                    }
                </Button>
            }
        </Modal.Footer>
    </Modal>

}
