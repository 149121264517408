import React from 'react';
import styles from './index.module.scss';
import moment from 'moment';
import iconeAdipometro from './adipometro.png';

// componentes
import BotaoTabela from './../../../ComponentesCadastro/BotaoTabela';
import Inativar from './Inativar';

// elemento registro
function Registro({
    dados,
    clicouEditar,
    clicouAtender,
    alterouStatus,
    onClickTr
}){
    
    return <tr onClick={onClickTr}>
        {/* <td className="estreito text-center font-weight-bold">{dados.pk_cad_paciente}</td> */}
        <td>{dados.nome}</td>
        <td>{dados.data_nascimento && moment(dados.data_nascimento).format('DD/MM/YYYY')}</td>
        <td className="text-nowrap estreito">
            <BotaoTabela 
                onClick={clicouEditar}
                icone={["fas", "pencil-alt"]}
            >Editar</BotaoTabela>
            <BotaoTabela 
                onClick={clicouAtender}
                img={iconeAdipometro}
            >Atender</BotaoTabela>
            <Inativar 
                dados={dados}
                alterouStatus={alterouStatus}
            />
        </td>
    </tr>
}

export default Registro;