import React from 'react';
import styles from './index.module.scss';

// componentes
import { Draggable } from 'react-beautiful-dnd';
import ItemDados from './ItemDados';

function Item({
    dados,
    index
}){


    return <Draggable
        draggableId={`item-consulta-${index}`} 
        index={index}
    >
        {(provided, snapshot) => (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                <ItemDados 
                    dados={dados}
                />
            </div>
        )}
    </Draggable>
}

// item
export default Item;