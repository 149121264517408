import React from 'react';
import styles from './index.module.scss';

// componentes
import MaskedInput from 'react-maskedinput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Row, Col, ButtonToolbar, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';

function Telefone({
    dados,
    alterou,
    excluir
}){

    // referencias
    let telResInput = React.createRef();
    let telCelInput = React.createRef();

    return <div className={[styles.contato, 'mb-2'].join(' ')}>

        { dados.tipo === 'celular' ?
            <FontAwesomeIcon className={[styles.icone, "mr-2"].join(' ')} icon={["fas", 'mobile-alt']} />
            :
            <FontAwesomeIcon className={[styles.icone, "mr-2"].join(' ')} icon={["fas", 'phone-alt']} />
        }
        
        
        <div className={[styles.nome, 'mr-1'].join(' ')}>
            <Form.Control 
                size='sm'
                placeholder='Nome'
                autoComplete='nome'
                value={dados.contato}
                onChange={(e) => {
                    dados.contato = e.target.value;
                    alterou(dados);
                }}
            />
        </div>
        <div className={[styles.numero, 'mr-1'].join(' ')}>
            { dados.tipo === 'celular' ?
                <MaskedInput 
                    // placeholderChar=' '
                    className='form-control form-control-sm'
                    mask="(11) 1 1111-1111"
                    value={dados.numero}
                    ref={telCelInput}
                    onChange={(e) => {
                        dados.numero = telCelInput.current.mask.getRawValue().split('_').join('');
                        alterou(dados);
                    }}
                />
                :
                <MaskedInput 
                    // placeholderChar=' '
                    className="form-control form-control-sm"
                    mask="(11) 1111-11111"
                    value={dados.numero}
                    ref={telResInput}
                    onChange={(e) => {
                        dados.numero = telResInput.current.mask.getRawValue().split('_').join('');
                        alterou(dados);
                    }}
                />
                
            }
        </div>
        <div className={styles.opcao}>
            <ButtonToolbar className={styles.botao}>
                <OverlayTrigger
                    placement={'top'}
                    overlay={<Tooltip>Remover</Tooltip>}
                >
                    <Button
                        size='sm'
                        variant='padrao'
                        className={['rounded-circle'].join(' ')}
                        onClick={excluir}
                    >
                        <FontAwesomeIcon className="icone" icon={["fas", 'times']} />
                    </Button>
                </OverlayTrigger>
            </ButtonToolbar>
        </div>
    </div>

}

export default Telefone;