import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import Script from 'react-load-script';

// componentes
import { Row, Col, Button, Card, Form, Modal, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaskedInput from 'react-maskedinput';

// funções
import { converterReal, validarData } from './../../../Funcoes';

// serviços
import FormaPagamentroServico from './../../../../servicos/FormaPagamentroServico';
import TransacaoServico from './../../../../servicos/TransacaoServico';

// pagamento dados
export default function Pagamento(){

    // estados
    const { LoginReducer } = useSelector(state => state);
    const history = useHistory();
    const location = useLocation();
    let [carregando, alterarCarregando] = useState(true);
    let [dadosFormaPagamento, alterarDadosFormaPagamento] = useState(null);
    let [salvando, alterarSalvando] = useState(false);
    let [dados, alterarDados] = useState({
        nome: '',
        cpf: '',
        cartao_nome: '',
        cartao_cpf: '',
        telefone: '',
        cep: '',
        endereco: '',
        numero: '',
        bairro: '',
        complemento: '',
        cidade: '',
        estado: 'AC',
        data_nascimento: null,
        tokenCartao: null,
        hashPagseguro: null,
        id_forma_pagamento: null
    });
    let [dadosCartao, alterarDadosCartao] = useState({
        numero: '',
        bandeira: null,
        // brand: '',
        cvv: '',
        mes: '',
        ano: ''
    });
    let [erros, alterarErros] = useState([]);
    let [erro, alterarErro] = useState(null);
    let [modal, mostrarModal] = useState({
        show: false,
        titulo: '',
        mensagem: '',
        fechar: () => {}
    });
    let [pagseguroCarregado, alterarPagseguroCarregado] = useState(false);
    let [bandeiraCartao, alterarBandeiraCartao] = useState(null);
    let [carregandoBandeira, alterarCarregandoBandeira] = useState(false);
    let [planoAtual, alterarPlanoAtual] = useState(null);
    
    // referencias
    let cpfInput = React.createRef();
    let cpfCartaoInput = React.createRef();
    let telInput = React.createRef();
    let cepInput = React.createRef();
    let numeroCartaoInput = React.createRef();

    // temp
    useEffect(() => {
        // console.log(login);
    }, []);

    // carrega dados do plano escolhido
    useEffect(() => {

        // se informado código do plano escolhido
        if(location.state){

            // carrega os dados do plano escolhido
            consultarIdFormaPagamento();

        }else{
            history.push('/painel/assinatura');
        }

    }, []);

    // inicializa pagseguro
    useEffect(() => {
        
        // se pagseguro já foi carregado
        if(pagseguroCarregado){
            iniciarTransacao();
        }

    }, [pagseguroCarregado]);

    // carrega bandeira
    useEffect(() => {
        
        // se for menor
        if(dadosCartao.numero.length <= 5){
            alterarBandeiraCartao(null);
        }else{

            // se for maior que 6, verifica
            if(dadosCartao.numero.length >= 6 && bandeiraCartao === null){
                alterarCarregandoBandeira(true);

                // faz a consulta
                window.PagSeguroDirectPayment.getBrand({
                    cardBin: dadosCartao.numero,
                    success: retorno => {
                        
                        dadosCartao.bandeira = retorno.brand.name;
                        alterarBandeiraCartao(retorno.brand);
                        alterarDadosCartao({...dadosCartao});
                        alterarCarregandoBandeira(false);
                    },
                    error: retorno => {
                        alterarCarregandoBandeira(false);
                    }
                });
            }

        }

    }, [dadosCartao.numero]);

    // gerar hash do cartão
    useEffect(() => {
        if(bandeiraCartao !== null){

            // verifica se está tudo preenchido corretamente
            if(
                bandeiraCartao.config.acceptedLengths.includes(dadosCartao.numero.length) &&
                (
                    (bandeiraCartao.config.hasCvv && dadosCartao.cvv.length === bandeiraCartao.config.securityFieldLength) || 
                    !bandeiraCartao.config.hasCvv
                ) &&
                dadosCartao.mes.length > 1 &&
                dadosCartao.ano.length > 3
            ){
                gerarHashCartao();
            }
        }

    }, [dadosCartao]);

    // conulta id da forma de pagamento
    async function consultarIdFormaPagamento(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/formaPagamento/${location.state.pk_sis_forma_pagamento}`);
            dados.id_forma_pagamento = data.dados.pk_sis_forma_pagamento;
            alterarDados({...dados});
            alterarDadosFormaPagamento(data.dados);
            alterarCarregando(false);

            // verifica se possui uma última transação
            if(data.planoAtual.possuiTransacao){
                alterarPlanoAtual(data.planoAtual.dadosTransacao);
            }

        }catch({response}){

        }finally{

        }

    }

    // inicia transação
    async function iniciarTransacao(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/transacao/iniciar`);
            window.PagSeguroDirectPayment.setSessionId(data.tokenPagseguro);

        }catch({response}){
            if(response){
                if(response.status === 400){
                    console.error(response.data);
                }
            }
        }
    }

    // cria hash para o cartão de crédito com pagseguro
    function gerarHashCartao(){
        console.log('gerar hash');
        // faz a requisiçào
        // if(dados.tokenCartao === null){
            window.PagSeguroDirectPayment.createCardToken({
                cardNumber: dadosCartao.numero,
                brand: dadosCartao.bandeira,
                cvv: dadosCartao.cvv,
                expirationMonth: dadosCartao.mes,
                expirationYear: dadosCartao.ano,
                success: retorno => {
                    console.log(retorno.card);
                    // salva o token do cartão
                    dados.tokenCartao = retorno.card.token;
                    alterarDados({...dados});
                },
                error: retorno => {
                    console.log(dadosCartao);
                    console.log(retorno);

                    // não deixar proceder se houver erro
                }
            });
        // }
    }

    // finaliza
    async function salvar(){
        alterarErro(null);
        alterarSalvando(true);

        // gera hash do pagseguro
        dados.hashPagseguro = window.PagSeguroDirectPayment.getSenderHash();

        // // faz a requisição
        // TransacaoServico.finalizarTransacao({
        //     dados: dados
        // }, LoginReducer.dadosUsuarioEmpresa.token)
        // .then(({data, ...retorno}) => {
        //     console.log(data);
        //     alterarSalvando(false);

        //     if(data.status){
        //         history.replace('/painel/assinatura/finalizado');

        //     }else{
        //         alterarErro(data);
        //     }

        // });

        try{

            // faz a requisição
            let { data } = await axios.post(`/transacao`, dados);
            history.replace('/painel/assinatura/finalizado');

        }catch({response}){
            if(response){
                if(response.status === 400){
                    alterarErro(response.data);
                }
            }
        }finally{
            alterarSalvando(false);
        }
    }

    // busca cep
    function buscarCep(cepBuscar){

        // se foi totalmente preenchido
        if(cepBuscar.length === 8){

            // faz a busca
            axios.get("https://viacep.com.br/ws/"+ cepBuscar +"/json/", {
                responseType: 'json'
            }).then(retorno => {
                
                alterarDados({...dados, 
                    'cidade': retorno.data.localidade,
                    'estado': retorno.data.uf,
                    'endereco': retorno.data.logradouro,
                    'bairro': retorno.data.bairro,
                    'cep': cepBuscar
                });
            });

        }
    }

    // buscar erro
    function buscarErro(campo){

        if(erro && erro.erroCampo === campo){
            return {
                mensagem: erro.erro
            };
        }else{
            return null;
        }

        // return erros.find(erro => erro.campo === campo);
    }

    // carregando
    if(carregando){
        return <div>Carregando...</div>
    }

    // se possuir alguma transação aguardando aprovação
    if(planoAtual && ['1'].includes(planoAtual.status_transacao)){
        return <div>
            Aguardando aprovação de pagamento!
        </div>
    }

    // url pagseguro
    let urlPagseguroAmbiente = "https://stc.sandbox.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js";
    if(process.env.REACT_APP_AMBIENTE_PAGSEGURO === 'production'){
        urlPagseguroAmbiente = "https://stc.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js";
    }

    //retorno
    return <>
        <Script 
            url={urlPagseguroAmbiente}
            onCreate={() => {}}
            onError={() => {
                // alert('Houve um erro ao carregar dados do pagseguro!');
                mostrarModal({
                    show: true,
                    titulo: 'Não foi possível se conectar ao pagseguro!',
                    mensagem: 'Houve um erro ao tentar se conectar ao pagseguro.',
                    botaoFechar: 'Tentar novamente!',
                    fechar: () => {
                        window.location.reload();
                    }
                });
            }}
            onLoad={() => {
                alterarPagseguroCarregado(true);
            }}
        />

        <Modal
            show={!pagseguroCarregado}
            onHide={() => {}}
        >
            <Modal.Body className='text-center'>
                <FontAwesomeIcon className="icone mr-2" pulse icon={["fas", 'spinner']} /> Aguarde!
            </Modal.Body>
        </Modal>

        <Modal show={modal.show} onHide={modal.fechar}>
            <Modal.Header closeButton>
                <Modal.Title>{modal.titulo}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {modal.mensagem}

                { modal.botaoFechar &&
                    <p className='mt-3 text-center'>
                        <Button 
                            variant='padrao'
                            onClick={modal.fechar}
                        >
                            <span>{modal.botaoFechar}</span>
                        </Button>
                    </p>
                }
            </Modal.Body>
        </Modal>

        <Row>
            <Col lg='8'>
                <Card className='border-0 shadow-sm'>
                    <Card.Header className='border-0 bg-transparent'>
                        <Card.Title as='h5'>Dados Pessoais <small>(confira seus dados)</small></Card.Title>
                    </Card.Header>
                    <Card.Body>
                        
                        <Form.Row>
                            <Form.Group as={Col} lg='7'>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    value={dados.nome} 
                                    onChange={(e) => {
                                        alterarDados({...dados, nome: e.target.value});
                                    }} 
                                    isInvalid={buscarErro('nome')}
                                />
                                { (buscarErro('nome')) &&
                                    <Form.Control.Feedback type="invalid">{buscarErro('nome').mensagem}</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Form.Group as={Col} lg='5'>
                                <Form.Label>CPF</Form.Label>
                                <MaskedInput 
                                    className={["form-control", buscarErro('cpf')? 'is-invalid': ''].join(' ')}
                                    mask="111.111.111-11"
                                    value={dados.cpf}
                                    ref={cpfInput}
                                    onChange={(e) => {
                                        alterarDados({...dados, cpf: cpfInput.current.mask.getRawValue().split('_').join('')});
                                    }}
                                />
                                { (buscarErro('cpf')) &&
                                    <Form.Control.Feedback type="invalid">{buscarErro('cpf').mensagem}</Form.Control.Feedback>
                                }
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} lg='3'>
                                <Form.Label>CEP</Form.Label>
                                <MaskedInput
                                    className={['form-control', buscarErro('cep')? 'is-invalid': ''].join(' ')}
                                    mask="11111-111"
                                    value={dados.cep}
                                    ref={cepInput}
                                    onChange={(e) => {
                                        let cepNovo = cepInput.current.mask.getRawValue().split('_').join('');
                                        buscarCep(cepNovo);
                                        alterarDados({...dados, cep: cepNovo});
                                    }}
                                />
                                { (buscarErro('cep')) &&
                                    <Form.Control.Feedback type="invalid">{buscarErro('cep').mensagem}</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Form.Group as={Col} lg='7'>
                                <Form.Label>Endereço</Form.Label>
                                <Form.Control 
                                    value={dados.endereco} 
                                    onChange={(e) => alterarDados({...dados, endereco: e.target.value}) } 
                                    isInvalid={buscarErro('endereco')}
                                />
                                { (buscarErro('endereco')) &&
                                    <Form.Control.Feedback type="invalid">{buscarErro('endereco').mensagem}</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Form.Group as={Col} lg='2'>
                                <Form.Label>Numero</Form.Label>
                                <Form.Control 
                                    value={dados.numero} 
                                    onChange={(e) => {
                                        alterarDados({...dados, numero: e.target.value});
                                    }} 
                                    isInvalid={buscarErro('endereco')}
                                />
                                { (buscarErro('numero')) &&
                                    <Form.Control.Feedback type="invalid">{buscarErro('numero').mensagem}</Form.Control.Feedback>
                                }
                            </Form.Group>
                        </Form.Row>
                        
                        <Form.Row>
                            <Form.Group as={Col} lg='8'>
                                <Form.Label>Bairro</Form.Label>
                                <Form.Control 
                                    value={dados.bairro} 
                                    onChange={(e) => alterarDados({...dados, bairro: e.target.value}) } 
                                    isInvalid={buscarErro('bairro')}
                                />
                                { (buscarErro('bairro')) &&
                                    <Form.Control.Feedback type="invalid">{buscarErro('bairro').mensagem}</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Form.Group as={Col} lg='4'>
                                <Form.Label>Complemento</Form.Label>
                                <Form.Control 
                                    placeholder='Ex.: Casa, Ap 101'
                                    value={dados.complemento} 
                                    onChange={(e) => alterarDados({...dados, complemento: e.target.value}) }
                                    isInvalid={buscarErro('complemento')} 
                                />
                                { (buscarErro('complemento')) &&
                                    <Form.Control.Feedback type="invalid">{buscarErro('complemento').mensagem}</Form.Control.Feedback>
                                }
                            </Form.Group>
                        </Form.Row>

                        
                        <Form.Row>
                            <Form.Group as={Col} lg='6'>
                                <Form.Label>Cidade</Form.Label>
                                <Form.Control 
                                    value={dados.cidade} 
                                    onChange={(e) => alterarDados({...dados, cidade: e.target.value}) } 
                                    isInvalid={buscarErro('cidade')} 
                                />
                                { (buscarErro('cidade')) &&
                                    <Form.Control.Feedback type="invalid">{buscarErro('cidade').mensagem}</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Form.Group as={Col} lg='2'>
                                <Form.Label>Estado</Form.Label>
                                <Form.Control as="select" value={dados.estado} onChange={(e) => alterarDados({...dados, estado: e.target.value}) } >
                                    {[
                                        'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 
                                        'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 
                                        'MG', 'PA', 'PB', 'PR', 'PE', 'PI',
                                        'RJ', 'RN', 'RS', 'RO', 'RR', 'SC',
                                        'SP', 'SE', 'TO'
                                    ].map(uf => <option key={uf} value={uf}>{uf}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                        
                    </Card.Body>
                </Card>

                <Card className='mt-4 border-0 shadow-sm'>
                    <Card.Header className='border-0 bg-transparent'>
                        <Card.Title as='h4'>Cartão De Crédito</Card.Title>
                    </Card.Header>
                    <Card.Body>

                        <Form.Row>
                            <Form.Group as={Col} lg='9'>
                                <Form.Label>Nome <small>(Igual no cartão)</small></Form.Label>
                                <Form.Control
                                    value={dados.cartao_nome} 
                                    onChange={(e) => {
                                        alterarDados({...dados, cartao_nome: e.target.value});
                                    }} 
                                    isInvalid={buscarErro('cartao_nome')}
                                />
                                { (buscarErro('cartao_nome')) &&
                                    <Form.Control.Feedback type="invalid">{buscarErro('cartao_nome').mensagem}</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Form.Group as={Col} lg='3'>
                                <Form.Label>Nascimento</Form.Label>
                                <MaskedInput 
                                    mask="11/11/1111"
                                    value={(dados.data_nascimento === null)? '' : dados.data_nascimento.split('-').reverse().join('/')} 
                                    onChange={(e) => {
                                        let dateStr = e.target.value.split('_').join('').split('/').reverse().join('-');
                                        if(e.target.value === ''){
                                            dateStr = null;
                                        }
                                        // alterar os dados
                                        alterarDados({...dados, data_nascimento: dateStr});
                                    }}
                                    className={["form-control", (buscarErro('data_nascimento') || validarData(dados.data_nascimento, 'invalid')) ? 'is-invalid': ''].join(' ')}
                                />
                                { (buscarErro('data_nascimento') || validarData(dados.data_nascimento, 'invalid')) &&
                                    <Form.Control.Feedback type="invalid">Data inválida</Form.Control.Feedback>
                                }
                            </Form.Group>
                        </Form.Row>
                        
                        <Form.Row>
                            <Form.Group as={Col} lg='6'>
                                <Form.Label>CPF <small>(responsável pelo cartão)</small></Form.Label>
                                <MaskedInput 
                                    className={["form-control", buscarErro('cartao_cpf')? 'is-invalid': ''].join(' ')}
                                    mask="111.111.111-11"
                                    value={dados.cartao_cpf}
                                    ref={cpfCartaoInput}
                                    onChange={(e) => {
                                        alterarDados({...dados, cartao_cpf: cpfCartaoInput.current.mask.getRawValue().split('_').join('')});
                                    }}
                                />
                                { (buscarErro('cartao_cpf')) &&
                                    <Form.Control.Feedback type="invalid">{buscarErro('cartao_cpf').mensagem}</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Form.Group as={Col} lg='4'>
                                <Form.Label>Telefone</Form.Label>
                                <MaskedInput 
                                    className={["form-control", buscarErro('telefone')? 'is-invalid': ''].join(' ')}
                                    mask="(11) 11111-1111"
                                    value={dados.telefone}
                                    ref={telInput}
                                    onChange={(e) => {
                                        alterarDados({...dados, telefone: telInput.current.mask.getRawValue().split('_').join('')});
                                    }}
                                />
                                { (buscarErro('telefone')) &&
                                    <Form.Control.Feedback type="invalid">{buscarErro('telefone').mensagem}</Form.Control.Feedback>
                                }
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} lg='7'>
                                <Form.Label>
                                    Número do cartão

                                    { carregandoBandeira &&
                                        <FontAwesomeIcon className="icone ml-2" pulse icon={["fas", 'spinner']} />
                                    }

                                    {bandeiraCartao && 
                                        <img alt='' className={[styles.bandeira, 'ml-2'].join(' ')} src={`https://stc.pagseguro.uol.com.br/public/img/payment-methods-flags/68x30/${bandeiraCartao.name}.png`} />
                                    }
                                </Form.Label>
                                <MaskedInput
                                    className={['form-control', buscarErro('cartao_numero')? 'is-invalid': ''].join(' ')}
                                    mask="1111-1111-1111-1111"
                                    value={dadosCartao.numero}
                                    ref={numeroCartaoInput}
                                    onChange={(e) => {
                                        let numeroCartaoNovo = numeroCartaoInput.current.mask.getRawValue().split('_').join('');
                                        alterarDadosCartao({...dadosCartao, numero: numeroCartaoNovo});
                                    }}
                                />
                                { (buscarErro('cartao_numero')) &&
                                    <Form.Control.Feedback type="invalid">{buscarErro('cartao_numero').mensagem}</Form.Control.Feedback>
                                }
                            </Form.Group>
                        </Form.Row>
                        
                        <Form.Row>
                            <Form.Group as={Col} lg='3'>
                                <Form.Label>Mês</Form.Label>
                                <Form.Control
                                    value={dadosCartao.mes} 
                                    onChange={(e) => {
                                        alterarDadosCartao({...dadosCartao, mes: e.target.value});
                                    }} 
                                    isInvalid={buscarErro('cartao_mes')}
                                    placeholder='00'
                                    maxLength={2}
                                />
                                { (buscarErro('cartao_mes')) &&
                                    <Form.Control.Feedback type="invalid">{buscarErro('cartao_mes').mensagem}</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Form.Group as={Col} lg='3'>
                                <Form.Label>Ano</Form.Label>
                                <Form.Control 
                                    value={dadosCartao.ano} 
                                    onChange={(e) => {
                                        alterarDadosCartao({...dadosCartao, ano: e.target.value});
                                    }} 
                                    isInvalid={buscarErro('cartao_ano')}
                                    placeholder='0000'
                                    maxLength={4}
                                />
                                { (buscarErro('cartao_ano')) &&
                                    <Form.Control.Feedback type="invalid">{buscarErro('cartao_ano').mensagem}</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Form.Group as={Col} lg={{span: 3, offset: 3}}>
                                <Form.Label>Código de segurança</Form.Label>
                                <Form.Control
                                    value={dadosCartao.cvv} 
                                    onChange={(e) => {
                                        alterarDadosCartao({...dadosCartao, cvv: e.target.value});
                                    }} 
                                    isInvalid={buscarErro('cartao_cvv')}
                                    maxLength={(bandeiraCartao === null) ? 3 : bandeiraCartao.config.securityFieldLength}
                                />
                                { (buscarErro('cartao_cvv')) &&
                                    <Form.Control.Feedback type="invalid">{buscarErro('cartao_cvv').mensagem}</Form.Control.Feedback>
                                }
                            </Form.Group>
                        </Form.Row>

                    </Card.Body>
                </Card>
            </Col>
            <Col lg='4'>
                <Card className='border-0 shadow-sm text-center p-3'>
                    <Card.Header className='bg-transparent border-0 pb-0'>
                        <Card.Subtitle className='mb-3'>Plano Escolhido</Card.Subtitle>
                        <Card.Title as='h4'>{dadosFormaPagamento.descricao}</Card.Title>
                    </Card.Header>
                    <Card.Body className='pt-0'>
                    
                        <h3 className={['text-center my-0'].join(' ')}><small>R$</small> {converterReal(dadosFormaPagamento.valor)}</h3>
                        {  (dadosFormaPagamento.duracao_meses !== '1') &&
                            <p className='text-center'>
                                <small>R$ {converterReal(parseFloat(dadosFormaPagamento.valor) / parseInt(dadosFormaPagamento.duracao_meses))} / mês</small>
                            </p>
                        }
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Row>
            <Col lg='8'>
                { erro &&
                    <Alert variant='danger' className='mt-4'>
                        Alguns campos estão incorretos!
                    </Alert>
                }
                <SalvarFormulario 
                    salvando={salvando}
                    cancelar={() => {
                        history.goBack();
                    }}
                    onClick={salvar}
                    habilitarSalvar={dados.tokenCartao === null ? false : true}
                />
            </Col>
        </Row>
    </>

};

function SalvarFormulario({
    salvando,
    habilitarSalvar,
    cancelar,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" disabled={salvando} onClick={cancelar}>Cancelar</Button>
        <Button variant="success" disabled={salvando || !habilitarSalvar} onClick={onClick}>
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <span>Salvar</span>
            }
        </Button>
    </Form.Group>
}