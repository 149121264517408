import React, { useState, useEffect } from 'react';
import axios from 'axios';

// componentes
import { Button, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// componente
export default function ModalCadastroExame({
    id,
    salvou,
    fechou
}){

    // estados
    const [mostrar, alterarMostrar] = useState(true);
    const [salvando, alterarSalvando] = useState(false);
    const [dados, alterarDados] = useState({
        pk_cad_tipo_exame: id,
        descricao: ''
    });
    const [carregando, alterarCarregando] = useState(false);

    // inicializa
    useEffect(() => {
        if(dados.pk_cad_tipo_exame !== null){
            consultarId();
        }
    }, []);

    // salva exame
    async function salvar(){
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/tipoExame`, dados);
            salvou();
            alterarMostrar(false);
        }catch({response}){
            
        }finally{
            alterarSalvando(false);
        }
    }

    // em caso de edição consulta o id
    async function consultarId(){
        alterarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/tipoExame/${dados.pk_cad_tipo_exame}`);
            alterarDados(data.dados);
            alterarCarregando(false);

        }catch({response}){}
    }


    return <>
        
        <Modal
            show={mostrar}
            onHide={() => {}}
            onExited={() => {
                fechou();
            }}
        >
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Descrição do exame</Form.Label>
                    <Form.Control 
                        value={dados.descricao}
                        onChange={e => {
                            dados.descricao = e.target.value;
                            alterarDados({...dados});
                        }}
                    />
                </Form.Group>
                <Form.Group className="d-flex justify-content-around my-4">
                    <Button variant="danger" onClick={() => {
                        alterarMostrar(false);
                    }}>Cancelar</Button>
                    <Button variant="success" disabled={salvando} onClick={salvar}>
                        {salvando ? 
                            <>
                                <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                                <span>Salvando</span>
                            </> : 
                            <span>Salvar</span>
                        }
                    </Button>
                </Form.Group>
            </Modal.Body>
        </Modal>

    </>
    

}