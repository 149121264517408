import React from 'react';
import { Route, useParams } from 'react-router-dom';

// componentes
import OpcoesAtendimento from './OpcoesAtendimento';
import ClienteInformacao from './ClienteInformacao';

// opções de atendimento ao cliente
import AnamneseConsultar from './../Anamnese/Consultar';
import RastreamentoMetabolicoConsultar from './../RastreamentoMetabolico/Consultar';
import PlanoAlimentarConsultar from './../PlanoAlimentar/Consultar';
import TeiaInterRelacaoMetabolicaConsultar from './../TeiaInterRelacaoMetabolica/Consultar';
import AvaliacaoAntropometricaConsultar from './../AvaliacaoAntropometrica/Consultar';
import BioimpedanciaMagneticaConsultar from './../BioimpedanciaMagnetica/Consultar';
import SolicitacaoExameConsultar from './../SolicitacaoExame/Consultar';

// retorna componente
export default function Atender(){
    // console.log(props);
    // pk_cad_paciente
    // console.log(props.match.params.id);
    // console.log(props.match.params.idagendamento)
    // console.log(props.match.params);

    // estados
    const { idpaciente } = useParams();

    // rota base
    let rotaBase = `/painel/paciente/:idpaciente/atender/agendamento/:idagendamento`;
    
    return <div>
        {/* <h4>Atendimento ao Paciente</h4> */}
        {/* <Card className='border-0 shdow-sm mb-2'>
            <Card.Body>
                Dados do cliente aqui
            </Card.Body>
        </Card> */}

        <ClienteInformacao 
            idpaciente={idpaciente}
        />

        {[
            {path: `${rotaBase}`, component: OpcoesAtendimento},
            {path: `${rotaBase}/anamnese`, component: AnamneseConsultar},
            {path: `${rotaBase}/rastreamento-metabolico`, component: RastreamentoMetabolicoConsultar},
            {path: `${rotaBase}/teia-metabolica`, component: TeiaInterRelacaoMetabolicaConsultar},
            {path: `${rotaBase}/plano-alimentar`, component: PlanoAlimentarConsultar},
            {path: `${rotaBase}/avaliacao-antropometrica`, component: AvaliacaoAntropometricaConsultar},
            {path: `${rotaBase}/bioimpedancia-magnetica`, component: BioimpedanciaMagneticaConsultar},
            {path: `${rotaBase}/solicitacao-exame`, component: SolicitacaoExameConsultar},
        ].map((rota, a) => (
            <Route key={a} exact {...rota} />
        ))}
        
        
    </div>
}