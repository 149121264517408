import React from 'react';

import { Form } from 'react-bootstrap';

function Item({
    dados,
    registro,
    alterou
}){

    return <tr>
        <td>{dados.descricaoOpcao}</td>
        {['0','1','2','3','4'].map(numero => 
            <td key={numero}>
                <Form.Check 
                    type='radio'
                    name={dados.id}
                    checked={registro[dados.id] === numero}
                    onChange={e => {
                        registro[dados.id] = numero;
                        alterou();
                    }}
                />
            </td>    
        )}
    </tr>
}

export default Item;