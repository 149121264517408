import React from 'react';
import styles from './index.module.scss';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// icones
import iconeAlterar from './imagens/alterar.png';
import iconeAtender from './imagens/atender.png';
import iconeCancelar from './imagens/cancelar.png';

// opções do agendamento
function AgendamentoOpcoes({
    atender,
    cancelar,
    alterar,
    cancelando=false
}){

    // retorna
    return <div className={styles.opcoes}>

        <Button 
            variant='padrao' 
            onClick={alterar} 
            className={styles.botao}
            disabled={cancelando}
        >
            <span>Alterar<br />Agendamento</span>
            <img src={iconeAlterar} />
        </Button>
        
        <Button 
            variant='padrao' 
            onClick={atender} 
            className={styles.botao}
            disabled={cancelando}
        >
            <span>Atender<br />Paciente</span>
            <img src={iconeAtender} />
        </Button>
        
        <Button 
            variant='padrao' 
            onClick={cancelar} 
            className={styles.botao}
            disabled={cancelando}
        >
            {cancelando &&
                <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
            }
            <span>Cancelar<br />Agendamento</span>
            <img src={iconeCancelar} />
        </Button>
    </div>
}

export default AgendamentoOpcoes;