import React, { useState, useEffect, useContext } from 'react';

// componentes
import { Row, Col, Card, Badge } from 'react-bootstrap';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { calcularNutrienteAlimento } from './../../PlanoNutrientes';

// importa contexto
import { PlanoAlimentarContexto } from './../../../ComponentePlanoAlimentar';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, percent, index,
    }) => {
    
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    // console.log((percent * 100).toFixed(0));

    return <text 
        x={x} 
        y={y} 
        fill="white" 
        textAnchor={x > cx ? 'start' : 'end'} 
        dominantBaseline="central"
    >
        {`${(percent * 100).toFixed(0)}%`}
    </text>
};


// componente
export default function Graficos({
    campos
}){

    
    // estados
    let planoDados = useContext(PlanoAlimentarContexto);
    const [graficoDados, alterarGraficoDados] = useState([]);


    // faz a contagem
    useEffect(() => {
        
        // criase então, os arrays para cada opção
        let dadosOpcoes = [];
        planoDados.refeicoes.forEach(refeicao => {
            refeicao._opcoes.forEach((opcao, pos) => {
                
                // define opção dados
                if(!dadosOpcoes[pos]){
                    dadosOpcoes[pos] = {};
                }

                // itens da opção
                opcao.forEach(item => {

                    // atribui os totais da opção
                    campos.forEach(campo => {

                        // define campo se não possui
                        if(!dadosOpcoes[pos][campo.id]){
                            dadosOpcoes[pos][campo.id] = 0.0;
                        }

                        // incrementa valor calórico
                        dadosOpcoes[pos][campo.id] += calcularNutrienteAlimento(item, campo.id)
                    });
                })
                
            });
        });

        // reajusta para o padrão dos gráficos
        let novosDadosOpcoes = dadosOpcoes.map(opcao => {
            let opcaoNova = [];
            campos.forEach(campo => {
                opcaoNova.push({name: campo.descricao, value: opcao[campo.id]});
            });
            return opcaoNova;
        });
        alterarGraficoDados(novosDadosOpcoes);

    }, [campos]);



    return <>

        <div className={'mt-3'}>
            {campos.map(campo => 
                <Badge
                    style={{background: campo.cor}}
                    className='m-1 text-white'
                >{campo.descricao}</Badge>    
            )}
        </div>
        <Row
            className='mt-3'
        >
            {graficoDados.map((graficoDado, pos) => 

                <Col
                    md={3}
                    key={pos}
                >
                    <Card
                        className='border-0'
                    >
                        <Card.Body
                            style={{height: '300px'}}
                        >
                            <div>Opção {pos + 1}</div>
                            <ResponsiveContainer>
                                <PieChart >
                                    <Pie
                                        isAnimationActive={false}
                                        data={graficoDado}
                                        labelLine={false}
                                        label={renderCustomizedLabel}
                                        fill="#8884d8"
                                        dataKey="value"
                                        >
                                            {campos.map(campo => 
                                                <Cell 
                                                    key={campo.id}
                                                    // stackId={"a"}
                                                    name={campo.descricao}
                                                    // dataKey={'gordo'}
                                                    key={`cell-${campo.id}`} 
                                                    fill={campo.cor || `#7272e7`}
                                                    // stroke={'black'}
                                                />
                                            )}
                                    </Pie>
                                    <Tooltip
                                    formatter={(value) => {
                                        return `${value.toFixed(3).toString().replace('.', ',')} g/ml`
                                    }}
                                        contentStyle={{
                                            padding: '0.25rem 0.5rem',
                                            border: 'none',
                                            borderRadius: '0.5rem',
                                            background: '#dfdfdf',
                                        }}
                                        itemStyle={{
                                            color: '#515151'
                                        }}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>
            )}
        </Row>
    </>

}