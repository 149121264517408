import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import axios from 'axios';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Container, Button, Form, Row, Col } from 'react-bootstrap';

// Modal para cadastro de receita
export default function ModalCadastrar({
    // menuAlimentoAberto=true,
    // AlimentosConsultaExterna,
    registro=null,
    itemRefeicao=false, // define se é alteração na refeição, ou da refeição padrão
    suplemento='N',
    tipo='R',
    fechou,
    salvou
}){

    // estados
    const { LoginReducer } = useSelector(state => state);
    let [modo, alterarModo] = useState('cadastro') // cadastro, edicao
    let [mostrar, alterarMostrar] = useState(true);
    let [salvando, alterarSalvando] = useState(false);
    let [carregando, alterarCarregando] = useState(true);
    let [dados, alterarDados] = useState({
        pk_cad_receita: null,
        titulo: null,
        descricao: null,
        energia: null,
        titulo: null,
        suplemento: suplemento, // suplemento se o tipo for S (suplemento) u F (formulas magistrais)
        tipo: tipo,
        energia: 0.0,
        publica: 'N'
    });


    // tipo -- R: receita, F: formulas magistrais, S: suplemento

    // inicializa
    useEffect(() => {

        // verifica se foi informado os dados do registro
        if(registro !== null){
            alterarModo('edicao');

            // faz a consulta do código
            consultarId();

        }else{
            alterarCarregando(false);
        }

    }, []);

    // consultar dados da receita
    async function consultarId(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/receita/${registro.pk_cad_receita}`);
            alterarDados(data.dados);

            // se for item da refeição, utilizar descrição e titulo da mesa
            if(itemRefeicao){

                // se o titulo já foi alterado, utiliza
                if(registro.titulo !== null){
                    data.dados.titulo = registro.titulo;
                }

                // se a descrição já foi alterada, utiliza
                if(registro.descricao !== null){
                    data.dados.descricao = registro.descricao;
                }

            }

            // carregado
            alterarCarregando(false);

        }catch({response}){

        }

    }


    // ao salvar, verificar se menu externo de alimentos de está aberto para inclusão de alimento na lista
    async function salvar(){

        // salvando
        alterarSalvando(true);

        // verifica se é alteração local ou global
        if(itemRefeicao){

            // envia os dados atualizados da descrição da receita e titulo
            salvou(dados);
            fechou();

        }else{
            
            try{

                // faz a requisição
                let { data } = await axios.post(`/receita`, dados);
                salvou(data);
                fechou();

            }catch({response}){

            }

        }
        

        
        
        

        // // retorno, quando finaliza, esse deve ser o retorno
        // if(data.status){
        //     salvou();
        //     alterarMostrar(false);
        // }
    }

    // modal de carregando os dados
    if(carregando){
        return <Modal 
            show={mostrar}
            onHide={() => {}}
        >
            <Modal.Body>
                <p>Carregando <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} /></p>
            </Modal.Body>
        </Modal>
    }

    return <Modal 
        show={mostrar} 
        onExited={fechou}
        onHide={() => {}}
        size={'lg'}
    >
        <Modal.Header className='border-0'>
            <Modal.Title>
                Cadastro de 
                { dados.tipo === 'R' && ' receita' }
                { dados.tipo === 'S' && ' suplemento' }
                { dados.tipo === 'F' && ' fórmula magistral' }
            </Modal.Title>

            {/* 
                se for [itemRefeicao], 
                mostrar mensagem de que as alterações 
                só surtiram efeito nessa refeição, nesse item 
            */}

        </Modal.Header>
        <Modal.Body>
            <Container>
                <Form.Group>
                    <Form.Label>Titulo</Form.Label>
                    <Form.Control 
                        value={dados.titulo || ''}
                        onChange={e => {
                            dados.titulo = e.target.value;
                            alterarDados({...dados});
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Descrição</Form.Label>
                    <Form.Control 
                        rows={6} 
                        as='textarea'  
                        value={dados.descricao || ''}
                        onChange={e => {
                            dados.descricao = e.target.value;
                            alterarDados({...dados});
                        }}
                    />
                </Form.Group>
                <Row>
                    <Col lg='8'>
                    
                    </Col>
                    <Col lg='4'>
                        <Form.Group>
                            <Form.Label>Total <b>Kcal</b></Form.Label>
                            <Form.Control 
                                value={dados.energia}
                                onChange={e => {
                                    dados.energia = e.target.value;
                                    alterarDados({...dados});
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
        </Modal.Body>
        <Modal.Footer  className='border-0'>
            <Button
                variant='danger'
                onClick={() => {alterarMostrar(false)}}
                disabled={salvando}
            >
                <span>Fechar</span>
            </Button>
            {   dados.publica === 'N' &&
                <Button
                    onClick={salvar}
                    variant='success'
                    disabled={salvando}
                >
                    {salvando ? 
                        <>
                            <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                            <span>Salvando</span>
                        </> : 
                        <span>Salvar</span>
                    }
                </Button>
            }
        </Modal.Footer>
    </Modal>
}
