import React from 'react';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';
import { Link } from 'react-router-dom';
import ListaCheckbox from './../../../ComponentesCadastro/ListaCheckbox';

// cria os parametros
function Parametros({
    parametros, 
    alterarParametros
}){

    // parametros
    return <div className="parametros">
        <div className="parametros-inicial">
            {/* <Link to='/painel/usuarios/convidar'>
                <Button variant="padrao" className="botao-cadastrar">
                    <FontAwesomeIcon className="icone" icon={["fas", "user-plus"]} />
                    <span>Aumentar Equipe</span>
                </Button>
            </Link> */}
            <DebounceInput 
                placeholder="Ex.: Roger Waters" 
                debounceTimeout={400}
                element={Form.Control} 
                value={parametros.texto}
                onChange={(e) =>{
                    alterarParametros({...parametros, texto: e.target.value, pagina: 1})
                }}
            />
            <div className="campo-flags">
                <ListaCheckbox 
                    itens={[
                        {id: 'A', label: 'Ativos'},
                        {id: 'I', label: 'Inativos'}
                    ]}
                    value={parametros.status}
                    label='tipo-pessoa'
                    onChange={(ids) => {
                        parametros.status = ids;
                        alterarParametros({...parametros, pagina: 1});
                    }}
                />
            </div>
        </div>

    </div>

}


export default Parametros;