import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import InputConsultar from './InputConsultar';
import FiltroPasta from './FiltroPasta';
import Alimentos from './Alimentos';

// contextos
import { PlanoAlimentarContexto } from './../../../ComponentePlanoAlimentar';

// exporta contexto 
export const consultaAlimentosContexto = React.createContext(null);

// consulta alimentos para inserir ao plano
export default function ConsultaAimentos(){

    // pega o contexto do plano
    let planoDados = useContext(PlanoAlimentarContexto);

    // estados
    let [mostrar, alterarMostrar] = useState(true);
    let [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: 10,
        pasta: null, // null, alimentos_tucunduva, formulas_magistrais, suplementos, receitas, minhas_formulas_magistrais, meus_suplementos, minhas_receitas
        texto: ''
    });

    // parametros pasta
    let pastas = [
        {id: null, descricao: 'Todos'},
        {id: 'minhas_receitas', descricao: 'Minhas receitas'},
        {id: 'meus_alimentos', descricao: 'Meus alimentos'},
        {id: 'meus_suplementos', descricao: 'Meus suplementos'},
        {id: 'minhas_formulas_magistrais', descricao: 'Minhas formúlas magistrais'},
        {id: 'receitas', descricao: 'Receitas'},
        {id: 'suplementos', descricao: 'Suplementos'},
        {id: 'formulas_magistrais', descricao: 'Fórmulas magistrais'},
        {id: 'alimentos_tucunduva', descricao: 'Alimentos Tucunduva'}
    ];

    // inicializa
    useEffect(() => {

        consultar();

    }, [parametros]);

    // faz a consulta
    async function consultar(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/alimento`, {
                params: parametros
            });
            if(parametros.pagina === 1){
                planoDados.alterarConsulta(data.registros);
            }else{
                planoDados.alterarConsulta([...planoDados.consulta, ...data.registros]);
            }

        }catch({response}){

        }

    }

    return <consultaAlimentosContexto.Provider
        value={{
            mostrar, alterarMostrar,
            parametros, alterarParametros,
            pastas
        }}
    >
        <div
            className={[styles.consulta, mostrar ? styles.mostrar : ''].join(' ')}
        >
            <FiltroPasta 
                clicouMostrarEsconder={() => {
                    alterarMostrar(!mostrar);
                }}
                mostrar={mostrar}
            />
            {mostrar &&
                <>
                    <InputConsultar />
                    <Alimentos />
                </>
            }
            
            {/* {planoDados.modo} */}
        </div>
    </consultaAlimentosContexto.Provider>

}
