import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Container, Button, Form } from 'react-bootstrap';

// modal de confirmação
function ModalConfirmar({
    titulo,
    descricao='',
    confirmar,
    cancelar,
    textoConfirmar='Confirmar',
    textoCancelar='Cancelar',
    fechou
}){

    // estados
    let [mostrar, alterarMostrar] = useState(true);

    useEffect(() => {
        console.log(descricao);
    }, []);

    return <Modal
        show={mostrar}
        onExited={fechou}
        onHide={() => {}}
        centered
    >   
        { titulo &&
            <Modal.Header>
                <Modal.Title>{titulo}</Modal.Title>
            </Modal.Header>
        }
        <Modal.Body className='text-center'>
            {descricao}
        </Modal.Body>
        <Modal.Footer className='text-right border-0'>

            <Button
                variant='danger'
                size='sm'
                onClick={() => {
                    alterarMostrar(false);
                    cancelar();
                }}
            >
                {textoCancelar}
            </Button>

            <Button
                variant='success'
                size='sm'
                onClick={() => {
                    alterarMostrar(false);
                    confirmar();
                }}
            >
                {textoConfirmar}
            </Button>

        </Modal.Footer>
    </Modal>

}

export default ModalConfirmar;