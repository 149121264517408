import React, { useState } from 'react';
import axios from 'axios';

// componentes
import { Card, Form, Modal, Button } from 'react-bootstrap';
import BotaoTabela from './../../../../ComponentesCadastro/BotaoTabela';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';

// componente
export default function Inativar({
    dados,
    alterouStatus
}){

    // estados
    const [confirmar, alterarConfirmar] = useState(false);
    const [salvando, alterarSalvando] = useState(false);

    // salva
    async function salvar(){
        alterarSalvando(true);

        try{

            // faz a requisição
            let statusNovo = (dados.status === 'A') ? 'I' : 'A';
            let { data } = await axios.post(`/paciente`, {
                pk_cad_paciente: dados.pk_cad_paciente,
                status: statusNovo
            });
            alterouStatus(statusNovo);
            alterarConfirmar(false);

        }catch({response}){}
        finally{
            alterarSalvando(false);
        }

        
    }

    return <>
        <BotaoTabela 
            onClick={() => {
                alterarConfirmar(true);
            }}
            color={(dados.status === 'A') ? 'green' : 'red'}
            icone={(dados.status === 'A') ? faToggleOn : faToggleOff}
        >
            {(dados.status === 'A') ? 'Ativado' : 'Inativado'}
        </BotaoTabela>

        <Modal
            show={confirmar}
            onHide={() => {}}
        >
            <Modal.Body>
                <p
                    className={'text-center'}
                >
                    Confirma a {(dados.status === 'A') ? 'inativação' : 'ativação'} do paciente?
                </p>
                <div
                    className='d-flex justify-content-around'
                >
                    <Button variant="danger" onClick={() => {alterarConfirmar(false)}} disabled={salvando}>Cancelar</Button>
                    <Button variant="success" disabled={salvando} onClick={salvar}>
                        {salvando ? 
                            <>
                                <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                                <span>Desistir</span>
                            </> : 
                            <span>Sim, quero {(dados.status === 'A') ? 'inativar' : 'ativar'}</span>
                        }
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    </>
}