import React from 'react';

// componentes
import { Card, Row, Col } from 'react-bootstrap';

// finalizado
function Finalizado({

}){

    return <>
        <Row>
            <Col lg={{span:6, offset:3}}>
                <Card className='border-0 shadow-sm'>
                    <Card.Body>
                        <h4 className='text-center'>
                            Aguarde a aprovação de seu pagamento!
                        </h4>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>
}

// finalizado
export default Finalizado;