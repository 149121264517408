import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';

// componentes
import { calcularNutrienteAlimento } from './../../PlanoNutrientes';
import { Button } from 'react-bootstrap';

// refeição
function Refeicao({
    dados,
    campos
}){

    // define as opçoes que seram apresentadas
    let [opcoesMostrar, alterarOpcoesMostrar] = useState([]);

    // inicializa
    useEffect(() => {

        // se possuir opções
        if(dados._opcoes.length > 0){
            alterarOpcoesMostrar([0]);
        }

    }, []);

    // retorna elemento contendo dados do alimento
    function dadosMedidaAlimento(item){

        // se possuir medida caseira vinculada
        if(item.fk_cad_alimento_medida_caseira !== null){

            // busca medida
            let medidaCaseira = item._dadosAlimento._medidas.find(medida => {
                if(item.fk_cad_alimento_medida_caseira === medida.pk_cad_alimento_medida_caseira){
                    return true;
                }
                return false;
            });

            // retorna dados do alimento
            return <>
                <td>{parseFloat(medidaCaseira.quantidade)}</td>
                <td>{medidaCaseira.descricao}</td>
            </>

        }else{
            return <><td></td><td></td></>
        }

    }

    return <>
        <thead
            className={styles.cabecalho}
        >
            <tr>
                <th
                    className={styles.descricao}
                >
                    <span>{dados.descricao}</span>

                    {/* mostrar, esconder opções */}
                    { dados._opcoes.map((opcao, b) => 
                        <Button 
                            key={b}
                            variant={opcoesMostrar.indexOf(b) >= 0 ? 'success' : 'padrao'}
                            size='sm'
                            className={[styles.opcaoMostrar, 'mr-2'].join(' ')}
                            onClick={() => {
                                if(opcoesMostrar.indexOf(b) >= 0){
                                    opcoesMostrar.splice(opcoesMostrar.indexOf(b), 1);
                                }else{
                                    opcoesMostrar.push(b);
                                }
                                alterarOpcoesMostrar([...opcoesMostrar]);
                            }}
                        >
                            {b + 1}
                        </Button>
                    )}
                </th>

                {/* completa cabelçalho até o final */}
                <th colSpan={3 + campos.length}></th>
            </tr>
        </thead>

        {/* mostra opções */}
        { dados._opcoes.map((opcao, b) => 
            
            // se opção tiver inclusa para mostrar
            (opcoesMostrar.includes(b)) &&
            <React.Fragment key={b}>
                <thead
                    className={styles.opcaoCabecalho}
                >
                    <tr>
                        <th>Opção { b + 1 }</th>
                        <th colSpan={3 + campos.length}></th>
                    </tr>
                </thead>
                <tbody
                    className={[styles.opcaoItens, ''].join(' ')}
                >
                    { opcao.map((item, c) => 
                        ['A', 'R'].includes(item.categoria) &&
                        <tr 
                            key={item.categoria + item.codigo || item._tempId}
                        >
                            <td
                                className='p-1 text-left'
                            >
                                {item.descricao}
                            </td>
                            <td>{item.quantidade && parseFloat(item.quantidade).toString().replace('.', ',')}</td>
                            { item.categoria === 'A' ?
                                dadosMedidaAlimento(item)
                                :
                                <><td></td><td></td></>
                            }
                            { campos.map(campo => 
                                <td key={campo.id}>
                                    {calcularNutrienteAlimento(item, campo.id).toFixed(2).replace('.', ',')}
                                </td>    
                            )}
                        </tr>
                    )}
                </tbody>
            </React.Fragment>

        )}

    </>

}

export default Refeicao;