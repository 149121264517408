import React, { useEffect, useContext } from 'react';
import styles from './index.module.scss';

// componentes
import { Draggable } from 'react-beautiful-dnd';
import ItemDados from './ItemDados';
import BotaoOpcao from './../../../../../BotaoOpcao';

// importa contexto
import { PlanoAlimentarContexto } from './../../../../../../../../ComponentePlanoAlimentar';

// componente
function Item({
    dados,
    index,
    indexOpcao,
    indexRefeicao
}){

    // contexto
    let planoDados = useContext(PlanoAlimentarContexto);

    // remove o item
    function removerItem(){
        planoDados.refeicoes[indexRefeicao]._opcoes[indexOpcao].splice(index, 1);
        planoDados.alterarRefeicoes([...planoDados.refeicoes]);
    }

    useEffect(() => {
        // console.log(indexRefeicao, indexOpcao, index, dados);
    }, [])

    return <Draggable
        draggableId={`item-${indexRefeicao}-${indexOpcao}-${index}`} 
        index={index}
    >
        {(provided, snapshot) => (
            <div
                className={[
                    styles.item, 
                    'p-1 mb-2 border-bottom border-right rounded bg-light'
                ].join(' ')}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                <ItemDados 
                    dados={dados}
                />
                <div
                    className={styles.itemOpcoes}
                >

                    { dados.categoria === 'R' &&
                        <BotaoOpcao 
                            onClick={() => {
                                
                                // alterar a receita da refeição
                                planoDados.alterarCadastrarReceita({
                                    itemRefeicao: true,
                                    registro: Object.assign(
                                        {}, dados._dadosReceita, {
                                            titulo: dados.descricao,
                                            descricao: dados.descricao_receita
                                        }
                                    ),
                                    salvou: (dadosNovos) => {
                                        // planoDados.alterarConsulta([dadosNovos.item, ...planoDados.consulta]);
                                        dados.descricao = dadosNovos.titulo;
                                        dados.descricao_receita = dadosNovos.descricao;
                                        planoDados.alterarRefeicoes([...planoDados.refeicoes]);
                                    }
                                });
                            }}
                            tooltip='Editar'
                            icone={["fas", "pencil-alt"]}
                            placement='bottom'
                            className='mr-2'
                        />
                    }
                    
                    <BotaoOpcao 
                        onClick={removerItem}
                        tooltip='Remover Alimento'
                        icone={["fas", "times"]}
                        placement='bottom'
                    />
                </div>
            </div>
        )}
    </Draggable>

    return 

}

export default Item;