import React, { useState } from 'react';
import './index.module.scss';
import './LoginPagina.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import useIsMounted from 'ismounted';

// componentes
import { Alert, Button } from 'react-bootstrap';
import UsuarioServico from './../../servicos/UsuarioServico';
import backGroundImage from './../../assets/shutterstock_478777894.jpg';
import logoEvolutts from './../../assets/logotipo_evolutts.png';

// pagina login
export default function Login(){

    // define se está carregando
    const dispatch = useDispatch();
    const history = useHistory();
    const isMounted = useIsMounted();
    const [carregando, alterarCarregando] = useState(false);
    let [erros, alterarErros] = useState({});

    // define dados do formulário
    let [dados, updateDados] = useState({
        email: '',
        senha: ''
    });

    async function clicouEntrar(){
        alterarCarregando(true);
        alterarErros({});

        try{

            // faz a requisição
            let { data } = await axios.post(`/login`, dados);
            console.log(data);// altera os dados do usuário
            dispatch({
                type: 'loginReducer/FAZER_LOGIN',
                dadosUsuario: data.dadosUsuario,
                dadosEmpresa: data.dadosEmpresa,
                dadosUsuarioEmpresa: data.dadosUsuarioEmpresa
            });

        }catch({response}){
            if(response){
                if(response.status === 400){
                    alterarErros(response.data)
                }
            }
        }finally{
            if (isMounted.current) {
                alterarCarregando(false);
            }
        }

    };


    // retorna componente
    return <div className="container-fluid login-pagina">
        <div className="row no-gutter">
            <div 
                className="d-none d-md-flex col-md-4 col-lg-6 bg-image" 
                style={{
                    background: `linear-gradient(rgba(0, 0, 0, 0.45),rgba(0, 0, 0, 0.45)),url(${backGroundImage})`,
                    backgroundSize: `cover`
                }}
            >
                <img src={logoEvolutts} />
            </div>
            <div className="col-md-8 col-lg-6">
                <div className="login d-flex align-items-center py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-lg-8 mx-auto">
                            <h3 className="login-heading mb-4">Bem vindo!</h3>
                                <div className="form-label-group">
                                    <input 
                                        type="text" id="inputEmail"
                                        className={["form-control", (erros.email) ? 'input-erro' : ''].join(' ')} 
                                        placeholder="Endereço de email" 
                                        required autoFocus
                                        value={dados.email}
                                        onChange={(e) => {
                                            updateDados({
                                                ...dados,
                                                email: e.target.value
                                            });
                                        }}
                                        onKeyDown={e => {
                                            if(e.keyCode === 13) clicouEntrar(e);
                                        }}
                                    />
                                    <label htmlFor="inputEmail">Endereço de email</label>
                                    { erros.email && 
                                        <Alert className="alerta-erro" dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.email}</p></Alert>
                                    }
                                </div>

                                <div className="form-label-group">
                                    <input 
                                        id="inputConfirmPassword"
                                        type="password"
                                        className={["form-control", (erros.senha) ? 'input-erro' : ''].join(' ')}
                                        placeholder="Senha" required 
                                        value={dados.senha}
                                        onChange={(e) => {
                                            updateDados({
                                                ...dados,
                                                senha: e.target.value
                                            });
                                        }}
                                        onKeyDown={e => {
                                            if(e.keyCode === 13) clicouEntrar(e);
                                        }}
                                    />
                                    <label htmlFor="inputConfirmPassword">Senha</label>
                                    {(erros.senha) && 
                                        <Alert className="alerta-erro" dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.senha}</p></Alert>
                                    }
                                </div>

                                {/* <div className="custom-control custom-checkbox mb-3">
                                    <input type="checkbox" className="custom-control-input" id="customCheck1"/>
                                    <label className="custom-control-label" htmlFor="customCheck1">Lembrar</label>
                                </div> */}
                                <button 
                                    onClick={clicouEntrar} 
                                    className="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2" 
                                    type="submit"
                                    disabled={carregando}
                                >
                                {carregando ? 
                                   (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) : ''
                                }
                                {carregando ? ' Carregando' : 'Entrar' }
                                </button>

                                <p className='text-center mt-3'>
                                    <Button
                                        variant='success'
                                        className='rounded-pill  px-4'
                                        size='sm'
                                        onClick={() => {
                                            history.push('/cadastro');
                                        }}
                                    >
                                        <span>Experimente grátis</span>
                                    </Button>
                                </p>
                                {/* <div className="text-center">
                                    <Link className="small" to="lembrarSenha">Esqueceu a senha?</Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}
