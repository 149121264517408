import React, { useContext } from 'react';
import styles from './index.module.scss';

// componentes
import { Droppable } from 'react-beautiful-dnd';
import Item from './Item';
import BotaoOpcao from './../../../../BotaoOpcao';

// importa contexto
import { PlanoAlimentarContexto } from './../../../../../../../ComponentePlanoAlimentar';


function Opcao({
    dados,
    ultimaOpcao=false,
    index,
    indexRefeicao
}){

    // contexto
    let planoDados = useContext(PlanoAlimentarContexto);

    // remove opção
    function removerOpcao(){
        planoDados.refeicoes[indexRefeicao]._opcoes.splice(index, 1);
        planoDados.alterarRefeicoes([...planoDados.refeicoes]);
    }

    // adicionar comentário ao final da opção
    function incluirComentario(){

        // insere texto livre em branco
        planoDados.refeicoes[indexRefeicao]._opcoes[index].push(planoDados.criarItemComentario());
        planoDados.alterarRefeicoes([...planoDados.refeicoes]);
    }

    return <div
        className={[
            styles.container,
            'rounded mb-2'
        ].join(' ')}
    >
        <div 
            className={styles.opcaoMenuOpcoes}
        >
            <BotaoOpcao 
                onClick={incluirComentario}
                tooltip='Adicionar comentário'
                icone={["fas", "comment"]}
                placement='bottom'
                className='mr-3'
            />
            <BotaoOpcao 
                onClick={removerOpcao}
                tooltip='Remover Opção'
                icone={["fas", "times"]}
                placement='bottom'
            />
        </div>

        <div 
            className='font-weight-bold  mb-2'
        >
            Opção: {index+1}
        </div>
        <Droppable 
            droppableId={`itens-${indexRefeicao}-${index}`} 
            type='itens'
        >
            {(provided, snapshot) => (
                <div
                    className={[
                        styles.itensContainer, 
                        'rounded'
                    ].join(' ')}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    { dados.map((itemDados, indexItem) =>
                        <Item 
                            key={itemDados.pk_cad_plano_alimentar_bloco_item || itemDados._tempId}
                            dados={itemDados}
                            index={indexItem}
                            indexOpcao={index}
                            indexRefeicao={indexRefeicao}
                        />
                    )}
                    
                    {ultimaOpcao && 
                        <div 
                            className={[
                                styles.areaDroparItem, 
                                'rounded px-1'
                            ].join(' ')}
                        >
                            <span>Arraste o alimento aqui!</span>
                        </div>
                    }
                    {provided.placeholder}
                    
                </div>
            )}
        </Droppable>


    </div>

}

export default Opcao;