let padding = '0 0px';
let customStyles = {
    control: (base, state) => ({
        ...base,
        height: '24px',
        minHeight: '24px',
        padding: padding,
        border: 'none', 
        boxShadow: 'none',
        background: 'none',
        '&:hover': { border: 'none', boxShadow: 'none' },
        '&:focus': { border: 'none', outLine: '0', boxShadow: 'none'  }
    }),
    valueContainer: base => ({
        ...base,
        padding: padding,
        lineHeight: '24px',
        height: '24px',
        minHeight: '24px',
        verticalAlign: 'middle',
    }),
    indicatorsContainer: provided => ({
        ...provided,
        padding: padding,
        lineHeight: '24px',
        height: '24px',
        minHeight: '24px',
        verticalAlign: 'middle',
        fontSize: '14px',
    }),
    input: base => ({
        ...base,
        margin: '0px',
        padding: padding
    })
};

export default customStyles;