import React, { useState, useEffect } from 'react';
import Flatpick from 'react-flatpickr';
import { Portuguese } from "flatpickr/dist/l10n/pt";
import TimeField from 'react-simple-timefield';
import moment from 'moment';
import { Form, Row, Col } from 'react-bootstrap';

function DiaAgendamento({
    data,
    alterou
}){
    
    // converte dados
    let dados = {
        data: moment(data).format('YYYY-MM-DD'),
        hora: moment(data).format('HH:mm:ss'),
    };
    
    return <Row className='mb-3'>
        <Col >
            <Form.Label className='col-form-label-sm m-0'>Data</Form.Label>
            <Flatpick 
                className="form-control bg-white" 
                value={dados.data}
                options={{
                    dateFormat: "Y-m-d",
                    altFormat: "d/m/Y",
                    altInput: true,
                    locale: Portuguese,
                    defaultDate: dados.data,
                    onChange: (date, dateStr, instance) => {
                        if(dateStr !== ''){
                            alterou(`${dateStr} ${dados.hora}`)
                        }
                        
                    }
                }}
            />
        </Col>

        <Col>
            <Form.Label className='col-form-label-sm m-0'>Hora</Form.Label>
            <TimeField 
                value={dados.hora || '00:00'}
                onChange={(value) => {
                    if(value === '00:00'){
                        // value = null
                    }else{
                        value += ':00';
                    }
                    alterou(`${dados.data} ${value}`);
                }}
                onFocus={(e) => {
                    e.target.select();
                }}
                input={<Form.Control  />}
                colon=":"
            />
        </Col>

    </Row>
    
}

export default DiaAgendamento;