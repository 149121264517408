import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';

// componentes
import { Card, Button } from 'react-bootstrap';
import Refeicao from './Refeicao';
import CabecalhoCampos from './CabecalhoCampos';
import Graficos from './Graficos';

// importa contexto
import { PlanoAlimentarContexto } from './../../ComponentePlanoAlimentar';

// calcula as calorias do alimento
export function calcularNutrienteAlimento(item, campo = 'energia'){

    // se for alimento
    if(item.categoria === 'A' && item.fk_cad_alimento_medida_caseira !== null){

        // busca medida caseira
        let medidaCaseira = item._dadosAlimento._medidas.find(medida => {
            if(item.fk_cad_alimento_medida_caseira === medida.pk_cad_alimento_medida_caseira){
                return true;
            }
            return false;
        });

        // faz a regrinha
        let quantidade = parseFloat(item.quantidade);
        let energia = parseFloat(item._dadosAlimento[campo]);
        let porcao = parseFloat(item._dadosAlimento.porcao);
        let porcaoMedida = parseFloat(medidaCaseira.quantidade);

        // se a porção for 0, não divisivel
        if(porcao === 0){
            return 0;
        }

        // calcula as calorias
        console.log(quantidade, energia, porcao, porcaoMedida);
        let kCal = ((energia * porcaoMedida) / porcao) * quantidade;
        return kCal;
    }

    return 0;

}

// componente
function PlanoNutrientes({

}){

    // variavel de contexto
    let planoDados = useContext(PlanoAlimentarContexto);

    // estados
    let [campos, alterarCampos] = useState([
        {id: 'energia', descricao: 'Energia', mostrar: true, cor: '#00cc00'},
        {id: 'proteina', descricao: 'Proteína', mostrar: true, cor: '#FF0000'},
        {id: 'carboidrato', descricao: 'Carboidrato', mostrar: false, cor: '#FE642E'},

        {id: 'proteina_liquida', descricao: 'Proteína liquida', mostrar: false, cor: '#FE9A2E'},
        {id: 'gordura_total', descricao: 'Gordura total', mostrar: false, cor: '#FACC2E'},
        {id: 'calcio', descricao: 'Cálcio', mostrar: false, cor: '#F7FE2E'},
        {id: 'fosforo', descricao: 'Fósforo', mostrar: false, cor: '#BFFF00'},
        {id: 'ferro', descricao: 'Ferro', mostrar: false, cor: '#80FF00'},
        {id: 'vitamina_a', descricao: 'Vitamina A', mostrar: false, cor: '#00FF00'},
        {id: 'vitamina_b1', descricao: 'Vitamina B1', mostrar: false, cor: '#00FFBF'},
        {id: 'vitamina_b2', descricao: 'Vitamina B2', mostrar: false, cor: '#848484'},
        {id: 'niacina', descricao: 'Niacina', mostrar: false, cor: '#FF0040'},
        {id: 'vitamina_c', descricao: 'Vitamina C', mostrar: false, cor: '#B4045F'},
        {id: 'colina', descricao: 'Colina', mostrar: false, cor: '#FF00BF'},
        {id: 'fibra_total', descricao: 'Fibra total', mostrar: false, cor: '#CC2EFA'},
        {id: 'sodio', descricao: 'Sódio', mostrar: false, cor: '#8000FF'},
        {id: 'gordura_poliinsaturada', descricao: 'Gordura poliinsaturada', mostrar: false, cor: '#2E64FE'},
        {id: 'gordura_monosaturada', descricao: 'Gordura monosaturada', mostrar: false, cor: '#A9D0F5'},
        {id: 'gordura_saturada', descricao: 'Gordura saturada', mostrar: false, cor: '#0404B4'},
        {id: 'fibra_soluvel', descricao: 'Fibra Soluvel', mostrar: false, cor: '#04B45F'},
        {id: 'fibra_insoluvel', descricao: 'Fibra insoluvel', mostrar: false, cor: '#D7DF01'},
        {id: 'vitamina_b6', descricao: 'Vitamina B6', mostrar: false, cor: '#B43104'},
        {id: 'vitamina_b12', descricao: 'Vitamina B12', mostrar: false, cor: '#A901DB'},
        {id: 'vitamina_d', descricao: 'Vitamina D', mostrar: false, cor: '#D8F781'},
        {id: 'fosfolipidios', descricao: 'Fósfolipidios', mostrar: false, cor: '#A9A9F5'},
        {id: 'acido_patotenico', descricao: 'Ácido patotênico', mostrar: false, cor: '#E0ECF8'},
        {id: 'vitamina_e', descricao: 'Vitamina E', mostrar: false, cor: '#04B45F'},
        {id: 'iodo', descricao: 'Iodo', mostrar: false, cor: '#F5A9A9'},
        {id: 'magnesio', descricao: 'Magnésio', mostrar: false, cor: '#F6CED8'},
        {id: 'zinco', descricao: 'Zinco', mostrar: false, cor: '#F2F2F2'},
        {id: 'manganes', descricao: 'Manganês', mostrar: false, cor: '#424242'},
        {id: 'vitamina_k', descricao: 'Vitamina K', mostrar: false, cor: '#A9F5F2'},
        {id: 'cobre', descricao: 'Cobre', mostrar: false, cor: '#00BFFF'},
        {id: 'selenio', descricao: 'Selênio', mostrar: false, cor: '#F7BE81'},
    ]);

    {/* dados caloricos dos alimentos escolhidos, com essa opção habilitada, não mostra consulta de alimentos */}
    useEffect(() => {
        console.log(planoDados.refeicoes);
    }, []);

    return <>
        <Card className='ml-2 shadow-sm border-0 w-100'>
            <Card.Body>

                <CabecalhoCampos 
                    campos={campos}
                    alterarCampos={alterarCampos}
                />

                <table className={styles.tabela}>
                    <thead className={styles.cabecalho}>
                        <tr>
                            <th></th>
                            <th>(Q)</th>
                            <th>(g/ml)</th>
                            <th>Medida</th>
                            {campos.filter(campo => campo.mostrar).map(campo => 
                                <th key={campo.id}>{campo.descricao}</th>    
                            )}
                        </tr>
                    </thead>
                    {/* <tbody> */}
                    { planoDados.refeicoes.map((refeicao, a) => 
                        <Refeicao 
                            key={a}
                            dados={refeicao}
                            campos={campos.filter(campo => campo.mostrar)}
                        />
                    )}
                    {/* </tbody> */}
                </table>

                {/* graficos */}

            </Card.Body>
        </Card>

        <Graficos 
            campos={campos.filter(campo => campo.mostrar)}
        />
    </>

};

export default PlanoNutrientes;