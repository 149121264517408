import React from 'react';

// componentes
import { Card, Form, Row, Col, Button } from 'react-bootstrap';

function Exame({
    dados,
    alterou
}){

    return <div className='mb-2 p-2 bg-light rounded'>
        <Form.Row>
            <Form.Label column lg='4'>{dados._dadosExame.descricao}</Form.Label>
            <Col lg={{span: 4, offset: 4}}>
                <Form.Control 
                    value={dados.resultado || ''}
                    onChange={e => {
                        dados.resultado = e.target.value;
                        alterou(dados);
                    }}
                />
            </Col>
        </Form.Row>
    </div>
}

export default Exame;