
// importar icones utilizados do FontAwesomeIcon
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// icones font awesome
import { library } from '@fortawesome/fontawesome-svg-core';

// import { far } from '@fortawesome/free-regular-svg-icons'; 

// library.add(fas, far, fab);

// fas
import { 
    faPhone, faSpinner, faEnvelope, faCopyright, faPowerOff, faInfoCircle,
    faListUl, faUserFriends, faImages, faBookOpen, faPlus, faList, faChartLine,
    faUsers, faEllipsisV, faCalendarAlt, faLandmark, faTrain, faLock, faTrash,
    faPencilAlt, faAngleRight, faAngleLeft, faQuestionCircle, faBackspace,
    faAddressCard, faTicketAlt, faHeart, faDollarSign, faTimes, faCheck, faHandHoldingUsd,
    faMinus, faUserCircle, faCog, faHome, faFileInvoiceDollar, faCoins, 
    faCalendarDay, faCalendarCheck, faSave, faSearchPlus, faSearchMinus, faThumbsUp, faReceipt, 
    faBriefcase, faWallet, faPlusSquare, faBalanceScale, faPiggyBank, faEye, faEyeSlash,
    faBone, faBlind, faSkullCrossbones, faEllipsisH, faCircle, faEngine, faMoneyCheckAlt,
    faBold, faAlignLeft, faAlignCenter, faAlignRight, faAlignJustify, faEraser,
    faPaperPlane, faMailBulk, faEnvelopeOpen, faSyncAlt, faSquare, faCodeBranch,
    faKeyboard, faClipboardCheck, faRocket, faGlobeAmericas, faHandsHelping, faListAlt,
    faBrain, faBalanceScaleRight, faWeight, faFileMedical, faUtensils, faCapsules, faFolder,
    faAppleAlt, faSearch, faCaretRight, faFolderOpen, faPrint, faComment, faCopy, faHighlighter,
    faAngleDown, faColumns, faChevronLeft, faCaretLeft, faPhoneAlt, faMobileAlt, faPaste, faDownload,
    faSignInAlt, faUserClock, faSortAmountDown, faSortAmountDownAlt
} from '@fortawesome/free-solid-svg-icons';




library.add(
    faPhone, faSpinner, faEnvelope, faCopyright, faPowerOff, faInfoCircle,
    faListUl, faUserFriends, faImages, faBookOpen, faPlus, faList, faChartLine,
    faUsers, faEllipsisV, faCalendarAlt, faLandmark, faTrain, faLock, faTrash,
    faPencilAlt, faAngleRight, faAngleLeft, faQuestionCircle, faBackspace,
    faAddressCard, faTicketAlt, faHeart, faDollarSign, faTimes, faCheck, faHandHoldingUsd,
    faMinus, faUserCircle, faCog, faHome, faFileInvoiceDollar, faCoins,
    faCalendarDay, faCalendarCheck, faSave, faSearchPlus, faSearchMinus, faThumbsUp, faReceipt,
    faBriefcase, faWallet, faPlusSquare, faBalanceScale, faPiggyBank, faEye, faEyeSlash,
    faBone, faBlind, faSkullCrossbones, faEllipsisH, faCircle, faMoneyCheckAlt,
    faBold, faAlignLeft, faAlignCenter, faAlignRight, faAlignJustify, faEraser,
    faPaperPlane, faMailBulk, faEnvelopeOpen, faSyncAlt, faSquare, faCodeBranch,
    faKeyboard, faClipboardCheck, faRocket, faGlobeAmericas, faHandsHelping, faListAlt,
    faBrain, faBalanceScaleRight, faWeight, faFileMedical, faUtensils, faCapsules, faFolder,
    faAppleAlt, faSearch, faCaretRight, faFolderOpen, faPrint, faComment, faCopy, faHighlighter,
    faAngleDown, faColumns, faChevronLeft, faCaretLeft, faPhoneAlt, faMobileAlt, faPaste, faDownload,
    faSignInAlt, faUserClock, faSortAmountDown, faSortAmountDownAlt
);