import React from 'react';
import styles from './index.module.scss';

// componentes
import { Row, Col, Card } from 'react-bootstrap';

// cards limitações
function LimitacoesCards({

}){

    return <Row className='align-items-end'>
        <Col lg='6'>
            
            <Card className='shadow-sm border-0'>
                <Card.Header className='text-center bg-transparent border-0'>
                    <Card.Title as='h4'>Versão de teste</Card.Title>
                </Card.Header>
                <Card.Body>
                    <ul>
                        <li>
                            Até 15 pacientes
                        </li>
                        <li>
                            Até 10 cadastros de atendimento por paciente:
                            <ul>
                                <li>Anamnese</li>
                                <li>Rastreamento metabólico</li>
                                <li>Teia de inter relação metabólica</li>
                                <li>Avaliação Antropometrica</li>
                                <li>Bioimpedancia Magnética</li>
                                <li>Solicitação de exames</li>
                                <li>Planos alimentares</li>
                            </ul>
                        </li>
                    </ul>
                </Card.Body>
            </Card>

        </Col>
        <Col lg='6'>
            
            <Card className={[styles.cardVersaoPro, 'shadow-sm border-0'].join(' ')}>
                <Card.Header className='text-center bg-transparent border-0'>
                    <Card.Title as='h4'>Versão completa</Card.Title>
                </Card.Header>
                <Card.Body>
                    <ul>
                        <li>
                            Pacientes ilimitados
                        </li>
                        <li>
                            Atendimentos ilimitados
                        </li>
                    </ul>
                </Card.Body>
            </Card>

        </Col>
    </Row>

}

// 
export default LimitacoesCards