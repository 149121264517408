import React from 'react';
import styles from './index.module.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function BotaoOpcao({
    onClick,
    tooltip,
    icone,
    placement,
    className=''
}){

    return <OverlayTrigger
        placement={placement}
        overlay={
            <Tooltip>
                {tooltip}
            </Tooltip>
        }
        >
        <button 
            className={[styles.botao, className].join(' ')}
            onClick={onClick}
            
        >
            <FontAwesomeIcon  className={[styles.icone].join(' ')} icon={icone}></FontAwesomeIcon>
        </button>
    </OverlayTrigger>
}


export default BotaoOpcao;