import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

// componentes
import { Card, Col, Row } from 'react-bootstrap';
import Carregador from './../../ComponentesCadastro/Carregador';
import PaginacaoComponente from './../../ComponentesCadastro/Paginacao';
import Parametros from './Parametros';
import Registro from './Registro';

// serviços
import UsuarioEmpresaServico from './../../../../servicos/UsuarioEmpresaServico'; 

// função para consulta
export default function Consultar(){

    // estados
    let history = useHistory();
    let login = useSelector(state => state.login);
    let [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: 10,
        status: ['A'],
        texto: '',
        infoUsuario: true
    });
    let [totalRegistros, atualizarTotalRegistros] = useState(0);
    let [carregando, mostrarCarregando] = useState(true);
    let [consulta, alterarConsulta] = useState([]);

    // faz a consulta
    useEffect(() => {

        // // faz a requisição
        // UsuarioEmpresaServico.consultar(parametros, login.dadosUsuarioEmpresa.token)
        // .then(({data, ...retorno}) => {
        //     alterarConsulta(data.registros);
        //     atualizarTotalRegistros(parseInt(data.totalRegistros));
        //     mostrarCarregando(false);
        // });
        consultarRegistros();

    }, [parametros]);

    // consultar registros
    async function consultarRegistros(){
        mostrarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/empresa/usuario`);
            alterarConsulta(data.registros);
            atualizarTotalRegistros(parseInt(data.totalRegistros));

        }catch({response}){}
        finally{
            mostrarCarregando(false);
        }

    }

    return <Row>
        <Col>
            <Card className="painel-consulta border-0 shadow-sm" >
                <Card.Header>
                    <Card.Title as='h4'>Usuários da minha empresa</Card.Title>
                    <Parametros parametros={parametros} alterarParametros={alterarParametros}/>
                </Card.Header>
                <Card.Body>
                    <table className="table table-hover tabela">
                        <thead>
                            <tr>
                                {/* <th>Código</th> */}
                                <th>Nome</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {consulta.map((registro, a) => 
                                <Registro 
                                    key={registro.dados.pk_cad_usuario_empresa} 
                                    dados={registro.dados}
                                    usuario={registro.usuario}
                                    parametros={parametros}
                                    onClickTr={() => console.log('clicou TR')}
                                    // clicouEditar={() => {
                                    //     history.push(`/painel/convenios/alterar/${registro.pk_cad_convenio}`);
                                    // }}
                                    clicouJornada={() => {
                                        history.push(`/painel/usuarios/${registro.dados.pk_cad_usuario_empresa}/jornada`);
                                    }}
                                />
                            )}
                        </tbody>
                    </table>
                    <PaginacaoComponente
                        pagina={parametros.pagina}
                        registrosPorPagina={parametros.registrosPorPagina}
                        totalRegistros={totalRegistros}
                        alterouPagina={(pagina) => {
                            alterarParametros({...parametros, pagina: pagina});
                        }}
                    />
                    {carregando && 
                        <Carregador className="rounded-bottom" />
                    }
                </Card.Body>
            </Card>
        </Col>
    </Row>

}
