import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import InputDecimal from './../../../../../../../../../../ComponentesCadastro/InputDecimal';

// contexto
import { PlanoAlimentarContexto } from './../../../../../../../../../../ComponentePlanoAlimentar';

function MedidaCaseira({
    dados
}){


    // utiliza contexto
    let planoDados = useContext(PlanoAlimentarContexto);

    // dados._dadosAlimento._medidas

    // data_cadastro: "2018-04-07 09:31:39"
    // descricao: "Medida Padrão"
    // fk_cad_alimento: "641"
    // fk_cad_empresa: null
    // pk_cad_alimento_medida_caseira: "2524"
    // quantidade: "100.000"
    // status: "A"

    let [temp, aTemp] = useState('0')

    // ao modificar os dados do item
    function alterarDadosMedida(){

        // PlanoAlimentarContexto
        planoDados.alterarRefeicoes([...planoDados.refeicoes]);

    }


    return <div
        className={styles.medidasCaseira}
    >
        <div className={[styles.medida, 'mt-1'].join(' ')}>
            <InputDecimal 
                size='sm'
                valor={dados.quantidade}
                onChange={valor => {
                    dados.quantidade = valor;
                    alterarDadosMedida();
                }}
                className={styles.quantidade}
                casas={2}
                cortarZeros={true}
            />
            <Form.Control
                size='sm'
                as='select'
                className={styles.opcao}
                variant={'outline-secondary'}
                value={dados.fk_cad_alimento_medida_caseira || ''}
                onChange={e => {
                    dados.fk_cad_alimento_medida_caseira = e.target.value;
                    alterarDadosMedida();
                }}
            >
                {
                    dados.fk_cad_alimento_medida_caseira === null &&
                    <option value=''>Escolha uma medida!</option>
                }

                {   dados._dadosAlimento._medidas.map(medida => 
                    <option
                        key={medida.pk_cad_alimento_medida_caseira}
                        value={medida.pk_cad_alimento_medida_caseira}
                    >

                            {/* parseFloat(valor).toString().replace('.', ',') */}
                        {medida.descricao} ({parseFloat(medida.quantidade).toString().replace('.', ',')}g/ml)
                    </option>
                )}

            </Form.Control>
            <OverlayTrigger
                placement='top'
                overlay={
                    <Tooltip>
                        Cadastrar Medida Caseira
                    </Tooltip>
                }
                >
                <Button 
                    size='sm'
                    className={[styles.novaMedida, 'ml-1'].join(' ')}
                    onClick={() => {
                        planoDados.alterarCadastrarMedidaCaseira({
                            idAlimento: dados._dadosAlimento.pk_cad_alimento
                        });
                    }}
                    // variant={'outline-secondary'}
                    variant='padrao'
                >
                    <FontAwesomeIcon  className={[styles.icone].join(' ')} icon={["fas", 'plus']}></FontAwesomeIcon>
                </Button>
            </OverlayTrigger>
            
        </div>
    </div>
}

export default MedidaCaseira;