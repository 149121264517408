import React, { useContext } from 'react';
import styles from './index.module.scss';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { consultaAlimentosContexto } from './../../../../ConsultaAlimentos';

// ao clicar sobre opção
function Opcao({
    descricao,
    onClick,
    valor
}){

    // contexto com os dados do componente pai
    let consultaAlimentos = useContext(consultaAlimentosContexto);


    return <div 
        className={[
            styles.opcao, 'p-2 rounded mb-2',
            (consultaAlimentos.parametros.pasta === valor) ? styles.selecionar : ''
        ].join(' ')}
        onClick={() => {
            onClick(valor);
        }}
    >
        { (consultaAlimentos.parametros.pasta === valor) ?
            <FontAwesomeIcon className={[styles.icone, 'mt-1'].join(' ')} icon={["far", 'folder-open']} />
        :
            <FontAwesomeIcon className={[styles.icone, 'mt-1'].join(' ')} icon={["far", 'folder']} />
        }
        
        <div className={[styles.descricao, 'ml-2 '].join(' ')}>
            {descricao}
        </div>
    </div>
}

// retorna
export default Opcao;