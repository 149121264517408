import React from 'react';
import styles from './index.module.scss'

// componentes
import { Table } from 'react-bootstrap';
import Item from './Item';

function Opcoes({
    dados,
    registro,
    alterou
}){

    return <>
        <Table className={[styles.tabela, 'tabela'].join(' ')}>
            <thead>
                <tr>
                    <th>{dados.descricao}</th>
                    <th>0</th>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                </tr>
            </thead>
            <tbody>
                {dados.itens.map(itemDado => 
                    <Item 
                        key={itemDado.id}
                        dados={itemDado}
                        registro={registro}
                        alterou={alterou}
                    />
                )}
            </tbody>
        </Table>
    </>
}

export default Opcoes;