import React from 'react';
import './PainelSidebar.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import imagemSidebar from './../../../assets/sidebar-6.jpg';
import logoEvolutts from './../../../assets/logotipo_evolutts.png';

// componente
function PainelSidebar(props){
    
    // propriedades
    return <div className={["painel-sidebar", props.className].join(' ')}>
        <div className="itens">
            <div className="logo">
            <button onClick={props.encolherMenu}>
                <FontAwesomeIcon className="icone" size="2x" icon={["fas", 'list-ul']} />
            </button>
                {/* <Link to='/painel' className="texto-simples">{props.logo}</Link> */}
                <Link className="logo-imagem" to='/painel'>
                    <img  src={logoEvolutts} />
                </Link>
                
            </div>
            <ul className="nav">
                {props.links.map((link, a) => (
                    <li key={a} className="nav-item">
                        <Link to={link.url} className="nav-link">
                            <FontAwesomeIcon className="icone" icon={["fas", link.icone]} />
                            <p>{link.descricao}</p>
                        </Link >
                    </li>
                ))}
            </ul>
        </div>
        <div 
            className="itens-fundo" 
            style={{
                backgroundImage: `url(${imagemSidebar})`
            }}
        ></div>
    </div>
}

// retorno
export default PainelSidebar;