import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';

// compoentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip, Badge, Button  } from 'react-bootstrap';
import { faPencilAlt, faFileAlt } from '@fortawesome/free-solid-svg-icons';

// importa contexto
import { PlanoAlimentarContexto } from './../../../../../../ComponentePlanoAlimentar';

// componente
function ItemDados({
    dados
}){

    // contexto
    let planoDados = useContext(PlanoAlimentarContexto);

    // expandir mais informações
    let [expandir, alterarExpandir] = useState(false);

    return <div
        className={[styles.itemDados, 'p-2 bg-light mb-2 rounded'].join(' ')}
    >
        <div 
            className={styles.descricao}
        >
            {dados.descricao}
        </div>
        <div className='text-right'>
            {dados.categoria === 'A' &&
                <Badge variant="info">{dados._tabela.descricao}</Badge>
            }
        </div>
        
        {(dados.categoria === 'R') && 
            <p className={'text-right m-0 mt-1'}>
                <i
                    className={styles.linkVerMais}
                    onClick={() => {
                        alterarExpandir(!expandir);
                    }}
                ><small>{expandir ? 'Ver menos' : 'Ver mais'} </small></i>
            </p>
        }

        { expandir &&
            <>
                <hr />
                <div className={[styles.descricaoReceita, 'pb-2'].join(' ')} dangerouslySetInnerHTML={{__html: dados._dadosReceita.descricao.replace(/\n/g, "<br />")}}></div>
            </>
        }

        <div
            className={styles.opcoes}
        >
            <button
                className={[styles.botao, 'rounded'].join(' ')}
                onClick={() => {
                    
                    // inclui alimento na refeição
                    planoDados.incluirItemNovoDestacado(dados);

                }}
            >
                <FontAwesomeIcon className={'icone fa-sm'} icon={["fas", "highlighter"]}></FontAwesomeIcon>
                <span>Incluir</span>
            </button>
            <button
                className={[styles.botao, 'rounded mr-2'].join(' ')}
                onClick={() => {
                    
                    // alterar informações do alimento
                    if(dados.categoria === 'A'){
                        planoDados.alterarCadastrarAlimento({
                            registro: dados._dadosAlimento,
                            salvou: (dadosNovos) => {
                                Object.assign(dados, dadosNovos.item);
                                planoDados.alterarConsulta([...planoDados.consulta]);
                            }
                        });
                    }else if(dados.categoria === 'R'){

                        // alterar informação da receita
                        planoDados.alterarCadastrarReceita({
                            registro: dados._dadosReceita,
                            salvou: (dadosNovos) => {
                                Object.assign(dados, dadosNovos.item);
                                planoDados.alterarConsulta([...planoDados.consulta]);
                            }
                        });
                    }

                }}
            >
                <FontAwesomeIcon 
                    className={'icone fa-sm'} 
                    icon={(dados.publica === 'N') ? faPencilAlt : faFileAlt}
                ></FontAwesomeIcon>
                {dados.publica === 'N' ? <span>Editar</span> : <span>Abrir</span>}
            </button>
        </div>
    </div>

}

export default ItemDados;