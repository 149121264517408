import React from 'react';
import styles from './index.module.scss';
import { ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// botão para tabelas
function BotaoTabela({
    onClick,
    icone=null,
    img=null,
    color = '',
    ...props
}){
    
    // botao
    return <ButtonToolbar style={{
        display: 'inline-flex'
    }}>
        <OverlayTrigger 
            placement='top'
            overlay={
            <Tooltip id={`tooltip-top`}>
                {props.children}
            </Tooltip>
            }
        >
            <button {...{
                className: styles.botao,
                onClick: e => {e.stopPropagation(); onClick(e); },
            }}>
                { img && 
                    <img className={styles.img} src={img} />
                }
                { icone &&
                    <FontAwesomeIcon style={{color: color}} className="icone" icon={icone} />
                }
                
            </button>
        </OverlayTrigger>
    </ButtonToolbar>

}


export default BotaoTabela;