import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';

// componentes
import { Button, Card, Row, Col, Badge, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputDecimal from './../../../ComponentesCadastro/InputDecimal';
import GraficoMassa from './GraficoMassa';

// pega os dados, faz o calculo e retorna um objeto contendo as informações corretas
export function calcularDados(dados, dadosPaciente){

    // prepara os dados para retorno
    let calculos = {
        gordura: 0.0,
        pesoGordo: 0.0,
        massaMagra: 0.0,
        imc: 0.0,
        pesoIdeal: 0.0,
        rcq: 0.0,
        geb: 0.0,
        get: 0.0
    };

    // se não tiver carregado o paciente ainda, retorna
    if(dadosPaciente === null){
        return calculos;
    }
    let idade = moment().diff(dadosPaciente.data_nascimento, 'years');

    // calcula peso gordo
    let gordura = 0.0;
    if(dados.tipo === '1'){
        gordura = ((parseFloat(dados.pct) + parseFloat(dados.pcsi) + parseFloat(dados.pca) + parseFloat(dados.pcse)) * 0.153 + 5.783);

    }else if(dados.tipo === '2'){

        // se não possuir data de nascimento o calculo é inválido
        if(dadosPaciente.data_nascimento === null){
            gordura = 0.0;
        }else{

            // calcula
            let st = parseFloat(dados.pcse) + parseFloat(dados.pct) + parseFloat(dados.pca) + parseFloat(dados.pcsi) + parseFloat(dados.coxa) + parseFloat(dados.peitoral) + parseFloat(dados.axilar_media);
            let dc = 0.0;

            // calcula densidade corporal
            if(dadosPaciente.sexo === 'M'){
                dc = 1.112 - (( 0.00043499 * st ) + (0.00000055 * (st * st))) - (0.0002882 * idade);
            }else{
                dc = 1.0970 - (( 0.00046971 * st ) + (0.00000056 * (st * st))) - (0.00012828 * idade);
            }

            // calcula gordura
            gordura = ((4.95 / dc) - 4.5) * 100;
        }
    }

    // finaliza peso gordo
    calculos.gordura = gordura;
    calculos.pesoGordo = gordura * ((parseFloat(dados.pa)) / 100);
    calculos.massaMagra = parseFloat(dados.pa) - calculos.pesoGordo;
    calculos.imc = parseFloat(dados.pa) / ((parseFloat(dados.altura) / 100) * (parseFloat(dados.altura) / 100));
    if(isNaN(calculos.imc) || !isFinite(calculos.imc)){
        calculos.imc = 0.0;
    }

    // calcula peso ideal
    if(dadosPaciente.sexo === 'M'){

        // masculino
        calculos.pesoIdeal = (72.7 * (parseFloat(dados.altura) / 100)) - 58;

    }else{

        // feminino
        calculos.pesoIdeal = (62.1 * (parseFloat(dados.altura) / 100)) - 44.7;
    }

    // relação cintura / quadril
    calculos.rcq = parseFloat(dados.cc) / parseFloat(dados.cq);
    if(isNaN(calculos.rcq) || !isFinite(calculos.rcq)){
        calculos.rcq = 0.0;
    }

    // calcula a geba
    if(dadosPaciente.data_nascimento !== null){

        // calcula geb
        let fatorAtividade = 0.0;
        if(dadosPaciente.sexo === 'M'){
            calculos.geb = 66.47 + (13.75 * parseFloat(dados.pa)) + (5 * parseFloat(dados.altura)) - (6.76 * idade);

            // numero de fator
            fatorAtividade = 1.55;
            if(dados.atividade === 'M'){
                fatorAtividade = 1.78;
            }else if(dados.atividade === 'I'){
                fatorAtividade = 12.1;
            }
        }else{
            calculos.geb = 655 + (9.6 * parseFloat(dados.pa)) + (1.7 * parseFloat(dados.altura)) - (4.7 * idade);

            fatorAtividade = 1.56;
            if(dados.atividade === 'M'){
                fatorAtividade = 1.64;
            }else if(dados.atividade === 'I'){
                fatorAtividade = 1.82;
            }                
        }

        calculos.get = calculos.geb * fatorAtividade;

    }


    return calculos;

}

// componente
export default function Cadastrar(){

    // dados
    const history = useHistory();
    const { id, idpaciente, idagendamento } = useParams();
    let [dados, alterarDados] = useState({
        pk_cad_avaliacao_antropometrica: id || null,
        pa: '0',
        altura: '0',
        cc: '0',
        cq: '0',
        pcse: '0',
        pct: '0',
        pca: '0',
        pcsi: '0',
        coxa: '0',
        peitoral: '0',
        axilar_media: '0',
        tipo: '2',
        atividade: 'L'
    });
    let [calculos, alterarCalculos] = useState({
        gordura: 0.0,
        pesoGordo: 0.0,
        massaMagra: 0.0,
        imc: 0.0,
        pesoIdeal: 0.0,
        rcq: 0.0,
        geb: 0.0,
        get: 0.0
    });
    let [dadosPaciente, alterarDadosPaciente] = useState(null);
    let [carregando, alterarCarregando] = useState(true);
    let [salvando, alterarSalvando] = useState(false);
    let [modo, alterarModo] = useState('cadastro'); // cadastro, edicao

    // carrega os dados
    useEffect(() => {

        // verifica se é edição
        if(dados.pk_cad_avaliacao_antropometrica !== null){
            alterarModo('edicao');
            consultarId();

        }else{
            alterarDados({
                ...dados,
                fk_cad_paciente: idpaciente,
                fk_cad_agenda: idagendamento
            });
            alterarCarregando(false);
        }


    }, []);

    // carrega os dados do paciente
    useEffect(() => {

        // consulta os dados do cliente
        consultarIdPaciente();

    }, []);

    // calcula os totais
    useEffect(() => {

        // altera os calculos
        alterarCalculos(calcularDados(dados, dadosPaciente));

    }, [dados, dadosPaciente]);

    // consulta id
    async function consultarId(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/avaliacaoAntropometrica/${dados.pk_cad_avaliacao_antropometrica}`);
            alterarDados({
                ...dados,
                ...data.dados
            });
            alterarCarregando(false);

        }catch({response}){}


    }

    // consulta id do paciente
    async function consultarIdPaciente(){
        
        try{

            // faz a requisição
            let { data } = await axios.get(`/paciente/${idpaciente}`);
            alterarDadosPaciente(data.dados);

        }catch({response}){}
    }

    // salva
    async function salvar(){
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/avaliacaoAntropometrica`, dados);
            alterarSalvando(false);
            retornar(true);

        }catch({response}){}
    }

    // volta a tela anteiror
    function retornar(paraListagem = false){
        history.goBack();
        // if(match.params.id || paraListagem){
        //     history.push(`/painel/paciente/${match.params.idpaciente}/atender/agendamento/${match.params.idagendamento}/avaliacao-antropometrica`);
        // }else{
        //     history.push(`/painel/paciente/${match.params.idpaciente}/atender/agendamento/${match.params.idagendamento}`);
        // }
    }

    // carregando
    if(carregando){
        return <div>Carregando</div>
    }

    return <>
        <Row>
            <Col lg='4'>
                <Card className='border-0 shadow-sm'>
                    <Card.Body>
                        <Form.Row>
                            <Form.Label column  md='7' className='text-right' >
                                Peso (Kg)
                            </Form.Label>
                            <Col md='5'>
                                <InputDecimal 
                                    valor={dados.pa}
                                    onChange={valorNovo => {
                                        dados.pa = valorNovo;
                                        alterarDados({...dados});
                                    }}
                                    casas={3}
                                    className='text-right'
                                />
                            </Col>
                        </Form.Row>

                        <Form.Row className='mt-2'>
                            <Form.Label column  md='7' className='text-right' >
                                Altura (cm)
                            </Form.Label>
                            <Col md='5'>
                                <InputDecimal 
                                    valor={dados.altura}
                                    onChange={valorNovo => {
                                        dados.altura = valorNovo;
                                        alterarDados({...dados});
                                    }}
                                    casas={1}
                                    className='text-right'
                                />
                            </Col>
                        </Form.Row>

                        <Form.Row className='mt-2'>
                            <Form.Label column  md='7' className='text-right' >
                                Circ. Cintura (cm)
                            </Form.Label>
                            <Col md='5'>
                                <InputDecimal 
                                    valor={dados.cc}
                                    onChange={valorNovo => {
                                        dados.cc = valorNovo;
                                        alterarDados({...dados});
                                    }}
                                    casas={1}
                                    className='text-right'
                                />
                            </Col>
                        </Form.Row>

                        <Form.Row className='mt-2'>
                            <Form.Label column  md='7' className='text-right' >
                                Circ. Quadril (cm)
                            </Form.Label>
                            <Col md='5'>
                                <InputDecimal 
                                    valor={dados.cq}
                                    onChange={valorNovo => {
                                        dados.cq = valorNovo;
                                        alterarDados({...dados});
                                    }}
                                    casas={1}
                                    className='text-right'
                                />
                            </Col>
                        </Form.Row>

                        <Form.Row className='mt-2'>
                            <Form.Label column  md='7' className='text-right' >
                                Subescapular (mm)
                            </Form.Label>
                            <Col md='5'>
                                <InputDecimal 
                                    valor={dados.pcse}
                                    onChange={valorNovo => {
                                        dados.pcse = valorNovo;
                                        alterarDados({...dados});
                                    }}
                                    casas={2}
                                    className='text-right'
                                />
                            </Col>
                        </Form.Row>
                        
                        <Form.Row className='mt-2'>
                            <Form.Label column  md='7' className='text-right' >
                                Tripcital (mm)
                            </Form.Label>
                            <Col md='5'>
                                <InputDecimal 
                                    valor={dados.pct}
                                    onChange={valorNovo => {
                                        dados.pct = valorNovo;
                                        alterarDados({...dados});
                                    }}
                                    casas={2}
                                    className='text-right'
                                />
                            </Col>
                        </Form.Row>
                        
                        <Form.Row className='mt-2'>
                            <Form.Label column  md='7' className='text-right' >
                                Abdominal (mm)
                            </Form.Label>
                            <Col md='5'>
                                <InputDecimal 
                                    valor={dados.pca}
                                    onChange={valorNovo => {
                                        dados.pca = valorNovo;
                                        alterarDados({...dados});
                                    }}
                                    casas={2}
                                    className='text-right'
                                />
                            </Col>
                        </Form.Row>
                        
                        <Form.Row className='mt-2'>
                            <Form.Label column  md='7' className='text-right' >
                                Suprailíaca (mm)
                            </Form.Label>
                            <Col md='5'>
                                <InputDecimal 
                                    valor={dados.pcsi}
                                    onChange={valorNovo => {
                                        dados.pcsi = valorNovo;
                                        alterarDados({...dados});
                                    }}
                                    casas={2}
                                    className='text-right'
                                />
                            </Col>
                        </Form.Row>

                        { dados.tipo === '2' && 
                            <>
                                <Form.Row className='mt-2'>
                                    <Form.Label column  md='7' className='text-right' >
                                        Coxa (mm)
                                    </Form.Label>
                                    <Col md='5'>
                                        <InputDecimal 
                                            valor={dados.coxa}
                                            onChange={valorNovo => {
                                                dados.coxa = valorNovo;
                                                alterarDados({...dados});
                                            }}
                                            casas={0}
                                            className='text-right'
                                        />
                                    </Col>
                                </Form.Row>

                                <Form.Row className='mt-2'>
                                    <Form.Label column  md='7' className='text-right' >
                                        Peitoral (mm)
                                    </Form.Label>
                                    <Col md='5'>
                                        <InputDecimal 
                                            valor={dados.peitoral}
                                            onChange={valorNovo => {
                                                dados.peitoral = valorNovo;
                                                alterarDados({...dados});
                                            }}
                                            casas={0}
                                            className='text-right'
                                        />
                                    </Col>
                                </Form.Row>
                                
                                <Form.Row className='mt-2'>
                                    <Form.Label column  md='7' className='text-right' >
                                        Axilar Média (mm)
                                    </Form.Label>
                                    <Col md='5'>
                                        <InputDecimal 
                                            valor={dados.axilar_media}
                                            onChange={valorNovo => {
                                                dados.axilar_media = valorNovo;
                                                alterarDados({...dados});
                                            }}
                                            casas={0}
                                            className='text-right'
                                        />
                                    </Col>
                                </Form.Row>
                                
                                <Form.Row className='mt-2'>
                                    <Form.Label column  md='7' className='text-right' >
                                        Atividade
                                    </Form.Label>
                                    <Col md='5'>
                                        <Form.Control
                                            as='select'
                                            value={dados.atividade}
                                            onChange={e => {
                                                dados.atividade = e.target.value;
                                                alterarDados({...dados});
                                            }}
                                        >
                                            {[
                                                { value: 'L', children: 'Leve'},
                                                { value: 'M', children: 'Moderada'},
                                                { value: 'I', children: 'Intensa'}
                                            ].map(o => 
                                                <option key={o.value} {...o} />
                                            )}
                                        </Form.Control>
                                    </Col>
                                </Form.Row>
                            </>
                        }

                    </Card.Body>
                </Card>
            </Col>
            <Col lg='8'>
                <Card className='border-0 shadow-sm'>
                    <Card.Body>
                        <Form.Row className='mt-2'>
                            <Form.Label column  md='3' className='' >
                                Protocolo
                            </Form.Label>
                            <Col md='9'>
                                <Form.Control
                                    as='select'
                                    value={dados.tipo}
                                    onChange={e => {
                                        dados.tipo = e.target.value;
                                        alterarDados({...dados});
                                    }}
                                >
                                    {[
                                        { value: '1', children: 'Protocolo de Faulkner, 1968 - 4 Dobras cutâneas'},
                                        { value: '2', children: 'Protocolo de Pollock, 1984 - 7 Dobras cutâneas'}
                                    ].map(o => 
                                        <option key={o.value} {...o} />
                                    )}
                                </Form.Control>
                            </Col>
                        </Form.Row>
                    </Card.Body>
                </Card>

                <Row>
                    <Col lg='8'>
                        <Card className='border-0 shadow-sm mt-4'>
                            <Card.Body>

                                <Form.Row>
                                    <Form.Label column lg='7' className='col-form-label-sm'>Peso gordo</Form.Label>
                                    <Col lg={{span: 5}}>
                                        <p className='text-right'>
                                            {calculos.pesoGordo.toFixed(3).replace('.', ',')} <small>Kg</small>
                                        </p>
                                    </Col>
                                </Form.Row>
                                
                                <Form.Row>
                                    <Form.Label column lg='7' className='col-form-label-sm'>Massa magra</Form.Label>
                                    <Col lg={{span: 5}}>
                                        <p className='text-right'>
                                        {calculos.massaMagra.toFixed(3).replace('.', ',')} <small>Kg</small>
                                        </p>
                                    </Col>
                                </Form.Row>
                                
                                <Form.Row>
                                    <Form.Label column lg='7' className='col-form-label-sm'>
                                        Peso Ideal no intervalo de
                                    </Form.Label>
                                    <Col lg={{span: 5}}>
                                        <p className='text-right '>
                                        {(calculos.pesoIdeal - 2).toFixed(3).replace('.', ',')} <small>Kg</small>
                                        <small className='mx-1'>a</small>
                                        {(calculos.pesoIdeal + 2).toFixed(3).replace('.', ',')} <small>kg</small>
                                        </p>
                                    </Col>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Label column lg='7' className='col-form-label-sm'>Gordura</Form.Label>
                                    <Col lg={{span: 5}}>
                                        <p className='text-right'>
                                        {calculos.gordura.toFixed(2).replace('.', ',')} <small>%</small>
                                        </p>
                                    </Col>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Label column lg='7' className='col-form-label-sm'>IMC <small>(Ìndice de massa corporal)</small></Form.Label>
                                    <Col lg={{span: 5}}>
                                        <p className='text-right m-0'>
                                        {calculos.imc.toFixed(2).replace('.', ',')}
                                        </p>
                                    </Col>
                                </Form.Row>
                                <p className='font-weight-lighter'>
                                    { calculos.imc <= 18.5 && 'Abaixo do peso'}
                                    { (calculos.imc >= 18.6 && calculos.imc <= 24.9) && 'Peso ideal (parabéns)'}
                                    { (calculos.imc >= 25 && calculos.imc <= 29.9) && 'Levemente acima do peso'}
                                    { (calculos.imc >= 30 && calculos.imc <= 34.9) && 'Obesidade grau I'}
                                    { (calculos.imc >= 35 && calculos.imc <= 39.9) && 'Obesidade grau II (severa)'}
                                    { (calculos.imc >= 40) && 'Obesidade grau III (mórbida)'}
                                </p>
                                
                                <Form.Row>
                                    <Form.Label column lg='7' className='col-form-label-sm'>
                                    RC/Q <small>(Relação cintura quadril)</small>
                                    </Form.Label>
                                    <Col lg={{span: 5}}>
                                        <p className='text-right m-0'>
                                        {calculos.rcq.toFixed(2).replace('.', ',')}
                                        </p>
                                    </Col>
                                </Form.Row>
                                <p className='font-weight-lighter'>
                                    {dadosPaciente && dadosPaciente.sexo === 'M' &&
                                        <>
                                            {calculos.rcq > 1 ? 
                                                <span className='text-danger'>Indicatório de sindrome metabólica</span>
                                                :
                                                <>Relação normal</>
                                            }
                                        </>
                                    }
                                    {dadosPaciente && dadosPaciente.sexo === 'F' &&
                                        <>
                                            {calculos.rcq > 0.85 ? 
                                                <span className='text-danger'>Indicatório de sindrome metabólica</span>
                                                :
                                                <>Relação normal</>
                                            }
                                        </>
                                    }
                                </p>
                                
                                <Form.Row>
                                    <Form.Label column lg='7' className='col-form-label-sm'>
                                    GET <small>(Gasto Energético Total)</small>
                                    </Form.Label>
                                    <Col lg={{span: 5}}>
                                        <p className='text-right'>
                                        {calculos.get.toFixed(4).replace('.', ',')} <small>Kcal</small>
                                        </p>
                                    </Col>
                                </Form.Row>
                                
                                <Form.Row>
                                    <Form.Label column lg='7' className='col-form-label-sm'>
                                    GEB <small>(Gasto Energético Basal)</small>
                                    </Form.Label>
                                    <Col lg={{span: 5}}>
                                        <p className='text-right'>
                                        {calculos.geb.toFixed(1).replace('.', ',')} <small>Kcal</small>
                                        </p>
                                    </Col>
                                </Form.Row>
                                

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col
                        lg={'4'}
                        className='p-0'
                    >
                        <GraficoMassa 
                            calculos={calculos}
                        />
                    </Col>
                </Row>

                <SalvarFormulario 
                    salvando={salvando}
                    cancelar={() => {
                        // history.push('/painel/pacientes');
                        retornar();
                    }}
                    onClick={() => salvar()}
                />
            
            </Col>
        </Row>
    </>
}

function SalvarFormulario({
    salvando,
    cancelar,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button variant="success" disabled={salvando} onClick={onClick}>
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <span>Salvar</span>
            }
        </Button>
    </Form.Group>
}
