import React from 'react';
import styles from './index.module.scss';

// componentes
import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import InputDecimal from './../../../../ComponentesCadastro/InputDecimal';
import InputInteiro from './../../../../ComponentesCadastro/InputInteiro';
import InputHora from './../../../../ComponentesCadastro/InputHora';

// habitos de vida
function FormularioHabitosVida({
    dados,
    alterarDados,
    alterarAba,
    eventoAlterarDados
}){

    // retorna componente
    return <Card className='border-0 shadow-sm'>
        <Card.Body>
            <h3 className='text-center border-bottom mb-3 pb-2'>Hábitos de vida</h3>

            <Form.Row>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Refeições</Form.Label>
                </Col>
                <Col md='9'>
                    <Form.Row>
                        <Col className='pt-2' md='6'>
                            <Form.Check 
                                inline
                                label={'Refeições fora de casa'}
                                id='refeicoes-fora-casa'
                                name='refeicoes_fora_casa'
                                checked={dados.refeicoes_fora_casa === 'S'}
                                onChange={eventoAlterarDados}
                            />
                        </Col>
                        <Col md='6'>
                            <Form.Control 
                                placeholder='Quantas vezes na semana'
                                name='refeicao_vezes_semana'
                                value={dados.refeicao_vezes_semana || ''}
                                onChange={eventoAlterarDados}
                                maxLength='100'
                            />
                        </Col>
                    </Form.Row>
                    
                    <Form.Row className='mt-2'>
                        <Col className='pt-2' md='6'>
                            <Form.Check 
                                inline
                                label={'Refeições em casa'}
                                id='refeicoes-em-casa'
                                name='refeicoes_em_casa'
                                checked={dados.refeicoes_em_casa === 'S'}
                                onChange={eventoAlterarDados}
                            />
                        </Col>
                        <Col md='6'>
                            <Form.Control 
                                placeholder='Quantas vezes na semana'
                                name='refeicao_casa_vezes_semana'
                                value={dados.refeicao_casa_vezes_semana || ''}
                                onChange={eventoAlterarDados}
                                maxLength='100'
                            />
                        </Col>
                    </Form.Row>
                    
                    <Form.Row className='mt-2'>
                        <Col className='pt-2' md='6'>
                            <Form.Check 
                                inline
                                label={'Refeições variadas'}
                                id='refeicoes-variadas'
                                name='refeicoes_variadas'
                                checked={dados.refeicoes_variadas === 'S'}
                                onChange={eventoAlterarDados}
                            />
                        </Col>
                        <Col md='6'>
                            <Form.Control 
                                placeholder='Quantas vezes na semana'
                                name='refeicao_variadas_vezes_semana'
                                value={dados.refeicao_variadas_vezes_semana || ''}
                                onChange={eventoAlterarDados}
                                maxLength='100'
                            />
                        </Col>
                    </Form.Row>
                </Col>
            </Form.Row>



            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Alimentação</Form.Label>
                </Col>
                <Col md='9' className='pt-2'>
                    {[
                        {value:'1', label: 'Onívoro'},
                        {value:'2', label: 'Vegetariano(a)'},
                        {value:'3', label: 'Vegano(a)'}
                    ].map(alimentacao => 
                        <Form.Check 
                            key={alimentacao.value}
                            inline
                            type='radio'
                            {...alimentacao}
                            id={`vegetariano-${alimentacao.value}`}
                            name='vegetariano'
                            checked={dados.vegetariano === alimentacao.value}
                            onChange={e => {
                                dados.vegetariano = e.target.value;
                                if(e.target.value === '2'){
                                    dados.tipo_vegetariano = '1';
                                }else{
                                    dados.tipo_vegetariano = null;
                                }
                                alterarDados({...dados});
                            }}
                        />
                    )}
                </Col>
            </Form.Row>

            { dados.tipo_vegetariano &&
                <Form.Row className='mt-2'>
                    <Col md='3' className='pt-1 text-right'>
                        <Form.Label className='font-weight-bold'>Tipo de Vegetariano</Form.Label>
                    </Col>
                    <Col md='9' className='pt-2'>
                        {[
                            {value:'1', label: 'Vegano'},
                            {value:'2', label: 'Ovolactovegetariano'},
                            {value:'3', label: 'Lacto-vegetariano'},
                            {value:'4', label: 'Vegetariano-estrito'}
                        ].map(alimentacao => 
                            <Form.Check 
                                key={alimentacao.value}
                                inline
                                type='radio'
                                {...alimentacao}
                                id={`tipo-vegetariano-${alimentacao.value}`}
                                name='tipo_vegetariano'
                                checked={dados.tipo_vegetariano === alimentacao.value}
                                onChange={eventoAlterarDados}
                            />
                        )}
                    </Col>
                </Form.Row>
            }

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Álcool</Form.Label>
                </Col>
                <Col md='9'>
                    <Form.Row>
                        <Col className='pt-2' md='6'>
                            <Form.Check 
                                inline
                                label={'Ingestão de bebida alcoólica'}
                                id='alcool-ingestao'
                                name='alcool_ingestao'
                                checked={dados.alcool_ingestao === 'S'}
                                onChange={eventoAlterarDados}
                            />
                        </Col>
                        <Col md='6'>
                            <Form.Control 
                                placeholder='Frequência'
                                name='alcool_frequencia'
                                value={dados.alcool_frequencia || ''}
                                onChange={eventoAlterarDados}
                                maxLength='100'
                            />
                        </Col>
                    </Form.Row>
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Form.Label column md='3' className='font-weight-bold text-right'>Tabagismo</Form.Label>
                <Col md='9' className='pt-2'>
                    {[
                        {value:'N', label: 'Não Fumante'},
                        {value:'S', label: 'Fumante'},
                        {value:'X', label: 'Ex fumante'}
                    ].map(alimentacao => 
                        <Form.Check 
                            key={alimentacao.value}
                            inline
                            type='radio'
                            {...alimentacao}
                            id={`tabagismo-fumante-${alimentacao.value}`}
                            name='tabagismo_fumante'
                            checked={dados.tabagismo_fumante === alimentacao.value}
                            onChange={eventoAlterarDados}
                        />
                    )}
                </Col>
            </Form.Row>
            <Form.Row className='mt-1'>
                <Col md={{span: 9, offset: 3}}>
                    <Form.Control 
                        placeholder='Observação tabagismo'
                        name='tabagismo_frequencia'
                        onChange={eventoAlterarDados}
                        value={dados.tabagismo_frequencia || ''}
                        maxLength='100'
                    />
                </Col>
            </Form.Row>
            

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Mora com quantas pessoas?</Form.Label>
                </Col>
                <Col md='3'>
                    <InputInteiro 
                        valor={dados.mora_quantas_pessoas}
                        onChange={valorNovo => {
                            dados.mora_quantas_pessoas = valorNovo;
                            alterarDados({...dados});
                        }}
                        placeholder='0'
                        max={127}
                        aceitarNull={true}
                    />
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Quem realiza as compras?</Form.Label>
                </Col>
                <Col md='9'>
                    <Form.Control 
                        name='compras_quem_realiza'
                        maxLength='100'
                        value={dados.compras_quem_realiza || ''}
                        onChange={eventoAlterarDados}
                    />
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Onde realiza as compras?</Form.Label>
                </Col>
                <Col md='9'>
                    <Form.Control 
                        name='compras_onde_realiza'
                        value={dados.compras_onde_realiza || ''}
                        onChange={eventoAlterarDados}
                        maxLength='100'
                    />
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Quantas vezes por mês?</Form.Label>
                </Col>
                <Col md='2'>
                    <InputInteiro 
                        valor={dados.compras_quantas_vezes_mes}
                        onChange={valorNovo => {
                            dados.compras_quantas_vezes_mes = valorNovo;
                            alterarDados({...dados});
                        }}
                        placeholder='0'
                        max={127}
                        aceitarNull={true}
                    />
                </Col>
                <Col className='pt-2'>
                    <Form.Check 
                        inline
                        label='Leva filhos para as compras'
                        name='compras_leva_filhos'
                        onChange={eventoAlterarDados}
                        checked={dados.compras_leva_filhos === 'S'}
                        id='compras-leva-filhos'
                    />
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Consumo de óleo por mês?</Form.Label>
                </Col>
                <Col md='3'>
                    <InputDecimal 
                        valor={dados.litros_oleo_mes || '0.0'}
                        onChange={valorNovo => {
                            dados.litros_oleo_mes = valorNovo;
                            alterarDados({...dados});

                        }}
                        casas='3'
                    />
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Tipo de óleo consumido?</Form.Label>
                </Col>
                <Col md='3'>
                    <Form.Control
                        value={dados.tipo_oleo_usado}
                        name='tipo_oleo_usado'
                        onChange={eventoAlterarDados}
                        as='select'
                    >
                        {[
                            {value: '1', children: 'Óleo Vegetal'},
                            {value: '2', children: 'Óleo de Soja'},
                            {value: '3', children: 'Óleo de Algodão'},
                            {value: '4', children: 'Outro'}
                        ].map(opcao => 
                            <option key={opcao.value} {...opcao} />
                        )}
                    </Form.Control>
                </Col>
                <Col md={{span:5, offset: 1}}>
                    <Form.Control 
                        value={dados.tipo_oleo_outro || ''}
                        name='tipo_oleo_outro'
                        onChange={eventoAlterarDados}
                        placeholder='Observação outro'
                        maxLength='100'
                    />
                </Col>
            </Form.Row>


            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Gramas de sal utilizado por mês?</Form.Label>
                </Col>
                <Col md='3'>
                    <InputDecimal 
                        valor={dados.kg_sal_mes || '0.0'}
                        onChange={valorNovo => {
                            dados.kg_sal_mes = valorNovo;
                            alterarDados({...dados});
                        }}
                        casas='3'
                    />
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Gramas de açucar utilizado por mês?</Form.Label>
                </Col>
                <Col md='3'>
                    <InputDecimal 
                        valor={dados.kg_acucar_mes || '0.0'}
                        onChange={valorNovo => {
                            dados.kg_acucar_mes = valorNovo;
                            alterarDados({...dados});
                        }}
                        casas='3'
                    />
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Sono?</Form.Label>
                </Col>
                <Col md='9' className='pt-2'>
                    {[
                        {value:'1', label: 'Dorme Bem'},
                        {value:'2', label: 'Dorme Mal'},
                        {value:'3', label: 'Variado'}
                    ].map(alimentacao => 
                        <Form.Check 
                            key={alimentacao.value}
                            inline
                            type='radio'
                            {...alimentacao}
                            id={`sono-${alimentacao.value}`}
                            name='sono'
                            checked={dados.sono === alimentacao.value}
                            onChange={eventoAlterarDados}
                        />
                    )}
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Quantas horas?</Form.Label>
                </Col>
                <Col md='3'>
                    <InputHora 
                        value={dados.sono_quantas_horas}
                        onChange={hora => {
                            dados.sono_quantas_horas = hora;
                            alterarDados({...dados});
                        }}
                    />
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Observação do sono</Form.Label>
                </Col>
                <Col md='9'>
                    <Form.Control 
                        as='textarea'
                        name='sono_observacao'
                        value={dados.sono_observacao || ''}
                        onChange={eventoAlterarDados}
                        maxLength='1000'
                    />
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Exercicios Físicos</Form.Label>
                </Col>
                <Col md='9'>
                    <Form.Row>
                        <Col md='6'>
                            <Form.Control 
                                as='textarea'
                                name='exercicios_fisicos_quais'
                                value={dados.exercicios_fisicos_quais || ''}
                                onChange={eventoAlterarDados}
                                maxLength='1000'
                            />
                        </Col>
                        <Col md='6'>
                            <Form.Control 
                                as='textarea'
                                name='exercicios_fisicos_observacao'
                                value={dados.exercicios_fisicos_observacao || ''}
                                onChange={eventoAlterarDados}
                                maxLength='1000'
                            />
                        </Col>
                    </Form.Row>
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Patologias Associadas</Form.Label>
                </Col>
                <Col md='9'>
                    <Form.Control 
                        as='textarea'
                        name='patologias_associadas'
                        value={dados.patologias_associadas || ''}
                        onChange={eventoAlterarDados}
                        maxLength='2000'
                    />
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Uso de Medicamentos</Form.Label>
                </Col>
                <Col md='9'>
                    <Form.Control 
                        as='textarea'
                        name='uso_medicamentos'
                        onChange={eventoAlterarDados}
                        value={dados.uso_medicamentos || ''}
                        maxLength='2000'
                    />
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Exames Bioquimicos</Form.Label>
                </Col>
                <Col md='9'>
                    <Form.Control 
                        as='textarea'
                        name='exames_bioquimicos'
                        value={dados.exames_bioquimicos || ''}
                        onChange={eventoAlterarDados}
                        maxLength='2000'
                    />
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Histórico Familiar</Form.Label>
                </Col>
                <Col md='9'>
                    <Form.Control 
                        as='textarea'
                        name='historico_familiar'
                        value={dados.historico_familiar || ''}
                        onChange={eventoAlterarDados}
                        maxLength='2000'
                    />
                </Col>
            </Form.Row>

            <p className='text-right mt-4'>
                <Button
                    size='sm'
                    onClick={() => {
                        alterarAba('avaliacaoClinica');
                    }}
                >
                    <span>Ir para Avaliação Clínica</span>
                </Button>
            </p>
        </Card.Body>
    </Card>
}

export default FormularioHabitosVida;