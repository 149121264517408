import React from 'react';
import styles from './index.module.scss';

// componentes
import { Form, Row, Col } from 'react-bootstrap';
import InputDecimal from './../../../ComponentesCadastro/InputDecimal';

// formulário para informar os dados nutricionais
function DadosNutricionais({
    dados,
    alterarDados
}){

    return <>
        <hr />

        <Form.Row>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Proteína
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.proteina || '0'}
                        onChange={valor => {
                            dados.proteina = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Carboidrato
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.carboidrato || '0'}
                        onChange={valor => {
                            dados.carboidrato = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Lipídios
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.gordura_total || '0'}
                        onChange={valor => {
                            dados.gordura_total = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Selênio
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.selenio || '0'}
                        onChange={valor => {
                            dados.selenio = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
        </Form.Row>
        
        <Form.Row>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Cálcio
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.calcio || '0'}
                        onChange={valor => {
                            dados.calcio = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Fósforo
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.fosforo || '0'}
                        onChange={valor => {
                            dados.fosforo = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Ferro
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.ferro || '0'}
                        onChange={valor => {
                            dados.ferro = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Vitamina A
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.vitamina_a || '0'}
                        onChange={valor => {
                            dados.vitamina_a = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
        </Form.Row>
        
        <Form.Row>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Vitamina B1
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.vitamina_b1 || '0'}
                        onChange={valor => {
                            dados.vitamina_b1 = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Vitamina B2
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.vitamina_b2 || '0'}
                        onChange={valor => {
                            dados.vitamina_b2 = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Niacina
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.niacina || '0'}
                        onChange={valor => {
                            dados.niacina = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Vitamina C
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.vitamina_c || '0'}
                        onChange={valor => {
                            dados.vitamina_c = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
        </Form.Row>
        
        <Form.Row>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Colina
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.colina || '0'}
                        onChange={valor => {
                            dados.colina = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Fibra Total
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.fibra_total || '0'}
                        onChange={valor => {
                            dados.fibra_total = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Sódio
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.sodio || '0'}
                        onChange={valor => {
                            dados.sodio = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Gordura Poliinsaturada
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.gordura_poliinsaturada || '0'}
                        onChange={valor => {
                            dados.gordura_poliinsaturada = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
        </Form.Row>
        
        <Form.Row>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Gordura Monosaturada
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.gordura_monosaturada || '0'}
                        onChange={valor => {
                            dados.gordura_monosaturada = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Gordura Saturada
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.gordura_saturada || '0'}
                        onChange={valor => {
                            dados.gordura_saturada = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Fibra Solúvel
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.fibra_soluvel || '0'}
                        onChange={valor => {
                            dados.fibra_soluvel = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Fibra Insolúvel
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.fibra_insoluvel || '0'}
                        onChange={valor => {
                            dados.fibra_insoluvel = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
        </Form.Row>
        
        <Form.Row>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Vitamina B6
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.vitamina_b6 || '0'}
                        onChange={valor => {
                            dados.vitamina_b6 = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Vitamina B12
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.vitamina_b12 || '0'}
                        onChange={valor => {
                            dados.vitamina_b12 = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Vitamina D
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.vitamina_d || '0'}
                        onChange={valor => {
                            dados.vitamina_d = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Fosfolipídios
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.fosfolipidios || '0'}
                        onChange={valor => {
                            dados.fosfolipidios = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
        </Form.Row>
        
        <Form.Row>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Ácido Pantoténico
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.acido_patotenico || '0'}
                        onChange={valor => {
                            dados.acido_patotenico = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Vitamina E
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.vitamina_e || '0'}
                        onChange={valor => {
                            dados.vitamina_e = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Iodo
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.iodo || '0'}
                        onChange={valor => {
                            dados.iodo = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Magnésio
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.magnesio || '0'}
                        onChange={valor => {
                            dados.magnesio = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
        </Form.Row>
        
        <Form.Row>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Zinco
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.zinco || '0'}
                        onChange={valor => {
                            dados.zinco = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Manganês
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.manganes || '0'}
                        onChange={valor => {
                            dados.manganes = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Vitamina K
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.vitamina_k || '0'}
                        onChange={valor => {
                            dados.vitamina_k = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label
                        className='col-form-label-sm'
                    >
                        Cobre
                    </Form.Label>
                    <InputDecimal 
                        size='sm'
                        valor={dados.cobre || '0'}
                        onChange={valor => {
                            dados.cobre = valor;
                            alterarDados({...dados});
                        }}
                        casas={3}
                        cortarZeros={true}
                    />
                </Form.Group>
            </Col>
        </Form.Row>
    </>
}

export default DadosNutricionais;