import React, { useState } from 'react';
import styles from './index.module.scss';

// componentes
import { Button } from 'react-bootstrap';

function CabecalhoCampos({
    campos,
    alterarCampos
}){

    // estados
    let [ocultar, alterarOcultar] = useState(true);

    // busca campo, habilita ou desabilita
    function habilitarCampo(id){
        let campoBuscar = campos.find(campo => {
            if(campo.id === id){
                return true;
            }
            return false;
        });

        // altera
        campoBuscar.mostrar = !campoBuscar.mostrar;
        alterarCampos([...campos]);
    }

    if(ocultar){
        return <div className='text-right mb-2'>
            <Button
                size='sm'
                variant='padrao'
                onClick={() => {
                    alterarOcultar(!ocultar);
                }}
            >
                Habilitar campos extras?
            </Button>
        </div>
    }

    return <div className='text-right mb-2'>
        { campos.map(campo => 
            <Button
                className='ml-1 mb-1'
                key={campo.id}
                size='sm'
                variant={campo.mostrar ? 'success' : 'danger'}
                onClick={() => {
                    habilitarCampo(campo.id);
                }}
            >
                {campo.descricao}
            </Button>
        )}
        
    </div>
}

export default CabecalhoCampos;