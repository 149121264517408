import React, { useContext } from 'react';
import styles from './index.module.scss';

// contextos
import { PlanoAlimentarContexto } from './../../../../ComponentePlanoAlimentar';
import { consultaAlimentosContexto } from './../../ConsultaAlimentos';
// componentes
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Button } from 'react-bootstrap';
import Item from './Item';

function Alimentos({

}){
    
    // pega o contexto do plano
    let planoDados = useContext(PlanoAlimentarContexto);
    let consultaAlimentos = useContext(consultaAlimentosContexto);

    return <div
        className={['p-1 bg-white rounded'].join(' ')}
    >
        <Droppable 
            isDropDisabled={true}
            droppableId="item-novo" 
            type="itens"
        >
            {(provided, snapshot) => (
                <div
                    className={[styles.lista]}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >   

                    <div className={'p-1'}>
                        
                
                        {planoDados.consulta.map((itemConsulta, index) => 
                            <Item 
                                key={`${itemConsulta.categoria}${itemConsulta.codigo}`}
                                index={index}
                                dados={itemConsulta}
                            />
                        )}
                    </div>

                    <div
                        className={'text-center'}
                    >
                        <Button
                            variant='padrao'
                            onClick={() => {
                                consultaAlimentos.parametros.pagina+= 1;
                                consultaAlimentos.alterarParametros({...consultaAlimentos.parametros});
                            }}
                        >
                            Carregar mais
                        </Button>
                    </div>
                    
                    {/* {provided.placeholder} */} 
                </div>
            )}
        </Droppable>
        
    </div>

}

export default Alimentos;