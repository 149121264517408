import React, { useState, useEffect } from 'react';

function TeiaSvg({
    value,
    onChange
}){

    // atributos do svg
    let configSvg = {
        width: 960,
        height: 660
    }
    let cW = 70;
    let cH = 45;

    // margens
    let margem1 = 220; 
    let margem2 = 170;
    let top = 20;

    let [elementos, alterarElementos] = useState([
        {
            id: 1, 
            nome: 'opcao_1', 
            descricao: 'Desequilibrios Nutricionais', 
            c: [1, 2, 3, 4, 5],  // se ativo manter esses ligado
            x: (configSvg.width / 2) - cW , 
            y: 20 + top,
            componente: <>
                <tspan x="70" y="3" dy="0em">Desequilibrios</tspan>
                <tspan x="70" y="3" dy="1.1em">Nutricionais</tspan>
            </>,
            componenteTranslate: '0,38.09375',
            documentacao: <>
                <tspan x="105" y="3" dy="0em">Desiquilibrio Nutricionais:</tspan>
                <tspan x="105" y="3" dy="1.1em">Desiquilibrio na ingestão de</tspan>
                <tspan x="105" y="3" dy="2.2em">macronutrientes, micronutrientes,</tspan>
                <tspan x="105" y="3" dy="3.3000000000000003em">aminoácidos e ácidos graxos</tspan>
                <tspan x="105" y="3" dy="4.4em">essenciais. Estados hipercatábólicos</tspan>
                <tspan x="105" y="3" dy="5.5em">e outras.</tspan>
            </>,
            documentacaoTranslate: '550,20',
            ativo: false
        },
        {
            id: 2, 
            nome: 'opcao_2', 
            descricao: 'Disfunção Imunológica e Inflamação', 
            c: [7, 8, 9], 
            x: margem1, 
            y: 130 + top,
            componente: <>
                <tspan x="70" y="3" dy="0em">Disfunção</tspan>
                <tspan x="70" y="3" dy="1.1em">Imunológica e</tspan>
                <tspan x="70" y="3" dy="2.2em">Inflamação</tspan>
            </>,
            componenteTranslate: '0,30.3984375',
            documentacao: <>
                <tspan x="110" y="3" dy="0em">Disf. Imunológica e inflamação:</tspan>
                <tspan x="110" y="3" dy="1.1em">Consumo excessivo de ácidos graxos</tspan>
                <tspan x="110" y="3" dy="2.2em">saturados, trans e/ou araquidônico.</tspan>
                <tspan x="110" y="3" dy="3.3000000000000003em">Infecções crônicas (virais, bacterianas,</tspan>
                <tspan x="110" y="3" dy="4.4em">parasitárias, etc.), Alergias</tspan>
                <tspan x="110" y="3" dy="5.5em">alimentares, doenças auto imunes.</tspan>
            </>,
            documentacaoTranslate: '0,120',
            ativo: false
        },
        {
            id: 3, 
            nome: 'opcao_3', 
            descricao: 'Estresse Oxidativo e Metabolismo Energético', 
            c: [1, 11, 12, 13, 14], 
            x: margem2, 
            y: 260 + top,
            componente: <>
                <tspan x="70" y="3" dy="0em">Estresse</tspan>
                <tspan x="70" y="3" dy="1.1em">Oxidativo e</tspan>
                <tspan x="70" y="3" dy="2.2em">Metabolismo</tspan>
                <tspan x="70" y="3" dy="3.3000000000000003em">Energético</tspan>
            </>,
            componenteTranslate: '0,22.6953125',
            documentacao: <>
                <tspan x="85" y="3" dy="0em">Extresse Oxidativo e metab.</tspan>
                <tspan x="85" y="3" dy="1.1em">Energético: Déficit de</tspan>
                <tspan x="85" y="3" dy="2.2em">nicronutriente e</tspan>
                <tspan x="85" y="3" dy="3.3000000000000003em">fitoquimicos, exposição a</tspan>
                <tspan x="85" y="3" dy="4.4em">toxinas, estresse físico ou</tspan>
                <tspan x="85" y="3" dy="5.5em">mental. Polimorfismos</tspan>
                <tspan x="85" y="3" dy="6.6000000000000005em">genéticos em enzimas</tspan>
                <tspan x="85" y="3" dy="7.700000000000001em">antioxidantes, mitocondriais</tspan>
                <tspan x="85" y="3" dy="8.8em">e outras.</tspan></>,
            documentacaoTranslate: '0,250',
            ativo: false
        },
        {
            id: 4, 
            nome: 'opcao_4', 
            descricao: 'Alterações Gastrointestinais', 
            c: [2, 16, 17], 
            x: margem1, 
            y: 390 + top,
            componente: <>
                <tspan x="70" y="3" dy="0em">Alterações</tspan>
                <tspan x="70" y="3" dy="1.1em">Gastrointestinais</tspan>
            </>,
            componenteTranslate: '0,38.09375',
            documentacao: <>
                <tspan x="110" y="3" dy="0em">Alterações Gastrointestinais: Estado</tspan>
                <tspan x="110" y="3" dy="1.1em">de disbiose intestinal,</tspan>
                <tspan x="110" y="3" dy="2.2em">hiperpermeabilidade, constipação</tspan>
                <tspan x="110" y="3" dy="3.3000000000000003em">diarréia e suas repercusões nas setes</tspan>
                <tspan x="110" y="3" dy="4.4em">funções básicas do TGI: Digestória,</tspan>
                <tspan x="110" y="3" dy="5.5em">excretória, abosrtiva, imunológica,</tspan>
                <tspan x="110" y="3" dy="6.6000000000000005em">detoxificativa, neurológica e</tspan>
                <tspan x="110" y="3" dy="7.700000000000001em">endócrina.</tspan>
            </>,
            documentacaoTranslate: '0,410',
            ativo: false
        },
        {
            id: 5, 
            nome: 'opcao_5', 
            descricao: 'Desequilibrios Estruturais', 
            c: [3, 7, 11, 18, 19], 
            x: (configSvg.width / 2) - cW, 
            y: 510 + top,
            componente: <>
                <tspan x="70" y="3" dy="0em">Desequilibrios</tspan>
                <tspan x="70" y="3" dy="1.1em">Estruturais</tspan>
            </>,
            componenteTranslate: '0,38.09375',
            documentacao: <>
                <tspan x="105" y="3" dy="0em">Desequilibrios Estruturais: Consumo</tspan>
                <tspan x="105" y="3" dy="1.1em">desequilibrado de lipídios cis/trans,</tspan>
                <tspan x="105" y="3" dy="2.2em">ômega3/6, excessivo de colesterol,</tspan>
                <tspan x="105" y="3" dy="3.3000000000000003em">desvio do padrçao anatômico</tspan>
                <tspan x="105" y="3" dy="4.4em">ósseo, muscular ou articular.</tspan>
            </>,
            documentacaoTranslate: '200,580',
            ativo: false
        },
        {
            id: 6, 
            nome: 'opcao_6', 
            descricao: 'Problemas Detoxificação', 
            c: [4, 8, 12], 
            x: configSvg.width - (cW * 2) - margem1, 
            y: 390 + top,
            componente: <>
                <tspan x="70" y="3" dy="0em">Problemas</tspan>
                <tspan x="70" y="3" dy="1.1em">Detoxificação</tspan>
            </>,
            componenteTranslate: '0,38.09375',
            documentacao: <>
                <tspan x="110" y="3" dy="0em">Problemas detoxificação: Exposição a</tspan>
                <tspan x="110" y="3" dy="1.1em">xenobióticos (ocupacional</tspan>
                <tspan x="110" y="3" dy="2.2em">doméstico). Alto consumo de</tspan>
                <tspan x="110" y="3" dy="3.3000000000000003em">alimentos contaminados com</tspan>
                <tspan x="110" y="3" dy="4.4em">resíduos de agrotóxicos.</tspan>
                <tspan x="110" y="3" dy="5.5em">Polimorfismo genéticos nas enzimas</tspan>
                <tspan x="110" y="3" dy="6.6000000000000005em">de fase I e/ou II e insuficiência</tspan>
                <tspan x="110" y="3" dy="7.700000000000001em">nutricional.</tspan>
            </>,
            documentacaoTranslate: '740,480',
            ativo: false
        },
        {
            id: 7, 
            nome: 'opcao_7', 
            descricao: 'Interação Corpo-Mente', 
            c: [5, 9, 13, 16, 18], 
            x: configSvg.width - (cW * 2) - margem2, 
            y: 260 + top,
            componente: <>
                <tspan x="70" y="3" dy="0em">Interação</tspan>
                <tspan x="70" y="3" dy="1.1em">Corpo-Mente</tspan>
            </>,
            componenteTranslate: '0,38.09375',
            documentacao: <>
                <tspan x="85" y="3" dy="0em">Interação</tspan>
                <tspan x="85" y="3" dy="1.1em">corpo-mente:Característica</tspan>
                <tspan x="85" y="3" dy="2.2em">de personalidade, extresse</tspan>
                <tspan x="85" y="3" dy="3.3000000000000003em">mental, ansiedade,</tspan>
                <tspan x="85" y="3" dy="4.4em">depressão, hiperatividade,</tspan>
                <tspan x="85" y="3" dy="5.5em">pânico, esquizofrenia,</tspan>
                <tspan x="85" y="3" dy="6.6000000000000005em">autismo, bipolaridade, e</tspan>
                <tspan x="85" y="3" dy="7.700000000000001em">suas repercussões no</tspan>
                <tspan x="85" y="3" dy="8.8em">indivíduo.</tspan>
            </>,
            documentacaoTranslate: '790,320',
            ativo: false
        },
        {
            id: 8, 
            nome: 'opcao_8', 
            descricao: 'Disfunções Neuroendócrinas', 
            c: [14, 17, 19], 
            x: configSvg.width - (cW * 2) - margem1, 
            y: 130 + top,
            componente: <>
                <tspan x="70" y="3" dy="0em">Disfunções</tspan>
                <tspan x="70" y="3" dy="1.1em">Neuroendócrinas</tspan>
            </>,
            componenteTranslate: '0,38.09375',
            documentacao: <>
                <tspan x="110" y="3" dy="0em">Disfunções neuroendócrinas: uso de</tspan>
                <tspan x="110" y="3" dy="1.1em">anticocepcionais, hormônios sexuais,</tspan>
                <tspan x="110" y="3" dy="2.2em">tireoidianos, de benzodiazepínicos e</tspan>
                <tspan x="110" y="3" dy="3.3000000000000003em">outros medicamentos. lesão por</tspan>
                <tspan x="110" y="3" dy="4.4em">metais, tóxicos como mercúrio,</tspan>
                <tspan x="110" y="3" dy="5.5em">cádmio e chumbo. Disfunções</tspan>
                <tspan x="110" y="3" dy="6.6000000000000005em">idiopáticas ou adquiridas, no sistema</tspan>
                <tspan x="110" y="3" dy="7.700000000000001em">endócrino ou nervoso</tspan>
            </>,
            documentacaoTranslate: '740,170',
            ativo: false
        }
    ]);

    // conexões
    let [conexoes, alterarConexoes] = useState([
        {
            id: 1, 
            x1: elementos[0].x + cW, y1: elementos[0].y + (cH * 2), 
            x2: elementos[2].x + (cW * 2), y2: elementos[2].y + cH,
            el: null,
            ativo: false
        },
        {
            id: 2, 
            x1: elementos[0].x + cW, y1: elementos[0].y + (cH * 2), 
            x2: elementos[3].x + (cW * 2), y2: elementos[3].y + cH,
            el: null,
            ativo: false
        },
        {
            id: 3, 
            x1: elementos[0].x + cW, y1: elementos[0].y + (cH * 2), 
            x2: elementos[4].x + cW, y2: elementos[4].y,
            el: null,
            ativo: false
        },
        {
            id: 4, 
            x1: elementos[0].x + cW, y1: elementos[0].y + (cH * 2), 
            x2: elementos[5].x, y2: elementos[5].y + cH,
            el: null,
            ativo: false
        },
        {
            id: 5, 
            x1: elementos[0].x + cW, y1: elementos[0].y + (cH * 2), 
            x2: elementos[6].x, y2: elementos[6].y + cH,
            el: null,
            ativo: false
        },
        {
            id: 6, 
            x1: elementos[1].x + (cW * 2), y1: elementos[1].y + cH, 
            x2: elementos[3].x + (cW * 2), y2: elementos[3].y + cH,
            el: null,
            ativo: false
        },
        {
            id: 7, 
            x1: elementos[1].x + (cW * 2), y1: elementos[1].y + cH, 
            x2: elementos[4].x + cW, y2: elementos[4].y,
            el: null,
            ativo: false
        },
        {
            id: 8, 
            x1: elementos[1].x + (cW * 2), y1: elementos[1].y + cH, 
            x2: elementos[5].x, y2: elementos[5].y + cH,
            el: null,
            ativo: false
        },
        {
            id: 9, 
            x1: elementos[1].x + (cW * 2), y1: elementos[1].y + cH, 
            x2: elementos[6].x, y2: elementos[6].y + cH,
            el: null,
            ativo: false
        },
        {
            id: 10, 
            x1: elementos[1].x + (cW * 2), y1: elementos[1].y + cH, 
            x2: elementos[7].x, y2: elementos[7].y + cH,
            el: null,
            ativo: false
        },
        {
            id: 11, 
            x1: elementos[2].x + (cW * 2), y1: elementos[2].y + cH, 
            x2: elementos[4].x + cW, y2: elementos[4].y,
            el: null,
            ativo: false
        },
        {
            id: 12, 
            x1: elementos[2].x + (cW * 2), y1: elementos[2].y + cH, 
            x2: elementos[5].x, y2: elementos[5].y + cH,
            el: null,
            ativo: false
        },
        {
            id: 13, 
            x1: elementos[2].x + (cW * 2), y1: elementos[2].y + cH, 
            x2: elementos[6].x, y2: elementos[6].y + cH,
            el: null,
            ativo: false
        },
        {
            id: 14, 
            x1: elementos[2].x + (cW * 2), y1: elementos[2].y + cH, 
            x2: elementos[7].x, y2: elementos[7].y + cH,
            el: null,
            ativo: false
        },
        {
            id: 15, 
            x1: elementos[3].x + (cW * 2), y1: elementos[3].y + cH, 
            x2: elementos[5].x, y2: elementos[5].y + cH,
            el: null,
            ativo: false
        },
        {
            id: 16, 
            x1: elementos[3].x + (cW * 2), y1: elementos[3].y + cH, 
            x2: elementos[6].x, y2: elementos[6].y + cH,
            el: null,
            ativo: false
        },
        {
            id: 17, 
            x1: elementos[3].x + (cW * 2), y1: elementos[3].y + cH, 
            x2: elementos[7].x, y2: elementos[7].y + cH,
            el: null,
            ativo: false
        },
        {
            id: 18,
            x1: elementos[4].x + cW, y1: elementos[4].y,
            x2: elementos[6].x, y2: elementos[6].y + cH,
            el: null,
            ativo: false
        },
        {
            id: 19,
            x1: elementos[4].x + cW, y1: elementos[4].y,
            x2: elementos[7].x, y2: elementos[7].y + cH,
            el: null,
            ativo: false
        },
        {
            id: 20,
            x1: elementos[5].x, y1: elementos[5].y + cH,
            x2: elementos[7].x, y2: elementos[7].y + cH,
            el: null,
            ativo: false
        },
    ]);

    // ao alterar elementos, reconfigura conexões
    useEffect(() => {

        // define todas as conexões como falsa
        conexoes.map(c => {
            c.ativo = false;
        });

        // verifica quais conexões estão ativas
        elementos.map(e => {
            if(e.ativo){
                e.c.map(ec => {
                    conexoes.find(c => c.id === ec).ativo = true;
                });
            }
        });

        alterarConexoes([...conexoes]);

    }, [elementos]);

    useEffect(() => {

        elementos.map(e => {
            e.ativo = (value[e.nome] === 'S') ? true : false;
        });
        alterarElementos([...elementos]);
    }, [value]);

    // verifica se a conexão esta ativa
    function conexaoAtiva(elemento){

        let ativo = false;
        conexoes.forEach(c => {
            if(elemento.c.includes(c.id)){
                if(c.ativo){
                    ativo = true;
                }
            }
        })

        return ativo;
    }


    return <svg width="960" height="660">
        <filter id="dropshadow" height="130%" filterUnits="userSpaceOnUse">
            <feGaussianBlur in="SourceAlpha" stdDeviation="3"/> 
            <feOffset dx="2" dy="2" result="offsetblur"/> 
            <feComponentTransfer>
                <feFuncA type="linear" slope="0.5"/>
            </feComponentTransfer>
            <feMerge> 
                <feMergeNode/>
                <feMergeNode in="SourceGraphic"/> 
            </feMerge>
        </filter>
        { conexoes.map(c => 
            <line 
                key={c.id} x1={c.x1} y1={c.y1} x2={c.x2} y2={c.y2} 
                filter="url(#dropshadow)" 
                style={{stroke: 'rgb(24, 0, 179)', opacity: (c.ativo) ? '1' : '0.4', strokeWidth: '2', strokeDasharray: '4'}}
            ></line>
        )}

        { elementos.map(e => 
            <React.Fragment key={e.id}>
                <g transform={`translate(${e.documentacaoTranslate})`}>
                    <text style={{textAnchor: 'middle', fontSize: '12px', fontWeight: 'normal', fill: 'rgb(2, 2, 2)'}}>
                        {e.documentacao}
                    </text>
                </g>
                <g 
                    transform={`translate(${e.x},${e.y})`} 
                    style={{cursor: 'pointer'}} 
                    onClick={() => {
                        onChange(e.nome, !e.ativo);
                    }}
                >
                    <ellipse 
                        cx={cW} cy={cH} rx={cW} ry={cH} 
                        filter="url(#dropshadow)" 
                        style={{fill: (e.ativo || conexaoAtiva(e)) ? 'rgb(255, 200, 98)' : 'rgb(0, 84, 107)'}}
                    ></ellipse>
                    <text
                        style={{textAnchor: 'middle', fontSize: '14px', fontWeight: 'bold', fill: (e.ativo || conexaoAtiva(e)) ? 'rgb(76, 76, 76)' : 'rgb(247, 247, 247)'}} 
                        transform={`translate(${e.componenteTranslate})`}
                    >
                        {e.componente}
                    </text>
                </g>
            </React.Fragment>
        )}
    </svg>

}

export default TeiaSvg;