
export default [
    {descricao: 'Cabeça', itens: [
        {id: 'cabeca_1', descricaoOpcao: 'Dor de cabeça'},
        {id: 'cabeca_2', descricaoOpcao: 'Tonturas'},
        {id: 'cabeca_3', descricaoOpcao: 'Sensação de desmaio'},
        {id: 'cabeca_4', descricaoOpcao: 'Insônia'}
    ]},
    {descricao: 'Olhos', itens: [
        {id: 'olhos_1', descricaoOpcao: 'Lacrimejantes ou coçante'},
        {id: 'olhos_2', descricaoOpcao: 'Bolsas ou olheiras abaixo dos olhos'},
        {id: 'olhos_3', descricaoOpcao: 'Inchados, vermelhos ou com cílios colando'},
        {id: 'olhos_4', descricaoOpcao: 'Visão borrada ou em túnel (sem miopia/astigmatismo)'}
    ]},
    {descricao: 'Ouvidos', itens: [
        {id: 'ouvidos_1', descricaoOpcao: 'Coceira'},
        {id: 'ouvidos_2', descricaoOpcao: 'Retirada de fluido purulento ou ouvido'},
        {id: 'ouvidos_3', descricaoOpcao: 'Dores de ouvido, infecções auditivas'},
        {id: 'ouvidos_4', descricaoOpcao: 'Zunido, perda da audição'}
    ]},
    {descricao: 'Nariz', itens: [
        {id: 'nariz_1', descricaoOpcao: 'Entupido'},
        {id: 'nariz_2', descricaoOpcao: 'Corrimento nasal, espirros e coceira dos olhos'},
        {id: 'nariz_3', descricaoOpcao: 'Excessiva formação de muco'},
        {id: 'nariz_4', descricaoOpcao: 'Problemas de seios nasais (sinusite)'},
        {id: 'nariz_5', descricaoOpcao: 'Ataques de espirros'}
    ]},
    {descricao: 'Boca / Garganta', itens: [
        {id: 'boca_1', descricaoOpcao: 'Tosse crônica'},
        {id: 'boca_2', descricaoOpcao: 'Dor de garganta, rouquidão ou perda de voz'},
        {id: 'boca_3', descricaoOpcao: 'Aftas'},
        {id: 'boca_4', descricaoOpcao: 'Frequente necessidade de limpar a garganta'},
        {id: 'boca_5', descricaoOpcao: 'Língua gengivas ou lábios inchados / descoloridos'}
    ]},
    {descricao: 'Pele', itens: [
        {id: 'pele_1', descricaoOpcao: 'Acne'},
        {id: 'pele_2', descricaoOpcao: 'Perda de cabelo'},
        {id: 'pele_3', descricaoOpcao: 'Suor excessivo'},
        {id: 'pele_4', descricaoOpcao: 'Feridas que coçam, erupções ou pele seca'},
        {id: 'pele_5', descricaoOpcao: 'Vermelhidão, calorões'}
    ]},
    {descricao: 'Coração', itens: [
        {id: 'coracao_1', descricaoOpcao: 'Batidas irregulares ou falhando'},
        {id: 'coracao_2', descricaoOpcao: 'Dor no peito'},
        {id: 'coracao_3', descricaoOpcao: 'Batidas rápidas demais'},
    ]},
    {descricao: 'Pulmões', itens: [
        {id: 'pulmoes_1', descricaoOpcao: 'Congestão no peito'},
        {id: 'pulmoes_2', descricaoOpcao: 'Pouco fôlego'},
        {id: 'pulmoes_3', descricaoOpcao: 'Asma, bronquite'},
        {id: 'pulmoes_4', descricaoOpcao: 'Dificuldade para respirar'}
    ]},
    {descricao: 'Trato Digestivo', itens: [
        {id: 'digestivo_1', descricaoOpcao: 'Náuseas, vômito'},
        {id: 'digestivo_2', descricaoOpcao: 'Constipação, prisão de ventre'},
        {id: 'digestivo_3', descricaoOpcao: 'Arrotos e/ou gases intestinais'},
        {id: 'digestivo_4', descricaoOpcao: 'Dor estomacal / intestinal'},
        {id: 'digestivo_5', descricaoOpcao: 'Diarréia'},
        {id: 'digestivo_6', descricaoOpcao: 'Sente-se inchado, abdômen distendido'},
        {id: 'digestivo_7', descricaoOpcao: 'Azia'}
    ]},
    {descricao: 'Articulações e Músculos', itens: [
        {id: 'articulacoes_1', descricaoOpcao: 'Dores articulares'},
        {id: 'articulacoes_2', descricaoOpcao: 'Rigidez ou limitação dos movimentos'},
        {id: 'articulacoes_3', descricaoOpcao: 'Sensação de fraqueza ou cansaço'},
        {id: 'articulacoes_4', descricaoOpcao: 'Artrite / artrose'},
        {id: 'articulacoes_5', descricaoOpcao: 'Dores musculares'}
    ]},
    {descricao: 'Energia / Atividade', itens: [
        {id: 'atividade_1', descricaoOpcao: 'Fadiga, moleza'},
        {id: 'atividade_2', descricaoOpcao: 'Hiperatividade'},
        {id: 'atividade_3', descricaoOpcao: 'Apatia, letargia'},
        {id: 'atividade_4', descricaoOpcao: 'Dificuldade em descansar, relaxar'}
    ]},
    {descricao: 'Mente', itens: [
        {id: 'mente_1', descricaoOpcao: 'Memória ruim'},
        {id: 'mente_2', descricaoOpcao: 'Concentração ruim'},
        {id: 'mente_3', descricaoOpcao: 'Dificuldade em tomar decisões'},
        {id: 'mente_4', descricaoOpcao: 'Pronuncia palavras de forma indistinta, confusa'},
        {id: 'mente_5', descricaoOpcao: 'Confusão mental, compreensão ruim'},
        {id: 'mente_6', descricaoOpcao: 'Fraca concentração motora'},
        {id: 'mente_7', descricaoOpcao: 'Repetições de palavras, com pausas involuntárias'},
        {id: 'mente_8', descricaoOpcao: 'Problemas de aprendizagem'}
    ]},
    {descricao: 'Emoções', itens: [
        {id: 'emocoes_1', descricaoOpcao: 'Mudanças de humor'},
        {id: 'emocoes_2', descricaoOpcao: 'Raiva, irritabilidade, agressividade'},
        {id: 'emocoes_3', descricaoOpcao: 'Ansiedade, medo, nervosismo'},
        {id: 'emocoes_4', descricaoOpcao: 'Depressão'}
    ]},
    {descricao: 'Outros', itens: [
        {id: 'outros_1', descricaoOpcao: 'Frequentemente doente'},
        {id: 'outros_2', descricaoOpcao: 'Coceira genital ou corrimento'},
        {id: 'outros_3', descricaoOpcao: 'Frequente ou urgente vontade de urinar'}
    ]}
];