import React, { useEffect, useRef, useContext } from 'react';
import styles from './index.module.scss';

// compoentes
import Opcao from './Opcao';

import { consultaAlimentosContexto } from './../../../ConsultaAlimentos';

function FiltroOpcoes({
    fechou
}){

    // carrega contexto
    let consultaAlimentos = useContext(consultaAlimentosContexto);

    // referencias
    let componenteSelect = useRef(null);

    // fecha o componente
    const handleClick = e => {
        if (componenteSelect.current.contains(e.target)) {
          // clicou dentro
          return;
        }
        // clicou fora
        fechou();
    };

    // inicializa
    useEffect(() => {

        // montar
        document.addEventListener("mousedown", handleClick);

        // desmontar
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };

    }, []);

    // alterar a opção
    function alterarOpcao(valorFiltro){
        consultaAlimentos.parametros.pasta = valorFiltro;
        consultaAlimentos.parametros.pagina = 1;
        consultaAlimentos.alterarParametros({...consultaAlimentos.parametros});
        fechou();
    }


    return <div  ref={componenteSelect} className={[styles.opcoesFiltro, 'rounded shadow-lg p-2 bg-light'].join(' ')}>

        {consultaAlimentos.pastas.map((pasta) => 
            <Opcao 
                key={pasta.id}
                descricao={pasta.descricao}
                valor={pasta.id}
                onClick={alterarOpcao}
            />
        )}
    </div>

}

export default FiltroOpcoes;