import React from 'react';
import moment from 'moment';

// componentes
import BotaoTabela from './../../../../ComponentesCadastro/BotaoTabela';

function Registro({
    dados,
    parametros,
    clicouEditar,
    clicouEditarNovo
}){
    console.log(dados.data_cadastro);
    return <tr>
        <td className="estreito text-center font-weight-bold">
            {moment(dados.data_cadastro).format('DD/MM/YYYY')}
        </td>
        <td></td>
        <td className="text-nowrap estreito">
            <BotaoTabela 
                onClick={clicouEditarNovo}
                icone={["fas", "pencil-alt"]}
            >
                Editar
            </BotaoTabela>
            {/* <BotaoTabela 
                onClick={clicouEditar}
                icone={["fas", "pencil-alt"]}
            >
                Editar
            </BotaoTabela> */}
        </td>
    </tr>
}

export default Registro;