import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import axios from 'axios';
import useIsMounted from 'ismounted';

// componentes
import MaskedInput from 'react-maskedinput';
import { Form, Card, Row, Col, Button, Alert, ButtonToolbar, OverlayTrigger, Tooltip  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// componente cadastro
export default function Cadastrar(){

    // estados
    const isMounted = useIsMounted();
    const history = useHistory();
    const { id } = useParams();
    let [modo, alterarModo] = useState('cadastro'); // cadastro, edicao
    let [carregando, alterarCarregando] = useState(true);
    let [dados, alterarDados] = useState({
        pk_cad_convenio: id || null,
        descricao: '',
        cpf_cnpj: '',
        telefone: '',
        celular: '',
        email: '',
        cep: '',
        endereco: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        estado: ''
    });
    let [salvando, alterarSalvando] = useState(false);
    let [erro, alterarErro] = useState(null);

    // referencias
    let cepInput = React.createRef();
    let telResInput = React.createRef();
    let telCelInput = React.createRef();

    // carrega componente
    useEffect(() => {

        // verifica se é alteração
        if(dados.pk_cad_convenio !== null){
            alterarModo('edicao');
            consultarId();

        }else{
            alterarCarregando(false);
        }

    }, []);

    // consulta id
    async function consultarId(){

        try{

            // faz a requisiçao
            let { data } = await axios.get(`/convenio/${dados.pk_cad_convenio}`);
            alterarDados(data.dados);
            alterarCarregando(false);

        }catch({response}){}
    }

    async function salvar(){
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/convenio`, dados);
            history.push('/painel/convenios');

        }catch({response}){
            if(response){
                if(response.status === 400){
                    alterarErro(response.data);
                }
            }
        }finally{
            if (isMounted.current) {
                alterarSalvando(false);
            }
        }

    }

    // busca cep
    function buscarCep(cepBuscar){
        let buscarCepCon = axios.create({ headers: null });

        // se foi totalmente preenchido
        if(cepBuscar.length === 8){

            // faz a busca
            buscarCepCon.get("https://viacep.com.br/ws/"+ cepBuscar +"/json/", {
                responseType: 'json'
            }).then(retorno => {
                
                alterarDados({...dados, 
                    'cidade': retorno.data.localidade,
                    'estado': retorno.data.uf,
                    'endereco': retorno.data.logradouro,
                    'bairro': retorno.data.bairro,
                    'cep': cepBuscar
                });
            });

        }
    }

    // carregando
    if(carregando){
        return <div>Carregando...</div>
    }

    return <>
    <Row>
        <Col lg='8'>
            <Card className='mb-4 border-0 shadow-sm'>
                <Card.Header className={[styles.cabecalho, 'bg-white'].join(' ')}>
                    <Card.Title as='h4' className='mb-0'>{modo === 'cadastro' ? 'Cadastrar' : 'Editar'} convênio {dados.pk_cad_convenio && <i><small> - {dados.pk_cad_convenio}</small></i>}</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form.Row>
                        <Col md='7'>
                            <Form.Label>Nome Convênio</Form.Label>
                            <Form.Control 
                                value={dados.descricao}
                                onChange={e => {
                                    alterarDados({...dados, descricao: e.target.value});
                                }}
                            />
                        </Col>
                        <Col md='5'>
                            <Form.Label>CPF / CNPJ</Form.Label>
                            <Form.Control 
                                value={dados.cpf_cnpj}
                                onChange={e => {
                                    alterarDados({...dados, cpf_cnpj: e.target.value});
                                }}
                            />
                        </Col>
                    </Form.Row>

                    <Form.Row className='mt-3'>
                        <Col md='6'>
                            <Form.Label>Email</Form.Label>
                            <Form.Control 
                                value={dados.email}
                                onChange={e => {
                                    alterarDados({...dados, email: e.target.value});
                                }}
                            />
                        </Col>
                        <Col md='3'>
                            <Form.Label>Celular</Form.Label>
                            <MaskedInput 
                                className='form-control'
                                mask="(11) 1 1111-1111"
                                value={dados.celular}
                                ref={telCelInput}
                                onChange={(e) => {
                                    dados.celular = telCelInput.current.mask.getRawValue().split('_').join('');
                                    alterarDados({...dados});
                                }}
                            />
                        </Col>
                        <Col md='3'>
                            <Form.Label>Telefone</Form.Label>
                            <MaskedInput 
                                className="form-control"
                                mask="(11) 1111-11111"
                                value={dados.telefone}
                                ref={telResInput}
                                onChange={(e) => {
                                    dados.telefone = telResInput.current.mask.getRawValue().split('_').join('');
                                    alterarDados({...dados});
                                }}
                            />
                        </Col>
                    </Form.Row>

                    <Form.Row className='mt-3'>
                        <Col md='3'>
                            <Form.Label>CEP</Form.Label>
                            <MaskedInput
                                className={['form-control'].join(' ')}
                                mask="11111-111"
                                value={dados.cep}
                                ref={cepInput}
                                onChange={(e) => {
                                    let cepNovo = cepInput.current.mask.getRawValue().split('_').join('');
                                    buscarCep(cepNovo);
                                    alterarDados({...dados, cep: cepNovo});
                                    
                                }}
                            />
                        </Col>
                        <Col md='7'>
                            <Form.Label>Endereço</Form.Label>
                            <Form.Control 
                                value={dados.endereco}
                                onChange={e => {
                                    alterarDados({...dados, endereco: e.target.value});
                                }}
                            />
                        </Col>
                        <Col md='2'>
                            <Form.Label>Número</Form.Label>
                            <Form.Control 
                                value={dados.numero}
                                onChange={e => {
                                    alterarDados({...dados, numero: e.target.value});
                                }}
                            />
                        </Col>
                    </Form.Row>
                    
                    <Form.Row className='mt-3'>
                        <Col md='4'>
                            <Form.Label>Bairro</Form.Label>
                            <Form.Control 
                                value={dados.bairro}
                                onChange={e => {
                                    alterarDados({...dados, bairro: e.target.value});
                                }}
                            />
                        </Col>
                        <Col md='6'>
                            <Form.Label>Cidade</Form.Label>
                            <Form.Control 
                                value={dados.cidade}
                                onChange={e => {
                                    alterarDados({...dados, cidade: e.target.value});
                                }}
                            />
                        </Col>
                        <Col md='2'>
                            <Form.Label>Estado</Form.Label>
                            <Form.Control 
                                value={dados.estado}
                                onChange={e => {
                                    alterarDados({...dados, estado: e.target.value});
                                }}
                            />
                        </Col>
                    </Form.Row>

                    <Form.Group className='mt-3 mb-0'>
                        <Form.Label>Complemento</Form.Label>
                            <Form.Control 
                                value={dados.complemento}
                                onChange={e => {
                                    alterarDados({...dados, complemento: e.target.value});
                                }}
                            />
                    </Form.Group>

                </Card.Body>
            </Card>

            <SalvarFormulario 
                salvando={salvando}
                onClick={() => salvar(dados)}
                cancelar={() => {
                    history.goBack();
                }}
            />
            { erro && 
                <Alert variant='danger'>
                    Falha ao salvar! Confira se todos os campos estão corretos!
                </Alert>
            }
        </Col>
    </Row>
    </>
}

function SalvarFormulario({
    salvando,
    cancelar,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button variant="success" disabled={salvando} onClick={onClick}>
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <span>Salvar</span>
            }
        </Button>
    </Form.Group>
}

