import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';

// importa componentes
import { Tab, Nav, Button } from 'react-bootstrap';
import Refeicoes from './Refeicoes';
import OpcoesTab from './OpcoesTab';
import EditorTextoLivre from './EditorTextoLivre';


// importa contexto
import { PlanoAlimentarContexto } from './../../../ComponentePlanoAlimentar';

function ContainerRefeicoes({

}){

    // contexto
    let planoDados = useContext(PlanoAlimentarContexto);

    return <div
        className={[styles.containerAbas, 'px-2'].join(' ')}
    >
        <div
            className={['bg-white p-2 rounded shadown-sm'].join(' ')}
        >
            <Tab.Container
                defaultActiveKey={planoDados.refeicaoAba}
                onSelect={key => {planoDados.alterarRefeicaoAba(key)}}
            >
                <Nav
                    variant='tabs'
                >

                    {/* refeições */}
                    <Nav.Item>
                        <Nav.Link
                            eventKey='refeicoes'
                        >
                            Refeições
                        </Nav.Link>
                    </Nav.Item>

                    {/* documento transcrição, texto livre */}
                    <Nav.Item>
                        <Nav.Link
                            eventKey='documento'
                        >
                            Texto livre
                        </Nav.Link>
                    </Nav.Item>

                    {/* formulas magistrais, texto livre */}
                    <Nav.Item>
                        <Nav.Link
                            eventKey='prescricao'
                        >
                            Prescrição
                        </Nav.Link>
                    </Nav.Item>
                    
                    <OpcoesTab />
                </Nav>
                <Tab.Content>
                    <Tab.Pane
                        eventKey="refeicoes"
                    >
                        <div style={{display: 'grid'}}>
                            <Refeicoes />
                        </div>
                    </Tab.Pane>
                    <Tab.Pane
                        eventKey="documento"
                    >
                        <EditorTextoLivre 
                            titulo='Plano Alimentar'
                            value={planoDados.documentoJson}
                            onChange={documentoNovo => {
                                planoDados.alterarDocumentoJson(documentoNovo);
                            }}
                            idCliente={'1'}
                        />
                    </Tab.Pane>
                    <Tab.Pane
                        eventKey="prescricao"
                    >
                        <EditorTextoLivre 
                            titulo='Prescrição'
                            value={planoDados.prescricaoJson}
                            onChange={prescricaoNova => {
                                planoDados.alterarPrescricaoJson(prescricaoNova);
                            }}
                        />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div>
    </div>
}

export default ContainerRefeicoes;