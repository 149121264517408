import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import axios from 'axios';
// import Moment from 'react-moment';
import 'moment/locale/pt-br';

// importa reducers
import RootReducer from './RootReducer';

// configurações
import './scss/geral.scss';
import './icones';
import './axiosConfig';

// // padrões do moment
// Moment.globalLocale = 'pt-br';

// configuração para sincronizar as abas
const config = {
    // TOGGLE_TODO will not be triggered in other tabs
    blacklist: [
        // 'planoAlimentarCadastro/ALTERAR_REFEICOES', 
        // 'alimentosConsultaExterna/REGISTROS',
        // 'planoAlimentarCadastro/INCLUIR',
        // 'planoAlimentarCadastro/FOCAR_REFEICAO',
        // 'planoAlimentarCadastro/CANCELAR_AUTOSCROLL'
    ]
}
const middlewares = [
    createStateSyncMiddleware(config),
];


// cria store
const store = createStore(RootReducer, {}, applyMiddleware(...middlewares));

// init state with other tabs
initStateWithPrevTab(store);

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
