import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import AsyncSelect from 'react-select/lib/Async';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import moment from 'moment';
import styles from './index.module.scss';
import { Form } from 'react-bootstrap';
import axios from 'axios';

// componentes
import OptionSelect from './OptionSelect';

// serviços
import PacienteServico from './../../../../../../servicos/PacienteServico';

// componente para busca de paciente
export default function BuscarPaciente({
    dados,
    alterouCliente
}){

    // estados
    const { LoginReducer } = useSelector(state => state);
    let [pacientes, alterarPacientes] = useState([]);
    let [carregando, alterarCarregando] = useState(true);
    let [dadosPaciente, alterarDadosPaciente] = useState(null);

    // inicializa
    useEffect(() => {

        // se possuir código, carrega os dados do paciente
        if(dados.fk_cad_paciente){

            // consulta os dados do paciente
            consultarIdPaciente();

        }else{
            alterarCarregando(false);
        }

    }, []);

    // faz a consulta dos dados do paciente
    async function consultarIdPaciente(){

        try{
            let { data } = await axios.get(`/paciente/${dados.fk_cad_paciente}`);
            alterarDadosPaciente(data);
            alterarCarregando(false);
        }catch({response}){

        }
    }

    // função para carregar os pacientes
    async function consultarPacientes(inputValue, callback){

        try{

            // faz a requisição
            let { data } = await axios.get(`/paciente`, {
                params: {
                    texto: inputValue,
                    ordenar_coluna: ['nome'],
                    ordenar_ordem: ['asc'],
                    incluirConvenio: true
                }
            });
            alterarPacientes(data.registros);
            callback(data.registros)

        }catch({response}){

        }

    }

    // salvar cliente novo
    async function salvar(inputValue){
        
        // carregado
        alterarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/paciente`, {
                pk_cad_paciente: null,
                nome: inputValue
            });
            alterarDadosPaciente(data);
            alterouCliente(data)
            alterarCarregando(false);
            
        }catch({response}){

        }

    }

    return <>
        <Form.Label className='col-form-label-sm m-0'>Buscar Cliente</Form.Label>
        <AsyncCreatableSelect 
            isDisabled={carregando}
            isClearable
            loadOptions={consultarPacientes} // ao alterar, carrega
            defaultOptions={pacientes}       // lista pré carregada
            value={dadosPaciente && {
                label: `${dadosPaciente.dados.nome} 
                ${dadosPaciente.dados.data_nascimento ? `, ${moment(dadosPaciente.dados.data_nascimento).format('DD/MM/YYYY')}` : ''} `
            }} 
            onCreateOption={salvar}
            onChange={(e, validar) => {
                
                // valida
                if(e === null){
                    alterarDadosPaciente(null);
                    alterouCliente(null);
                }else{
                    alterarDadosPaciente({...e});
                    alterouCliente(e);
                }
            }}
            components={{
                Option: OptionSelect
            }}
            noOptionsMessage={() => 'Digite algo para fazer a consulta'}
            loadingMessage={() => 'Carregando...'}
            placeholder='Nome, cpf ou data de nascimento'
        />
    </>
}
