import React, { useState, useEffect } from 'react';

// componentes
import { Form } from 'react-bootstrap';

function InputInteiro({
    valor,
    onChange,
    disabled=false,
    className='',
    placeholder='',
    aceitarNull=false,
    max=null
}){

    // variaveis
    let [focado, alterarFoco] = useState(false);
    let inputRef = React.createRef();
    let [valorTemp, alterarValorTemp] = useState(valor);

    useEffect(() => {
        if(focado){
            inputRef.current.select();
        }
    }, [focado]);

    useEffect(() => {
        alterarValorTemp(valor);
    }, [valor]);

    
    return <Form.Control 
        placeholder={placeholder}
        disabled={disabled}
        className={className}
        type="text" 
        // style={{width: '110px'}}
        ref={inputRef}
        value={valorTemp || ''}
        onKeyDown={(e) => {

            // verifica a tela e deixa seguir o curso
            let codigoTecla = e.keyCode;
            if([8, 37, 39].includes(codigoTecla)){
                return e;
            }
            
            if (
                (e.which < 48 || e.which > 57) && (e.which < 96 || e.which > 105)
            ) {
                e.preventDefault();
            }

            if(e.keyCode === 13){
                e.target.blur();
            }
        }}
        onChange={(e) => {
            // let valorAtualizar = e.target.value === '' ? '1' : e.target.value;
            let valorAtualizar = e.target.value;
            if(valorAtualizar === ''){
                if(aceitarNull){
                    valorAtualizar = null;
                }else{
                    valorAtualizar = '1';
                }
            }
            alterarValorTemp(valorAtualizar);
            
        }} 
        onFocus={(e) => {
            alterarFoco(true);
        }}
        onBlur={(e) => {

            // corrige para quando for vazio
            let valorAtualizar = e.target.value;
            if(valorAtualizar === ''){
                if(aceitarNull){
                    valorAtualizar = null;
                }else{
                    valorAtualizar = '1';
                }
            }

            // se possuir numero máximo e ultrapassar, mantém máximo
            if(max !== null && max < parseInt(valorAtualizar)){
                valorAtualizar = max;
            }
            
            onChange(valorAtualizar);
            alterarFoco(false);
        }}
    />
}


export default InputInteiro;