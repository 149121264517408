import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';

// componentes
import { Button, Card, Row, Col, Form, Tab, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Formulario from './Formulario';
import { toast } from 'react-toastify';

// serviços
import BioimpedanciaMagneticaServico from './../../../../../servicos/BioimpedanciaMagneticaServico';

export default function Cadastrar(){

    // estados
    const history = useHistory();
    const { login } = useSelector(state => state);
    const { id, idpaciente, idagendamento } = useParams();
    let [carregando, alterarCarregando] = useState(true);
    let [modo, alterarModo] = useState('cadastro'); // cadastro, edicao
    let [salvando, alterarSalvando] = useState(false);
    let [dados, alterarDados] = useState({
        pk_cad_bioimpedancia_magnetica: id || null,
        percentual_gordura_corporal_total: '0',
        peso_gordura_corporal_total: '0',
        percentual_gordura_adequado: '0',
        indice_massa_corporal: '0',
        taxa_metabolismo_basal: '0',
        peso_massa_corporal_magra: '0',
        peso_ideal: '0',
        percentual_massa_magra_ideal: '0',
        quantidade_agua_corporal: '0',
        percentual_agua_corporal: '0',
        percentual_adequado_agua: '0',
        conclusao: null
    });
    let [dadosUltimo, alterarDadosUltimo] = useState(null);

    // buscar último cadastro, se for edição, anterior ao cadastro da edição
    useEffect(() => {
        
        // se for cadastro, carrega o último cadastro feito
        if(dados.pk_cad_bioimpedancia_magnetica === null){
            consultarUltimo();
        }

    }, []);

    // carrega os dados
    useEffect(() => {

        // se for edição
        if(dados.pk_cad_bioimpedancia_magnetica !== null){
            alterarModo('edicao');
            consultarId();
        }else{
            alterarDados({
                ...dados,
                fk_cad_paciente: idpaciente,
                fk_cad_agenda: idagendamento
            });
            alterarCarregando(false);
        }

    }, []);

    // consulta id
    async function consultarId(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/bioimpedanciaMagnetica/${dados.pk_cad_bioimpedancia_magnetica}`);
            alterarDados({
                ...dados,
                ...data.dados
            });

            // se possuir dados do anterior
            if(data.anterior.possuiAnterior){
                alterarDadosUltimo(data.anterior.dados);
            }
            
            // finaliza
            alterarCarregando(false);

        }catch({response}){}
    }

    // carrega os dados do último cadastro
    async function consultarUltimo(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/bioimpedanciaMagnetica/ultimo`, {
                params: {
                    fk_cad_paciente: idpaciente,
                    data: moment().format('YYYY-MM-DD HH:mm:ss')
                }
            });
            if(data.possuiAnterior){
                alterarDadosUltimo(data.dados);
            }

        }catch({response}){}
    }

    // salva registro
    async function salvar(){
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/bioimpedanciaMagnetica`, dados);
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Bioimpedancia cadastrada!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    <p 
                        onClick={() => {
                            history.push(`/painel/paciente/${dados.fk_cad_paciente}/agendamento/${dados.fk_cad_agenda}/bioimpedancia-magnetica/alterar/${dados.pk_cad_bioimpedancia_magnetica}`);
                            closeToast();
                        }}
                    >
                        Clique para abrir novamente!
                    </p>
                </div>
            </>);
            history.goBack();


        }catch({response}){

        }finally{
            alterarSalvando(false);
        }

    }

    // volta a tela anteiror
    function retornar(paraListagem = false){
        history.goBack();
        // if(match.params.id || paraListagem){
        //     history.push(`/painel/paciente/${match.params.idpaciente}/atender/agendamento/${match.params.idagendamento}/bioimpedancia-magnetica`);
        // }else{
        //     history.push(`/painel/paciente/${match.params.idpaciente}/atender/agendamento/${match.params.idagendamento}`);
        // }
    }

    // carregando
    if(carregando){
        return <div>Carregando...</div>
    }

    return <>
        <Row>
            <Col sm='6'>
                <Card className='border-0 shadow-sm'>
                    <Card.Body>

                        <Formulario 
                            titulo='Bioimpedância Atual'
                            dados={dados}
                            alterarDados={alterarDados}
                        />

                    </Card.Body>
                </Card>
            </Col>
            { dadosUltimo &&
                <Col sm='6'>
                    <Card className='border-0 shadow-sm'>
                        <Card.Body>
                            <Formulario 
                                titulo='Bioimpedância Anterior'
                                dados={dadosUltimo}
                                modoLeitura={true}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            }
        </Row>

        <SalvarFormulario 
            salvando={salvando}
            cancelar={() => {
                // history.push('/painel/pacientes');
                retornar();
            }}
            onClick={() => salvar()}
        />

    </>
}

function SalvarFormulario({
    salvando,
    cancelar,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button variant="success" disabled={salvando} onClick={onClick}>
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <span>Salvar</span>
            }
        </Button>
    </Form.Group>
}
