
// icones font awesome
import { library } from '@fortawesome/fontawesome-svg-core';


// far
import { 
    faHeart, faPlusSquare, faMinusSquare, faEnvelope, faListAlt, faFolderOpen, faFolder,
    faCreditCard
} from '@fortawesome/free-regular-svg-icons'; 


library.add(
    faHeart, faPlusSquare, faMinusSquare, faEnvelope, faListAlt, faFolderOpen, faFolder,
    faCreditCard
);