import React, { useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import styles from './index.module.scss';

// componentes
import Carregador from './../../../../ComponentesCadastro/Carregador';

// apresenta os dados do paciente resumido
function DadosPaciente({
    dados,
    carregando
}){

    // se for nulo não mostra nada
    if(dados === null){
        return <></>
    }
    // if(true){
    //     // return <div className="d-flex align-items-center">
    //     //     <strong>Loading...</strong>
    //     //     <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
    //     // </div>
    //     return 
    // }


    return <div className={[styles.formulario, 'rounded'].join(' ')}>
        <div className='bg-light rounded shadow-sm p-2'>
            <Row>
                <Col md='6' className='border-right'>
                    <Form.Group>
                        <Form.Label className='col-form-label-sm m-0'>Email</Form.Label>
                        <p className='border-bottom'>
                            {dados.dados.email}
                        </p>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className='col-form-label-sm m-0'>Convênio</Form.Label>
                        <p className='border-bottom'>
                            {dados.convenio && dados.convenio.descricao}
                        </p>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className='col-form-label-sm m-0'>Observação</Form.Label>
                        <p className='border-bottom'>
                            {dados.dados.observacao}
                        </p>
                    </Form.Group>
                </Col>
                <Col md='6'>
                    <div className={[styles.telefone, 'pb-1 mb-3'].join(' ')}>
                        <table className='tabela w-100'>
                            <thead>
                                <tr>
                                    <th className='py-0 text-center'>Contato</th>
                                    <th className='py-0 text-center'>Numero</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dados.dados.telefones.map((telefone, i) => 
                                    <tr key={i}>
                                        <td className='text-center'>{telefone.contato}</td>
                                        <td className='text-center'>{telefone.numero}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <Form.Group>
                        <Form.Label className='col-form-label-sm m-0'>Indicação</Form.Label>
                        <p className='border-bottom'>
                            {dados.dados.observacao_indicacao}
                        </p>
                    </Form.Group>
                </Col>
            </Row>
            { carregando && <Carregador className='rounded' /> }
        </div>
    </div>
}


export default DadosPaciente;