import React from 'react';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Carregador({
    className,
    bg='light',
    color='rgb(77, 77, 77)',
    descricao='Carregando'
}){

    return <div style={{color: color}} className={[styles.carregador, `bg-${bg}`, className].join(' ')}>
        <FontAwesomeIcon className={styles.icone} icon={'spinner'} pulse />
        <span>{descricao}</span>
    </div>
}

export default Carregador;