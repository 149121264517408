import React from 'react';
import styles from './index.module.scss';

// componentes
import { Card, Form, Row, Col, Button } from 'react-bootstrap';

// habitos de vida
function FormularioHabitosAlimentares({
    dados,
    alterarDados,
    alterarAba,
    eventoAlterarDados
}){

    return <Card className='border-0 shadow-sm'>
        <Card.Body>

            <h3 className='text-center border-bottom mb-3 pb-2'>Hábitos Alimentares</h3>
            
            <Form.Row>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Suplementos alimentares</Form.Label>
                </Col>
                <Col md='9'>
                    <Form.Control 
                        as='textarea'
                        name='suplementos_alimentares'
                        value={dados.suplementos_alimentares || ''}
                        onChange={eventoAlterarDados}
                        maxLength='2000'
                    />
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Alergia alimentar</Form.Label>
                </Col>
                <Col md='9'>
                    <Form.Control 
                        as='textarea'
                        name='alergia_alimentar'
                        value={dados.alergia_alimentar || ''}
                        onChange={eventoAlterarDados}
                        maxLength='2000'
                    />
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Intolerâncias alimentares</Form.Label>
                </Col>
                <Col md='9'>
                    <Form.Control 
                        as='textarea'
                        name='intolerancias_alimentares'
                        value={dados.intolerancias_alimentares || ''}
                        onChange={eventoAlterarDados}
                        maxLength='2000'
                    />
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Recordatório alimentar</Form.Label>
                </Col>
                <Col md='9'>
                    <Form.Control 
                        as='textarea'
                        name='recordatorio_alimentar'
                        value={dados.recordatorio_alimentar || ''}
                        onChange={eventoAlterarDados}
                        maxLength='2000'
                    />
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Observação</Form.Label>
                </Col>
                <Col md='9'>
                    <Form.Control 
                        as='textarea'
                        name='observacao_alimentar'
                        value={dados.observacao_alimentar || ''}
                        onChange={eventoAlterarDados}
                        maxLength='2000'
                    />
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Tipos de alimentos consumido nos finais de semana</Form.Label>
                </Col>
                <Col md='9'>
                    <Form.Control 
                        as='textarea'
                        name='tipos_alimentos_consumido_final_semana'
                        value={dados.tipos_alimentos_consumido_final_semana || ''}
                        onChange={eventoAlterarDados}
                        maxLength='2000'
                    />
                </Col>
            </Form.Row>

        </Card.Body>
    </Card>

}

// habitos alimentares
export default FormularioHabitosAlimentares;
