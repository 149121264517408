
// define reducer de painel
export default function(state = {
    dados: {},
    numero: 0,
    menuEncolhido: false
}, action){

    // // se a ação não for para esse reducer
    // if(action.reducer !== 'painel'){
    //     return state;
    // }

    let dadosAtualizar = null;
    
    switch(action.type){
        case 'painel/ALTERAR_MENU_ENCOLHIDO':
            dadosAtualizar = {
                menuEncolhido: action.estado
            };
            return Object.assign({}, state, dadosAtualizar);
        default:
            return state;
    }
}