import React, { useContext } from 'react';
import styles from './index.module.scss';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, OverlayTrigger, Tooltip  } from 'react-bootstrap';
import iconeReceita2 from './iconeReceita2.png';
import iconeSuplemento2 from './iconeSuplemento2.png';
import { EditorState, convertToRaw, convertFromRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

// importa contexto
import { PlanoAlimentarContexto } from './../../../../ComponentePlanoAlimentar';


// opções
function OpcoesTab({

}){

    // contextos
    let planoDados = useContext(PlanoAlimentarContexto);

    // ao salvar item novo
    function salvarItem(dadosNovos){
        planoDados.alterarConsulta([dadosNovos.item, ...planoDados.consulta]);
    }

    return <div
        className={styles.opcoes}
    >
        
        <OverlayTrigger
            overlay={<Tooltip>Fazer Impressão</Tooltip>}
            placement={'bottom'}
        >
            <Button 
                variant='padrao' 
                size='sm'
                className={'mr-2'}
                // className={styles.botaoTranscrever}
                // onClick={clicouTranscrever}
                onClick={() => {
                    // planoDados.alterarCadastrarAlimento({
                    //     salvou: salvarItem
                    // });

                    // verifica aba                     
                    if(planoDados.refeicaoAba === 'refeicoes'){
                        let converterDocumento = convertFromRaw(JSON.parse(planoDados.criaTranscricaoRefeicoes()));
                        let conteudoEditor = EditorState.createWithContent(converterDocumento);
                        let rawContentState = convertToRaw(conteudoEditor.getCurrentContent());
                        let markup = draftToHtml(
                            rawContentState
                        );
                        planoDados.fazerImpressaoTexto(markup, 'Plano alimentar');
                    }

                    // texto livre
                    if(planoDados.refeicaoAba === 'documento'){
                        if(planoDados.documentoJson !== null){
                            let converterDocumento = convertFromRaw(JSON.parse(planoDados.documentoJson));
                            let conteudoEditor = EditorState.createWithContent(converterDocumento);
                            let rawContentState = convertToRaw(conteudoEditor.getCurrentContent());
                            let markup = draftToHtml(
                                rawContentState
                            );
                            planoDados.fazerImpressaoTexto(markup, 'Plano alimentar');
                        }
                    }
                    
                    // prescrição
                    if(planoDados.refeicaoAba === 'prescricao'){
                        if(planoDados.prescricaoJson !== null){
                            let converterDocumento = convertFromRaw(JSON.parse(planoDados.prescricaoJson));
                            let conteudoEditor = EditorState.createWithContent(converterDocumento);
                            let rawContentState = convertToRaw(conteudoEditor.getCurrentContent());
                            let markup = draftToHtml(
                                rawContentState
                            );
                            planoDados.fazerImpressaoTexto(markup, ' ');
                        }
                    }
                }}
            >
                { planoDados.fazendoImpressaoTexto ?
                    <FontAwesomeIcon className="icone fa-lg" pulse icon={["fas", "spinner"]} />
                    :
                    <FontAwesomeIcon className="icone fa-lg" icon={["fas", "print"]} />
                }
            </Button>
        </OverlayTrigger>

        <OverlayTrigger
            overlay={<Tooltip>Cadastrar Alimento</Tooltip>}
            placement={'bottom'}
        >
            <Button 
                variant='padrao' 
                size='sm'
                className={'mr-2'}
                // className={styles.botaoTranscrever}
                // onClick={clicouTranscrever}
                onClick={() => {
                    planoDados.alterarCadastrarAlimento({
                        salvou: salvarItem
                    });
                }}
            >
                <FontAwesomeIcon className='icone fa-lg' icon={["fas", 'apple-alt']} />
            </Button>
        </OverlayTrigger>
        
        <OverlayTrigger
            overlay={<Tooltip>Cadastrar receita</Tooltip>}
            placement={'bottom'}
        >
            <Button 
                variant='padrao' 
                size='sm'
                className={'mr-2'}
                onClick={() => {
                    planoDados.alterarCadastrarReceita({
                        itemRefeicao: false,
                        suplemento:'N',
                        tipo:'R',
                        salvou: salvarItem
                    });
                }}
            >
                <img src={iconeReceita2} className={styles.imagem} />
            </Button>
        </OverlayTrigger>

        
        <OverlayTrigger
            overlay={<Tooltip>Cadastrar suplemento</Tooltip>}
            placement={'bottom'}
        >
            <Button 
                variant='padrao' 
                size='sm'
                className={'mr-2'}
                // className={styles.botaoTranscrever}
                // onClick={clicouTranscrever}
                onClick={() => {
                    planoDados.alterarCadastrarReceita({
                        itemRefeicao: false,
                        suplemento:'S',
                        tipo:'S',
                        salvou: salvarItem
                    });
                }}
            >
                <img src={iconeSuplemento2} className={styles.imagem} />
            </Button>
        </OverlayTrigger>
        
        <OverlayTrigger
            overlay={<Tooltip>Cadastrar fórmula magistral</Tooltip>}
            placement={'bottom'}
        >
            <Button 
                variant='padrao' 
                size='sm'
                className={'mr-2'}
                // className={styles.botaoTranscrever}
                // onClick={clicouTranscrever}
                onClick={() => {
                    planoDados.alterarCadastrarReceita({
                        itemRefeicao: false,
                        suplemento:'N',
                        tipo:'F',
                        salvou: salvarItem
                    });
                }}
            >
                <FontAwesomeIcon className='icone fa-lg' icon={["fas", 'capsules']} />
            </Button>
        </OverlayTrigger>
        
        <OverlayTrigger
            overlay={<Tooltip>Transcrever <br/>para documento</Tooltip>}
            placement={'bottom'}
        >
            <Button 
                variant='padrao' 
                size='sm'
                // className={styles.botaoTranscrever}
                // onClick={clicouTranscrever}
                onClick={() => {
                    planoDados.transcreverTextoLivre();
                }}
            >
                <FontAwesomeIcon className='icone fa-lg' icon={["fas", 'copy']} />
            </Button>
        </OverlayTrigger>


    </div>

}

export default OpcoesTab;