import React, { useState, useContext } from 'react';
import styles from './index.module.scss';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import FiltroOpcoes from './FiltroOpcoes';
import { parseAsync } from '@babel/core';


import { consultaAlimentosContexto } from './../../ConsultaAlimentos';

function FiltroPasta({
    mostrar,
    clicouMostrarEsconder
}){

    // contexto com os dados do componente pai
    let consultaAlimentos = useContext(consultaAlimentosContexto);

    // estados
    let [mostrarOpcoes, alterarMostrarOpcoes] = useState(false);


    if(!mostrar){
        return <OverlayTrigger
            overlay={<Tooltip>Mostrar</Tooltip>}
        >
            <Button 
                variant='padrao' 
                className='ml-2'
                size='sm' 
                onClick={clicouMostrarEsconder}
            >
                <FontAwesomeIcon  icon={["fas", 'caret-left']} />
            </Button>
        </OverlayTrigger>
    }

    return <div
        className={[styles.containerFiltro, 'd-flex align-items-start'].join(' ')}
    >
        <div
            className={[styles.pasta, 'rounded flex-grow-1'].join(' ')}
            onClick={() => {
                alterarMostrarOpcoes(true);
            }}
        >
            {/* <Button
                className={[styles.botaoPasta, 'mr-2 my-0 p-0 px-1'].join(' ')}
                variant='padrao'
                size='sm'
            >
                <FontAwesomeIcon className='icone' icon={["fas", 'list']} />
            </Button> */}
            <div className={[styles.pastaDescricao, 'p-2 text-truncate'].join(' ')}>
                <FontAwesomeIcon className='icone fa-sm mr-2' icon={["far", 'folder-open']} /> 
                {consultaAlimentos.pastas.find(pasta => {
                    if(pasta.id === consultaAlimentos.parametros.pasta){
                        return true;
                    }
                }).descricao}
            </div>
            
        </div>

        <OverlayTrigger
            overlay={<Tooltip>Esconder</Tooltip>}
        >
            <Button 
                variant='padrao' 
                className='ml-2'
                size='sm' 
                onClick={clicouMostrarEsconder}
            >
                <FontAwesomeIcon  icon={["fas", 'caret-right']} />
            </Button>
        </OverlayTrigger>

        { mostrarOpcoes &&
            <FiltroOpcoes 
                fechou={() => {
                    alterarMostrarOpcoes(false);
                }}
            />
        }
        
    </div>
}

export default FiltroPasta;