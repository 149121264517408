import React from 'react';
import styles from './index.module.scss';

// imagens de bristol
import imgBristol1 from './bristol/tipo1.png';
import imgBristol2 from './bristol/tipo2.png';
import imgBristol3 from './bristol/tipo3.png';
import imgBristol4 from './bristol/tipo4.png';
import imgBristol5 from './bristol/tipo5.png';
import imgBristol6 from './bristol/tipo6.png';
import imgBristol7 from './bristol/tipo7.png';

// componentes
import { Card, Form, Row, Col, Button } from 'react-bootstrap';

// habitos de vida
function FormularioAvaliacaoClinica({
    dados,
    alterarDados,
    alterarAba,
    eventoAlterarDados
}){

    return <Card className='border-0 shadow-sm'>
        <Card.Body>

            <h3 className='text-center border-bottom mb-3 pb-2'>Avaliação Clínica</h3>

            <Form.Row>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Apetite</Form.Label>
                </Col>
                <Col md='9' className='pt-2'>
                    <div>
                        {[
                            {value:'1', label: 'Normal'},
                            {value:'2', label: 'Aumentado'},
                            {value:'3', label: 'Diminuido'}
                        ].map(alimentacao => 
                            <Form.Check 
                                key={alimentacao.value}
                                inline
                                type='radio'
                                {...alimentacao}
                                id={`apetite-${alimentacao.value}`}
                                name='apetite'
                                checked={dados.apetite === alimentacao.value}
                                onChange={eventoAlterarDados}
                            />
                        )}
                    </div>
                    <div className='mt-1'>
                        <Form.Control 
                            placeholder='Observação apetite'
                            name='apetite_observacao'
                            value={dados.apetite_observacao || ''}
                            onChange={eventoAlterarDados}
                            maxLength='300'
                        />
                    </div>
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Mastigação</Form.Label>
                </Col>
                <Col md='9' className='pt-2'>
                    <div>
                        {[
                            {value:'1', label: 'Normal'},
                            {value:'2', label: 'Rápida'},
                            {value:'3', label: 'Lenta'}
                        ].map(alimentacao => 
                            <Form.Check 
                                key={alimentacao.value}
                                inline
                                type='radio'
                                {...alimentacao}
                                id={`mastigacao-${alimentacao.value}`}
                                name='mastigacao'
                                checked={dados.mastigacao === alimentacao.value}
                                onChange={eventoAlterarDados}
                            />
                        )}
                    </div>
                    <div className='mt-1'>
                        <Form.Control 
                            placeholder='Observação mastigação'
                            name='mastigacao_observacao'
                            value={dados.mastigacao_observacao || ''}
                            onChange={eventoAlterarDados}
                            maxLength='300'
                        />
                    </div>
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Hábito Intestinal</Form.Label>
                </Col>
                <Col md='9' className='pt-2'>
                    <div>
                        {[
                            {value:'1', label: 'Normal'},
                            {value:'2', label: 'Constipante'},
                            {value:'3', label: 'Diarréico'},
                            {value:'4', label: 'Variado'}
                        ].map(alimentacao => 
                            <Form.Check 
                                key={alimentacao.value}
                                inline
                                type='radio'
                                {...alimentacao}
                                id={`habito-intestinal-${alimentacao.value}`}
                                name='habito_intestinal'
                                checked={dados.habito_intestinal === alimentacao.value}
                                onChange={eventoAlterarDados}
                            />
                        )}
                    </div>
                    <div className='mt-1'>
                        <Form.Control 
                            placeholder='Observação Hábito Intestinal'
                            name='habito_intestinal_observacao'
                            value={dados.habito_intestinal_observacao || ''}
                            onChange={eventoAlterarDados}
                            maxLength='300'
                        />
                    </div>
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Escala de bristol</Form.Label>
                </Col>
                <Col md='9' className='pt-2'>
                    {[
                        {value:'1', label: 'Pequenos fragmentos duros, semelhantes a nozes.', img: imgBristol1},
                        {value:'2', label: 'Em forma de salsicha, mas com gumos.', img: imgBristol2},
                        {value:'3', label: 'Em forma de salsicha, com fissuras á superficie.', img: imgBristol3},
                        {value:'4', label: 'Em forma de salsicha ou cobra(mais finas), mas suaves e macias.', img: imgBristol4},
                        {value:'5', label: 'Fezes fragmentadas, mas em pedaços com contornos bem definidos e macias.', img: imgBristol5},
                        {value:'6', label: 'Em pedaços esfarrapados.', img: imgBristol6},
                        {value:'7', label: 'Líquidas.', img: imgBristol7},
                        {value:'0', label: 'não soube informar!', img: null},
                    ].map(tipo => 
                        <div 
                            key={tipo.value} 
                            className={[styles.bristol, 'mb-3'].join(' ')}
                        >
                            <Form.Check 
                                inline
                                type='radio'
                                id={`escala-bristol-${tipo.value}`}
                                name='escala_bristol'
                                checked={dados.escala_bristol === tipo.value}
                                onChange={eventoAlterarDados}
                                value={tipo.value}
                            />
                            { tipo.img &&
                                <img className={[styles.imagemBristol, 'mr-2'].join(' ')} src={tipo.img} />
                            }
                            <span>{tipo.label}</span>
                        </div>
                    )}
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>frequência evacuação</Form.Label>
                </Col>
                <Col md='9'>
                    <Form.Control 
                        name='frequencia_evacuacao'
                        value={dados.frequencia_evacuacao || ''}
                        onChange={eventoAlterarDados}
                        maxLength='100'
                    />
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Laxante</Form.Label>
                </Col>
                <Col md='9' className='pt-2'>
                    <Form.Check 
                        inline
                        label='Faz uso'
                        name='laxante_uso'
                        checked={dados.laxante_uso === 'S'}
                        onChange={eventoAlterarDados}
                    />
                </Col>
            </Form.Row>
            
            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Ingestão hídrica</Form.Label>
                </Col>
                <Col md='9'>
                    <Form.Control 
                        name='ingestao_hidrica'
                        value={dados.ingestao_hidrica || ''}
                        onChange={eventoAlterarDados}
                        maxLength='100'
                    />
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Hábito urinário</Form.Label>
                </Col>
                <Col md='5'>
                    <Form.Control 
                        name='habito_urinario'
                        value={dados.habito_urinario || ''}
                        onChange={eventoAlterarDados}
                        maxLength='100'
                    />
                </Col>
                <Col md='4'>
                    <Form.Control 
                        placeholder='Vezes ao dia'
                        name='habito_urinario_frequencia'
                        value={dados.habito_urinario_frequencia || ''}
                        onChange={eventoAlterarDados}
                    />
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Coloração da urina</Form.Label>
                </Col>
                <Col md='9' className='pt-2'>
                    <div>
                        {[
                            {value:'1', label: 'Amarela forte'},
                            {value:'2', label: 'Amarela clara'},
                            {value:'3', label: 'Transparente'},
                            {value:'4', label: 'Outro'}
                        ].map(alimentacao => 
                            <Form.Check 
                                key={alimentacao.value}
                                inline
                                type='radio'
                                {...alimentacao}
                                id={`coloracao-urina-${alimentacao.value}`}
                                name='coloracao_urina'
                                checked={dados.coloracao_urina === alimentacao.value}
                                onChange={eventoAlterarDados}
                            />
                        )}
                    </div>
                    <div className='mt-1'>
                        <Form.Control 
                            placeholder='Observação'
                            name='urina_observacao'
                            value={dados.urina_observacao || ''}
                            onChange={eventoAlterarDados}
                            maxLength='300'
                        />
                    </div>
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Unha</Form.Label>
                </Col>
                <Col md='9' className='pt-2'>
                    <div>
                        {[
                            {value:'1', label: 'Fraca'},
                            {value:'2', label: 'Manchas brancas'},
                            {value:'3', label: 'Fungos'},
                            {value:'4', label: 'Normal'}
                        ].map(alimentacao => 
                            <Form.Check 
                                key={alimentacao.value}
                                inline
                                type='radio'
                                {...alimentacao}
                                id={`unha-${alimentacao.value}`}
                                name='unha'
                                checked={dados.unha === alimentacao.value}
                                onChange={eventoAlterarDados}
                            />
                        )}
                    </div>
                    <div className='mt-1'>
                        <Form.Control 
                            placeholder='Observação'
                            name='unha_observacao'
                            value={dados.unha_observacao || ''}
                            onChange={eventoAlterarDados}
                            maxLength='300'
                        />
                    </div>
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Cabelo</Form.Label>
                </Col>
                <Col md='9' className='pt-2'>
                    <div>
                        {[
                            {value:'1', label: 'Alopecia'},
                            {value:'2', label: 'Queda importante'},
                            {value:'3', label: 'Pouca queda'},
                            {value:'4', label: 'Nenhuma queda'}
                        ].map(alimentacao => 
                            <Form.Check 
                                key={alimentacao.value}
                                inline
                                type='radio'
                                {...alimentacao}
                                id={`cabelo-${alimentacao.value}`}
                                name='cabelo'
                                checked={dados.cabelo === alimentacao.value}
                                onChange={eventoAlterarDados}
                            />
                        )}
                    </div>
                    <div className='mt-1'>
                        <Form.Control 
                            placeholder='Observação'
                            name='cabelo_observacao'
                            value={dados.cabelo_observacao || ''}
                            onChange={eventoAlterarDados}
                            maxLength='300'
                        />
                    </div>
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Observação</Form.Label>
                </Col>
                <Col md='9'>
                    <Form.Control 
                        as='textarea'
                        name='observacao_clinica'
                        value={dados.observacao_clinica || ''}
                        onChange={eventoAlterarDados}
                        maxLength='2000'
                    />
                </Col>
            </Form.Row>

            <Form.Row className='mt-2'>
                <Col md='3' className='pt-1 text-right'>
                    <Form.Label className='font-weight-bold'>Sintomas</Form.Label>
                </Col>
                <Col md='9'>
                    <Form.Control 
                        as='textarea'
                        name='sintomas'
                        onChange={eventoAlterarDados}
                        value={dados.sintomas || ''}
                        maxLength='2000'
                    />
                </Col>
            </Form.Row>

            <p className='text-right mt-4'>
                <Button
                    size='sm'
                    onClick={() => {
                        alterarAba('habitosAlimentares');
                    }}
                >
                    <span>Ir para Hábitos Alimentares</span>
                </Button>
            </p>
            
        </Card.Body>
    </Card>

}

export default FormularioAvaliacaoClinica;
