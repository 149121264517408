import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';

// componentes
import { Card, Table, Button } from 'react-bootstrap';
import OpcoesAtendimento from './../../Atender/OpcoesAtendimento';
import Registro from './Registro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// botão para cadastro
export function RastreamentoMetabolicoBotaoCadastro({
    idpaciente,
    idagendamento
}){
    
    const history = useHistory();

    return <Button
        variant='padrao'
        size='sm'
        onClick={e => {
            e.stopPropagation();
            history.push(`/painel/paciente/${idpaciente}/rastreamento-metabolico/cadastrar/${idagendamento}`);
        }}
    >
        <FontAwesomeIcon className="icone" icon={["fas", "plus"]}></FontAwesomeIcon>
        <span>Cadastrar</span>
    </Button>
}

// dados do titulo
export function RastreamentoMetabolicoTitulo(){

    return {
        icone: ['fas', 'brain'],
        descricao: 'Rastreamento Metabolico'
    }
}

// consultar
export default function Consultar({
    idpaciente=null,
    idagendamento=null,
    expandido=false
}){

    // estados
    const history = useHistory();
    const params = useParams();
    let [parametros, alterarParametros] = useState({
        // pagina: 1,
        // registrosPorPagina: 10,
        fk_cad_paciente: idpaciente || params.idpaciente
    });
    let [consulta, alterarConsulta] = useState([]);

    // inicializa
    useEffect(() => {

        // faz consulta
        consultarRegistros();

    }, [parametros]);

    // consultar
    async function consultarRegistros(){

        try{

            // faz a requisição
            let { data } = await axios.get('/rastreamentoMetabolico', { params: parametros });
            alterarConsulta(data.registros);

        }catch({response}){}
    }

    return <div className={styles.atendimentoConsulta}>
        { !expandido && 
        <div>
            <OpcoesAtendimento 
                minificado={true} 
                abaVoltar='rastreamento-metabolico'
                idpaciente={idpaciente}
                idagendamento={idagendamento}
            />
        </div>
        }
        <div className={styles.containerPainel}>
            <Card className={'painel-consulta border-0 shadow-sm'}>
                <Card.Header className='d-flex cabecalho-atendimento'>
                    <h4 className='flex-grow-1'>
                        <FontAwesomeIcon className="icone mr-2" icon={RastreamentoMetabolicoTitulo().icone} />
                        <span>{RastreamentoMetabolicoTitulo().descricao}</span>
                    </h4>
                    <div>
                        <RastreamentoMetabolicoBotaoCadastro 
                            idpaciente={parametros.fk_cad_paciente}
                            idagendamento={idagendamento || params.idagendamento}
                            history={history}
                        />
                    </div>
                </Card.Header>
                <Card.Body>
                    <Table hover className="tabela">
                        <thead>
                            <tr>
                                {/* <th>Código</th> */}
                                <th>Data</th>
                                <th>Resultado</th>
                                <th></th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            { consulta.map(registro =>  
                                <Registro 
                                    key={registro.dados.pk_cad_rastreamento_metabolico}
                                    dados={registro.dados}
                                    parametros={parametros}
                                    clicouEditar={() => {
                                        history.push(`/painel/paciente/${parametros.fk_cad_paciente}/agendamento/${idagendamento || params.idagendamento}/rastreamento-metabolico/alterar/${registro.dados.pk_cad_rastreamento_metabolico}`)
                                    }}
                                />
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </div>
    </div>
}
