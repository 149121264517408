import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';

// importa contexto
import { PlanoAlimentarContexto } from './../../ComponentePlanoAlimentar';
import Opcao from './Opcao';
import { Button } from 'react-bootstrap';
import BotaoCarregando from './../../ComponentesCadastro/BotaoCarregando';

// componente
function MenuOpcoes({
    
}){

    // utiliza contexto
    let planoContexto = useContext(PlanoAlimentarContexto);

    // componente
    return <div
        className={[styles.menuOpcoes, 'p-1'].join(' ')}
    >
        <BotaoCarregando 
            variant='success'
            texto='Salvar'
            icone={["fas", 'save']} 
            textoCarregando='Salvando'
            carregando={planoContexto.salvando}
            onClick={() => {
                planoContexto.salvou(planoContexto.refeicoes, planoContexto.documentoJson, planoContexto.prescricaoJson); // refeicoes, documento, prescrição, vai com o tipo que veio
            }}
            disabled={planoContexto.salvando}
            className={[styles.opcao, 'w-100 mb-2'].join(' ')}
            iconeClass={styles.icone}
        />
        <Opcao 
            icone={["fas", 'times']} 
            onClick={planoContexto.cancelou}
            variant='danger'
            disabled={planoContexto.salvando}
        >
            Cancelar
        </Opcao>
        {   planoContexto.modo === 'nutrientes' &&
            <Opcao 
                icone={["fas", 'columns']} 
                onClick={() => {
                    planoContexto.alterarModo('plano');
                }}
                disabled={planoContexto.salvando}
            >
                Plano
            </Opcao>
        }
        { planoContexto.modo === 'plano' &&
            <Opcao 
                icone={["fas", 'chart-line']} 
                onClick={() => {
                    planoContexto.alterarModo('nutrientes');
                }}
                disabled={planoContexto.salvando}
            >
                Nutrientes
            </Opcao>
        }

    </div>
}

export default MenuOpcoes;