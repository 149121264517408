import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

function ContainerColuna({
    id,
    children
}){

    return <Droppable 
        droppableId={id} 
        type="itens"
    >
        {(provided, snapshot) => (
            <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={['rounded p-1', snapshot.isDraggingOver && 'bg-light'].join(' ')}
            >
                {children}
                <div className='p-1'>
                    {provided.placeholder}
                </div>
            </div>
        )}
    </Droppable>
}

// retorna
export default ContainerColuna;