import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

// componentes
import { Card, Table, Button } from 'react-bootstrap';
import Registro from './Registro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OpcoesAtendimento from './../../Atender/OpcoesAtendimento';

// botão para cadastro
export function PlanoAlimentarBotaoCadastroNovo({
    idpaciente,
    idagendamento
}){

    const history = useHistory();
    
    return <Button
        variant='padrao'
        size='sm'
        className='mr-2'
        onClick={e => {
            e.stopPropagation();
            history.push(`/painel/paciente/${idpaciente}/plano-alimentar-novo/cadastrar/${idagendamento}`);
        }}
    >
        <FontAwesomeIcon className="icone" icon={["fas", "plus"]}></FontAwesomeIcon>
        <span>Cadastrar</span>
    </Button>
}


// dados do titulo
export function PlanoAlimentarTitulo(){

    return {
        icone: ['fas', 'utensils'],
        descricao: 'Planos alimentares'
    }
}

// componente
export default function PlanoAlimentarConsultar({
    idpaciente=null,
    idagendamento=null,
    expandido=false
}){

    // estados
    const history = useHistory();
    const { LoginReducer } = useSelector(state => state);
    const params = useParams();
    let [parametros, alterarParametros] = useState({
        // pagina: 1,
        // registrosPorPagina: 10,
        // status: ['A'],
        fk_cad_paciente: idpaciente || params.idpaciente
    });
    let [consulta, alterarConsulta] = useState([]);

    useEffect(() => {

        consultarRegistros();

    }, [parametros]);

    // consultar registros
    async function consultarRegistros(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/planoAlimentar`, {
                params: parametros
            });
            alterarConsulta(data.registros);

        }catch({response}){

        }

    }

    return <div className={styles.atendimentoConsulta}>
        { !expandido &&
            <div>
                <OpcoesAtendimento 
                    minificado={true}
                    abaVoltar='plano-alimentar'
                    idpaciente={idpaciente}
                    idagendamento={idagendamento}
                />
            </div>
        }
        <div className={styles.containerPainel}>
            <Card className={'painel-consulta border-0 shadow-sm'}>
                <Card.Header className='d-flex cabecalho-atendimento'>
                    <h4 className='flex-grow-1'>
                        <FontAwesomeIcon className="icone mr-2" icon={PlanoAlimentarTitulo().icone} />
                        <span>{PlanoAlimentarTitulo().descricao}</span>
                    </h4>
                    <div>
                        <PlanoAlimentarBotaoCadastroNovo 
                            idpaciente={parametros.fk_cad_paciente}
                            idagendamento={idagendamento || params.idagendamento}
                            history={history}
                        />
                        {/* <PlanoAlimentarBotaoCadastro 
                            idpaciente={parametros.fk_cad_paciente}
                            idagendamento={idagendamento || match.params.idagendamento}
                            history={history}
                        /> */}
                    </div>
                </Card.Header>
                <Card.Body>
                    <Table hover className="tabela">
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th></th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {consulta.map((registro, a) => 
                                <Registro 
                                    key={a}
                                    dados={registro.dados}
                                    parametros={parametros}
                                    clicouEditar={() => {
                                        history.push(`/painel/paciente/${parametros.fk_cad_paciente}/agendamento/${idagendamento || params.idagendamento}/plano-alimentar/alterar/${registro.dados.pk_cad_plano_alimentar}`)
                                    }}
                                    clicouEditarNovo={() => {
                                        history.push(`/painel/paciente/${parametros.fk_cad_paciente}/agendamento/${idagendamento || params.idagendamento}/plano-alimentar-novo/alterar/${registro.dados.pk_cad_plano_alimentar}`)
                                    }}
                                />   
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </div>
    </div>
}
