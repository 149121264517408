import React from 'react';
import moment from 'moment';

// componentes
import BotaoTabela from './../../../../ComponentesCadastro/BotaoTabela';

function Registro({
    dados,
    parametros,
    clicouEditar
}){

    return <tr>
        {/* <td className="estreito text-center font-weight-bold">
            {dados.pk_cad_bioimpedancia_magnetica}
        </td> */}
        <td className="estreito text-center font-weight-bold">
            {moment(dados.data_cadastro).format('DD/MM/YYYY')}
        </td>
        <td>{dados.percentual_gordura_corporal_total.toString().replace('.', ',')}</td>
        <td>{dados.peso_gordura_corporal_total.toString().replace('.', ',')}</td>
        <td>{dados.indice_massa_corporal.toString().replace('.', ',')}</td>
        <td>{dados.taxa_metabolismo_basal.toString().replace('.', ',')}</td>
        <td>{dados.peso_massa_corporal_magra.toString().replace('.', ',')}</td>
        <td>{dados.peso_ideal.toString().replace('.', ',')}</td>
        <td>{dados.percentual_massa_magra_ideal.toString().replace('.', ',')}</td>
        <td>{dados.quantidade_agua_corporal.toString().replace('.', ',')}</td>
        <td>{dados.percentual_agua_corporal.toString().replace('.', ',')}</td>
        <td>{dados.percentual_adequado_agua.toString().replace('.', ',')}</td>
        <td className="text-nowrap estreito">
            <BotaoTabela 
                onClick={clicouEditar}
                icone={["fas", "pencil-alt"]}
            >
                Editar
            </BotaoTabela>
        </td>
    </tr>
}

export default Registro;