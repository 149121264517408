import React from 'react';
import moment from 'moment';

// componentes
import { Badge } from 'react-bootstrap';
import BotaoTabela from './../../../../ComponentesCadastro/BotaoTabela';
import Inativar from './Inativar';

function Registro({
    dados,
    parametros,
    clicouEditar,
    clicouResultados,
    alterouStatus,
}){
    
    return <>
        
        <tr>
            <td className="estreito text-center font-weight-bold">
                {moment(dados.data_cadastro).format('DD/MM/YYYY')}
            </td>
            <td>
                { dados.entregue === 'S' &&
                    <Badge variant={'success'}>
                        Entregue
                    </Badge>
                }
            </td>
            <td className="text-nowrap estreito">
                <BotaoTabela 
                    onClick={clicouResultados}
                    icone={["fas", "paste"]}
                >
                    Inserir Resultados
                </BotaoTabela>
                <BotaoTabela 
                    onClick={clicouEditar}
                    icone={["fas", "pencil-alt"]}
                >
                    Editar
                </BotaoTabela>
                <Inativar 
                    dados={dados}
                    alterouStatus={alterouStatus}
                />
                
            </td>
        </tr>

    </>
}

export default Registro;