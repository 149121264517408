import React from 'react';
import styles from './index.module.scss';
import moment from 'moment';

// funções
import { mascaraCpf } from './../../../../../../Funcoes';

// componente options
function OptionSelect({
    data, ...props
}){

    // propriedades importantes
    let { innerProps, innerRef, isFocused, isDisabled } = props;

    // retorna componente
    return <div tabIndex='-1' ref={innerRef} {...innerProps} className={[styles.option, isFocused && styles.focado].join(' ')}>
        { (data.__isNew__) ?
            <>
                <p><b>Cadastrar!</b></p>
                <p>{data.value}</p>
            </>
        :
            <>
                <p>{data.dados.nome}</p>
                <p>{mascaraCpf(data.dados.cpf)} {data.dados.data_nascimento ? `- ${moment(data.dados.data_nascimento).format('DD/MM/YYYY')}` : ''}</p>
            </>
        }
    </div>
}

// opção
export default OptionSelect;