import React, { useState } from 'react';
import styles from './index.module.scss';

// componentes
import { Button, ButtonToolbar, OverlayTrigger, Tooltip  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Opcao({
    minificado,
    abrir,
    verMais,
    expandir=false,
    children=<></>,
    titulo,
    botaoCadastro,
    ativo=false
}){

    // alterar icone
    // let [iconePasta, alterarIconePasta] = useState('folder');

    return <>
        <div className='mb-2'>
            { !expandir &&
                <div 
                    className={[styles.opcao, 'rounded p-3', minificado && [styles.minificado, 'mb-2'].join(' ')].join(' ')} 
                    // onMouseLeave={() => {alterarIconePasta('folder')}}
                    // onMouseEnter={() => {alterarIconePasta('folder-open')}}
                    onClick={(e) => {
                        if(!ativo){
                            abrir();
                        }
                    }}
                >

                    {minificado &&
                        <ButtonToolbar>
                            <OverlayTrigger
                                placement='right'
                                overlay={<Tooltip hidden={ativo}>{titulo.descricao}</Tooltip>}
                            >
                            <Button
                                variant={ativo ? 'primary' : 'padrao'}
                                className=''
                                disabled={ativo}
                            >
                                { titulo.img ?
                                    <img src={ativo ? titulo.img2 : titulo.img} style={{objectFit: 'contain'}} width={20} height={16} />
                                    :
                                    <FontAwesomeIcon  className="icone fa-fw" icon={titulo.icone}></FontAwesomeIcon>
                                }
                                
                            </Button>
                            </OverlayTrigger>
                        </ButtonToolbar>
                    }

                    { !minificado &&
                        <>
                            <div className={[styles.iconePasta, 'mx-2'].join(' ')}>
                                { titulo.img ?
                                    <img src={titulo.img} style={{objectFit: 'contain'}} width={20} height={16} />
                                    :
                                    <FontAwesomeIcon  className="icone fa-fw" icon={titulo.icone}></FontAwesomeIcon>
                                }
                                {/* <FontAwesomeIcon  className="icone fa-fw" icon={titulo.icone}></FontAwesomeIcon> */}
                            </div>

                        
                            <div className={styles.descricao}>
                                {titulo.descricao}
                            </div>
                            
                            {botaoCadastro}
                        </>
                    }

                    
                </div>
            }
            { expandir && children}
            
        { (!minificado && expandir) &&
            <p className='text-right'>
                <Button 
                    onClick={verMais} 
                    size='sm' 
                    variant='padrao'
                    className='mt-2'
                >
                Ver mais
                </Button>
            </p>
        }
        </div>
    </>
}

export default Opcao;