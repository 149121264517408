import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios';

// componentes
import { Card, Row, Col, Form, Button, Modal, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import TipoExame from './TipoExame';
import ContainerColuna from './ContainerColuna';
import ModalConfirmar from './../../../ComponentesCadastro/ModalConfirmar';
import ModalCadastroExame from './ModalCadastroExame';

// componente
export default function Cadastrar(){

    // estados
    const history = useHistory();
    const { id, idpaciente, idagendamento } = useParams();
    let [exames, alterarExames] = useState(null);
    let [carregando, alterarCarregando] = useState(true);
    let [salvando, alterarSalvando] = useState(false);
    let [dados, alterarDados] = useState({
        pk_cad_solicitacao_exame: id || null
    });
    let [examesSelecionados, alterarExamesSelecionados] = useState([]);
    let [confirmarImpressao, alterarConfirmarImpressao] = useState(false);
    let [criandoPdf, alterarCriandoPdf] = useState(false);
    const [cadastroExame, alterarCadastroExame] = useState(null);

    // ao finalizar alteração
    function onDragEnd(retorno){
        
        // altera o destino
        if (!retorno.destination) {
            return;
        }

        // pega dados
        let {source, destination} = retorno;

        // altera a posição dos itens
        let [item] = exames[source.droppableId].splice(source.index, 1);
        exames[destination.droppableId].splice(destination.index, 0, item);

        // altera exames
        alterarExames({...exames});

        // envia requisição salvando posição dos exames
        salvarPosicaoExames(exames);

    }

    useEffect(() => {

        // verifica se é edição
        if(dados.pk_cad_solicitacao_exame !== null){
            consultarId();
        }else{
            alterarDados({
                ...dados,
                fk_cad_paciente: idpaciente,
                fk_cad_agenda: idagendamento
            });
            carregarTiposExames();
        }

        // carrega os dados do paciente
        // PacienteServico


    }, []);

    // consulta id
    async function consultarId(){
        alterarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/solicitacaoExame/${dados.pk_cad_solicitacao_exame}`);
            
            alterarDados({
                ...dados,
                ...data.dados
            });
            alterarExames(data.exames);

            // estames selecionados
            let examesSelecionado = data.examesSelecionado.map(item => 
                item.fk_cad_tipo_exame
            );
            alterarExamesSelecionados([...examesSelecionado]);
            alterarCarregando(false);

        }catch({response}){}


    }

    // carrega os tipos de exame
    async function carregarTiposExames(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/tipoExame`);
            alterarExames(data.registros);

            alterarCarregando(false);

        }catch({response}){}

    }

    // salva ordem dos exames alterado
    async function salvarPosicaoExames(examesNovaOrdem){

        try{

            // faz a requisição
            let { data } = await axios.post(`/tipoExame/ordem`, examesNovaOrdem);
            
        }catch({response}){}

    }

    // volta a tela anteiror
    function retornar(paraListagem = false){
        history.goBack();
        // if(match.params.id || paraListagem){
        //     history.push(`/painel/paciente/${match.params.idpaciente}/atender/agendamento/${match.params.idagendamento}/solicitacao-exame`);
        // }else{
        //     history.push(`/painel/paciente/${match.params.idpaciente}/atender/agendamento/${match.params.idagendamento}`);
        // }
    }

    // salvar
    async function salvar(){
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/solicitacaoExame`, {
                dados: dados,
                examesSelecionados: examesSelecionados
            });
            dados.pk_cad_solicitacao_exame = data.pk_cad_solicitacao_exame;
            alterarDados({...dados})
            alterarConfirmarImpressao(true);

        }catch({response}){

        }finally{
            alterarSalvando(false);
        }

    }

    // inclui/exclui exame selecionado
    function selecionarExame(id){
        let buscaExameSelecionado = examesSelecionados.find(opcao => {
            if(opcao === id){
                return true;
            }
        });
        
        // incrementa ou remove elemento
        if(!buscaExameSelecionado){
            alterarExamesSelecionados([...examesSelecionados, id]);
        }else{
            examesSelecionados.splice(examesSelecionados.indexOf(id),1);
            alterarExamesSelecionados([...examesSelecionados]);
        }
    }

    // gerar pdf
    async function criarPdf(){
        // 
        alterarCriandoPdf(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/solicitacaoExame/${dados.pk_cad_solicitacao_exame}/pdf`);
            
            // faz o dowload
            window.open(
                // `${process.env.REACT_APP_URL_PHP}/download.php?p=temp&f=${encodeURIComponent(data.arquivo)}&n=${encodeURIComponent(data.nome)}`,
                `${process.env.REACT_APP_URL_ARQUIVOS}/download.php/${encodeURIComponent(data.nome)}?p=temp&f=${encodeURIComponent(data.arquivo)}&n=${encodeURIComponent(data.nome)}`,
                '_blank'
            );
            retornar(true);

        }catch({ response }){

        }finally{
            alterarCriandoPdf(false);
        }


    }

    if(carregando){
        return <div>Carregando...</div>
    }

    return <>
        {/* <TestePdf /> */}
        {/* <Button
            onClick={criarPdf}
        >Gerar PDF</Button> */}

        { confirmarImpressao &&
            <ModalConfirmar 
                // titulo='Impressão'
                descricao='Deseja fazer a impressão da solicitação de exames?'
                textoConfirmar='Fazer impressão'
                textoCancelar='Depois'
                confirmar={() => {
                    criarPdf();
                }}
                cancelar={() => {
                    retornar(true);
                }}
                fechou={() => {
                    alterarConfirmarImpressao(false);
                }}
            />
        }

        <Card className='border-0 shadow-sm'>
            <Card.Body>
                <Row>
                    <DragDropContext 
                        onDragEnd={onDragEnd} 
                    >
                        <Col md='6'>
                            <ContainerColuna
                                id='coluna1'
                            >
                                {exames.coluna1.map((item, a) => 
                                    <TipoExame
                                        key={item.pk_cad_tipo_exame}
                                        dados={item}
                                        index={a}
                                        onChange={selecionarExame}
                                        checked={examesSelecionados.find(opcao => opcao === item.pk_cad_tipo_exame) ? true : false}
                                        clicouEditar={() => {
                                            alterarCadastroExame({id: item.pk_cad_tipo_exame})
                                        }}
                                    />
                                )}
                            </ContainerColuna>
                        </Col>
                        <Col md='6'>
                            <ContainerColuna
                                id='coluna2'
                            >
                                {exames.coluna2.map((item, a) => 
                                    <TipoExame 
                                        key={item.pk_cad_tipo_exame}
                                        dados={item}
                                        index={a}
                                        onChange={selecionarExame}
                                        checked={examesSelecionados.find(opcao => opcao === item.pk_cad_tipo_exame) ? true : false}
                                        clicouEditar={() => {
                                            alterarCadastroExame({id: item.pk_cad_tipo_exame})
                                        }}
                                    />
                                )}
                            </ContainerColuna>
                        </Col>
                    </DragDropContext>
                </Row>

                <Button
                    variant='padrao'
                    size='sm'
                    onClick={() => {
                        alterarCadastroExame({id: null});
                    }}
                >
                    <FontAwesomeIcon className={'icone'} icon={faPlus} />
                    <span>Adicionar exame</span>
                </Button>
                { cadastroExame &&
                    <ModalCadastroExame 
                        id={cadastroExame.id}
                        fechou={() => {
                            alterarCadastroExame(null);
                        }}
                        salvou={() => {
                            carregarTiposExames();
                        }}
                    />
                }
            </Card.Body>
        </Card>
        { criandoPdf &&
            <Modal 
                centered size='sm' 
                show={true}
                onHide={() => {}}
            >
                <Modal.Body className='text-center'>
                    <FontAwesomeIcon className="icone mr-2" pulse icon={["fas", 'spinner']} />
                    <span>Carregando impressão, aguarde!</span>
                </Modal.Body>
            </Modal>
        }
        <SalvarFormulario 
            salvando={salvando}
            criandoPdf={criandoPdf}
            cancelar={() => {
                retornar();
            }}
            onClick={() => salvar()}
        />
    </>
}

function SalvarFormulario({
    salvando,
    criandoPdf,
    cancelar,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button variant="success" disabled={salvando || criandoPdf} onClick={onClick}>
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <span>Salvar</span>
            }
        </Button>
    </Form.Group>
}
