import React, { useState, useEffect } from 'react';
import './index.module.scss';
import './PainelPagina.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Route } from 'react-router-dom';
import axios from 'axios';

// importa sidebar componente
import PainelSidebar from './PainelSidebar';
import PainelNavbar from './PainelNavbar';

// paginas
// import ClientesPaginas from './clientes/ClientesPagina';
// import ClienteCadastrarPagina from './clientes/ClienteCadastroPagina';
import AgendaConsultar from './Agenda/Consultar';
// import AgendaCadastrar from './Agenda/Cadastrar';
import PacientesAtender from './Pacientes/Atender';
import PacientesConsultar from './Pacientes/Consultar';
import PacientesCadastrar from './Pacientes/Cadastrar';
import ConveniosConsultar from './Convenios/Consultar';
import ConveniosCadastrar from './Convenios/Cadastrar';
import UsuariosConsultar from './Usuarios/Consultar';
import UsuariosJornada from './Usuarios/Jornada';

// funções destinadas aos pacientes
// import PlanoAlimentarCadastrar from './Pacientes/PlanoAlimentar/Cadastrar';
import PlanoAlimentarCadastrarNovo from './Pacientes/PlanoAlimentar/CadastrarNovo';
import AnamneseCadastrar from './Pacientes/Anamnese/Cadastrar';
import RastreamentoMetabolicoCadastrar from './Pacientes/RastreamentoMetabolico/Cadastrar';
import TeiaInterRelacaoMetabolicaCadastrar from './Pacientes/TeiaInterRelacaoMetabolica/Cadastrar';
import AvaliacaoAntropometricaCadastrar from './Pacientes/AvaliacaoAntropometrica/Cadastrar';
import BioimpedanciaMagneticaCadastrar from './Pacientes/BioimpedanciaMagnetica/Cadastrar';
import SolicitacaoExameCadastrar from './Pacientes/SolicitacaoExame/Cadastrar';
import SolicitacaoExameResultado from './Pacientes/SolicitacaoExame/Resultado';
import Assinatura from './Assinatura';
import AssinaturaPagamento from './Assinatura/Pagamento';
import AssinaturaFinalizado from './Assinatura/Finalizado';

// componente
export default function PainelPagina(){
    
    // reducers
    const { PainelReducer, LoginReducer } = useSelector(state => state);
    const dispatch = useDispatch();
    let [liberado, alterarLiberado] = useState(false);

    
    // consulta as permissões do usuário
    useEffect(() => {
        
        // altera token de usuário para as requisições
        axios.defaults.headers.common['tokenusuario'] = LoginReducer.dadosUsuarioEmpresa.token;

        // atualiza dados da empresa por mais atual
        verificarDadosEmpresa();

        // permissões
        alterarLiberado(true);

    }, []);

    // atauliza os dados da empresa
    async function verificarDadosEmpresa(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/empresa/${LoginReducer.dadosUsuarioEmpresa.pk_cad_usuario_empresa}`);
            dispatch({
                type: 'loginReducer/ALTERAR_EMPRESA',
                dadosEmpresa: data.empresa,
                dadosUsuarioEmpresa: data.dados
            });
            
        }catch({response}){

        }
        
    }
    
    // rotas
    let rotas = [

        // agenda
        {path: '/painel', component: AgendaConsultar},
        // {path: '/painel/agenda/:id/:data/:hora', component: AgendaCadastrar}, // id: pk_cad_usuario_empresa
        
        // clientes
        // {path: '/painel/clientes', component: ClientesPaginas},
        // {path: '/painel/clientes/cadastrar', component: ClienteCadastrarPagina},
        // {path: '/painel/clientes/alterar/:id', component: ClienteCadastrarPagina}

        // pacientes
        {path: '/painel/pacientes', component: PacientesConsultar},
        {path: '/painel/pacientes/cadastrar', component: PacientesCadastrar},
        {path: '/painel/pacientes/alterar/:id', component: PacientesCadastrar},
        {path: '/painel/paciente/:idpaciente/atender', component: PacientesAtender},
        {path: '/painel/paciente/:idpaciente/atender/agendamento/:idagendamento', component: PacientesAtender},

        // itens do paciente
        {path: '/painel/paciente/:idpaciente/atender/agendamento/:idagendamento/anamnese', component: PacientesAtender},
        {path: '/painel/paciente/:idpaciente/atender/agendamento/:idagendamento/plano-alimentar', component: PacientesAtender},
        {path: '/painel/paciente/:idpaciente/atender/agendamento/:idagendamento/rastreamento-metabolico', component: PacientesAtender},
        {path: '/painel/paciente/:idpaciente/atender/agendamento/:idagendamento/teia-metabolica', component: PacientesAtender},
        {path: '/painel/paciente/:idpaciente/atender/agendamento/:idagendamento/avaliacao-antropometrica', component: PacientesAtender},
        {path: '/painel/paciente/:idpaciente/atender/agendamento/:idagendamento/bioimpedancia-magnetica', component: PacientesAtender},
        {path: '/painel/paciente/:idpaciente/atender/agendamento/:idagendamento/solicitacao-exame', component: PacientesAtender},

        // anamnese
        {path: '/painel/paciente/:idpaciente/anamnese/cadastrar/:idagendamento', component: AnamneseCadastrar},
        {path: '/painel/paciente/:idpaciente/agendamento/:idagendamento/anamnese/alterar/:id', component: AnamneseCadastrar},

        // rastreamento metabolico
        {path: '/painel/paciente/:idpaciente/rastreamento-metabolico/cadastrar/:idagendamento', component: RastreamentoMetabolicoCadastrar},
        {path: '/painel/paciente/:idpaciente/agendamento/:idagendamento/rastreamento-metabolico/alterar/:id', component: RastreamentoMetabolicoCadastrar},

        // teia inter relação metabolica
        {path: '/painel/paciente/:idpaciente/teia-metabolica/cadastrar/:idagendamento', component: TeiaInterRelacaoMetabolicaCadastrar},
        {path: '/painel/paciente/:idpaciente/agendamento/:idagendamento/teia-metabolica/alterar/:id', component: TeiaInterRelacaoMetabolicaCadastrar},

        // avaliação antropometrica
        {path: '/painel/paciente/:idpaciente/avaliacao-antropometrica/cadastrar/:idagendamento', component: AvaliacaoAntropometricaCadastrar},
        {path: '/painel/paciente/:idpaciente/agendamento/:idagendamento/avaliacao-antropometrica/alterar/:id', component: AvaliacaoAntropometricaCadastrar},

        // bioimpedancia magnetica 
        {path: '/painel/paciente/:idpaciente/bioimpedancia-magnetica/cadastrar/:idagendamento', component: BioimpedanciaMagneticaCadastrar},
        {path: '/painel/paciente/:idpaciente/agendamento/:idagendamento/bioimpedancia-magnetica/alterar/:id', component: BioimpedanciaMagneticaCadastrar},

        // solicitação de exames
        {path: '/painel/paciente/:idpaciente/solicitacao-exame/cadastrar/:idagendamento', component: SolicitacaoExameCadastrar},
        {path: '/painel/paciente/:idpaciente/agendamento/:idagendamento/solicitacao-exame/alterar/:id', component: SolicitacaoExameCadastrar},
        {path: '/painel/paciente/:idpaciente/agendamento/:idagendamento/solicitacao-exame/resultado/:id', component: SolicitacaoExameResultado},
        

        // // plano alimentar
        // {path: '/painel/paciente/:idpaciente/plano-alimentar/cadastrar/:idagendamento', component: PlanoAlimentarCadastrar},
        // {path: '/painel/paciente/:idpaciente/agendamento/:idagendamento/plano-alimentar/alterar/:id', component: PlanoAlimentarCadastrar},


        
        // plano alimentar novo
        {path: '/painel/paciente/:idpaciente/plano-alimentar-novo/cadastrar/:idagendamento', component: PlanoAlimentarCadastrarNovo},
        {path: '/painel/paciente/:idpaciente/agendamento/:idagendamento/plano-alimentar-novo/alterar/:id', component: PlanoAlimentarCadastrarNovo},



        // {path: '/painel/paciente/:idpaciente/anamnese/cadastrar', component: PacientesAtender},
        // {path: '/painel/paciente/:idpaciente/anamnese/alterar/:id', component: PacientesAtender},

        // convenios
        {path: '/painel/convenios', component: ConveniosConsultar},
        {path: '/painel/convenios/cadastrar', component: ConveniosCadastrar},
        {path: '/painel/convenios/alterar/:id', component: ConveniosCadastrar},

        // usuários
        {path: '/painel/usuarios', component: UsuariosConsultar},
        {path: '/painel/usuarios/:id/jornada', component: UsuariosJornada},

        // assinatura
        {path: '/painel/assinatura', component: Assinatura},
        {path: '/painel/assinatura/pagamento', component: AssinaturaPagamento},
        {path: '/painel/assinatura/finalizado', component: AssinaturaFinalizado},
        


        

    ];

    
    // ajustando axios
    if(!liberado){
        return <></>
    }
    
    return <div className={['painel-pagina', (PainelReducer.menuEncolhido)? 'menu-encolher' : ''].join(' ')}>
        <PainelSidebar 
            logo='EVOLUTTS' 
            className={(PainelReducer.menuEncolhido)? 'encolher' : ''} 
            encolherMenu={() => {
                // encolherMenu(!menuEncolhido)
                dispatch({
                    type: 'painel/ALTERAR_MENU_ENCOLHIDO',
                    estado: !PainelReducer.menuEncolhido
                });
            }} 
            links={[
                {icone: 'calendar-alt', descricao: 'Agenda', url: '/painel'},
                {icone: 'users', descricao: 'Pacientes', url: '/painel/pacientes'},
                {icone: 'hands-helping', descricao: 'Convênios', url: '/painel/convenios'},
                // // {icone: 'clipboard-list', descricao: 'Procedimentos', url: '/painel/procedimentos'}
                {icone: 'user-friends', descricao: 'Usuarios', url: '/painel/usuarios'}
            ]} 
        />
        <div className="painel-pagina-corpo">
            <PainelNavbar />
            <div className="content">
                <div className="container-fluid">

                    {/* <Route path="/painel/clientes" component={ClientesPaginas} />
                    <Route exact path="/painel/clientes/cadastrar" component={ClienteCadastrarPagina} />
                    <Route exact path="/painel/clientes/alterar/:id" component={ClienteCadastrarPagina} /> */}

                    {rotas.map((rota, a) => (
                        <Route key={a} exact {...rota} />
                    ))}

                </div>
            </div>
        </div>
    </div>;

}
