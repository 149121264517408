import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

// componentes
import { Card, Button } from 'react-bootstrap';
import Arquivo, { ArquivoUpload } from './Arquivo';

// carrega e salva novos anexos
export default function Anexos({
    id // pk_cad_solicitacao_exame
}){

    // estados
    const { login } = useSelector(state => state);
    let [anexos, alterarAnexos] = useState([]);
    let [carregando, alterarCarregando] = useState(true);
    let [arquivosUpload, alterarArquivosUpload] = useState([]);

    // referencias
    let inputRef = React.createRef();

    // adiciona arquivo
    function adicionarArquivos(){

        // insere na fila
        let arrayNovo = arquivosUpload;
        Array.from(inputRef.current.files).forEach(arquivo => {
            arrayNovo.push({arquivo, progresso: null});
        });
        alterarArquivosUpload([...arrayNovo]);

        salvar();
    }

    // salva os arquivos
    function salvar(){

        // salva arquivo por arquivo
        arquivosUpload.forEach(async (dados, a) => {

            // faz a requisição
            let salvarArquivo = new FormData();
            salvarArquivo.append('arquivo', dados.arquivo);
            salvarArquivo.append('fk_cad_solicitacao_exame', id);

            // verifica o progresso da imagem do card
            function progressoArquivo(progressEvent){
                let progress = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                dados.progresso = progress;
                alterarArquivosUpload([...arquivosUpload]);
            }

            // faz a requisição
            try{
                let { data } = await axios.post(`/solicitacaoExameAnexo`, salvarArquivo, {
                    onUploadProgress: progressoArquivo,
                    headers: {'Content-type': 'multipart/form-data'}
                });
    
                // insere no outro array de arquivos
                anexos.push(data);
                alterarAnexos([...anexos]);

            }catch({response}){

            }finally{
                let index = arquivosUpload.indexOf(dados);
                if(index >= 0){
                    arquivosUpload.splice(index, 1);
                }
                alterarArquivosUpload(arquivosUpload);

            }

        });

    }

    // consulta os anexos
    useEffect(() => {
        consultarRegistros();
    }, []);

    // consulta anexos
    async function consultarRegistros(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/solicitacaoExameAnexo`, {
                params: {
                    fk_cad_solicitacao_exame: id
                }
            });
            alterarAnexos(data.registros);
            alterarCarregando(false);

        }catch({response}){}
    }

    // carregando
    if(carregando){
        return <small>Carregando Anexos ...</small>
    }

    return <Card
        className={'border-0'}
    >
        <Card.Body>
            <p className='text-right'>
                <Button
                    variant='padrao'
                    onClick={() => inputRef.current.click()}
                    size='sm'
                    disabled={arquivosUpload.length > 0}
                >
                    Anexar
                </Button>
                <input 
                    hidden 
                    type='file' 
                    multiple 
                    ref={inputRef}
                    onChange={adicionarArquivos}
                />
            </p>
            {anexos.map(anexo => 
                <Arquivo 
                    key={anexo.dados.pk_cad_solicitacao_exame_anexo}
                    dados={anexo.dados}

                />    
            )}
            {arquivosUpload.map((upload, a) => 
                <ArquivoUpload
                    key={a}
                    dados={upload}
                />    
            )}
        </Card.Body>
    </Card>
}
