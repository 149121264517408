import React, { useContext } from 'react';
import styles from './index.module.scss';

// componentes
import Opcao from './Opcao';
import { Button } from 'react-bootstrap';

// importa contexto
import { PlanoAlimentarContexto } from './../../../../../../ComponentePlanoAlimentar';



// opções
function Opcoes({
    registros,
    indexRefeicao
}){

    let planoDados = useContext(PlanoAlimentarContexto);

    // adiciona opção assa refeição
    function adicionarOpcao(){
        planoDados.refeicoes[indexRefeicao]._opcoes.push([]);
        planoDados.alterarRefeicoes([...planoDados.refeicoes]);

    }

    return <div
        className={[
            styles.opcoes,
            'rounded p-1 dropsss'
        ].join(' ')}
    >
        {registros.map((registro, index) => 
            <Opcao 
                key={index}
                dados={registro}
                index={index}
                indexRefeicao={indexRefeicao}
                ultimaOpcao={registros.length - 1  === index}
            />
        )}

        <Button
            variant='padrao'
            size='sm'
            onClick={adicionarOpcao}
        >
            Opção {registros.length + 1}
        </Button>
    </div>
}

export default Opcoes;