import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Table, Button } from 'react-bootstrap';
import OpcoesAtendimento from './../../Atender/OpcoesAtendimento';
import Registro from './Registro';

// icones
import adipometroIcone from './../../../Pacientes/Consultar/Registro/adipometro.svg';
import adipometroIcone2 from './../../../Pacientes/Consultar/Registro/adipometro2.svg';

// botão para cadastro
export function AvaliacaoAntropometricaBotaoCadastro({
    idpaciente,
    idagendamento
}){

    // estados
    const history = useHistory();
    
    return <Button
        variant='padrao'
        size='sm'
        onClick={e => {
            e.stopPropagation();
            history.push(`/painel/paciente/${idpaciente}/avaliacao-antropometrica/cadastrar/${idagendamento}`);
        }}
    >
        <FontAwesomeIcon className="icone" icon={["fas", "plus"]}></FontAwesomeIcon>
        <span>Cadastrar</span>
    </Button>
}

// dados do titulo
export function AvaliacaoAntropometricaTitulo(){

    return {
        // icone: ['fas', 'balance-scale-right'],
        img: adipometroIcone,
        img2: adipometroIcone2,
        descricao: 'Avaliação Antropometrica'
    }
}

// componente
export default function Consultar({
    idpaciente=null,
    idagendamento=null,
    expandido=false
}){

    // estados
    const history = useHistory();
    const params = useParams();
    let [parametros, alterarParametros] = useState({
        // pagina: 1,
        // registrosPorPagina: 10,
        fk_cad_paciente: idpaciente || params.idpaciente,
        ordenar_campo: ['data_cadastro'],
        ordenar_ordem: ['desc']
    });
    let [consulta, alterarConsulta] = useState([]);
    let [dadosPaciente, alterarDadosPaciente] = useState(null);
    let [criandoPdf, alterarCriandoPdf] = useState(false);

    // inicializa
    useEffect(() => {
        
        // faz consulta
        consultarRegistros();

    }, [parametros]);

    // consulta registros
    async function consultarRegistros(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/avaliacaoAntropometrica`, { params: parametros });
            alterarDadosPaciente(data.paciente);
            alterarConsulta(data.registros);

        }catch({response}){}


    }

    // criar pdf
    async function gerarImpressao(){
        alterarCriandoPdf(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/avaliacaoAntropometrica/impressao`, { params: parametros });
            alterarCriandoPdf(false);

            // faz o dowload
            window.open(
                // `${process.env.REACT_APP_URL_PHP}/download.php?p=temp&f=${encodeURIComponent(data.arquivo)}&n=${encodeURIComponent(data.nome)}`,
                `${process.env.REACT_APP_URL_ARQUIVOS}/download.php/${encodeURIComponent(data.nome)}?p=temp&f=${encodeURIComponent(data.arquivo)}&n=${encodeURIComponent(data.nome)}`,
                '_blank'
            );

        }catch({response}){}

    }

    return <div className={styles.atendimentoConsulta}>
    { !expandido &&
        <div>
            <OpcoesAtendimento 
                minificado={true}
                abaVoltar='avaliacao-antropometrica'
                idpaciente={idpaciente}
                idagendamento={idagendamento}
            />
        </div>
    }
        <div className={styles.containerPainel}>
            <Card className={'painel-consulta border-0  shadow-sm'}>
                <Card.Header className='d-flex'>
                    <h4 className='flex-grow-1'>
                        { AvaliacaoAntropometricaTitulo().img ?
                            <img className='mr-2' src={AvaliacaoAntropometricaTitulo().img} width={18} height={18} />
                            :
                            <FontAwesomeIcon className="icone mr-2" icon={AvaliacaoAntropometricaTitulo().icone} />
                        }
                        <span>{AvaliacaoAntropometricaTitulo().descricao}</span>
                    </h4>
                    <div>
                        <Button
                            variant='info'
                            size='sm'
                            className='mr-3'
                            disabled={criandoPdf}
                            onClick={gerarImpressao}
                        >   
                            { criandoPdf ?
                                <FontAwesomeIcon className="icone" pulse icon={['fas', 'spinner']} />
                                :
                                <FontAwesomeIcon className="icone" icon={['fas', 'print']} />
                            }
                            <span>Impressão</span>
                        </Button>
                        <AvaliacaoAntropometricaBotaoCadastro 
                            idpaciente={parametros.fk_cad_paciente}
                            idagendamento={idagendamento || params.idagendamento}
                            history={history}
                        />
                    </div>
                </Card.Header>
                <Card.Body>
                    <Table hover className="tabela">
                        <thead>
                            <tr>
                                <th 
                                    className='d-flex justify-content-between align-items-center'
                                    style={{cursor: 'pointer'}} 
                                    onClick={() => {
                                        let ordenar = parametros.ordenar_campo.indexOf('data_cadastro');
                                        
                                        if(ordenar >= 0 && parametros.ordenar_ordem[ordenar] === 'asc'){
                                            parametros.ordenar_campo.splice(ordenar, 1);
                                            parametros.ordenar_ordem.splice(ordenar, 1);
                                        }else{
                                            if(parametros.ordenar_ordem[ordenar] === 'desc'){
                                                parametros.ordenar_ordem[ordenar] = 'asc';
                                            }else{
                                                parametros.ordenar_campo.push('data_cadastro');
                                                parametros.ordenar_ordem.push('desc');
                                            }
                                        }
                                        alterarParametros({...parametros});
                                    }}
                                > 
                                    <span>Data</span>

                                    {parametros.ordenar_campo.indexOf('data_cadastro') >= 0 &&
                                    
                                        <FontAwesomeIcon 
                                            icon={['fas', 
                                                (parametros.ordenar_ordem[parametros.ordenar_campo.indexOf('data_cadastro')]) === 'desc' ?
                                                'sort-amount-down'
                                                :
                                                'sort-amount-down-alt'
                                            ]}
                                        />
                                    }
                                </th>
                                <th>Protocolo</th>
                                <th>PA <small>(Kg)</small></th>
                                <th>Altura <small>(cm)</small></th>
                                <th>IMC</th>
                                <th>RC/Q</th>
                                <th>Gordura <small>(%)</small></th>
                                <th>Massa <small>(Kg)</small></th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            { consulta.map(registro =>  
                                <Registro 
                                    key={registro.dados.pk_cad_avaliacao_antropometrica}
                                    dados={registro.dados}
                                    dadosPaciente={dadosPaciente}
                                    parametros={parametros}
                                    clicouEditar={() => {
                                        history.push(`/painel/paciente/${parametros.fk_cad_paciente}/agendamento/${idagendamento || params.idagendamento}/avaliacao-antropometrica/alterar/${registro.dados.pk_cad_avaliacao_antropometrica}`)
                                    }}
                                />
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </div>
    </div>
}
