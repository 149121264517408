import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';

// componentes
import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import Exame from './Exame';
import Anexos from './Anexos';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// componente
export default function Resultado(){

    // estados
    const { login } = useSelector(state => state);
    const { id, idpaciente, idagendamento } = useParams();
    const history = useHistory();
    let [carregando, alterarCarregando] = useState(true);
    let [dados, alterarDados] = useState({
        pk_cad_solicitacao_exame: id
    });
    let [exames, alterarExames] = useState([]);
    let [salvando, alterarSalvando] = useState(false);

    // carrega os registros para inserção de resultado
    useEffect(() => {
        consultarId();
    }, []);

    // consulta id 
    async function consultarId(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/solicitacaoExame/${dados.pk_cad_solicitacao_exame}`);
            alterarDados(data.dados);
            alterarExames(data.examesSelecionado);
            alterarCarregando(false);

        }catch({response}){}

    }

    // salva os dados
    async function salvar(){
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/solicitacaoExame/resultado`, {
                dados: dados,
                exames: exames
            });
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Resultados entregues!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    <p 
                        onClick={() => {
                            history.push(`/painel/paciente/${dados.fk_cad_paciente}/agendamento/${dados.fk_cad_agenda}/solicitacao-exame/resultado/${dados.pk_cad_solicitacao_exame}`);
                            closeToast();
                        }}
                    >
                        Clique para abrir novamente!
                    </p>
                </div>
            </>);
            history.goBack();

        }catch({ response }){

        }finally{
            alterarSalvando(false);
        }



    }

    // carregando
    if(carregando){
        return <div>
            Carregando ...
        </div>
    }


    return <>
        <Row>
            <Col lg='8'>
                <Card className='border-0 shadow-sm'>
                    <Card.Header className='bg-white border-0'>
                        <Card.Title as='h4'>Resultado da solicitação de exames</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        {exames.map(exame => 
                            <Exame 
                                key={exame.pk_cad_solicitacao_exame_tipo}
                                dados={exame}
                                alterou={novosDados => {
                                    Object.assign(exame, novosDados);
                                    alterarExames([...exames]);
                                }}
                            />
                        )}
                    </Card.Body>
                </Card>

                <SalvarFormulario 
                    salvando={salvando}
                    cancelar={() => {
                        history.goBack();
                    }}
                    onClick={() => salvar()}
                />
            </Col>
            <Col lg='4'>
                {/* anexos viram aqui */}
                <Anexos 
                    id={dados.pk_cad_solicitacao_exame}
                />
            </Col>
        </Row>
    </>

}


function SalvarFormulario({
    salvando,
    cancelar,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button variant="success" disabled={salvando} onClick={onClick}>
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <span>Salvar</span>
            }
        </Button>
    </Form.Group>
}
