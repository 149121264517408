import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';

// componentes
import { Card, Table, Button } from 'react-bootstrap';
import OpcoesAtendimento from './../../Atender/OpcoesAtendimento';
import Registro from './Registro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// botão para cadastro
export function SolicitacaoExameBotaoCadastro({
    idpaciente,
    idagendamento
}){

    // estados
    let history = useHistory();
    
    return <Button
        variant='padrao'
        size='sm'
        onClick={e => {
            e.stopPropagation();
            history.push(`/painel/paciente/${idpaciente}/solicitacao-exame/cadastrar/${idagendamento}`);
        }}
    >
        <FontAwesomeIcon className="icone" icon={["fas", "plus"]}></FontAwesomeIcon>
        <span>Cadastrar</span>
    </Button>
}

// dados do titulo
export function SolicitacaoExameTitulo(){

    return {
        icone: ['fas', 'file-medical'],
        descricao: 'Solicitação de exames'
    }
}

// componente
export default function SolicitacaoExame({
    idpaciente=null,
    idagendamento=null,
    expandido=false
}){

    // estados
    let history = useHistory();
    let login = useSelector(state => state.login);
    let parametrosUrl = useParams();
    let [parametros, alterarParametros] = useState({
        // pagina: 1,
        // registrosPorPagina: 10,
        fk_cad_paciente: idpaciente || parametrosUrl.idpaciente
    });
    let [consulta, alterarConsulta] = useState([]);
    let [criandoPdf, alterarCriandoPdf] = useState(false);

    // gera impressão
    async function gerarImpressao(){

        // cria pdf
        alterarCriandoPdf(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/solicitacaoExame/impressao`, {
                params: {
                    fk_cad_paciente: parametros.fk_cad_paciente,
                    pagina: 1,
                    registrosPorPagina: 10,
                    entregue: ['S']
                }
            });

            // faz o dowload
            // ${process.env.REACT_APP_URL_PHP}/api
            window.open(
                // `${process.env.REACT_APP_URL_PHP}/download.php?p=temp&f=${encodeURIComponent(data.arquivo)}&n=${encodeURIComponent(data.nome)}`,
                `${process.env.REACT_APP_URL_ARQUIVOS}/download.php/${encodeURIComponent(data.nome)}?p=temp&f=${encodeURIComponent(data.arquivo)}&n=${encodeURIComponent(data.nome)}`,
                '_blank'
            );

        }catch({response}){

        }finally{
            alterarCriandoPdf(false);
        }

    }

    // inicializa
    useEffect(() => {
        consultarRegistros();
    }, [parametros]);

    // faz a consulta
    async function consultarRegistros(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/solicitacaoExame`, { params: parametros });
            alterarConsulta(data.registros);

        }catch({response}){}

    }

    return <div className={styles.atendimentoConsulta}>
        { !expandido &&
            <div>
                <OpcoesAtendimento 
                    minificado={true}
                    abaVoltar='solicitacao-exame'
                    idpaciente={idpaciente}
                    idagendamento={idagendamento}
                />
            </div>
        }
        <div className={styles.containerPainel}>
            <Card className={'painel-consulta border-0 shadow-sm'}>
                <Card.Header className='d-flex cabecalho-atendimento'>
                    <h4 className='flex-grow-1'>
                        <FontAwesomeIcon className="icone mr-2" icon={SolicitacaoExameTitulo().icone} />
                        <span>{SolicitacaoExameTitulo().descricao}</span>
                    </h4>
                    <div>
                        <Button
                            variant='info'
                            size='sm'
                            className='mr-3'
                            disabled={criandoPdf}
                            onClick={gerarImpressao}
                        >   
                            { criandoPdf ?
                                <FontAwesomeIcon className="icone" pulse icon={['fas', 'spinner']} />
                                :
                                <FontAwesomeIcon className="icone" icon={['fas', 'print']} />
                            }
                            <span>Impressão</span>
                        </Button>
                        <SolicitacaoExameBotaoCadastro 
                            idpaciente={parametros.fk_cad_paciente}
                            idagendamento={idagendamento || parametrosUrl.idagendamento}
                            history={history}
                        />
                    </div>
                </Card.Header>
                <Card.Body>
                    <Table hover className="tabela">
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th></th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            { consulta.map(registro =>  
                                <Registro 
                                    key={registro.dados.pk_cad_solicitacao_exame}
                                    dados={registro.dados}
                                    parametros={parametros}
                                    clicouEditar={() => {
                                        history.push(`/painel/paciente/${parametros.fk_cad_paciente}/agendamento/${idagendamento || parametrosUrl.idagendamento}/solicitacao-exame/alterar/${registro.dados.pk_cad_solicitacao_exame}`)
                                    }}
                                    clicouResultados={() => {
                                        history.push(`/painel/paciente/${parametros.fk_cad_paciente}/agendamento/${idagendamento || parametrosUrl.idagendamento}/solicitacao-exame/resultado/${registro.dados.pk_cad_solicitacao_exame}`)
                                    }}
                                    alterouStatus={(novoStatus) => {
                                        registro.dados.status = novoStatus;
                                        alterarConsulta([...consulta]);
                                    }}
                                />
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </div>
    </div>

}
