import React from 'react';

// componentes
import { Tabs, Tab } from 'react-bootstrap';

// componente
export default function DiasSemana({
    value,
    onChange
}){

    // retorna
    return <>
        <Tabs 
            defaultActiveKey={value} 
            onSelect={key => {
                onChange(key);
            }}
        >
            <Tab eventKey={1} title='Domingo' />
            <Tab eventKey={2} title='Segunda' />
            <Tab eventKey={3} title='Terça' />
            <Tab eventKey={4} title='Quarta' />
            <Tab eventKey={5} title='Quinta' />
            <Tab eventKey={6} title='Sexta' />
            <Tab eventKey={7} title='Sábado' />
        </Tabs>
    </>
}