import React, { useState, useContext } from 'react';
import styles from './index.module.scss';

// componentes
import { DebounceInput } from 'react-debounce-input';
import { Button, InputGroup, FormControl  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// importa contexto
import { consultaAlimentosContexto } from './../../ConsultaAlimentos';

function InputConsultar({

}){

    // contexto
    let consultaAlimentos = useContext(consultaAlimentosContexto);

    // estados
    let [inputConsulta, alterarInputConsulta] = useState(consultaAlimentos.parametros.texto);

    // texto informado, aplicar parametro
    function aplicarParametroEConsultar(valorTexto){
        alterarInputConsulta(valorTexto);
        consultaAlimentos.parametros.texto = valorTexto;
        consultaAlimentos.parametros.pagina = 1;
        consultaAlimentos.alterarParametros({...consultaAlimentos.parametros});
    }

    return <InputGroup
        className={' my-2'}
    >
        <DebounceInput 
            // placeholder="" 
            debounceTimeout={400}
            className='border-right-0'
            element={FormControl} 
            value={inputConsulta}
            onChange={(e) =>{
                aplicarParametroEConsultar(e.target.value);
            }}
        />
        <InputGroup.Append >
            <InputGroup.Text 
                className={[styles.botaoLupa, 'border-left-0 bg-light rounded-right'].join(' ')}
                onClick={() => {
                    aplicarParametroEConsultar(inputConsulta);
                }}
            >
                <FontAwesomeIcon className='fa-fw' icon={["fas", 'search']} />
            </InputGroup.Text>
        </InputGroup.Append>

    </InputGroup>

}

export default InputConsultar;