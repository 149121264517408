import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

// componentes
import { Card, Col, Row } from 'react-bootstrap';
import Carregador from './../../ComponentesCadastro/Carregador';
import PaginacaoComponente from './../../ComponentesCadastro/Paginacao';
import Parametros from './Parametros';
import Registro from './Registro';
import moment from 'moment';

// componente
export default function Consultar(){

    // estados
    const history = useHistory();
    const { LoginReducer } = useSelector(state => state);
    let [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: 10,
        status: ['A'],
        texto: ''
    });
    let [totalRegistros, atualizarTotalRegistros] = useState(0);
    let [carregando, mostrarCarregando] = useState(true);
    let [consulta, alterarConsulta] = useState([]);

    // faz a consulta
    useEffect(() => {
        mostrarCarregando(true);

        // faz a requisição
        consultarRegistros();

    }, [parametros]);

    // faz a consulta
    async function consultarRegistros(){
        
        try{

            // faz a requisição
            let { data } = await axios.get(`/paciente`, { params: parametros });
            alterarConsulta(data.registros);
            atualizarTotalRegistros(parseInt(data.totalRegistros));
            mostrarCarregando(false);

        }catch({response}){

        }

    }

    // atender cliente
    async function atender(registro){

        // carregando...
        mostrarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/agenda`, {
                pk_cad_agenda: null,
                fk_cad_paciente: registro.pk_cad_paciente,
                fk_cad_convenio: registro.fk_cad_convenio,
                data_hora: moment().format('YYYY-MM-DD HH:mm:ss'),
                fk_cad_usuario_empresa: LoginReducer.dadosUsuarioEmpresa.pk_cad_usuario_empresa,
                situacao: '5' // aguardando, situação 6 após finalizar o atendimento
            });
            history.push(`/painel/paciente/${data.dados.fk_cad_paciente}/atender/agendamento/${data.dados.pk_cad_agenda}`);

        }catch({response}){

        }

    }

    return <Row>
        <Col>
            <Card className="painel-consulta border-0 shadow-sm" >
                <Card.Header>
                    <Card.Title as='h4'>Pacientes</Card.Title>
                    <Parametros parametros={parametros} alterarParametros={alterarParametros}/>
                </Card.Header>
                <Card.Body>
                    <table className="table table-hover tabela">
                        <thead>
                            <tr>
                                {/* <th>Código</th> */}
                                <th>Nome</th>
                                <th>Nascimento</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {consulta.map((registro, a) => 
                                <Registro 
                                    key={a} 
                                    dados={registro.dados}
                                    parametros={parametros}
                                    onClickTr={() => console.log('clicou TR')}
                                    clicouEditar={() => {
                                        history.push(`/painel/pacientes/alterar/${registro.dados.pk_cad_paciente}`);
                                    }}
                                    clicouAtender={() => {
                                        atender(registro.dados);
                                    }}
                                    alterouStatus={status => {
                                        registro.dados.status = status;
                                        alterarConsulta([...consulta]);
                                    }}
                                />
                            )}
                        </tbody>
                    </table>
                    <PaginacaoComponente
                        pagina={parametros.pagina}
                        registrosPorPagina={parametros.registrosPorPagina}
                        totalRegistros={totalRegistros}
                        alterouPagina={(pagina) => {
                            alterarParametros({...parametros, pagina: pagina});
                        }}
                    />
                    {carregando && 
                        <Carregador className="rounded-bottom" />
                    }
                </Card.Body>
            </Card>
        </Col>
    </Row>
}
