import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/lib/Async';
import axios from 'axios';

// componentes
import OptionSelect from './OptionSelect';

// serviços
import UsuarioEmpresaServico from './../../../../../servicos/UsuarioEmpresaServico';

function ComboUsuarioAgenda({
    id,
    alterou,
    login
}){

    // estados
    let [consulta, alterarConsulta] = useState([]);
    let [carregando, alterarCarregando] = useState(true);
    let [dados, alterarDados] = useState(null);

    // inicializa
    useEffect(() => {

        // carrega alguns
        consultar('');

        // se possuir cõdigo, carrega os dados do convênio
        if(id !== null || (dados !== null && id !== dados.pk_cad_usuario_empresa)){
            consultarCodigo();
        }else{
            alterarCarregando(false);
        }

    }, []);

    // ao modificar cliente externamente
    useEffect(() => {
        console.log(dados, id);
        if(dados !== null && id !== dados.pk_cad_usuario_empresa){
            consultarCodigo();
        }

        if(dados === null && id !== null){
            consultarCodigo();
        }

    }, [id]);

    // consulta id
    async function consultarCodigo(){
        
        try{

            // faz a requisição
            let { data } = await axios.get(`/empresa/${id}`);
            alterarDados(data);
            alterarCarregando(false);

        }catch({response}){

        }
    }

    // faz a consulta
    async function consultar(inputValue, callback = null){

        try{

            // faz a requisição
            let { data } = await axios.get(`/empresa/usuario`, {
                params: {
                    texto: inputValue,
                    pagina: 1,
                    registrosPorPagina: 7,
                    infoUsuario: true
                }
            });
            alterarConsulta(data.registros);
            if(callback !== null){
                callback(data.registros);
            }

        }catch({response}){

        }finally{

        }

    }

    // retorna combo
    return <>
        <AsyncSelect 
            menuPlacement='auto'
            isDisabled={carregando}
            isClearable
            loadOptions={consultar} // ao alterar, carrega
            defaultOptions={consulta}       // lista pré carregada
            value={dados && {
                label: dados.usuario.nome
            }}  
            onChange={(e) => {
                console.log(e);
                if(e === null){
                    alterarDados(null);
                    alterou(null);
                }else{
                    alterarDados({...e});
                    alterou(e.dados.pk_cad_usuario_empresa);
                }
            }}
            components={{
                Option: OptionSelect
            }}
            noOptionsMessage={() => 'Descrição'}
            loadingMessage={() => 'Carregando...'}
            placeholder='Selecionar Usuário'
        />
    </>

}

// importa states
let mapStateToProps = function(state){
    let estado = {
        login: state.login
    };
    return estado;
};

// retorna
export default connect(mapStateToProps)(ComboUsuarioAgenda);
