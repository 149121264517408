import React, { useState, useRef, useEffect } from 'react';
import styles from './index.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, Card } from 'react-bootstrap';
import Moment from 'react-moment';
import moment from 'moment';
import axios from 'axios';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RegistroAgenda from './RegistroAgenda';
import AgendaCadastrar from './Cadastrar';
import ComboUsuarioAgenda from './ComboUsuarioAgenda';

// agenda
export default function AgendaPagina(){
    
    // inicializa registro selecionado
    let iniciaRegistroSelecionado = {registro: null, data: null, hora: null};
    
    // variaveis
    const { LoginReducer, AgendaReducer } = useSelector(state => state);
    const dispatch = useDispatch();
    let [carregandoRegistros, alterarCarregandoRegistros] = useState(true);
    let [registroSelecionado, alterarRegistroSelecionado] = useState({...iniciaRegistroSelecionado});
    let [registros, alterarRegistros] = useState([]);

    // inicializa os dados
    useEffect(() => {
        alterarCarregandoRegistros(true);

        // seleciona o usuário como padrão
        if(AgendaReducer.dadosConsulta.pk_cad_usuario_empresa === null){

            // define usuário padrão da agenda
            if(LoginReducer.dadosUsuarioEmpresa.padrao_agenda !== null){
                alterarUsuarioAgenda(LoginReducer.dadosUsuarioEmpresa.padrao_agenda);
            }else{
                alterarUsuarioAgenda(LoginReducer.dadosUsuarioEmpresa.pk_cad_usuario_empresa);
            }
        }

    }, []);

    // altera usuário da agenda
    function alterarUsuarioAgenda(idNovo){
        dispatch({
            type: 'agenda/ALTERAR_DADOS',
            dadosConsulta: Object.assign(AgendaReducer.dadosConsulta, {
                pk_cad_usuario_empresa: idNovo
            })
        });
    }

    // ao alterar usuário, carrega dados da agenda
    useEffect(() => {

        // carrega os dados da agenda do usuário
        alterarRegistros([]);
        consultarRegistros(AgendaReducer.dadosConsulta);
        
    }, [AgendaReducer.dadosConsulta.pk_cad_usuario_empresa]);

    // faz a consulta
    async function consultarRegistros(dadosConsulta){
        alterarCarregandoRegistros(true);

        try{

            let { data } = await axios.get(`/agenda/${dadosConsulta.pk_cad_usuario_empresa}`, {
                params: dadosConsulta
            });
            if(data.agenda.length > 0 ){
                alterarRegistros(data.agenda);
            }
            alterarCarregandoRegistros(false);

        }catch({response}){

        }

    }

    // limpa registro selecionado
    function limparRegistroSelecionado(){
        alterarRegistroSelecionado({...iniciaRegistroSelecionado});
    }

    // faz as requisições
    return <>
            {/* {carregandoRegistros && <div>Carregando...</div>} */}
        <div className={styles.botoesEvento}>
            <Button 
                variant="padrao"  
                onClick={() => {
                    let dataDecrementar = moment(registros[registros.length - 1].agendaData);
                    dataDecrementar.subtract(1, 'd');
                    dispatch({
                        type: 'agenda/ALTERAR_DADOS',
                        dadosConsulta: Object.assign(AgendaReducer.dadosConsulta, {
                            data: dataDecrementar.format('YYYY-MM-DD'),
                            evento: 'voltar'
                        })
                    });
                    consultarRegistros(AgendaReducer.dadosConsulta);
                }}
                disabled={carregandoRegistros}
            >
                {carregandoRegistros ? 
                    <>
                        <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                        <span>Voltar</span>
                    </> : 
                    <span>Voltar</span>
                }
            </Button>
            <div className={styles.comboUsuarios}>
                <ComboUsuarioAgenda 
                    id={AgendaReducer.dadosConsulta.pk_cad_usuario_empresa}
                    alterou={novoIdUsuario => {
                        alterarUsuarioAgenda(novoIdUsuario);
                    }}
                />
            </div>
            <Button 
                variant="padrao" 
                onClick={() => {
                    let dataIncrementar = moment(registros[0].agendaData);
                    dataIncrementar.add(1, 'd');
                    dispatch({
                        type: 'agenda/ALTERAR_DADOS',
                        dadosConsulta: Object.assign(AgendaReducer.dadosConsulta, {
                            data: dataIncrementar.format('YYYY-MM-DD'),
                            evento: 'avancar'
                        })
                    });
                    consultarRegistros(AgendaReducer.dadosConsulta);
                }}
                disabled={carregandoRegistros}
            >
                {carregandoRegistros ? 
                    <>
                        <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                        <span>Avançar</span>
                    </> : 
                    <span>Avançar</span>
                }
            </Button>
        </div>
        <div className="row">
        
            {registros.map((agenda, a) => <div key={a} className={`col-md-${ 12 / AgendaReducer.dadosConsulta.dias}`}>
                <Card className={["shadow-sm border-0", styles.card].join(' ')}>
                    <Card.Header className={styles.cabecalho}>
                        <span className={styles.data}><Moment format="DD/MM/YYYY">{agenda.agendaData}</Moment></span>
                    </Card.Header>
                    <div className={[styles.diaSemana, 'p-1'].join(' ')}>
                        <Moment format="dddd">{agenda.agendaData}</Moment>
                    </div>
                    <Card.Body className={['table-full-width table-responsive', styles.corpo].join(' ')}>

                        {agenda.horarios.map((registro, b) => 
                            <RegistroAgenda 
                                key={b}
                                data={agenda.agendaData}
                                hora={registro.hora}
                                agendado={registro.dados ? true : false}
                                situacaoAlterada={(codigo) => {
                                    
                                    registro.dados.situacao = codigo;
                                    alterarRegistros(registros);
                                }}
                                dados={registro.dados}
                                paciente={registro.paciente}
                                clicouAgenda={() => {
                                    console.log(agenda.agendaData);
                                    alterarRegistroSelecionado({
                                        registro, data: 
                                        agenda.agendaData, 
                                        hora: registro.hora
                                    });
                                    
                                }}
                            />
                        )}
                    </Card.Body>
                </Card>
            </div>)}
        </div>

        { registroSelecionado.registro &&
            <AgendaCadastrar 
                key={registroSelecionado.pk_cad_agenda}
                data={registroSelecionado.data}
                hora={registroSelecionado.hora}
                registro={registroSelecionado.registro}
                alterouDados={() => {
                    alterarRegistros([...registros]);
                }}
                salvouAgendamento={(dadosNovos) => {
                    if(dadosNovos.hora !== registroSelecionado.registro.hora){
                        consultarRegistros(AgendaReducer.dadosConsulta);
                    }else{
                        Object.assign(registroSelecionado.registro, dadosNovos);
                        alterarRegistros([...registros]);
                    }
                }}
                cancelouAgendamento={() => {
                    alterarRegistros([...registros]);
                }}
                fechou={limparRegistroSelecionado}
            />
        }
    </>
}