import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Container, Button, Form } from 'react-bootstrap';
import Registro from './Registro';

// modal escolher empresa para alterar
export default function ModalEmpresas({
    fechou,
    alterou
}){

    // estados
    const { AgendaReducer } = useSelector(state => state);
    const history = useHistory();
    const dispatch = useDispatch();
    let [carregando, alterarCarregando] = useState(true);
    let [mostrar, alterarMostrar] = useState(true);
    let [consulta, alterarConsulta] = useState([]);

    // inicializa
    useEffect(() => {
        consultarRegistros();
    }, []);

    // consulta as empresas
    async function consultarRegistros(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/empresas`);
            alterarConsulta(data.registros);
            alterarCarregando(false);

        }catch({response}){

        }
    }

    // aplica alteração
    function alterar(){

    }

    // retorna modal de carregando
    if(carregando){
        return <Modal 
            show={mostrar}
            onHide={() => {}}
        >
            <Modal.Body>
                <p>Carregando empresas <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} /></p>
            </Modal.Body>
        </Modal>
    }

    // retorna
    return <Modal 
        show={mostrar} 
        onExited={fechou}
        onHide={() => {}}
        size={'md'}
    >
        <Modal.Header className='border-0'>
            <Modal.Title>
                Alterar empresa
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
                {/* lista as empresas */}
                <table className="table table-hover tabela">
                    <tbody>
                        {consulta.map(registroConsulta => 
                            <Registro 
                                key={registroConsulta.usuarioEmpresa.pk_cad_usuario_empresa}
                                dados={registroConsulta}
                                clicouAlterar={() => {
                                    console.log(registroConsulta)
                                    // altera a empresa
                                    console.log(registroConsulta.empresa);
                                    dispatch({
                                        type: 'agenda/ALTERAR_DADOS',
                                        dadosConsulta: Object.assign(AgendaReducer.dadosConsulta, {
                                            pk_cad_usuario_empresa: registroConsulta.usuarioEmpresa.pk_cad_usuario_empresa
                                        })
                                    });
                                    dispatch({
                                        type: 'loginReducer/ALTERAR_EMPRESA',
                                        dadosEmpresa: registroConsulta.empresa,
                                        dadosUsuarioEmpresa: registroConsulta.usuarioEmpresa
                                    });


                                    // atualiza agenda
                                    // history.go(-(history.length - 1));
                                    history.go('/');
                                    alterarMostrar(false);
                                }}
                            />
                        )}
                    </tbody>
                </table>
            </Container>
        </Modal.Body>
        <Modal.Footer  className='border-0'>
            <Button
                variant='danger'
                onClick={() => {alterarMostrar(false)}}
                // disabled={salvando}
            >
                <span>Fechar</span>
            </Button>
            {/* <Button
                onClick={alterar}
                variant='success'
                disabled={salvando}
            >
                {salvando ? 
                    <>
                        <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                        <span>Salvando</span>
                    </> : 
                    <span>alterar</span>
                }
            </Button> */}
        </Modal.Footer>
    </Modal>

}