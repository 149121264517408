import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styles from './index.module.scss';
import iconeAgendar from './AgendamentoOpcoes/imagens/agendar.png';
import axios from 'axios';

// componentes
import { Modal, Row, Col, Button } from 'react-bootstrap';
import BotaoCarregando from './../../../ComponentesCadastro/BotaoCarregando';
import moment from 'moment';
import { toast } from 'react-toastify';

// componentes
import BuscarPaciente from './BuscarPaciente';
import DiaAgendamento from './DiaAgendamento';
import ApresentarDadosPaciente from './ApresentarDadosPaciente';
import AgendamentoOpcoes from './AgendamentoOpcoes';
import Carregador from './../../../ComponentesCadastro/Carregador';

// cadastra agenda
export default function AgendaCadastrar({
    registro,
    data,
    hora,
    fkCadUsuarioEmpresa,
    alterouDados,
    salvouAgendamento,
    fechou,
    cancelouAgendamento
}){
    
    // estados
    const { AgendaReducer } = useSelector(state => state);
    const history = useHistory();
    let [carregando, alterarCarregando] = useState(true);
    let [dados, alterarDados] = useState(null);
    let [dadosPaciente, alterarDadosPaciente] = useState(null);
    let [modo, alterarModo] = useState(null) // cadastro, edicao
    let [salvando, alterarSalvando] = useState(false);
    let [cadastro, mostrarCadastro] = useState(false);
    let [opcoes, mostrarOpcoes] = useState(false);
    let [cancelando, alterarCancelando] = useState(false);

    // inicializa
    useEffect(() => {

        // verifica os dados
        if(registro.dados){
            mostrarOpcoes(true);
        }else{
            mostrarCadastro(true);
            carregarDados();
        }
    }, []);

    // carrega os dados
    async function carregarDados(){
        alterarCarregando(true);

        // dados padrão do agendamento
        let compararDados = {
            pk_cad_agenda: null,
            fk_cad_usuario_empresa: AgendaReducer.dadosConsulta.pk_cad_usuario_empresa,
            fk_cad_paciente: null,
            data_hora: `${data} ${hora}`,
            fk_cad_convenio: null,
            situacao: 1
        };

        // carrega dados
        if(registro.dados){ // edição
            console.log(registro);
            alterarModo('edicao');
            Object.assign(compararDados, registro.dados);
            

            try{

                let { data } = await axios.get(`/agenda/${AgendaReducer.dadosConsulta.pk_cad_usuario_empresa}/${registro.dados.pk_cad_agenda}`);
                console.log(data);
                alterarDadosPaciente({
                    dados: registro.paciente,
                    convenio: data.convenio
                });
                alterarDados({...compararDados});
                alterarCarregando(false);
            }catch({response}){

            }

            // consulta todos os dados do agendamento
            // -> agendamentos anteriores desse paciente, nessa empresa
            // -> procedimentos
            // -> convenio
            // -> consulta do paciente vinculado

        }else{
            alterarModo('cadastro');
            alterarDados({...compararDados});
            alterarCarregando(false);
            alterarDadosPaciente(null);
        }
        
    }

    // salva
    async function salvar(){
        alterarSalvando(true);
        
        try{

            // faz a requisição
            let { data } = await axios.post(`/agenda`, dados);
            salvouAgendamento({dados: data.dados, hora: moment(dados.data_hora).format('hh:mm:ss'),paciente: data.paciente});
            mostrarCadastro(false);

        }catch({response}){

        }finally{
            alterarSalvando(false);
        }
    }

    // cancela agendamento
    async function cancelarAgendamento(){

        // faz a requisição
        alterarCancelando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/agenda`, {
                status: 'I',
                pk_cad_agenda: registro.dados.pk_cad_agenda
            });
            let nome = registro.paciente.nome;
            let hora = registro.hora.split(':');
            delete registro.dados;
            delete registro.paciente;
            cancelouAgendamento();
            mostrarOpcoes(false);

            // apresenta toast
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Agendamento cancelado!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    <p>
                        {/* Clique para desfazer! */}
                        {nome} as {hora[0]}:{hora[1]}
                    </p>
                </div>
            </>);

        }catch({response}){

        }finally{
            alterarCancelando(false);
        }

    }
    
    // componentes
    return <>
        <Modal
            size="lg"
            show={cadastro}
            onHide={() => {
                mostrarCadastro(false);
            }}
            onExited={() => {
                fechou();
            }}
            >
        
            <Modal.Header className='border-0'>
                <Modal.Title as='h5' className={styles.cabecalho}>
                    <img src={iconeAgendar} /> 
                    <span>Agendar Paciente</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{minHeight: '150px'}}>
                { carregando ? 
                    <Carregador className='rounded' />
                    :
                    <>
                        <Row>
                            <Col  md='6'>
                                <BuscarPaciente 
                                    dados={dados}
                                    alterouCliente={(dadosPaciente) => {
                                        // console.log(dadosPaciente);
                                        alterarDadosPaciente(dadosPaciente);
                                        if(dadosPaciente === null){
                                            alterarDados({...dados, 
                                                fk_cad_paciente: null, 
                                                fk_cad_convenio: null
                                            });
                                        }else{
                                            alterarDados({...dados, 
                                                fk_cad_paciente: dadosPaciente.dados.pk_cad_paciente, 
                                                fk_cad_convenio: dadosPaciente.dados.fk_cad_convenio
                                            });
                                        }

                                        
                                    }}
                                />
                            </Col>
                            <Col md='6'>
                                <DiaAgendamento 
                                    teste={[data, hora]}
                                    data={dados.data_hora}
                                    alterou={(horario) => {
                                        alterarDados({...dados, data_hora: horario})
                                    }}
                                />
                                
                            </Col>
                        </Row>
                        <ApresentarDadosPaciente 
                            dados={dadosPaciente}
                            carregando={carregando}
                        />
                        
                        <p className='text-right mt-3'>
                            <Button 
                                className='mr-2'
                                onClick={() => {
                                    mostrarCadastro(false);
                                }}
                                variant='danger'
                                disabled={salvando}
                            >
                                Cancelar
                            </Button>
                            <BotaoCarregando 
                                variant='success'
                                texto={modo === 'cadastro' ? 'Agendar' : 'Reagendar'}
                                textoCarregando='Salvando'
                                carregando={salvando}
                                disabled={dados.fk_cad_paciente === null}
                                onClick={salvar}
                            />
                        </p>
                    </>
                }
            </Modal.Body>
        </Modal>

        <Modal 
            show={opcoes}
            size="md"
            onHide={() => {
                if(!cancelando){
                    mostrarOpcoes(false);
                }
            }}
            onExited={() => {
                // fechar();
                if(!cadastro){
                    fechou();
                }
            }}
            >
            <Modal.Body>
                <AgendamentoOpcoes 
                    atender={() => {
                        // console.log(registro.dados);
                        // redireciona
                        // console.log(props);
                        console.log(registro.dados);
                        history.push(`/painel/paciente/${registro.dados.fk_cad_paciente}/atender/agendamento/${registro.dados.pk_cad_agenda}`);

                    }}
                    cancelar={() => {
                        cancelarAgendamento();
                    }}
                    cancelando={cancelando}
                    alterar={() => {
                        mostrarOpcoes(false);
                        mostrarCadastro(true);
                        carregarDados();
                    }}
                />
            </Modal.Body>
        </Modal>
    </>
}