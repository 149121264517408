import React, { useEffect, useState, useRef } from "react";
import styles from './index.module.scss';

function ComboStatus({ 
    value, 
    options, 
    placeholder = "Select", 
    onChange 
}) {
    const node = useRef();

    const [open, setOpen] = useState(false);

    const handleClick = e => {
        if (node.current.contains(e.target)) {
            // inside click
            return;
        }
        // outside click
        setOpen(false);
    };

    const handleChange = selectedValue => {
        onChange(selectedValue);
        setOpen(false);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    return (
        <div ref={node} className={styles.dropdown}>
            <button className={styles.dropdownToggler} onClick={e => setOpen(!open)}>
                {options.map(opt => 
                    (opt.id === value) && opt.descricao 
                )}
            </button>
            {open && (
                <ul className={styles.dropdownMenu}>
                    {options.map((opt, a) => (
                        <li key={a} className={[styles.dropdownMenuItem, (opt.id === value) ? styles.ativo : ''].join(' ')} onClick={e => handleChange(opt)}>
                            {opt.descricao}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default ComboStatus;
