import React, { useState, useEffect } from 'react';
import opcoesDados from './opcoesDados.js';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';

// componentes
import { Button, Card, Row, Col, Badge, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OpcoesTabela from './OpcoesTabela';

// calcula a pontuação
export function calcularPontuacao(dados, cor = null){

    // calcula pontuação
    let pontuacao = 0;
    opcoesDados.map(opcao => {
        opcao.itens.map(item => {
            pontuacao += parseInt(dados[item.id]);
        });
    });

    // se for para retornar a cor
    if(cor !== null){
        if(pontuacao > 100){
            return 'danger';
        }else if(pontuacao > 30){
            return 'warning';
        }else{
            return 'success'
        }
    }

    return pontuacao;
}

export default function Cadastrar(){

    // estados
    const history = useHistory();
    const { id, idpaciente, idagendamento } = useParams();
    let [dados, alterarDados] = useState({
        pk_cad_rastreamento_metabolico: id || null
    });
    let [carregando, alterarCarregando] = useState(true);
    let [salvando, alterarSalvando] = useState(false);
    let [opcoes] = useState(opcoesDados);
    let [modo, alterarModo] = useState('cadastro'); // cadastro, edicao

    // inicializa
    useEffect(() => {

        // verifica se é edição
        if(dados.pk_cad_rastreamento_metabolico !== null){
            alterarModo('edicao');
            consultarId();

        }else{
            opcoes.map(opcao => {
                opcao.itens.map(item => {
                    dados[item.id] = '0';
                });
            });

            alterarDados({
                ...dados,
                fk_cad_paciente: idpaciente,
                fk_cad_agenda: idagendamento
            });
            alterarCarregando(false);
        }

    }, []);

    // consulta id
    async function consultarId(){
        alterarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/rastreamentoMetabolico/${dados.pk_cad_rastreamento_metabolico}`);
            alterarDados({
                ...dados,
                ...data.dados
            });
            alterarCarregando(false);

        }catch({response}){}
    }

    // volta a tela anteiror
    function retornar(paraListagem = false){
        history.goBack();
        // if(match.params.id || paraListagem){
        //     history.push(`/painel/paciente/${match.params.idpaciente}/atender/agendamento/${match.params.idagendamento}/rastreamento-metabolico`);
        // }else{
        //     history.push(`/painel/paciente/${match.params.idpaciente}/atender/agendamento/${match.params.idagendamento}`);
        // }
    }

    // salva
    async function salvar(){
        alterarSalvando(true);


        try{

            // faz a requisição
            let { data } = await axios.post(`/rastreamentoMetabolico`, dados);
            alterarSalvando(false);
            retornar(true);

        }catch({response}){}
    }

    // carregando
    if(carregando){
        return <div>Carregando</div>
    }

    return <>
        <Row>
            <Col md='8'>
                <Card className='border-0 shadow-sm'>
                    <Card.Header className='bg-white border-0 pb-0'>
                        <Card.Title>Cadastro Rastreamento Metabolico</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <div>
                            <h6 className='text-center'>Avalie sintomas conforme a numeração abaixo</h6>
                            <ol start="0" className='mb-0'>
                                <li>Nunca ou quase nunca teve o sintoma</li>
                                <li>Ocasionalmente teve, efeito não foi severo</li>
                                <li>Ocasionalmente teve, efeito foi severo</li>
                                <li>Frequentemente teve, efeito não foi severo</li>
                                <li>Frequentemente teve, efeito foi severo</li>
                            </ol>
                        </div>
                    </Card.Body>
                </Card>

                <Card className='border-0 shadow-sm mt-4'>
                    <Card.Body>
                        {opcoes.map((opcao, a) => 
                            <OpcoesTabela 
                                key={a}
                                dados={opcao}
                                alterou={() => {
                                    alterarDados({...dados});
                                }}
                                registro={dados}
                            />
                        )}
                    </Card.Body>
                </Card>

                <SalvarFormulario 
                    salvando={salvando}
                    cancelar={() => {
                        retornar();
                    }}
                    onClick={() => salvar()}
                />
            </Col>
            <Col md='4'>
                <Card className='border-0 shadow-sm'>
                    <Card.Body>
                        <b>Menor que 20 pontos</b>
                        <p>Pessoas mais saudáveis, com menor chance de terem hipersensibilidades</p>

                        <b>Maior que 20 pontos</b>
                        <p>Pessoas saúdaveis com tendência a hipersensibilidade</p>
                        
                        <b>Maior que 30 pontos</b>
                        <p>Indicativo de existência de hipersensibilidades</p>

                        <b>Maior que 40 pontos</b>
                        <p>Absoluta certeza da existência de hipersensibilidade</p>

                        <b>Maior que 100 pontos</b>
                        <p>Pessoas com saúde muito ruim - alta dificuldade para executar tarefas diárias, pode estar associado á presença de outras doenças crônicas e degenerativas</p>
                            
                        <h3>Minha pontuação <Badge variant={calcularPontuacao(dados, 'cor')}>{calcularPontuacao(dados)}</Badge></h3>
                                
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>
}

function SalvarFormulario({
    salvando,
    cancelar,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button variant="success" disabled={salvando} onClick={onClick}>
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <span>Salvar</span>
            }
        </Button>
    </Form.Group>
}

