import React from 'react';
import styles from './index.module.scss';

// componentes
import BotaoTabela from './../../../ComponentesCadastro/BotaoTabela';

// elemento registro
function Registro({
    dados,
    usuario,
    clicouEditar,
    onClickTr,
    clicouJornada
}){
    
    return <tr onClick={onClickTr}>
        {/* <td className="estreito text-center font-weight-bold">{dados.pk_cad_convenio}</td> */}
        <td>{usuario.nome}</td>
        <td className="text-nowrap estreito">
            <BotaoTabela 
                onClick={clicouJornada}
                icone={["fas", "user-clock"]}
            >
                Jornada
            </BotaoTabela>
        </td>
    </tr>
}

export default Registro;