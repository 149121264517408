import React from 'react';
import styles from './index.module.scss';
import Moment from 'react-moment';

// componentes
import ComboStatus from './ComboStatus';

// registro agenda
function RegistroAgenda({
hora,
data,
agendado, // true / false
dados,
paciente,
situacao,
situacaoAlterada,
clicouAgenda
}){

    // situacoes
    let situacoes = [
        {id: 1, descricao: 'Agendado'},
        {id: 2, descricao: 'Não Compareceu'},
        {id: 3, descricao: 'Confirmado'},
        {id: 4, descricao: 'Encaixe'},
        {id: 5, descricao: 'Aguardando'},
        {id: 6, descricao: 'Atendido'}
    ];

    return <div  className={[styles.registro, (agendado? styles.agendado : '')].join(' ')}>
        <div className={styles.hora}>
            {/* {data} */}
            <Moment format="HH:mm">{`${data} ${hora}`}</Moment>
        </div>
        <div className={styles.dados}>
            {!agendado && 
                <button 
                    className={styles.botaoAgendar}
                    onClick={() => {
                        clicouAgenda();
                    }}
                >
                    Clique para agendar
                </button>
             }
            {agendado &&
            <>
                <div className={['p-2', styles.dadosCliente].join(' ')}>
                    <div className={styles.nome}
                        onClick={() => {
                            clicouAgenda();
                        }}
                        >
                        {paciente.nome}
                    </div>
                    {(paciente.telefones.length > 0) && 
                    <div className={styles.fones}>
                        {paciente.telefones.map((fone, a) => <span key={a}>{fone.numero}</span>)}
                    </div>}
                </div>
                <div className={styles.status}>
                    <ComboStatus 
                        value={parseInt(dados.situacao)}
                        options={situacoes}
                        placeholder={"Select"}
                        onChange={(e) => situacaoAlterada(e.id)}
                    />
                </div>
            </>}
        </div>
    </div>

}

// retorno
export default RegistroAgenda;