import React, { useState, useEffect, Suspense } from 'react';
import styles from './index.module.scss';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

// componentes
import { EditorState, convertToRaw, convertFromRaw, convertFromHTML, ContentState } from 'draft-js';

// importa componente do plano alimentar
import ComponentePlanoAlimentar from './../../../ComponentePlanoAlimentar';
// const ComponentePlanoAlimentar = React.lazy(() => import('./../../../ComponentePlanoAlimentar'));

// cadastro novo
export default function CadastrarNovo(){

    // estados
    const history = useHistory();
    const { LoginReducer } = useSelector(state => state);
    const params = useParams();
    let [refeicoes, alterarRefeicoes] = useState([]);
    let [carregando, alterarCarregando] = useState(true);
    let [modo, alterarModo] = useState('cadastro'); // cadastro, edicao
    let [dados, alterarDados] = useState({
        pk_cad_plano_alimentar: params.id || null,
        // fk_cad_paciente
        // descricao_documento: 'texto livre',
        // descricao_documento_json: null
    });
    let [salvando, alterarSalvando] = useState(false);
    let [fazendoImpressaoTexto, alterarFazendoImpressaoTexto] = useState(false);

    // verifica se é alteração
    useEffect(() => {
        
        // é uma alteração
        if(params.id){
            alterarModo('edicao');

            // faz a consulta dos dados
            consultarId();

        }else{
            alterarDados({
                ...dados,
                fk_cad_paciente: params.idpaciente,
                fk_cad_agenda: params.idagendamento
            });
            alterarCarregando(false);
        }

    }, []);

    // consulta os dados do plano alimentar
    async function consultarId(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/planoAlimentar/${dados.pk_cad_plano_alimentar}`);
            // ajusta descriçao dos objetos
            data.dados.descricao_documento_json = verificarJsonEditor(
                data.dados.descricao_documento, 
                data.dados.descricao_documento_json
            );
            data.dados.descricao_prescricao_json = verificarJsonEditor(
                data.dados.descricao_prescricao, 
                data.dados.descricao_prescricao_json
            );

            // refeições
            alterarRefeicoes(data.refeicoes);
            
            // altera os dados
            alterarDados(data.dados);

            // altera as refeições seguindo o padrão
            alterarCarregando(false);


        }catch({response}){

        }



    }

    // converte ou retorna valor de json padrão para editor
    function verificarJsonEditor(texto, jsonTexto){

        // verifica se já está convertido e mantém
        if(jsonTexto === null){

            // se texto for null, mantém null
            if(texto === null){
                return null;
            }

            // converte para o padrão
            let textoConverter = convertFromHTML(texto);
            if(textoConverter !== null){
                // console.log(textoConverter);

                // se o conteudo for vazio, então é nulo
                if(textoConverter.contentBlocks === null){
                    return null;
                }

                let estadoTexto = ContentState.createFromBlockArray(
                    textoConverter.contentBlocks,
                    textoConverter.entityMap
                );

                
                // converte para o padrão
                return JSON.stringify(convertToRaw(estadoTexto));
            }

        }else{

            // mantém e retorna
            return jsonTexto;
        }
    }

    // salvar plano alimentar
    async function salvar(refeicoes, documentoJson, prescricaoJson){

        // faz a requisição
        alterarSalvando(true);
        // dados.refeicoes = refeicoes;
        dados.descricao_documento_json = documentoJson;
        dados.descricao_prescricao_json = prescricaoJson;

        try{

            // faz a requisição
            let { data } = await axios.post(`/planoAlimentar`, {
                dados,
                refeicoes
            });
            alterarSalvando(false);
            history.push(`/painel/paciente/${params.idpaciente}/atender/agendamento/${params.idagendamento}/plano-alimentar`);

        }catch({response}){

        }

    }

    // faz impressão do plano
    async function fazerImpressaoTexto(html, titulo = 'Plano Alimentar'){
        alterarFazendoImpressaoTexto(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/planoAlimentar/impressao`, {
                titulo: titulo,
                texto: html,
                fk_cad_paciente: params.idpaciente
            });

            // faz o dowload
            window.open(
                // `${process.env.REACT_APP_URL_PHP}/download.php?p=temp&f=${encodeURIComponent(data.arquivo)}&n=${encodeURIComponent(data.nome)}`,
                `${process.env.REACT_APP_URL_ARQUIVOS}/download.php/${encodeURIComponent(data.nome)}?p=temp&f=${encodeURIComponent(data.arquivo)}&n=${encodeURIComponent(data.nome)}`,
                '_blank'
            );

        }catch({response}){

        }finally{
            alterarFazendoImpressaoTexto(false);
        }

    }

    // se estiver carregando, aguarda
    if(carregando){
        return <div>
            Carregando...
        </div>
    }

    // carregar dados do cliente e do plano salvo e informar ao componente
    return <>
        <ComponentePlanoAlimentar 
            refeicoesInicial={refeicoes}
            salvou={salvar}
            salvando={salvando}
            fazerImpressaoTexto={fazerImpressaoTexto}
            fazendoImpressaoTexto={fazendoImpressaoTexto}
            cancelou={() => {
                history.push(`/painel/paciente/${params.idpaciente}/atender/agendamento/${params.idagendamento}/plano-alimentar`);
            }}

            // descrições
            documento={dados.descricao_documento_json}
            prescricao={dados.descricao_prescricao_json}
        />
    </>
};