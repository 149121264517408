import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';

// componentes
import { Droppable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import Refeicao from './Refeicao';

// importa dados do plano
import { PlanoAlimentarContexto } from './../../../../ComponentePlanoAlimentar';

// refeições
function Refeicoes({

}){

    // contexto do plano
    let planoAlimentarDados = useContext(PlanoAlimentarContexto);

    // adiciona refeição
    function adicionarRefeicao(){

        // incrementa refeição
        planoAlimentarDados.refeicoes.push({
            descricao: '',
            pk_cad_plano_alimentar_bloco: null,
            _opcoes: [
                []
            ]
        });

        // aplica alterações
        planoAlimentarDados.alterarRefeicoes([...planoAlimentarDados.refeicoes]);

    }

    return <div
        className={[styles.refeicoes, 'p-2'].join(' ')}
    >
        <Droppable 
            droppableId="refeicao" 
            type="refeicao" 
            direction='horizontal'
        >
            {(provided, snapshot) => (
                <div
                    className={[styles.containerRefeicoes].join(' ')}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    {planoAlimentarDados.refeicoes.map((refeicao, index) => 
                        <Refeicao 
                            key={index}
                            index={index}
                            dados={refeicao}
                        />
                    )}
                    {provided.placeholder}

                    <Button 
                        variant='padrao' 
                        onClick={adicionarRefeicao} 
                        className='text-nowrap' 
                        size='sm'
                    >
                        <FontAwesomeIcon className="icone" icon={["fas", "plus"]}></FontAwesomeIcon>
                        <span>Adicionar <br/>Refeição</span>
                    </Button>

                </div>
            )}
        </Droppable>




        

        
    </div>

}

export default Refeicoes;