

import moment from 'moment';

// converte para reais o valor
export function converterReal(valor){
    return parseFloat(valor).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
}


export function mascaraCpf(cpf){
    // cpf=cpf.replace(/\D/g,"")
    // cpf=cpf.replace(/(\d{3})(\d)/,"$1.$2")
    // cpf=cpf.replace(/(\d{3})(\d)/,"$1.$2")
    // cpf=cpf.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
    // return cpf
    if(cpf === null){
        cpf = '';
    }

    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4");
}

// verifica se a data é valida
export function validarData(data, tipo = 'valid'){
    if(data !== null && data.length === 10){
        // console.log(moment(data, 'YYYY-MM-DD').isValid());
        
        // console.log(moment(new Date(data)).isValid());
        if(tipo === 'valid' && moment(data, 'YYYY-MM-DD').isValid()){
           return true;
        }else if(!moment(data, 'YYYY-MM-DD').isValid()){
            return true;
        }
    }
    if(tipo === 'valid'){
        return false;
    }else{
        return false;
    }
}