import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw, convertFromHTML, ContentState } from 'draft-js';
import { Button } from 'react-bootstrap';
import BotaoImpressao from './BotaoImpressao';

// edição de texto livre
function EditorTextoLivre({
    titulo,
    value,
    onChange
}){

    // define variaveis
    let [editorDocumento, alterarEditorDocumento] = useState(EditorState.createEmpty());

    // inicializa, sempre que o texto é alterado, atualiza novamente
    useEffect(() => {
        
        // se não for nulo, converte do json
        if(value !== null){
            let converterDocumento = convertFromRaw(JSON.parse(value));
            let conteudoEditor = EditorState.createWithContent(converterDocumento);
            alterarEditorDocumento(conteudoEditor);
        }
    }, [value]);

    return <Editor 
        editorState={editorDocumento}
        onEditorStateChange={atualizarTexto => {
            alterarEditorDocumento(atualizarTexto);
        }}
        stripPastedStyles={true}
        wrapperClassName="demo-wrapper"
        editorClassName={styles.editor}
        onBlur={() => {
            onChange(
                JSON.stringify(convertToRaw(editorDocumento.getCurrentContent()))
            );
        }}
        toolbar={{
            fontFamily: {
                // options: ['ralewaylight'],
                options: ['Segoe Ui', 'Arial'],
                // defaultTargetOption: 'Segoe Ui'
            },
            inline: {
                // className: 'btn btn-padrao btn-sm'
                // bold: { className: 'btn btn-padrao btn-sm p-0 px-1' },
            }
        }}
        // toolbarCustomButtons={[<BotaoImpressao titulo={titulo} />]}
    /> 

}

export default EditorTextoLivre;