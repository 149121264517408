import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import axios from 'axios';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Container, Button, Form, Row, Col } from 'react-bootstrap';
import InputDecimal from './../../ComponentesCadastro/InputDecimal';

// importa serviços
import AlimentoMedidaCaseiraServico from './../../../../servicos/AlimentoMedidaCaseiraServico';

// componente
export default function ModalCadastrarMedidaCaseira({
    fechou,
    salvou,
    registro=null,
    idAlimento
}){

    // estados
    const { LoginReducer } = useSelector(state => state);
    let [modo, alterarModo] = useState('cadastro') // cadastro, edicao
    let [mostrar, alterarMostrar] = useState(true);
    let [salvando, alterarSalvando] = useState(false);
    let [carregando, alterarCarregando] = useState(true);
    let [dados, alterarDados] = useState({
        pk_cad_alimento_medida_caseira: null,
        fk_cad_alimento: idAlimento,
        descricao: null,
        quantidade: '0' // 3 decimais, utilizar mesmo esquema quantidade
    });

    // inicializa
    useEffect(() => {

        // verifica se é edição
        if(registro !== null){


        }else{
            alterarCarregando(false);
        }

    }, []);

    // salvar medida caseira
    async function salvar(){

        // salvando
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/medidaCaseira`, dados);
            salvou(data.dados);
            fechou();

        }catch({response}){

        }
    }

    // modal de carregando os dados
    if(carregando){
        return <Modal 
            show={mostrar}
            onHide={() => {}}
        >
            <Modal.Body>
                <p>Carregando <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} /></p>
            </Modal.Body>
        </Modal>
    }


    return <Modal 
        show={mostrar} 
        onExited={fechou}
        onHide={() => {}}
        size={'md'}
    >
        <Modal.Header className='border-0'>
            <Modal.Title>
                Cadastro de medida caseira
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
                <Form.Group>
                    <Form.Label>Descrição</Form.Label>
                    <Form.Control 
                        value={dados.descricao || ''}
                        onChange={e => {
                            dados.descricao = e.target.value;
                            alterarDados({...dados});
                        }}
                    />
                </Form.Group>
                <Row>
                    <Col lg='4'>
                        <Form.Group>
                            <Form.Label>Quantidade g/ml</Form.Label>
                            <InputDecimal 
                                // size='sm'
                                valor={dados.quantidade}
                                onChange={valor => {
                                    dados.quantidade = valor;
                                    alterarDados({...dados});
                                    // alterarDadosMedida();
                                }}
                                // className={styles.quantidade}
                                casas={3}
                                cortarZeros={true}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
        </Modal.Body>
        <Modal.Footer  className='border-0'>
            <Button
                variant='danger'
                onClick={() => {alterarMostrar(false)}}
                disabled={salvando}
            >
                <span>Fechar</span>
            </Button>
            <Button
                onClick={salvar}
                variant='success'
                disabled={salvando}
            >
                {salvando ? 
                    <>
                        <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                        <span>Salvando</span>
                    </> : 
                    <span>Salvar</span>
                }
            </Button>
        </Modal.Footer>
    </Modal>


}