import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// componente
function BotaoCarregando({
    variant,
    texto,
    textoCarregando,
    carregando,
    onClick,
    disabled=false,
    icone=null,
    className='',
    iconeClass=''
}){

    return <Button
        variant={variant}
        disabled={carregando || disabled}
        onClick={onClick}
        className={className}
    >
        {   carregando ?
            <>
                <FontAwesomeIcon className={['icone', iconeClass].join(' ')} pulse icon={["fas", 'spinner']} />
                <span>{textoCarregando}</span>
            </>
            :
            <>
                { icone &&
                    <FontAwesomeIcon className={['icone', iconeClass].join(' ')} icon={icone} />
                }
                <span>{texto}</span>
            </>
        }
    </Button>
}

export default BotaoCarregando;