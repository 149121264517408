import React from 'react';
import {BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

// componentes
import LoginPagina from './paginas/Login';
import PainelPagina from './paginas/Painel';
import CadastroPagina from './paginas/Cadastro';

// componente raiz
export default function App(){

    // variaveis
    let loginReducer = useSelector(state => state.LoginReducer);

    // console.log(props);
    return <>
    
        <ToastContainer 
            toastClassName='toast fade show p-0'
            closeOnClick={false}
            hideProgressBar={true}
            closeButton={false}
        />

        <Router>
            <Switch>

                {/* se entrar na raiz, envia para o painel */}
                <Route exact path={'/'} >
                    <Redirect to="/painel" />
                </Route>

                <Route exact path={'/painel'} >
                    { loginReducer.logado ? <PainelPagina /> : <Redirect to="/login"/> }
                </Route>

                <Route exact path={'/login'} >
                    { loginReducer.logado ? <Redirect to="/painel"/> : <LoginPagina />}
                </Route>

                <Route exact path={'/cadastro'} >
                    { loginReducer.logado ? <Redirect to="/painel"/> : <CadastroPagina /> }
                </Route>

                <Route exact path={'/painel/*'} >
                    { loginReducer.logado ? <PainelPagina /> : <Redirect to="/login"/> }
                </Route>

            </Switch>
        </Router>
    </>
}