import { combineReducers } from 'redux';
import { withReduxStateSync } from 'redux-state-sync';

// importa reducers e actions
import LoginReducer from './paginas/Login/LoginReducer';
import PainelReducer from './paginas/Painel/PainelReducer';
import AgendaReducer from './paginas/Painel/Agenda/AgendaReducer';
// import PlanoAlimentarCadastroReducer from './paginas/Painel/Pacientes/PlanoAlimentar/Cadastrar/PlanoAlimentarCadastroReducer';
// import AlimentosConsultaExternaReducer from './paginas/Painel/Alimentos/ConsultaExterna/AlimentosConsultaExternaReducer';

// unifica reducers
let rootReducer  = combineReducers({
    LoginReducer: LoginReducer,
    PainelReducer: PainelReducer,
    AgendaReducer: AgendaReducer,
    // AlimentosConsultaExterna: AlimentosConsultaExternaReducer,
    // PlanoAlimentarCadastro: PlanoAlimentarCadastroReducer
});

// retorna reducer global
export default withReduxStateSync(rootReducer);