import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import backGroundImage from './../../assets/shutterstock_478777894.jpg';
import logoEvolutts from './../../assets/logotipo_evolutts.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';

// componentes
import { Form, Button, Alert } from 'react-bootstrap';

// componente
export default function Cadastro(){

    // estados
    const history = useHistory();
    let [salvando, alterarSalvando] = useState(false);
    let [erros, alterarErros] = useState({});
    let [dados, alterarDados] = useState({
        nome: '',
        email: '',
        senha: '',
        confirmar_senha: ''
    });

    // faz cadastro
    async function cadastrar(){
        alterarSalvando(true);
        alterarErros({});

        try{

            // faz a requisição
            let { data } = await axios.post(`/cadastro`, dados);
            alert('Cadastro efetuado com sucesso!');
            history.push('/login');

        }catch({response}){
            if(response){
                if(response.status === 400){
                    alterarErros(response.data);
                }
            }
        }finally{
            alterarSalvando(false);
        }
    }

    return <div className="container-fluid login-pagina">
        <div className="row no-gutter">
            <div 
                className="d-none d-md-flex col-md-4 col-lg-6 bg-image" 
                style={{
                    background: `linear-gradient(rgba(0, 0, 0, 0.45),rgba(0, 0, 0, 0.45)),url(${backGroundImage})`,
                    backgroundSize: `cover`
                }}
            >
                <img src={logoEvolutts} />
            </div>
            <div className="col-md-8 col-lg-6">
                <div className="login d-flex align-items-center py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-lg-8 mx-auto">
                                <h3 className="login-heading mb-4">Faça seu cadastro!</h3>
                                <Form.Group>
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control 
                                        className='rounded-pill'
                                        placeholder={'Seu nome completo'}
                                        value={dados.nome}
                                        onChange={e => {
                                            dados.nome = e.target.value;
                                            alterarDados({...dados});
                                        }}
                                        isInvalid={erros.nome ? true : false}
                                    />
                                    { erros.nome &&
                                        <Form.Control.Feedback type="invalid">{erros.nome}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control 
                                        value={dados.email}
                                        onChange={e => {
                                            dados.email = e.target.value;
                                            alterarDados({...dados});
                                        }}
                                        className='rounded-pill'
                                        placeholder={'Ex.: usuario@gmail.com'}
                                        isInvalid={erros.email ? true : false}
                                    />
                                    { erros.email &&
                                        <Form.Control.Feedback type="invalid">{erros.email}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Senha</Form.Label>
                                    <Form.Control 
                                        value={dados.senha}
                                        onChange={e => {
                                            dados.senha = e.target.value;
                                            alterarDados({...dados});
                                        }}
                                        className='rounded-pill'
                                        type='password'
                                        isInvalid={erros.senha ? true : false}
                                    />
                                    { erros.senha &&
                                        <Form.Control.Feedback type="invalid">{erros.senha}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Confirmar Senha</Form.Label>
                                    <Form.Control 
                                        value={dados.confirmar_senha}
                                        onChange={e => {
                                            dados.confirmar_senha = e.target.value;
                                            alterarDados({...dados});
                                        }}
                                        className='rounded-pill'
                                        type='password'
                                        isInvalid={erros.confirmar_senha ? true : false}
                                    />
                                    { erros.confirmar_senha &&
                                        <Form.Control.Feedback type="invalid">{erros.confirmar_senha}</Form.Control.Feedback>
                                    }
                                </Form.Group>

                                { Object.keys(erros).length > 0 &&
                                    <Alert variant='danger' dismissible onClose={() => { alterarErros([]) }}>
                                        Alguns campos não foram preenchidos corretamente!
                                    </Alert>
                                }

                                <p className='text-center mt-4'>
                                    <Button
                                        className='rounded-pill'
                                        variant='success'
                                        onClick={cadastrar}
                                        disabled={salvando}
                                    >
                                        {salvando ? 
                                            <>
                                                <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                                                <span>Finalizando Cadastro</span>
                                            </> : 
                                            <span>Finalizar Cadastro</span>
                                        }
                                    </Button>
                                </p>
                                <div className="text-center">
                                    <Link className="small" to="/login">Voltar para o login</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    {/* return <Container className='mt-5'>
        <Form.Row>
            <Col md={{span:'6', offset:'3'}}>
                <Form.Label>Email</Form.Label>
                <Form.Control 
                    placeholder={'Ex. usuario@gmail.com'}
                />
            </Col>
        </Form.Row>
    </Container> */}



}
