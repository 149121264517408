import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AsyncSelect from 'react-select/lib/Async';
import moment from 'moment';
import styles from './index.module.scss';
import { Form } from 'react-bootstrap';
import axios from 'axios';

// componentes
import OptionSelect from './OptionSelect';

// comboconvenio
export default function ComboConvenio({
    id,
    alterou
}){

    // estados
    let [convenios, alterarConvenios] = useState([]);
    let [carregando, alterarCarregando] = useState(true);
    let [dadosConvenio, alterarDadosConvenio] = useState(null);

    // inicializa
    useEffect(() => {

        // carrega alguns
        consultarConvenios('');

        // se possuir cõdigo, carrega os dados do convênio
        if(id !== null || (dadosConvenio !== null && id !== dadosConvenio.dados.pk_cad_convenio)){
            consultarIdConvenio();
        }else{
            alterarCarregando(false);
        }

    }, []);

    // ao modificar cliente externamente
    useEffect(() => {
        if(dadosConvenio !== null && id !== dadosConvenio.dados.pk_cad_convenio){
            consultarIdConvenio();
        }
    }, [id]);

    // consulta convenio
    async function consultarIdConvenio(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/convenio/${id}`);
            alterarDadosConvenio(data);
            alterarCarregando(false);

        }catch({response}){}

    }

    // consulta os clientes
    async function consultarConvenios(inputValue, callback = null){

        try{

            // faz a requisição
            let { data } = await axios.get(`/convenio`, {
                params: {
                    texto: inputValue,
                    pagina: 1,
                    registrosPorPagina: 7
                }
            });
            alterarConvenios(data.registros);
            if(callback !== null){
                callback(data.registros);
            }

        }catch({response}){

        }
    }

    return <>
        <AsyncSelect 
            menuPlacement='auto'
            isDisabled={carregando}
            isClearable
            loadOptions={consultarConvenios} // ao alterar, carrega
            defaultOptions={convenios}       // lista pré carregada
            value={dadosConvenio && {
                label: dadosConvenio.dados.descricao
            }}  
            onChange={(e) => {

                if(e === null){
                    alterarDadosConvenio(null);
                    alterou(null);
                }else{
                    alterarDadosConvenio({...e});
                    alterou(e.dados.pk_cad_convenio);
                }
            }}
            components={{
                Option: OptionSelect
            }}
            noOptionsMessage={() => 'Descrição'}
            loadingMessage={() => 'Carregando...'}
            placeholder='Selecionar Convênio'
        />
    </>

}