import React from 'react';
import styles from './index.module.scss';
import stylesOpcao from './../index.module.scss';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';

// opção layout
function Opcao({
    variant='padrao',
    icone=null,
    img=null,
    onClick,
    hidden=false,
    disabled=false,
    children
}){
    return <Button disabled={disabled} hidden={hidden} variant={variant} className={[stylesOpcao.opcao, 'w-100 mb-2 p-1'].join(' ')} onClick={onClick}>
        { icone !== null ?
            <FontAwesomeIcon className={[stylesOpcao.icone, 'icone'].join(' ')} size="2x" icon={icone} />
            :
            <img src={img} className={stylesOpcao.imagem} />
        }
        
        <span className='m-0'>
            {children}
        </span>
    </Button>
}

export default Opcao;
