import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import axios from 'axios';
import moment from 'moment';
import useIsMounted from 'ismounted';

// componentes
import MaskedInput from 'react-maskedinput';
import { Form, Card, Row, Col, Button, Alert, ButtonToolbar, OverlayTrigger, Tooltip  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputData from './../../ComponentesCadastro/InputData';
import Telefones from './Telefones';
import ComboConvenio from './../../ComponentesCadastro/ComboConvenio';

// calcula idade do paciente
export function calcularIdade(dados){

    // se não for informado retorna nulo
    if(dados.data_nascimento === null || dados.data_nascimento.length < 10){
        return 'Informe a data de nascimento!';
    }

    // calcula a idade
    let dataNascimento = moment(dados.data_nascimento);
    let anos = moment().diff(dataNascimento, 'years');
    dataNascimento.add(anos, 'years');
    let meses = moment().diff(dataNascimento, 'months');
    dataNascimento.add(meses, 'months');
    let dias = moment().diff(dataNascimento, 'days');

    // retorna idade calculada
    return `${anos} ano(s), ${meses} mese(s), ${dias} dia(s)`;

}

// componente
export default function Cadastrar(){

    // estados
    const isMounted = useIsMounted();
    const { LoginReducer } = useSelector(state => state);
    const history = useHistory();
    const { id } = useParams();
    let [modo, alterarModo] = useState('cadastro'); // cadastro, edicao
    let [carregando, alterarCarregando] = useState(true);
    let [dados, alterarDados] = useState({
        pk_cad_paciente: id || null,
        nome: '',
        data_nascimento: null,
        prontuario: '',
        email: '',
        observacao: '',
        telefones: [],
        estado_civil: 'S', // 'S - Solteiro, C - Casado, D - Divorciado, P - Separado, V - Viúvo',
        profissao: '',
        sexo: 'M',
        cns: '',
        nome_pai: '',
        nome_mae: '',
        cep: '',
        logradouro: '',
        numero: '',
        complemento: '',
        cidade: '',
        uf: '',
        fk_cad_convenio: null,
        plano: '',
        numero_carteira: '',
        validade: null,
        cpf: '',
        rg: '',
        observacao_indicacao: '',
        fk_cad_indicacao: null,
        religiao: '',
    });
    let [salvando, alterarSalvando] = useState(false);
    let [erros, alterarErros] = useState([]);
    let [validarCpf, alterarValidarCpf] = useState(null);

    // carrega dados
    useEffect(() => {

        // verifica se é edição e carrega os dados
        if(dados.pk_cad_paciente !== null){

            // faz a requisição
            consultarId();

        }else{
            alterarCarregando(false);
        }

    }, []);

    // consulta id do paciente
    async function consultarId(){
        
        try{

            // faz a requisição
            let { data } = await axios.get(`/paciente/${dados.pk_cad_paciente}`);
            alterarDados(data.dados);
            alterarCarregando(false);

        }catch({response}){}
        finally{ 
            alterarCarregando(false);
        }
    }

    // referencias
    let cepInput = React.createRef();
    let cpfInput = React.createRef();

    // salva
    async function salvar(){
        alterarSalvando(true);
        alterarErros([]);

        try{

            // faz a requisição
            let { data } = await axios.post(`/paciente`, dados);
            history.push('/painel/pacientes');

        }catch({response}){
            if(response){
                if(response.status === 400){
                    alterarErros(response.data);
                }
            }
        }finally{
            if (isMounted.current) {
                alterarSalvando(false);
            }
        }
    }

    // ao alterar registro
    function eventoAlterarDados(e){
        dados[e.target.name] = e.target.value;
        alterarDados({...dados});
    }

    // valida cpf
    function validarCampoCpf(){
        alterarErros([]);
        alterarValidarCpf(null);

        // valida cpf
        if(dados.cpf.length === 11){
            // alterarValidandoCpfCnpj(true);
            // console.log(dados.cpf);   
            
            // // faz a requisição
            // ClientesServico.validarCpf(dados, login.dadosUsuario.token )
            // .then(retorno => {
            //     alterarValidarCpf(retorno.data.status);
            //     if(!retorno.data.status){
            //         alterarErro(retorno.data);
            //     }
            // })
            // .catch(() => {})
            // .then(() => {alterarValidandoCpfCnpj(false)});
            
        }
    }

    // busca cep
    function buscarCep(cepBuscar){
        let buscarCepCon = axios.create({ headers: null });

        // se foi totalmente preenchido
        if(cepBuscar.length === 8){

            // faz a busca
            buscarCepCon.get("https://viacep.com.br/ws/"+ cepBuscar +"/json/", {
                responseType: 'json'
            }).then(retorno => {
                
                alterarDados({...dados, 
                    cidade: retorno.data.localidade,
                    uf: retorno.data.uf,
                    logradouro: retorno.data.logradouro,
                    // bairro: retorno.data.bairro,
                    cep: cepBuscar
                });
            });

        }
    }

    // carregando
    if(carregando){
        return <div>Carregando...</div>
    }

    return <>
        <Row>
            <Col md='8'>
                <Card className='mb-4 border-0 shadow-sm'>
                    <Card.Header className={[styles.cabecalho, 'bg-white'].join(' ')}>
                        <Card.Title as='h4' className='mb-0'>{modo === 'cadastro' ? 'Cadastrar' : 'Editar'} paciente</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form.Row className='mb-3'>
                            <Col md='8'>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control 
                                    value={dados.nome}
                                    name='nome'
                                    onChange={eventoAlterarDados}
                                />
                            </Col>
                            <Col md='4'>
                                <Form.Label>Nascimento</Form.Label>
                                <InputData 
                                    placeholder='00/00/0000'
                                    data={dados.data_nascimento}
                                    onChange={(data) => {
                                        dados.data_nascimento = data;
                                        alterarDados({...dados});
                                    }}
                                />
                            </Col>
                        </Form.Row>
                        
                        <Form.Row className='mb-3'>
                            <Col md='7'>
                                <Form.Label>CPF</Form.Label>
                                <MaskedInput 
                                    placeholder='000.000.000-00'
                                    className={["form-control", erros.cpf ? 'is-invalid': ''].join(' ')}
                                    mask="111.111.111-11"
                                    value={dados.cpf}
                                    ref={cpfInput}
                                    onChange={(e) => {
                                        dados.cpf = cpfInput.current.mask.getRawValue().split('_').join('');
                                        alterarDados({...dados});
                                        validarCampoCpf();
                                    }}
                                />
                                { erros.cpf &&
                                    <Form.Control.Feedback type="invalid">{erros.cpf}</Form.Control.Feedback>
                                }
                            </Col>
                            <Col md='5'>
                                <Form.Label>RG</Form.Label>
                                <Form.Control 
                                    value={dados.rg}
                                    name='rg'
                                    onChange={eventoAlterarDados}
                                />
                            </Col>
                        </Form.Row>

                        <Form.Row className='mb-3'>
                            <Col md='6'>
                                <Form.Label>Profissão</Form.Label>
                                <Form.Control 
                                    value={dados.profissao}
                                    name='profissao'
                                    onChange={eventoAlterarDados}
                                />
                            </Col>
                            <Col md='3'>
                                <Form.Label>Estado Civil</Form.Label>
                                <Form.Control
                                    value={dados.estado_civil}
                                    name='estado_civil'
                                    onChange={eventoAlterarDados}
                                    as='select'
                                >
                                    {[
                                        {value:'S', children: 'Solteiro'},
                                        {value:'C', children: 'Casado'},
                                        {value:'D', children: 'Divorciado'},
                                        {value:'P', children: 'Separado'},
                                        {value:'V', children: 'Viúvo'},
                                    ].map(estadoCivil => 
                                        <option key={estadoCivil.value} {...estadoCivil} />    
                                    )}
                                </Form.Control>
                            </Col>
                            <Col md='3'>
                                <Form.Label>Sexo</Form.Label>
                                <Form.Control
                                    value={dados.sexo}
                                    name='sexo'
                                    onChange={eventoAlterarDados}
                                    as='select'
                                >
                                    {[
                                        {value: 'M', children: 'Masculino'},
                                        {value: 'F', children: 'Feminino'},
                                    ].map(sexo => 
                                        <option key={sexo.value} {...sexo} />    
                                    )}
                                </Form.Control>
                            </Col>
                        </Form.Row>

                        <hr />

                        <Form.Row className='mb-3'>
                            <Col md='6'>
                                <Form.Label>Nome do pai</Form.Label>
                                <Form.Control 
                                    value={dados.nome_pai}
                                    name='nome_pai'
                                    onChange={eventoAlterarDados}
                                />
                            </Col>
                            <Col md='6'>
                                <Form.Label>Nome da Mãe</Form.Label>
                                <Form.Control
                                    value={dados.nome_mae}
                                    name='nome_mae'
                                    onChange={eventoAlterarDados}
                                />
                            </Col>
                        </Form.Row>

                        <Form.Group>
                                <Form.Label>Religião</Form.Label>
                                <Form.Control 
                                    value={dados.religiao}
                                    name='religiao'
                                    onChange={eventoAlterarDados}
                                />
                        </Form.Group>

                        <hr />

                        <Form.Row className='mb-3'>
                            <Col md='8'>
                                <Form.Label>Email</Form.Label>
                                <Form.Control 
                                    value={dados.email}
                                    name='email'
                                    onChange={eventoAlterarDados}
                                    isInvalid={erros.email ? true : false}
                                />
                                { erros.email &&
                                    <Form.Control.Feedback type="invalid">{erros.email}</Form.Control.Feedback>
                                }
                            </Col>
                            <Col md='4'>
                                <Form.Label>CNS</Form.Label>
                                <Form.Control 
                                    value={dados.cns}
                                    name='cns'
                                    onChange={eventoAlterarDados}
                                />
                            </Col>
                        </Form.Row>
                    </Card.Body>
                </Card>

                <Card className='mb-4 border-0 shadow-sm'>
                    <Card.Header className={[styles.cabecalho, 'bg-white'].join(' ')}>
                        <Card.Title as='h6' className='mb-0'>Convênio</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form.Row className='mb-3'>
                            <Col md='8'>
                                <Form.Label>Descrição Convênio</Form.Label>
                                {/* <Form.Control /> */}
                                <ComboConvenio 
                                    id={dados.fk_cad_convenio}
                                    alterou={(codigo) => {
                                        dados.fk_cad_convenio = codigo;
                                        alterarDados({...dados});
                                    }}
                                />
                            </Col>
                            <Col md='4'>
                                <Form.Label>Plano</Form.Label>
                                <Form.Control 
                                    value={dados.plano}
                                    name='plano'
                                    onChange={eventoAlterarDados}
                                    maxLength='100'
                                />
                            </Col>
                        </Form.Row>

                        <Form.Row>
                            <Col md='8'>
                                <Form.Label>Nº Carteirinha</Form.Label>
                                <Form.Control 
                                    value={dados.numero_carteira}
                                    name='numero_carteira'
                                    onChange={eventoAlterarDados}
                                    maxLength='100'
                                />
                            </Col>
                            <Col md='4'>
                                <Form.Label>Validade</Form.Label>
                                <InputData 
                                    placeholder='00/00/0000'
                                    data={dados.validade}
                                    onChange={(novaData) => {
                                        dados.validade = novaData;
                                        alterarDados({...dados});
                                    }}
                                />
                            </Col>
                        </Form.Row>
                    </Card.Body>
                </Card>

                <Card className='mb-4 border-0 shadow-sm'>
                    <Card.Header className={[styles.cabecalho, 'bg-white'].join(' ')}>
                        <Card.Title as='h6' className='mb-0'>Endereço</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form.Row className='mb-3'>
                            <Col md='3'>
                                <Form.Label>CEP</Form.Label>
                                <MaskedInput
                                    className={['form-control'].join(' ')}
                                    mask="11111-111"
                                    placeholder='00000-000'
                                    value={dados.cep}
                                    ref={cepInput}
                                    onChange={(e) => {
                                        let cepNovo = cepInput.current.mask.getRawValue().split('_').join('');
                                        buscarCep(cepNovo);
                                        alterarDados({...dados, cep: cepNovo});
                                        
                                    }}
                                />
                            </Col>
                            <Col md='7'>
                                <Form.Label>Logradouro</Form.Label>
                                <Form.Control 
                                    value={dados.logradouro}
                                    name='logradouro'
                                    onChange={eventoAlterarDados}
                                />
                            </Col>
                            <Col md='2'>
                                <Form.Label>Numero</Form.Label>
                                <Form.Control 
                                    value={dados.numero}
                                    name='numero'
                                    onChange={eventoAlterarDados}
                                />
                            </Col>
                        </Form.Row>

                        <Form.Row className='mb-3'>
                            <Col md='10'>
                                <Form.Label>Cidade</Form.Label>
                                <Form.Control 
                                    value={dados.cidade}
                                    name='cidade'
                                    onChange={eventoAlterarDados}
                                />
                            </Col>
                            <Col md='2'>
                                <Form.Label>Estado</Form.Label>
                                <Form.Control 
                                    value={dados.uf}
                                    maxLength={2}
                                    name='uf'
                                    onChange={eventoAlterarDados}
                                />
                            </Col>
                        </Form.Row>

                        <Form.Row className='mb-3'>
                            <Col md='8'>
                                <Form.Label>Bairro</Form.Label>
                                {/* <Form.Control 
                                    value={dados.bairro}
                                    name='bairro'
                                    onChange={eventoAlterarDados}
                                /> */}
                            </Col>
                            <Col md='4'>
                                <Form.Label>Complemento</Form.Label>
                                <Form.Control 
                                    value={dados.complemento}
                                    name='complemento'
                                    onChange={eventoAlterarDados}
                                />
                            </Col>
                        </Form.Row>
                    </Card.Body>
                </Card>

                { Object.keys(erros).length > 0 &&
                    <div className='text-danger'>Alguns campos estão incorretos!</div>
                }

                <SalvarFormulario 
                    salvando={salvando}
                    cancelar={() => {
                        history.push('/painel/pacientes');
                    }}
                    onClick={() => salvar(dados)}
                />
                { erros.length > 0 && 
                    <Alert variant='danger'>
                        Falha ao salvar! Confira se todos os campos estão corretos!
                    </Alert>
                }


            </Col>
            <Col md='4'>

                <Telefones 
                    value={dados.telefones}
                    onChange={telefoneNovo => {
                        dados.telefones = telefoneNovo;
                        alterarDados({...dados});
                    }}
                />

                <Card className='mb-4 border-0 shadow-sm'>
                    <Card.Body>
                        <Form.Group>
                            <Form.Label>Idade</Form.Label>
                            {/* <Form.Control 
                                value={calcularIdade()}
                                readOnly={true} 
                            /> */}
                            <p className='text-secondary border-bottom'>{calcularIdade(dados)}</p>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Prontuário</Form.Label>
                            <Form.Control 
                                value={dados.prontuario}
                                name='prontuario'
                                onChange={eventoAlterarDados}
                            />
                        </Form.Group>

                        <Form.Group className='mb-0'>
                            <Form.Label>Observação Paciente</Form.Label>
                            <Form.Control 
                                as='textarea' 
                                value={dados.observacao}
                                name='observacao'
                                onChange={eventoAlterarDados}
                            />
                        </Form.Group>
                    </Card.Body>
                </Card>

                <Card className='mb-4 border-0 shadow-sm'>
                    <Card.Body>
                        {/* <Form.Group>
                            <Form.Label>Indicação</Form.Label>
                            <Form.Control 
                            
                            />
                        </Form.Group> */}
                        <Form.Group className='mb-0'>
                            <Form.Label>Observação da Indição</Form.Label>
                            <Form.Control 
                                as='textarea' 
                                value={dados.observacao_indicacao}
                                name='observacao_indicacao'
                                onChange={eventoAlterarDados}
                            />
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>

}

function SalvarFormulario({
    salvando,
    cancelar,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button variant="success" disabled={salvando} onClick={onClick}>
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <span>Salvar</span>
            }
        </Button>
    </Form.Group>
}