import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import axios from 'axios';
import moment from 'moment';

// componentes
import { calcularIdade } from './../../Cadastrar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faIdCard } from '@fortawesome/free-solid-svg-icons';
import { Card, Row, Col } from 'react-bootstrap';

// componente
export default function ClienteInformacao({
    idpaciente
}){

    // estados
    let [carregando, alterarCarregando] = useState(true);
    let [consulta, alterarConsulta] = useState(null);

    // inicializa
    useEffect(() => {
        consultarId();
    }, []);

    // carrega os dados do paciente
    async function consultarId(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/paciente/${idpaciente}`);
            console.log(data);
            alterarConsulta(data);
            alterarCarregando(false);

        }catch({response}){}

    }

    if(carregando){
        return <Card className='border-0 mb-3'>
            <Card.Body className='text-center'> 
                <FontAwesomeIcon icon={faSpinner} pulse />
            </Card.Body>
        </Card>
    }

    return <>
        <Row
            className='mb-3'
        >
            <Col md={8} lg={6}>   
                <div
                    className='d-flex align-items-start'
                >
                    <div
                        className='py-2'
                        title='Informações do paciente'
                    >
                        <FontAwesomeIcon icon={faIdCard} size={'lg'} color={'grey'} />
                    </div>
                    <div className='flex-grow-1 ml-3'>
                        <Card
                            className='border-0'
                        >
                            <Card.Body className=''>
                                <div className='d-flex'>
                                    <div className='flex-grow-1'>
                                        <p className='m-0'>{consulta.dados.nome}</p>
                                        <p className={['m-0', styles.profissao].join(' ')}>{consulta.dados.profissao}</p>
                                        
                                    </div>
                                    <div className='text-right'>
                                        <p className='m-0'>
                                            {calcularIdade(consulta.dados)}
                                        </p>
                                        <p className={['m-0', styles.dataNascimento].join(' ')}>
                                            {moment(consulta.dados.data_nascimento).format('DD/MM/YYYY')}
                                        </p>
                                        
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </Col>    
        </Row>

        <hr />
    </>
}