// onde os itens do plano serão apresentados em listas

import React from 'react';
import styles from './index.module.scss';

// componentes
import ContainerRefeicoes from './ContainerRefeicoes';
import ConsultaAlimentos from './ConsultaAlimentos';

// apresenta os itens dos planos e mostra opções para inserção
function PlanoItens({
    
}){

    return <div
        className={styles.container}
    >
        {/* corpo do plano alimentar(onde os alimentos são lançados, documento, prescrição) */}
        <div
            className={styles.refeicoes}
        >
            <ContainerRefeicoes 

            />
        </div>
        <div
            className={styles.alimentos}
        >
            <ConsultaAlimentos 

            />
        </div>
            

        {/* consulta de alimentos/receitas */}
    </div>

}

export default PlanoItens;