import React from 'react';
import moment from 'moment';

// componentes
import BotaoTabela from './../../../../ComponentesCadastro/BotaoTabela';
import { calcularDados } from './../../Cadastrar';

function Registro({
    dados,
    dadosPaciente,
    parametros,
    clicouEditar
}){

    // tipo
    let tipo = '';
    switch(dados.tipo){
        case '1': tipo = 'Faulkner';break;
        case '2': tipo = 'Pollock';break;
    }
    let calculos = calcularDados(dados, dadosPaciente);

    return <tr>
        {/* <td className="estreito text-center font-weight-bold">
            {dados.pk_cad_rastreamento_metabolico}
        </td> */}
        <td className="estreito text-center font-weight-bold">
            {moment(dados.data_cadastro).format('DD/MM/YYYY')}
        </td>
        <td>{tipo}</td>
        {/* <td>{calculos.pesoGordo.toFixed(3)}</td> */}
        <td>{parseFloat(dados.pa).toFixed(3)}</td>
        <td>{dados.altura}</td>
        <td>{calculos.imc.toFixed(2)}</td>
        <td>{calculos.rcq.toFixed(2)}</td>
        <td>{calculos.gordura.toFixed(3)}</td>
        <td>{calculos.massaMagra.toFixed(3)}</td>
        <td className="text-nowrap estreito">
            <BotaoTabela 
                onClick={clicouEditar}
                icone={["fas", "pencil-alt"]}
            >
                Editar
            </BotaoTabela>
        </td>
    </tr>
}

export default Registro;