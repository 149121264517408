import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Table, Button } from 'react-bootstrap';
import OpcoesAtendimento from './../../Atender/OpcoesAtendimento';
import Registro from './Registro';

// botão para cadastro
export function BioimpedanciaMagneticaBotaoCadastro({
    idpaciente,
    idagendamento
}){

    // estados
    let history = useHistory();
    
    return <Button
        variant='padrao'
        size='sm'
        onClick={e => {
            e.stopPropagation();
            history.push(`/painel/paciente/${idpaciente}/bioimpedancia-magnetica/cadastrar/${idagendamento}`);
        }}
    >
        <FontAwesomeIcon className="icone" icon={["fas", "plus"]}></FontAwesomeIcon>
        <span>Cadastrar</span>
    </Button>
}

// dados do titulo
export function BioimpedanciaMagneticaTitulo(){

    return {
        icone: ['fas', 'weight'],
        descricao: 'Bioimpedancia Magnética'
    }
}

export default function Consultar({
    idpaciente=null,
    idagendamento=null,
    expandido=false
}){

    // estados
    let history = useHistory();
    let login = useSelector(state => state.login);
    let parametrosUrl = useParams();
    let [parametros, alterarParametros] = useState({
        // pagina: 1,
        // registrosPorPagina: 10,
        fk_cad_paciente: idpaciente || parametrosUrl.idpaciente
    });
    let [consulta, alterarConsulta] = useState([]);
    let [criandoPdf, alterarCriandoPdf] = useState(false);

    // inicializa
    useEffect(() => {
        consultarRegistros();
    }, [parametros]);

    // consulta os registros
    async function consultarRegistros(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/bioimpedanciaMagnetica`, { params: parametros });
            alterarConsulta(data.registros);

        }catch({response}){}

    }

    // cria pdf
    async function gerarImpressao(){
        alterarCriandoPdf(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/bioimpedanciaMagnetica/impressao`, { params: parametros });
            window.open(
                // `${process.env.REACT_APP_URL_PHP}/download.php?p=temp&f=${encodeURIComponent(data.arquivo)}&n=${encodeURIComponent(data.nome)}`,
                `${process.env.REACT_APP_URL_ARQUIVOS}/download.php/${encodeURIComponent(data.nome)}?p=temp&f=${encodeURIComponent(data.arquivo)}&n=${encodeURIComponent(data.nome)}`,
                '_blank'
            );

        }catch({response}){

        }finally{
            alterarCriandoPdf(false);
        }

    }

    return <div className={styles.atendimentoConsulta}>
        { !expandido &&
            <div>
                <OpcoesAtendimento 
                    minificado={true}
                    abaVoltar='bioimpedancia-magnetica'
                    idpaciente={idpaciente}
                    idagendamento={idagendamento}
                />
            </div>
        }
        <div className={styles.containerPainel}>
            <Card className={'painel-consulta border-0 shadow-sm'}>
                <Card.Header className='d-flex'>
                    <h4 className='flex-grow-1'>
                        <FontAwesomeIcon className="icone mr-2" icon={BioimpedanciaMagneticaTitulo().icone} />
                        <span>{BioimpedanciaMagneticaTitulo().descricao}</span>
                    </h4>
                    <div>
                        <Button
                            variant='info'
                            size='sm'
                            className='mr-3'
                            disabled={criandoPdf}
                            onClick={gerarImpressao}
                        >   
                            { criandoPdf ?
                                <FontAwesomeIcon className="icone" pulse icon={['fas', 'spinner']} />
                                :
                                <FontAwesomeIcon className="icone" icon={['fas', 'print']} />
                            }
                            <span>Impressão</span>
                        </Button>
                        <BioimpedanciaMagneticaBotaoCadastro 
                            idpaciente={parametros.fk_cad_paciente}
                            idagendamento={idagendamento || parametrosUrl.idagendamento}
                            history={history}
                        />
                    </div>
                </Card.Header>
                <Card.Body>
                    <Table hover className={["tabela", styles.tabelaBioimpedanciaMagnetica].join(' ')}>
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th title='Percentual de gordura corporal total' >PGC</th>
                                <th title='Peso de gordura corporal total'>PGC</th>
                                <th title='Indice de massa corporal'>IMC</th>
                                <th title='Taxa de metabolismo basal'>TMB</th>
                                <th title='Peso de massa corporal magra'>PMCM</th>
                                <th title='Peso ideal'>Peso<br /> Ideal</th>
                                <th title='Percentual de massa magra ideal'>PMMI</th>
                                <th title='Quantidade de água corporal'>QAC</th>
                                <th title='Percentual de água corporal'>PAC</th>
                                <th title='Percentual adequado de água'>Percentual <br/>adequado<br/> de água</th>
                                <th>Ações</th>
                            </tr>
                            <tr>
                                <th></th>
                                <th className='tc' >%</th>
                                <th className='tc'>Kg</th>
                                <th className='tc'></th>
                                <th className='tc'>kcal</th>
                                <th className='tc'>%</th>
                                <th className='tc'>kg</th>
                                <th className='tc'>+-%</th>
                                <th className='tc'>litros</th>
                                <th className='tc'>%</th>
                                <th className='tc'>%</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            { consulta.map(registro =>  
                                <Registro 
                                    key={registro.dados.pk_cad_bioimpedancia_magnetica}
                                    dados={registro.dados}
                                    parametros={parametros}
                                    clicouEditar={() => {
                                        history.push(`/painel/paciente/${parametros.fk_cad_paciente}/agendamento/${idagendamento || parametrosUrl.idagendamento}/bioimpedancia-magnetica/alterar/${registro.dados.pk_cad_bioimpedancia_magnetica}`)
                                    }}
                                />
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </div>
    </div>
}

