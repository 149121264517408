// componente para cadastro/ateração de plano alimentar
import React, { useState, useEffect,  } from 'react';
import styles from './index.module.scss';
import { DragDropContext } from 'react-beautiful-dnd';
import uniqid from 'uniqid';
import { useDispatch } from 'react-redux';

// plano antigo link para testes
// http://localhost:3001/painel/paciente/683719/plano-alimentar/cadastrar/1517
// plano antigo edição
// http://localhost:3001/painel/paciente/683719/agendamento/1517/plano-alimentar/alterar/3

// componentes
import { EditorState, convertFromRaw } from 'draft-js';
import MenuOpcoes from './MenuOpcoes';
import PlanoItens from './PlanoItens';
import PlanoNutrientes from './PlanoNutrientes';
import ReceitaModalCadastrar from './../Receita/ModalCadastrar';
import ModalCadastrarMedidaCaseira from './ModalCadastrarMedidaCaseira';
import ModalCadastrarAlimento from './ModalCadastrarAlimento';


import { convertToRaw, convertFromHTML, ContentState, Modifier } from 'draft-js';


// contexto
export const PlanoAlimentarContexto = React.createContext(null);

// componente cadastro de plano alimentar
export default function Cadastrar({
    refeicoesInicial=[],
    salvou,
    salvando,
    cancelou,
    fazerImpressaoTexto,
    fazendoImpressaoTexto,

    // transcrições
    documento=null,
    prescricao=null,
}){

    // cria estados de contexto
    const dispatch = useDispatch();
    let [modo, alterarModo] = useState('plano'); // plano, nutrientes ==> altera modo de visualização do plano
    let [refeicoes, alterarRefeicoes] = useState(refeicoesInicial);
    let [refeicaoFocada, alterarRefeicaoFocada] = useState(null);
    let [consulta, alterarConsulta] = useState([]); // consulta geral de alimentos/receitas 
    let [cadastrarReceita, alterarCadastrarReceita] = useState(null); // objeto 
    let [cadastrarMedidaCaseira, alterarCadastrarMedidaCaseira] = useState(null);
    let [cadastrarAlimento, alterarCadastrarAlimento] = useState(null);
    let [refeicaoAba, alterarRefeicaoAba] = useState('refeicoes');

    // // // documentos
    // let documentoConvertido = convertFromRaw(JSON.parse(documento));
    // let [documentoJson, alterarDocumentoJson] = useState(EditorState.createWithContent(documentoConvertido));
    let [documentoJson, alterarDocumentoJson] = useState(documento);
    let [prescricaoJson, alterarPrescricaoJson] = useState(prescricao);
    // 
    
    // inicializa
    useEffect(() => {
        // console.log(refeicoes);
        // minimiza o menu por padrão para deixa o ambiente mais amplo
        dispatch({
            type: 'painel/ALTERAR_MENU_ENCOLHIDO',
            estado: true
        });

    }, []);

    // useEffect(() => {
    //     console.log('alterou salvando');
    //     alterarSalvandoLocal(salvando);
    // }, [salvando]);

    // quando soltar algum item
    function onDragEnd(retorno){

        // sem destino, ignora
        if (!retorno.destination) {
            return;
        }
        
        // pega dados
        let {source, destination} = retorno;
        // console.log(retorno.type);

        // se for refeição, altera a mesma de lugar
        if(retorno.type === 'refeicao'){
            let [removido] = refeicoes.splice(source.index, 1);
            refeicoes.splice(destination.index, 0, removido);

        }else if (retorno.type === 'itens'){

            // é um item
            // se for item novo, inclui na posição
            if(source.droppableId === 'item-novo'){
                
                // busca a posição onde foi soltado o mesmo e inclui
                incluirItemNovoAoSoltar(source, destination);

            }else{

                // troca de posição
                let removido = null;
                refeicoes.forEach((refeicao, a) => {
                    refeicao._opcoes.forEach((item, b) => {
                        if(`itens-${a}-${b}` === source.droppableId){
                            [removido] = item.splice(source.index, 1);
                        }
                    });
                      
                });
                
                refeicoes.forEach((refeicao, a) => {
                    refeicao._opcoes.forEach((item, b) => {
                        if(`itens-${a}-${b}` === destination.droppableId){
                            item.splice(destination.index, 0, removido);
                        }
                    });
                    
                });

            }


        }

        // se for item, verifica qual ação tomar


        // altera as refeições
        alterarRefeicoes([...refeicoes]);

        // arrastou
        // console.log('organizar os registros');
    }

    // incluir item novo
    function incluirItemNovoAoSoltar(source, destination){

        // verifica refeição por refeição para encontrar a que foi soltado o item
        refeicoes.forEach((refeicao, a) => {

            // procura opção por opção buscando onde foi largado
            refeicao._opcoes.forEach((item, b) => {
                if(`itens-${a}-${b}` === destination.droppableId){

                    // pega os dados do item através do index
                    let itemIndex = {...consulta[source.index]};
                    item.splice(destination.index, 0, inserirItemNovo(itemIndex));
                }
            });
        });

    }

    // insere item na refeição destacada
    function incluirItemNovoDestacado(dadosItem){

        // verifica a aba
        if(refeicaoAba === 'documento' || refeicaoAba === 'prescricao'){

            let valorJson = documentoJson;
            if(refeicaoAba === 'prescricao'){
                valorJson = prescricaoJson;
            }

            if(valorJson === null){
                valorJson = JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent()));
            }

            // converte dados do documento
            let converterDocumento = convertFromRaw(JSON.parse(valorJson));
            let conteudoEditor = EditorState.createWithContent(converterDocumento);

            // pega o texto a ser inserido
            let textoInserir = '';
            if(dadosItem.categoria === 'A'){
                textoInserir = dadosItem.descricao;
            }
            if(dadosItem.categoria === 'R'){
                textoInserir = `${dadosItem._dadosReceita.titulo}\n ${dadosItem._dadosReceita.descricao}`;
            }
            
            // editor
            const contentState = Modifier.replaceText(
                conteudoEditor.getCurrentContent(),
                conteudoEditor.getSelection(),
                textoInserir
            );

            // aplica alterações
            conteudoEditor = EditorState.push(conteudoEditor, contentState, 'insert-characters');
            let json = JSON.stringify(convertToRaw(conteudoEditor.getCurrentContent()));

            if(refeicaoAba === 'documento'){
                alterarDocumentoJson(json);
            }else if(refeicaoAba === 'prescricao'){
                alterarPrescricaoJson(json);
            }
                
            // retorna
            return;
        }

        // se nenhuma tiver focada, retorna
        if(refeicaoFocada === null){
            return;
        }

        // pega os dados do item novo
        let itemNovo = inserirItemNovo(dadosItem);

        // verifica refeição por refeição para encontrar a que foi soltado o item
        refeicoes.forEach((refeicao, a) => {

            // refeição focada
            if(refeicaoFocada === refeicao){

                // se possuir opções
                if(refeicao._opcoes.length > 0){
                    refeicao._opcoes[refeicao._opcoes.length - 1].push(itemNovo);
                }
            }
        });
        
        // altera as refeições
        alterarRefeicoes([...refeicoes]);
    }

    // inserir item novo
    function inserirItemNovo(dadosItem){
        
        // insere item
        let itemNovo = {
            pk_cad_plano_alimentar_bloco_item: null,
            codigo: dadosItem.codigo,
            fk_cad_alimento_medida_caseira: null,
            descricao: dadosItem.descricao,
            categoria: dadosItem.categoria,
            _tempId: `_${uniqid()}`
        };

        // se for alimento,
        if(dadosItem.categoria === 'A'){
            itemNovo.quantidade = '1';
            itemNovo._dadosAlimento = Object.assign({}, dadosItem._dadosAlimento);
        }

        // se for receita
        if(dadosItem.categoria === 'R'){
            itemNovo._dadosReceita = Object.assign({}, dadosItem._dadosReceita);
            itemNovo.descricao_receita = null;
        }

        // retorna item pronto
        return itemNovo;

    }

    // cria item do tipo comentário
    function criarItemComentario(){
        return {
            pk_cad_plano_alimentar_bloco_item: null,
            descricao: 'Clique para editar!',
            tipo: 'T',
            _tempId: `_${uniqid()}`
        };
    }

    // quando é cadastrado medida caseira, insere 
    //medida em todos os itens semelhantes encontrados no plano
    function incluirMedidaCaseiraAlimentos(dadosMedida){

        // faz a busca por todos os itens e insere no item
        refeicoes.forEach((refeicao, a) => {

            // loop pelas opçoes
            refeicao._opcoes.forEach((opcao, b) => {

                // itens da opção
                opcao.forEach((item, c) => {

                    // se o item for um alimento
                    if(item.categoria === 'A'){

                        // se o item for compativel, incrementa
                        if(item._dadosAlimento.pk_cad_alimento === dadosMedida.fk_cad_alimento){
                            item._dadosAlimento._medidas.push(dadosMedida);
                        }
                    }

                });
            });
        });

        // Obs.: se for atualizar na consulta de alimentos também
        // verificar se a medida já não está presente!

        // Obs2.: Se for editar, não deixar editar quando fk_cad_empresa null

    }

    // transcrever dados da receita para o plano
    function transcreverTextoLivre(){

        alterarDocumentoJson(criaTranscricaoRefeicoes());

    }

    // retorna a transcrição das refeições do plano alimentar
    function criaTranscricaoRefeicoes(){

        // array com textos a ser incluido
        let textoIncluir = [
            {text: '\n- Refeições\n', type: 'header-four'}
        ];
        let receitas = [
        ];

        // verifica os dados da refeição para incluir
        refeicoes.forEach(refeicao => {

            // pega os dados da refeição
            textoIncluir.push({
                text: refeicao.descricao,
                type: 'header-five'
            });

            // verifica a refeição
            refeicao._opcoes.forEach((opcao, a) => {

                

                // soma as calorias
                let somarKcal = 0.0;
                let totalKcal = 0.0;
                let alimentosOpcao = '';

                // pega cada item da opção
                opcao.forEach((item, b) => {

                    // dados da medida caseira
                    let medidaCaseira = '';
                    if(item.categoria === 'A'){

                        // busca medida
                        let dadosMedida = item._dadosAlimento._medidas.find(medida => {
                            if(item.fk_cad_alimento_medida_caseira === medida.pk_cad_alimento_medida_caseira){
                                return true;
                            }
                            return false;
                        });

                        // cria medida caseira
                        let quantidade = parseFloat(item.quantidade).toString().replace('.',',');
                        medidaCaseira += `${quantidade} ${dadosMedida ? `${dadosMedida.descricao}(${dadosMedida.quantidade}g/ml)` : ''} de `;
                        
                        // calcula o total de calorias
                        if(dadosMedida){
                            let quantidadeMedida = quantidade * parseFloat(dadosMedida.quantidade);
                            let porcao = parseFloat(item._dadosAlimento.porcao);
                            let energia = parseFloat(item._dadosAlimento.energia);
                            totalKcal += (energia / porcao) *  quantidadeMedida;
                        }
                    }

                    // se for receita, inclui a receita no final do documento
                    if(item.categoria === 'R'){

                        // inclui dados da receita no array de receitas
                        receitas.push({
                            text: item.descricao,
                            type: 'header-six'
                        });
                        receitas.push({
                            text: item.descricao_receita || item._dadosReceita.descricao
                        });

                        // total de calorias
                        totalKcal += parseFloat(item._dadosReceita.energia);
                    }

                    // inclui dados do item
                    alimentosOpcao += `- ${medidaCaseira}${item.descricao}`;

                    // se não for o último item, concatena uma virgula
                    if(b < (opcao.length - 1)){
                        alimentosOpcao += ', ';
                    }

                });

                // inclui texto da opção
                textoIncluir.push({
                    text: `- Opção ${a+1} - ${totalKcal.toFixed(2).replace('.', ',')} Kcal`
                });

                // inclui alimentos da opção
                textoIncluir.push({
                    text: alimentosOpcao
                });

            });
        });

        // se não for incluido nenhum texto, ignora
        if(textoIncluir.length === 0){
            return;
        }

        // alterar dados do json, zerando tudo que possui
        let jsonAlterar = {
            blocks: [
                ...textoIncluir, 
                {text: '\n- Receitas\n', type: 'header-four'},
                ...receitas],
            entityMap: {}
        };

        // retorna objeto
        return JSON.stringify(jsonAlterar);
    }

    // não terá relação nenhuma com o plano alimentar
    return <>

        {/* cadastrar receita */}
        { cadastrarReceita && 
            <ReceitaModalCadastrar 
                {...cadastrarReceita}
                fechou={() => {
                    alterarCadastrarReceita(null);
                }}
            />
        }

        {/* cadastrar alimento */}
        { cadastrarAlimento &&
            <ModalCadastrarAlimento 
                {...cadastrarAlimento}
                fechou={() => {
                    alterarCadastrarAlimento(null);
                }}
            />
        }

        {/* cadastrar medida caseira */}
        { cadastrarMedidaCaseira && 
            <ModalCadastrarMedidaCaseira
                {...cadastrarMedidaCaseira}
                salvou={dadosMedida => {
                    incluirMedidaCaseiraAlimentos(dadosMedida);
                }}
                fechou={() => {
                    alterarCadastrarMedidaCaseira(null);
                }}
            />
        }

        <PlanoAlimentarContexto.Provider
            value={{
                refeicoes, alterarRefeicoes,
                refeicaoFocada, alterarRefeicaoFocada,
                modo, alterarModo,
                salvando,
                consulta, alterarConsulta,
                salvou, cancelou,
                criarItemComentario,
                cadastrarReceita, alterarCadastrarReceita,
                cadastrarMedidaCaseira, alterarCadastrarMedidaCaseira,
                cadastrarAlimento, alterarCadastrarAlimento,
                documentoJson, alterarDocumentoJson,
                prescricaoJson, alterarPrescricaoJson,
                transcreverTextoLivre, incluirItemNovoDestacado,
                fazerImpressaoTexto, fazendoImpressaoTexto,
                refeicaoAba, alterarRefeicaoAba,
                criaTranscricaoRefeicoes
            }}
        >
            <DragDropContext onDragEnd={onDragEnd} >

                {/* componentes do plano */}

                {/* apresenta opções, salvar, cancelar... */}
                <div className={styles.planoBase}>
                    <div
                        className={styles.menuOpcoes}
                    >
                        <MenuOpcoes />
                    </div>
                    <div
                        className={styles.planoDados}
                    >
                        { modo === 'plano' &&
                            <PlanoItens />
                        }

                        { modo === 'nutrientes' &&
                            <PlanoNutrientes />
                        }
                        
                    </div>
                </div>

            </DragDropContext>
        </PlanoAlimentarContexto.Provider>
    </>
    

}