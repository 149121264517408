import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';

// componentes
import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import DiasSemana from './DiasSemana';
import Horario from './Horario';

// retorna
export default function Jornada(){

    // estados
    let login = useSelector(state => state.login);
    let { id } = useParams();
    let [diaSemana, alterarDiaSemana] = useState(2);
    let [horarios, alterarHorarios] = useState([]);

    // inicializa
    useEffect(() => {
        consultarRegistros();
    }, [diaSemana]);

    // faz a consulta 
    async function consultarRegistros(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/jornada`, { params: {
                fk_cad_usuario_empresa: id,
                dia_semana: diaSemana,
                status: ['A', 'I']
            }});
            alterarHorarios(data.registros);

        }catch({response}){}
        finally{

        }

    }

    // adiciona horario novo
    function adicionarHorario(){

        let novoHorario = {
            pk_cad_jornada: null,
            fk_cad_usuario_empresa: id,
            fk_cad_procedimento: null,
            dia_semana: '2',
            hora_inicio: null,
            hora_final: null,
            duracao: null,
            status: 'A'
        };
        alterarHorarios([...horarios, novoHorario]);

    }

    // retorna
    return <>
        <Card className={'border-0 shadow-sm'}>
            <Card.Body>
                <DiasSemana 
                    value={diaSemana}
                    onChange={dia => {
                        alterarDiaSemana(dia);
                    }}
                />
                <div className={['mt-4 d-flex', styles.agendamentos].join(' ')}>
                    {horarios.map((horario, i) => 
                        <Horario 
                            key={horario.pk_cad_jornada || `t${i}`}
                            value={horario}
                            excluido={() => {
                                horarios.splice(i, 1);
                                alterarHorarios([...horarios]);
                            }}
                        />
                    )}
                </div>
                <div>
                    <Button
                        variant='padrao'
                        onClick={adicionarHorario}
                    >
                        Adicionar Horario
                    </Button>
                </div>
            </Card.Body>
        </Card>
    </>
}