import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from './index.module.scss';
import Moment from 'react-moment';

// icones
import IconeDoc from './icones/doc.png';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, OverlayTrigger, ButtonToolbar, Tooltip  } from 'react-bootstrap';

export default function Arquivo({
    dados
}){

    // estados
    let [executando, alterarExecutando] = useState(false);
    let [confirmarExclusao, mostrarConfirmarExclusao] = useState(false);

    // faz a exclusão do arquivo
    function excluir(){
        // alterarExecutando(true);
        // ArquivosServico.excluir({
        //     id: arquivo.id
        // }, login.dadosUsuario.token)
        // .then(() => {
        //     alterarExecutando(false);
        //     excluido(arquivo);
        // });
    }


    // 
    return <div className={[styles.arquivo, 'p-2 rounded'].join(' ')}>

        { dados.nome.split('.').pop() === 'jpg' ?
            <img src={`${process.env.PUBLIC_URL}/php/arquivos/anexos/${dados.fk_cad_empresa}/${dados.arquivo}`} className={[styles.icone, 'mr-2'].join(' ')} />
            :
            <img src={IconeDoc} className={[styles.icone, 'mr-2'].join(' ')} />
        }

        <div className={styles.descricao}>
            <div title={dados.nome} className={[styles.nome, 'text-truncate'].join(' ')}>{dados.nome}</div>
            {!confirmarExclusao &&
                <div><small className='text-secondary'><Moment format='DD/MM/YYYY'>{dados.data_cadastro}</Moment></small></div>
            }
            <div className={[styles.opcoesArquivo, 'p-2'].join(' ')}>

                <OverlayTrigger
                    placement='top'
                    overlay={<Tooltip>Abrir Arquivo</Tooltip>}
                >
                    <a 
                        href={`${process.env.REACT_APP_URL_PHP}/arquivos/anexos/${dados.fk_cad_empresa}/${dados.arquivo}`} 
                        target="_blank" 
                        download={true}
                        className={[styles.opcao, 'rounded ml-1 pb-1 px-1'].join(' ')}
                    >
                        <FontAwesomeIcon className={styles.iconeOpcao} icon={["fas", 'download']} />
                    </a>
                </OverlayTrigger>

                { confirmarExclusao && 
                    <>
                        <Button 
                            variant='danger' 
                            size='sm' 
                            className={[styles.opcaoExclusao, 'px-2 py-0 mr-1'].join(' ')}
                            onClick={() => {mostrarConfirmarExclusao(false)}}
                        >
                            <FontAwesomeIcon className={styles.iconeOpcaoExcluir} icon={["fas", 'times']} />
                            <span>Cancelar</span>
                        </Button>
                        <Button 
                            variant='success' 
                            size='sm' 
                            className={[styles.opcaoExclusao, 'px-2 py-0'].join(' ')}
                            onClick={excluir}
                        >
                            <FontAwesomeIcon className={styles.iconeOpcaoExcluir} icon={["fas", 'check']} />
                            <span>Confirmar</span>
                        </Button>
                    </>
                }

                {/* EXCLUIR */}
                { (!confirmarExclusao) &&
                    <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip>Excluir Arquivo</Tooltip>}
                    >
                        <button
                            className={[styles.opcao, 'rounded ml-1'].join(' ')}
                            onClick={() => {mostrarConfirmarExclusao(true)}}
                        >
                            <FontAwesomeIcon className={styles.iconeOpcao} icon={["fas", 'trash']} />
                        </button>
                    </OverlayTrigger>
                }
                
            </div>
        </div>
        { executando && 
            // <Carregador 
            //     className='rounded bg-light text-body'
            // />
            <p>Excluindo...</p>
        }
    </div>
}

export function ArquivoUpload({
    dados
}){

    // return <div>{dados.progresso}</div>

    return <div className={[styles.arquivo, styles.salvando, 'p-2 rounded shadow-sm'].join(' ')}>
        
        <div className={[styles.barra, 'rounded'].join(' ')} style={{width: `${dados.progresso || 0}%`}}><span>{dados.progresso || 0}%</span></div>
        
        <img src={IconeDoc} className={[styles.icone, 'mr-2'].join(' ')} />
        <div className={styles.descricao}>
            <div className={[styles.nome, 'text-truncate'].join(' ')} title={dados.arquivo.name}>{dados.arquivo.name}</div>
            <div><small className='text-secondary'>Salvando arquivo...</small></div>
        </div>
        
    </div>
}