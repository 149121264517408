import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import axios from 'axios';

// componentes
import { Row, Col, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LimitacoesCards from './LimitacoesCards';
import Planos from './Planos';

// retorna assinatura função
export default function Assinatura({
    login,
    history
}){

    // estados
    let [carregando, alterarCarregando] = useState(true);
    let [dados, alterarDados] = useState({
        pk_sis_forma_pagamento: null
    });
    let [formasPagamento, alterarFormasPagamento] = useState(null);

    // carrega as formas de pagamento
    useEffect(() => {

        // faz a requisição
        consultarFormas();

    }, []);

    // consultar as formas de pagamento
    async function consultarFormas(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/formaPagamento`);
            alterarFormasPagamento(data.registros);
            alterarCarregando(false);

        }catch({response}){}
    }

    // se estiver carregando
    if(carregando){
        return <div>Carregando...</div>
    }

    // componente
    return <>

        {/* diferença das versões */}
        <LimitacoesCards />

        {/* planos valores */}
        <Planos 
            formasPagamento={formasPagamento}
            idFormaPagamento={dados.pk_sis_forma_pagamento}
            alterou={novoIdPlano => {
                dados.pk_sis_forma_pagamento = novoIdPlano;
                alterarDados({...dados});
            }}
        />

        <div className='text-right mt-4'>
            <Button
                variant='warning'
                size='lg'
                disabled={dados.pk_sis_forma_pagamento === null ? true : false}
                onClick={() => {
                    history.push('/painel/assinatura/pagamento', {
                        pk_sis_forma_pagamento: dados.pk_sis_forma_pagamento
                    });
                }}
            >
                <span>Continuar para pagamento</span>
                <FontAwesomeIcon className="icone" icon={["far", 'credit-card']} />
            </Button>
        </div>
    </>

}
