import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import axios from 'axios';

// componentes
import { Form, Card, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputHora from './../../../ComponentesCadastro/InputHora';

// serviços
import JornadaServico from './../../../../../servicos/JornadaServico';

// horario
export default function Horario({
    value,
    excluido
}){

    // estados
    let login = useSelector(state => state.login);
    // let [valorInicial, alterarValorInicial] = useState(value);
    let [dados, alterarDados] = useState(value);
    let [salvando, alterarSalvando] = useState(false);
    let [erros, alterarErros] = useState({});

    // salvar
    async function salvar(){

        // salvando
        alterarErros({});
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/jornada`, dados);
            let horaSalva = {...data};
            alterarDados(horaSalva)

        }catch({response}){
            if(response){
                if(response.status === 400){
                    alterarErros(response.data);
                }
            }
        }finally{
            alterarSalvando(false);
        }

    }

    // excluir
    async function excluir(){

        // exclui
        alterarSalvando(true);

        // verifica se é novo
        if(dados.pk_cad_jornada === null){
            excluido();
        }else{

            try{

                // faz a requisição
                let { data } = await axios.delete(`/jornada`, { data: dados });
                excluido();

            }catch({response}){}

        }

    }

    return <div className={[styles.horario, 'p-2'].join(' ')}>
        <Card className={['border-0 bg-light'].join(' ')}>
            <Card.Body className={''}>
                <b>Horário de atendimento</b>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Inicio</Form.Label>
                            <InputHora 
                                value={dados.hora_inicio}
                                onChange={novaHora => {
                                    dados.hora_inicio = novaHora;
                                    alterarDados({...dados});
                                }}
                                className='text-center'
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Fim</Form.Label>
                            <InputHora 
                                value={dados.hora_final}
                                onChange={novaHora => {
                                    dados.hora_final = novaHora;
                                    alterarDados({...dados});
                                }}
                                className='text-center'
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Duração</Form.Label>
                            <InputHora 
                                value={dados.duracao}
                                onChange={novaHora => {
                                    dados.duracao = novaHora;
                                    alterarDados({...dados});
                                }}
                                className='text-center'
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
                {erros.horario &&
                    <p>{erros.horario}</p>
                }

                {/* <hr /> */}
                
                {/* CRIAR COMPONENTE COMBO DE CONSULTA DE PROCEDIMENTO IGUAL NOME PACIENTE */}
                {/* <Form.Group>
                    <Form.Label>Procedimento</Form.Label>
                    <Form.Control value={dados.fk_cad_procedimento} />
                </Form.Group> */}

                <div className='d-flex justify-content-between'>

                    <div>
                        <div className={'text-left'}>
                            <Form.Check 
                                type="switch"
                                id="switch-atender"
                                label="Atender nesse horario?"
                                checked={dados.status === 'A' ? true : false}
                                onChange={e => {
                                    dados.status = e.target.checked ? 'A' : 'I';
                                    alterarDados({...dados});
                                }}
                            />
                        </div>
                    </div>
                    
                    <div className={['', styles.opcoes].join(' ')}>
                        <Button
                            variant={'padrao'}
                            size='sm'
                            className={'m-1'}
                            disabled={salvando}
                            onClick={excluir}
                        >
                            { salvando ? 
                                <FontAwesomeIcon className="icone fa-fw" pulse icon={["fas", "spinner"]} />
                                :
                                <FontAwesomeIcon className="icone fa-fw" icon={["fas", "trash"]} />
                            }
                            
                            <span>Excluir</span>
                        </Button>

                        <Button
                            variant={'padrao'}
                            size='sm'
                            className={'m-1'}
                            onClick={salvar}
                            disabled={salvando}
                        >
                            { salvando ?
                                <FontAwesomeIcon className="icone fa-fw" pulse icon={["fas", "spinner"]} />
                                :
                                <FontAwesomeIcon className="icone fa-fw" icon={["fas", "save"]} />
                            }
                            
                            <span>Salvar</span>
                        </Button>
                    </div>
                </div>


            </Card.Body>
        </Card>
    </div>

}